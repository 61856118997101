import React from 'react';

import './TrendsWorkstation.scss'
import '../pages/Trends.scss'
import './App.scss'
import GlobalContext from './GlobalContext';

import { capitalizeFirstLetter, fetchPlayerRecord, getDictionaryValue, getGameResultText, getPlayerId, getPlayerName, getTeamGameResultText, hasPremiumAccess, isDictEmpty, parseDateFromString, safe } from '../linemate-react-common/src/util';
import { API_HOST } from '../react-web-constants';
import { buildTrendsData, getMarketLineValue, getPlayerWorkstationGames, getTeamWorkstationGames } from '../linemate-react-common/src/betting-utils';
import Button from './Button';
import { buildGraphData, getTeamLogoPath, getLeagueLogoPath, teamLogoPathOnErrorFallback } from '../react-web-utils';
import { BET_TYPE_FILTER, FILTER_QUERY_PARAMETER_LOCATION_VALUE, getGamelogSupportingStats, getPlayerPositionalBetTypes, getPlayerPropInfo, OPPONENT_FILTER, OUTCOME_TYPE_COVER, OUTCOME_TYPE_COVER_YES, OUTCOME_TYPE_OVER_UNDER, PLAYER_BET_TYPE_ID_TO_NAME, PLAYER_BET_TYPES_REVERSE_MAPPING, SCREENER_ONLY_PROPS, SCREENER_TEAM_SCORING_STATS, TEAM_BET_TYPE_REVERSE_MAPPING, TEAM_BETS, TIMEFRAME_FILTER, WITH_FILTER, WITHOUT_FILTER } from '../linemate-react-common/src/constants';
import InputSelection from './InputSelection';
import HitRateChart from './HitRateChart';
import _ from 'lodash';
import Table from './Table';
import { swapDictionaryKeyValue } from '../linemate-react-common/src/core-utils.js';

// TODO: extract this and re-use between this and app
//  The swapDictionaryKeyValue is a bit of laziness copied from app code since the filters work backwards here and in app for options
const baseFilters = (currentSeason, hasOpponent, configurationTeamsFilter) => {
    var filters = {}
    filters[TIMEFRAME_FILTER] = {
        options: swapDictionaryKeyValue({
            "Season": `SEASON_${currentSeason}`,
            "Head to Head": "N/A",
            "Last 5": "LAST_5",
            "Last 10": "LAST_10",
            "Last 20": "LAST_20",
            "Last 30": "LAST_30"
        }),
        selectedValue: "LAST_10",
        filterType: "single",
        affectsQuery: true
    }
    // TODO: key constant for split
    filters["split"] = {
        options: swapDictionaryKeyValue({
            "Home+Away": "ALL",
            "Home": "HOME",
            "Away": "AWAY"
        }),
        selectedValue: "ALL",
        filterType: "single",
        affectsQuery: true
    }
    // Remove the matchup option for timeframe and add the opponent filter if we have no game/opponent
    if (!hasOpponent) {
        // The N/A is for H2H
        delete filters[TIMEFRAME_FILTER]['options']['N/A'];
        const swappedTeamsFilter = _.cloneDeep(configurationTeamsFilter)
        swappedTeamsFilter.options = swapDictionaryKeyValue(swappedTeamsFilter.options)
        filters[OPPONENT_FILTER] = swappedTeamsFilter;
        filters[OPPONENT_FILTER].selectedValue = Object.keys(swappedTeamsFilter.options)[0]
        filters[OPPONENT_FILTER].affectsQuery = true
        filters[OPPONENT_FILTER].queryParameterLocation = FILTER_QUERY_PARAMETER_LOCATION_VALUE
    }
    filters[WITH_FILTER] = {
        options: {
            'all': 'All'
        },
        selectedValue: "all",
        filterType: "single",
        affectsQuery: true,
        queryParameterLocation: FILTER_QUERY_PARAMETER_LOCATION_VALUE,
        premium: true
    }
    filters[WITHOUT_FILTER] = {
        options: {
            'none': 'None'
        },
        selectedValue: "none",
        filterType: "single",
        affectsQuery: true,
        queryParameterLocation: FILTER_QUERY_PARAMETER_LOCATION_VALUE,
        premium: true
    }
    return filters;
}

const defaultFilters = {
  team: (currentSeason, league, hasOpponent, configurationTeamsFilter) => {
    var teamFilters = {}
    const betTypes = Object.keys(TEAM_BET_TYPE_REVERSE_MAPPING[league]);
    teamFilters[BET_TYPE_FILTER] = {
        options: Object.fromEntries((betTypes).map(x => [x,x])),
        selectedValue: betTypes[0],
        filterType: "single",
        affectsQuery: false
    }
    return Object.assign({}, teamFilters, baseFilters(currentSeason, hasOpponent, configurationTeamsFilter));
  },
  player: (league, currentSeason, playerPosition, hasOpponent, configurationTeamsFilter) => {
    var playerFilters = {}
    const betTypes = getPlayerPositionalBetTypes[league](playerPosition).filter(prop => !SCREENER_ONLY_PROPS[league].includes(prop));
    playerFilters[BET_TYPE_FILTER] = {
        options: Object.fromEntries((betTypes).map(x => [x,x])),
        selectedValue: betTypes[0],
        filterType: "single",
        affectsQuery: false
    }
    return Object.assign({}, playerFilters, baseFilters(currentSeason, hasOpponent, configurationTeamsFilter));
  }
}

// The key/value order is swapped between web and app so the selected value needs to be swapped too
// It's not ideal but at this point it would take too long to make both match, we'll do that later
// TODO: maybe we should swap it while we haven't gone too far into it
function updateSelectedValues(filters) {
  const updatedFilters = {};
  // const updatedFilters = filters;
  Object.keys(filters).forEach((key) => {
      updatedFilters[key] = _.cloneDeep(filters[key]);
      updatedFilters[key].options = swapDictionaryKeyValue(updatedFilters[key].options);
      updatedFilters[key].selectedValue = filters[key].options[filters[key].selectedValue];
  })
  return updatedFilters;
}

/**
 * NOTE:
 * This was originally designed integrated with the trends page where we base the selection on cards
 * All the info from the card is passed in at the same level as props so assigning the props as card 
 *  is the quickest solution without having to re-factor a bunch of code
 */
/**
 * Props:
 * - type:string
 * - gameId:string
 * - home:bool
 * - team:{}
 * - opposingTeam:{}
 * - player:{}?
 * - initialMarket:string?
 * - backAction:fn()?
 */
class TrendsWorkstation extends React.Component {

    static contextType = GlobalContext;

    // TODO: move the trends page styling related to this out here
    constructor(props) {
        super(props);

        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.increaseLine = this.increaseLine.bind(this);
        this.decreaseLine = this.decreaseLine.bind(this);
        this.lineInputChanged = this.lineInputChanged.bind(this);
        this.fetchData = this.fetchData.bind(this);

        this.state = {
            selectedCard: props,
            filters: {},
            hitRateLine: 0,
            filteredGames: [],
            graphData: [],
            // TODO: once we swap the filters we don't need that, we can use the filters selected value
            graphBetType: "",
            gamelogPage: 0
        }
    }

    componentDidMount() {
        // Workaround when porting the trends workstation as a generic component
        // This was originally designed integrated with the trends page where we base the selection on cards
        // All the info from the card is passed in at the same level as props so doing this is the quickest solution without having to re-factor a bunch of code
        const card = this.props;

        const currentSeason = this.context.configuration.metadata.leagues[this.context.league].currentSeason;
        const hasOpponent = card.opposingTeam;
        const configurationTeamsFilter = this.context.configuration.screener[this.context.league].filterGroups.player.opponent;

        var filters = {}
        if (card.type === "team") {
            filters = defaultFilters[card.type](currentSeason, this.context.league, hasOpponent, configurationTeamsFilter);
            if (this.props.initialMarket && this.props.initialMarket in TEAM_BETS[this.context.league]) {
                filters[BET_TYPE_FILTER].selectedValue = TEAM_BETS[this.context.league][this.props.initialMarket].displayName
            }
        } else if (card.type === "player") {
            filters = defaultFilters[card.type](this.context.league, currentSeason, card.player.position, hasOpponent, configurationTeamsFilter);
            if (this.props.initialMarket && this.props.initialMarket in PLAYER_BET_TYPE_ID_TO_NAME[this.context.league]) {
                filters[BET_TYPE_FILTER].selectedValue = PLAYER_BET_TYPE_ID_TO_NAME[this.context.league][this.props.initialMarket]
            }
        }

        const promises = []
        promises.push(fetch(`${API_HOST}/api/${this.context.league}/v1/players/forTeam?teamCode=${card.team.code}`).then(data => data.json()));
        this.setState({
            selectedCard: card,
            filteredGames: [],
            gamelogPage: 0,
            filters: filters,
        }, () => {
            Promise.all(promises).then((data) => {
                const tempState = this.state;
                const roster = data[0];
                roster.forEach((player) => {
                    tempState.filters[WITH_FILTER].options[getPlayerId(player.info)] = getPlayerName(player.info)
                    tempState.filters[WITHOUT_FILTER].options[getPlayerId(player.info)] = getPlayerName(player.info)
                })
                if (this.props.without && this.props.without.id && this.props.without.id in tempState.filters[WITHOUT_FILTER].options) {
                    tempState.filters[WITHOUT_FILTER].selectedValue = this.props.without.id;
                }
                this.setState(tempState, () => this.fetchData(tempState, true))
            })
            .catch((err) => {
                console.log(err)
            })
        })   
    }

    increaseLine() {
        this.setState((previousState) => {
            const newLine = previousState.hitRateLine + 0.5
            return {
                hitRateLine: newLine,
                trends: buildTrendsData(this.context.league, previousState.selectedCard.type, previousState.filteredGames, previousState.filters[BET_TYPE_FILTER].selectedValue, newLine)
            }
        })
    }

    decreaseLine() {
        this.setState((previousState) => {
            const newLine = previousState.hitRateLine - 0.5
            return {
                hitRateLine: newLine,
                trends: buildTrendsData(this.context.league, previousState.selectedCard.type, previousState.filteredGames, previousState.filters[BET_TYPE_FILTER].selectedValue, newLine)
            }
        })
    }

    lineInputChanged(event) {
        this.setState((previousState) => {
            const newLine = parseFloat(event.target.value);
            return {
                hitRateLine: newLine,
                trends: buildTrendsData(this.context.league, previousState.selectedCard.type, previousState.filteredGames, previousState.filters[BET_TYPE_FILTER].selectedValue, newLine)
            }
        })
    }

    filterOptionSelected(filter, value) {
        // If it's the same that's already selected don't do anything
        if (this.state.filters[filter].selectedValue === value) {
            return;
        }
        var tempState = this.state;
        tempState.filters[filter].selectedValue = value;
        // Only one between with/without can be active at once, resetting the opposite if one is selected
        if (filter === WITH_FILTER) {
            tempState.filters[WITHOUT_FILTER].selectedValue = Object.keys(tempState.filters[WITHOUT_FILTER].options)[0]
        }
        if (filter === WITHOUT_FILTER) {
            tempState.filters[WITH_FILTER].selectedValue = Object.keys(tempState.filters[WITH_FILTER].options)[0]
        }
        if (filter === BET_TYPE_FILTER) {
            const betType = tempState.filters[BET_TYPE_FILTER].selectedValue;
            tempState.hitRateLine = getMarketLineValue(this.context.league, tempState.selectedCard.type, this.props.markets, betType);
        }
        if (this.state.filters[filter].affectsQuery) {
            this.fetchData(tempState);
        } else {
            tempState.trends = buildTrendsData(this.context.league, tempState.selectedCard.type, tempState.filteredGames, tempState.filters[BET_TYPE_FILTER].selectedValue, tempState.hitRateLine);
            tempState.graphData = buildGraphData(this.context.league, tempState.selectedCard.type, tempState.filters[BET_TYPE_FILTER].selectedValue, tempState.selectedCard.team.code, tempState.filteredGames);
            tempState.graphBetType = tempState.filters[BET_TYPE_FILTER].selectedValue;
            this.setState(tempState);
        }
    }

    fetchData(state, setLine) {
        if (!state.selectedCard) {
            return;
        }
        const type = state.selectedCard.type;
        const teamCode = state.selectedCard.team.code;
        const opposingTeamCode = (state.selectedCard && state.selectedCard.opposingTeam && state.selectedCard.opposingTeam.code) || null
        const playerId = type === "player" ? getPlayerId(state.selectedCard.player) : "";
        const updatedSelectedFilters = updateSelectedValues(state.filters);
        const currentSeason = this.context.configuration.metadata.leagues[this.context.league].currentSeason;
        const fetchSupportingStats = type === "player" && opposingTeamCode;
        var promises = [
            type === "team" ?
            getTeamWorkstationGames(API_HOST, this.context.league, currentSeason, teamCode, opposingTeamCode, updatedSelectedFilters).then(response => response.json()):
            getPlayerWorkstationGames(API_HOST, this.context.league, currentSeason, opposingTeamCode, updatedSelectedFilters, playerId).then(response => response.json())
        ]
        if (fetchSupportingStats) {
            promises.push(fetchPlayerRecord(API_HOST, this.context.league, [playerId]));
            promises.push(fetch(`${API_HOST}/api/${this.context.league}/v1/teams/${opposingTeamCode}`).then(response => response.json()));
        }
        safe(Promise.all(promises))
        .then(responses => {
            const games = responses[0]
            const betType = updatedSelectedFilters[BET_TYPE_FILTER].selectedValue;
            if (setLine) {
                state.hitRateLine = getMarketLineValue(this.context.league, type, this.props.markets, betType);
            }
            state.loaded = true;
            state.filteredGames = games;
            state.gamesHash = games.map(x => x.gameID).join('_');
            state.trends = buildTrendsData(this.context.league, type, games, betType, state.hitRateLine);
            state.graphData = buildGraphData(this.context.league, type, betType, teamCode, games)
            state.graphBetType = betType
            if (fetchSupportingStats && !isDictEmpty(responses[1][0])) {
                state.player = responses[1][0]
                state.opposingTeam = responses[2];
            }
            this.setState(state, () => {
                if (fetchSupportingStats) {
                    // TODO for mlb to have pitcher opposing stats
                    // const opposingTeamData = [this.props.route.params.game.homeTeamData, this.props.route.params.game.awayTeamData].find(x => x && x.info && x.info.code !== teamCode);
                    // this.opponentSelection = determineOpposingFieldSelection(this.context.league, state.player, opposingTeamData);
                    // if (this.opponentSelection.opposingPlayerId) {
                    //     safe(fetchPlayerRecord(API_HOST, this.context.league, [this.opponentSelection.opposingPlayerId]))
                    //     .then(opposingPlayers => {
                    //         if (!isDictEmpty(opposingPlayers[0])) {
                    //             this.setState({opposingComparable: opposingPlayers[0]});
                    //         }
                    //     })
                    // } else {
                        this.setState({opposingComparable: state.opposingTeam});
                    // }
                }
            })
        })
    }

    getOutcomeBlock(outcome, trends, gamesCount) {
        var hitPercentage = 0;
        var hits = 0;
        if (trends) {
            if (outcome === 'under') {
                hitPercentage = trends.missPercentage;
                hits = gamesCount - trends.hits - trends.push;
            } else {
                hitPercentage = trends.hitPercentage;
                hits = trends.hits;
            }
        }
        return (
            <>
                <p className='text-style-h-4-medium'>{capitalizeFirstLetter(outcome)}</p>
                <div>
                    <p className='text-style-h-3-semibold'>{`${hitPercentage}%`}</p>
                    <div className='hit-rate-chart-info-details-fraction'>
                        <p className='text-style-body-medium'>{`${hits}/${gamesCount}`}</p>
                    </div>
                </div>
            </>
        )
    }

    // TODO: for the pricing page redirect, set proper origin
    // TODO: average for player
    // TODO: supporting stats
    render() {
        const userHasPremiumAccess = hasPremiumAccess(this.context.userAttributes);
        const selectedCard = this.state.selectedCard;
        const betType = this.state.filters[BET_TYPE_FILTER] ? this.state.filters[BET_TYPE_FILTER].selectedValue : null;
        var betOutcomeType = OUTCOME_TYPE_OVER_UNDER;
        if (betType && selectedCard && selectedCard.type === "team") {
            const betId = TEAM_BET_TYPE_REVERSE_MAPPING[this.context.league][betType];
            const betDetails = TEAM_BETS[this.context.league][betId]
            betOutcomeType = betDetails.outcomeType;
        }
        const isCoverOutcome = (betOutcomeType === OUTCOME_TYPE_COVER || betOutcomeType === OUTCOME_TYPE_COVER_YES)

        var gamelogSupportingStats = {};
        if (betType && selectedCard && selectedCard.type === "player") {
            const propID = PLAYER_BET_TYPES_REVERSE_MAPPING[this.context.league][betType];
            const playerPropInfo = getPlayerPropInfo[this.context.league](propID, this.state.situation);
            gamelogSupportingStats = getGamelogSupportingStats[this.context.league](playerPropInfo);
        } else {
            gamelogSupportingStats = SCREENER_TEAM_SCORING_STATS(this.context.league, "cumulativeStats", "offensive");
        }
        return (
            <>
                <div className='trends-page-details-title trends-page-details-title-mobile'>
                    {
                        this.props.backAction && (
                            <img src="assets/arrow-back.svg" alt="back" onClick={this.props.backAction}/>
                        )
                    }
                    <img src={getTeamLogoPath(this.context.league, selectedCard.team.code)} alt={selectedCard.team.code}/>
                    <div>
                        <p className='text-style-h-4-semibold'>{selectedCard.type === "player" ? getPlayerName(selectedCard.player) : selectedCard.team.code}</p>
                        {
                            selectedCard.opposingTeam && (
                                <p className='text-style-body-uppercase-normal'>&nbsp;{`${selectedCard.home ? "vs" : "@"} ${selectedCard.opposingTeam.code}`}</p>
                            )
                        }
                    </div>
                </div>
                <div className='trends-page-details-filters'>
                    <div className="trends-page-details-filters-wrapper">
                        {
                            Object.keys(this.state.filters).map((filter) => 
                                <div key={filter} className='trends-page-details-filters-wrapper-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                                    <div className='trends-page-details-filters-wrapper-filter-header'>
                                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                                        {
                                            this.state.filters[filter].premium && !userHasPremiumAccess && (
                                                <img src="assets/lock.svg" height={16} width={16} alt="lock"/>
                                            )
                                        }
                                    </div>
                                    <div className='trends-page-details-filters-wrapper-filter-input-selection'>
                                        <InputSelection 
                                            enabled={!this.state.filters[filter].premium || userHasPremiumAccess} 
                                            type="single"
                                            typography="md" 
                                            options={this.state.filters[filter].options} 
                                            selection={this.state.filters[filter].selectedValue} 
                                            selectionHandler={(selection) => this.filterOptionSelected(filter, selection)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                {/* TODO: supporting stats for player */}
                <div className='trends-page-details-hit-rate'>
                    <p className='text-style-h-3-medium'>Hit Rate</p>
                    <div className='trends-page-details-hit-rate-wrapper'>
                        <div>
                            <HitRateChart line={this.state.hitRateLine} graphData={this.state.graphData} graphLegendKeys={[this.state.graphBetType]}/>
                        </div>
                        {/* TODO: may want to extract this as a separate component */}
                        <div className='hit-rate-chart-info'>
                            <div className='hit-rate-chart-info-details'>
                                <div className='hit-rate-chart-info-details-line'>
                                    <p className='text-style-h-4-medium'>Line</p>
                                    <div>
                                        <div className='hit-rate-chart-info-details-line-decrease' onClick={this.decreaseLine}>
                                            <img src='assets/icon-minus.svg' alt='decrease'/>
                                        </div>
                                        <div className='hit-rate-chart-info-details-line-value'>
                                            <input type="number" value={this.state.hitRateLine} onChange={this.lineInputChanged}/>
                                        </div>
                                        <div className='hit-rate-chart-info-details-line-increase' onClick={this.increaseLine}>
                                            <img src='assets/icon-plus.svg' alt='increase'/>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isCoverOutcome && (
                                        <div className='hit-rate-chart-info-details-cover'>
                                            {this.getOutcomeBlock('cover', this.state.trends, this.state.filteredGames.length)}
                                        </div>
                                    )
                                }
                                {
                                    !isCoverOutcome && (
                                        <>
                                            <div className='hit-rate-chart-info-details-over-under'>
                                                {this.getOutcomeBlock('under', this.state.trends, this.state.filteredGames.length)}
                                            </div>
                                            <div className='hit-rate-chart-info-details-over-under'>
                                                {this.getOutcomeBlock('over', this.state.trends, this.state.filteredGames.length)}
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            {
                                selectedCard.type === "player" && (
                                    <div className='hit-rate-chart-info-average'>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className='trends-page-game-log'>
                    <p className='text-style-h-3-medium'>Gamelog</p>
                    <div className='trends-page-game-log-table'>
                        <Table 
                            className='trends-page-game-log-table-mobile'
                            columns={['DATE', 'OPPONENT', 'RESULT'].concat(Object.keys(gamelogSupportingStats))}
                            rowCount={Math.min(5, this.state.filteredGames.length)}
                            columnStyling={(columnIndex, columnName) => {
                                if (columnIndex === 0) {
                                    return {width: 90, textAlign: 'left', padding: '0px 12px'}
                                }
                                if (columnIndex === 1) {
                                    return {width: 100, textAlign: 'left', padding: '0px 8px'};
                                }
                                if (columnIndex === 2) {
                                    return {width: 105, textAlign: 'left', padding: '0px 8px'};
                                }
                                return {textAlign: 'right', padding: '0px 12px'}
                            }}
                            dataExtractor={(rowIndex, columnIndex, columnName) => {
                                const offset = this.state.gamelogPage * 5;
                                const gameIndex = offset + rowIndex;
                                if (gameIndex >= this.state.filteredGames.length) {
                                    return "";
                                }
                                const game = this.state.filteredGames.slice().reverse()[gameIndex];
                                if (columnIndex === 0) {
                                    const gameDate = parseDateFromString(game.timestamp);
                                    return `${String(gameDate.month).padStart(2, '0')}/${String(gameDate.day).padStart(2, '0')}/${gameDate.yearShort}`
                                }
                                if (columnIndex === 1) {
                                    const teamCode = game.opposingTeam;
                                    return (
                                        // Wrapping the content of the cell in a div allows us to then use flex inside
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                                            <img src={getTeamLogoPath(this.context.league, teamCode)} onError={({currentTarget}) => teamLogoPathOnErrorFallback(currentTarget, this.context.league)} height={32} width={32} alt={teamCode}/>
                                            <p className="text-style-label-medium">{teamCode}</p>
                                        </div>
                                    )
                                }
                                if (columnIndex === 2) {
                                    return selectedCard.type === "player" ? getGameResultText(this.context.league, game) : getTeamGameResultText(this.context.league, game);
                                }
                                return getDictionaryValue(game, gamelogSupportingStats[columnName]);
                            }}
                        />
                    </div>
                    <div className='trends-page-game-log-pagination'>
                        <div className='trends-page-game-log-pagination-button'>
                            <Button icon='assets/chevron-left.svg' type='secondary' enabled={this.state.gamelogPage > 0} onClick={() => this.setState((previousState) => ({gamelogPage: Math.max(0, previousState.gamelogPage - 1)}))}/>
                        </div>
                        <div className='trends-page-game-log-pagination-button'>
                            <Button icon='assets/chevron-right.svg' type='secondary' enabled={this.state.gamelogPage < ((this.state.filteredGames.length / 5) - 1)} onClick={() => this.setState((previousState) => ({gamelogPage: Math.min(previousState.gamelogPage + 1, Math.floor(this.state.filteredGames.length / 5))}))}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TrendsWorkstation;