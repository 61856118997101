import React from 'react';

import './Landing.scss'
import '../components/App.scss'

import GlobalContext from '../components/GlobalContext';
import { SUPPORTED_LEAGUES } from '../linemate-react-common/src/constants';
import Button from '../components/Button';
import { capitalizeFirstLetter } from '../linemate-react-common/src/util';
import { getLeagueLogoPath, getTheme } from '../react-web-utils';
import { SHOWCASE_FEATURES } from './Pricing';

const features = ['home', 'trends', 'workstation']
const featureText = {
    home: 'Finding a confident play from thousands of bet types should always be this easy.',
    trends: 'Every trending play with statistical headlines. Gain an edge with insights at your fingertips.',
    workstation: 'Looking to deep dive your analysis? From hit rate tables, to game logs, and custom filters, we\'ve got you covered.'
}

// TODO: check with Matt if we should redirect logged in users out of landing page
class Landing extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            featureToggleSelection: features[0]
        }
    }

    componentDidMount() {
        document.title = 'Linemate';
    }

    render() {
        return (
            <div className='landing-page-container'>
                <div className='landing-page-hero'>
                    <div className='landing-page-headline'>
                        <div className='landing-page-headline-title'>
                            <p className='color-fig-default'>Find&nbsp;</p>
                            <p>your</p>
                            <p className='color-fig-default'>&nbsp;next bet</p>
                        </div>
                        <p className='text-style-h-3-normal color-fig-subtle'>Discover free betting trends and insights.</p>
                        <div className='landing-page-headline-buttons'>
                            <div className='landing-page-headline-get-started-button-wrapper'>
                                <Button text="Get started" typography="xl" type="primary" enabled={true} onClick={() => window.location.href = `/${this.context.configuration.metadata.defaultLeague}`}/>
                            </div>
                            <div className='landing-page-headline-download-app-button-wrapper'>
                                <Button text="Download app" typography="xl" type="secondary" enabled={true} onClick={() => window.location.href = `/download`}/>
                            </div>
                        </div>
                    </div>
                    <div className='landing-page-features'>
                        <div className='landing-page-features-toggles'>
                        {
                            features.map((feature) => 
                                <div className='landing-page-features-toggles-toggle unselectable' data-selected={this.state.featureToggleSelection === feature} onClick={() => this.setState({featureToggleSelection: feature})}>
                                    <p className='text-style-body-normal'>{capitalizeFirstLetter(feature)}</p>
                                </div>
                            )
                        }
                        </div>
                        <p className='text-style-label-normal color-fig-subtle'>{featureText[this.state.featureToggleSelection]}</p>
                        {/* Because the image is theme based, if the user switches theme the image will stay from the old theme until they either refresh the page or switch feature */}
                        {/* This isn't perfect, it would be nice to handle the theme switch but can't be bothered */}
                        <img src={`assets/landing/hero-image-${this.state.featureToggleSelection}-${getTheme()}.png`} alt={this.state.featureToggleSelection}/>
                    </div>
                </div>
                <div className='landing-page-leagues'>
                    <p className='color-fig-default'>Covering all major leagues and more</p>
                    <div className='landing-page-leagues-icons'>
                    {
                        SUPPORTED_LEAGUES.map((league) =>
                            <img key={league} src={getLeagueLogoPath(league)} height={56} width={56} alt={league}/>
                        )
                    }
                    </div>
                </div>
                <div className='landing-page-showcase'>
                    <div className='landing-page-showcase-headline'>
                        <div className='landing-page-showcase-headline-title'>
                            <p className='color-fig-default'>Start saving time with Linemate+</p>
                        </div>
                        <p className='text-style-h-3-normal color-fig-subtle'>Unlocked access to daily plays with multiple merging trends. Time is money and we want you in a scoring position.</p>
                    </div>
                    {/* Copied from pricing */}
                    <div className='pricing-page-upgrade-showcase-features' style={{marginTop: 48, marginBottom: 48}}>
                    {
                        SHOWCASE_FEATURES.map((feature) =>
                            <div key={feature.title} className='pricing-page-upgrade-showcase-feature'>
                                <div className='pricing-page-upgrade-showcase-feature-image'>
                                    <img src={feature.image} alt={feature.title}/>
                                </div>
                                <div className='pricing-page-upgrade-showcase-feature-text'>
                                    <p className='text-style-body-semibold color-fig-default'>{feature.title}</p>
                                    <p className='text-style-label-normal color-fig-subtle'>{feature.subtitle}</p>
                                </div>
                            </div>
                        )
                    }
                    </div>
                    <div className='landing-page-showcase-buttons'>
                        <div className='landing-page-showcase-headline-get-started-button-wrapper'>
                            <Button text="Start free trial" typography="xl" type="primary" enabled={true} onClick={() => window.location.href = `/signup`}/>
                        </div>
                        <div className='landing-page-headline-download-app-button-wrapper'>
                            <Button text="View pricing" typography="xl" type="secondary" enabled={true} onClick={() => window.location.href = `/pricing`}/>
                        </div>
                    </div>
                </div>
                <div className='landing-page-footer'>
                    <div className='landing-page-footer-text'>
                        <p className='color-fig-subtle'>Ready for better plays?</p>
                        <p className='color-fig-default'>Start your free trial</p>
                    </div>
                    <div className='landing-page-footer-buttons'>
                        <div className='landing-page-showcase-headline-get-started-button-wrapper'>
                            {/* TODO: do we redirect to pricing after signup here? We're already having a link to pricing */}
                            <Button text="Start free trial" typography="xl" type="primary" enabled={true} onClick={() => window.location.href = `/signup`}/>
                        </div>
                        <div className='landing-page-headline-download-app-button-wrapper'>
                            <Button text="Download app" typography="xl" type="secondary" enabled={true} onClick={() => window.location.href = `/download`}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Landing;