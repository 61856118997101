import React from 'react';

import './TrendDetails.scss'
import './App.scss'
import GlobalContext from './GlobalContext';
import TabToggle from './TabToggle';
import GamelogDetails from './GamelogDetails';
import { getCardKey } from '../linemate-react-common/src/betting-utils';
import TrendsWorkstation from './TrendsWorkstation';
import { base64Encode, getPlayerName } from '../linemate-react-common/src/util';
import { getTeamLogoPath } from '../react-web-utils';


/**
 * Props:
 * - trend: {}
 * - type: parlay|straight
 * One of trend or profileInfo is necessary
 */
// It's called trend details but realistically it's a component that's used as a combination of trend gamelog details, workstation, profile etc.
class TrendDetails extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        if (props.source === "profile") {
            this.tabs = {
                workstation: 'Workstation',
                details: 'Details'
            }
            if (!props.trend) {
                delete this.tabs['details']
            }
        } else if (props.source === "trends") {
            this.tabs = {
                details: 'Details',
                workstation: 'Workstation'
            }
        }
        if (props.type === "parlay") {
            delete this.tabs['workstation']
        }

        this.state = {
            tab: Object.keys(this.tabs)[0]
        }
    }

    // TODO: The tabs are sub-optimal as it re-loads the data from back-end on each switch
    //  Ideally we should load them all at a higher level or cache them
    render() {
        var trend = this.props.trend;
        if (!trend && this.props.source === "trends") {
            return (<></>)
        }
        if (!trend && this.props.source === "profile") {
            // If we're in profile mode and we have no card, create a dummy card just to fill in the data
            trend = {
                id: 'n/a',
                gameId: '',
                // TODO: this is not actually necessarily true if there's no trend to not have an opponent
                //  Need to get the games and check there
                hasOpponent: false,
                type: this.props.customFeed.player ? "player" : "team",
                player: this.props.customFeed.player,
                team: {
                    code: this.props.customFeed.teamCode
                },
                opposingTeam: null
            }
        }
        var profileHref = "";
        if (this.props.source === "trends" && this.props.type === "straight") {
            const feed = base64Encode(JSON.stringify({
                title: 'Profile', 
                subtitle: trend.type === "player" ? getPlayerName(trend.player) : trend.team.code,
                player: trend.type === "player" ? trend.player : null,
                teamCode: trend.team.code
            }))
            // Here an alternative would have been /league/profile/team or player id
            // However in an attempt to try and hide what ids we use we will keep the existing trends url and add data to the encoded query param
            // Realistically anyone that knows what they are doing and actually want to find out can decode it but it adds a layer of "protection"
            profileHref = `/${this.context.league}/trends?feed=${feed}&profile=true`
        }
        return (
            <div className='trend-details-wrapper'>
                {/* Header */}
                <div className='trends-page-details-title trends-page-details-title-desktop'>
                    {
                        this.props.type === "straight" && (
                            <>
                                <div>
                                    <p className='text-style-h-2-semibold'>{trend.type === "player" ? getPlayerName(trend.player) : trend.team.code}</p>
                                    {

                                    }
                                    {
                                        trend.opposingTeam && (
                                            <p className='text-style-h-4-normal'>&nbsp;{`${trend.home ? "vs" : "@"} ${trend.opposingTeam.code}`}</p>
                                        )
                                    }
                                </div>
                                <img src={getTeamLogoPath(this.context.league, trend.team.code)} alt={trend.team.code}/>
                            </>
                        )
                    }
                    {
                        this.props.type === "parlay" && (
                            <p className='text-style-h-4-semibold color-fig-default'>Trend Details</p>
                        )
                    }
                </div>
                {/* Optional banner for profile */}
                {
                    this.props.source === "trends" && this.props.type === "straight" && (
                        <a className='trend-details-profile-banner' href={profileHref}>
                            <p className='text-style-body-medium color-fig-subtle'>View more trends and parlay trends for this {trend.type}</p>
                            <img src="assets/arrow-forward.svg" alt="arrow-right"/>
                        </a>
                    )
                }
                {/* TODO: add color variable for toggle */}
                {/* Tabs for details/workstation */}
                {
                    Object.keys(this.tabs).length > 1 && (
                        <div className='trend-details-tab-toggle'>
                            <TabToggle options={this.tabs} selection={this.state.tab} selectionHandler={(selection) => this.setState({tab: selection})} includeLegend={false}/>
                        </div>
                    )
                }
                {/* Details/workstation content depending on tab selection */}
                {
                    this.state.tab === "details" && this.props.type === "parlay" && (
                        <GamelogDetails 
                            key={getCardKey(trend)} 
                            parlay={this.props.type === "parlay" ? trend : null} 
                        />
                    )
                }
                {
                    this.state.tab === "details" && this.props.type === "straight" && (
                        <GamelogDetails 
                            key={getCardKey(trend)} 
                            trend={this.props.type === "straight" ? trend : null}
                            without={this.props.without}
                        />
                    )
                }
                {
                    // No workstation available for parlays, no need to check the type
                    this.state.tab === "workstation" && (
                        <TrendsWorkstation 
                            key={getCardKey(trend)} 
                            type={trend.type}
                            // I don't think this is needed anymore
                            gameId={trend.gameId}
                            home={trend.home}
                            team={trend.team}
                            opposingTeam={trend.opposingTeam}
                            // TODO
                            // markets={this.state.selectedCardMarkets}
                            // markets={trend.activeGameInfo ? trend.activeGameInfo.markets : null}
                            markets={{}}
                            initialMarket={'market' in trend ? trend.market.name : null}
                            player={trend.type === "player" ? trend.player : null}
                            // backAction={() => this.setState({mobileEntrySelected: false})}
                            // backAction={() => this.setState({mobileCardSelected: false})}
                            backAction={null}
                            without={this.props.without}
                        />
                    )
                }
            </div>
        );
    }
}

export default TrendDetails;