import React from 'react';
import { withRouter } from "react-router";
import {LEAGUE_CURRENT_RECORD_TYPE, LEAGUE_CURRENT_SEASON, buildRecordStringFn, TEAM_MODAL_STATS, TEAM_COLOR_CODES} from './linemate-react-common/src/constants.js';
import {API_HOST, RESPONSIVE_BAR_THEME} from './react-web-constants.js';
import $ from 'jquery';
import Scrollspy from 'react-scrollspy';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveBar } from '@nivo/bar';
import HorizontalComparisonBar from './horizontal-comparison-bar.js';
import Dropdown from './dropdown.js';
import NoResultsFrame from './no-results-frame.js';
import ScrollableGamesBanner from './scrollable-games-banner.js';
import GamePreviewBanner from './game-preview-banner.js';
import { isDictEmpty, parseDateFromString, decimalPrecision, getDictionaryValue, orderListByField, addOrdinalNumberSuffix, capitalizeFirstLetter, shouldBlockUserAccess, getRegionAffiliateTitle, base64Encode, isSoccer, getPlayerName, getTeamCityDisplayName, getTeamDisplayName, isWeeklyCompetition } from './linemate-react-common/src/util.js';
import { getTeamLogoPath, getTheme } from './react-web-utils.js';

import './fonts.css';
import './generic.css';
import './playbook.css';
import './buttons.css';
import HtmlHeaders from './html-headers.js';
import InputSelection from './components/InputSelection.jsx';
import { swapDictionaryKeyValue } from './linemate-react-common/src/core-utils.js';
import Button from './components/Button.jsx';

const wordTenseMap = {
    "score_present": "scores",
    "generate_present": "generates",
    "drive_present": "drives",
    "concede_present": "concedes",
    "allow_present": "allows",
    "total_present": "totals",
    "control_present": "controls",
    "score_past": "scored",
    "generate_past": "generated",
    "drive_past": "drove",
    "concede_past": "conceded",
    "allow_past": "allowed",
    "total_past": "totaled",
    "control_past": "controlled"
};

function getWordTense(word, tense) {
    if (`${word}_${tense}` in wordTenseMap) {
        return wordTenseMap[`${word}_${tense}`];
    }
    return "";
}

// League == nfl/nba/nhl
// tense == past/present, used to determine the conjugation of the carrier words
// type == offensive/defensive
// qualifier == cumulativeStats/averageStats
function getLeagueInsightsMetadata(league, tense, type, qualifier) {
    var statName = {
        creation: "",
        conversion: "",
        control: ""
    };
    var statField = {
        creation: "",
        conversion: "",
        control: ""
    };
    var carrierWords = {
        creation: "",
        conversion: "",
        control: ""
    };
    var valueType = {
        creation: "",
        conversion: "",
        control: ""
    };
    switch (league.toLowerCase()) {
        case "nfl":
            statName["creation"] = "points";
            statField["creation"] = "%qualifier%.%type%.points";
            // carrierWords["creation"] = "scores";
            valueType["creation"] = "flat";

            statName["conversion"] = "passing yards";
            statField["conversion"] = "%qualifier%.%type%.passingYards";
            // carrierWords["conversion"] = "totals";
            valueType["conversion"] = "flat";

            statName["control"] = "rushing yards";
            statField["control"] = "%qualifier%.%type%.rushingYards";
            // carrierWords["control"] = "totals";
            valueType["control"] = "flat";

            if (type === "offensive") {
                carrierWords["creation"] = getWordTense("score", tense);
                carrierWords["conversion"] = getWordTense("total", tense);
                carrierWords["control"] = getWordTense("total", tense);
            } else if (type === "defensive") {
                carrierWords["creation"] = getWordTense("concede", tense);
                carrierWords["conversion"] = getWordTense("concede", tense);
                carrierWords["control"] = getWordTense("concede", tense);
            }
            break;
        case "nhl":
            statName["creation"] = "goals";
            statField["creation"] = "%qualifier%.%type%.goals";
            // carrierWords["creation"] = "scores";
            valueType["creation"] = "flat";
            
            statName["conversion"] = "shot attempts";
            statField["conversion"] = "%qualifier%.%type%.shotAttempts";
            // carrierWords["conversion"] = "generates";
            valueType["conversion"] = "flat";

            statName["control"] = "scoring chances";
            statField["control"] = "%qualifier%.%type%.corsiPercentage";
            // carrierWords["control"] = "drives";
            valueType["control"] = "percentage";

            if (type === "offensive") {
                carrierWords["creation"] = getWordTense("score", tense);
                carrierWords["conversion"] = getWordTense("generate", tense);
                carrierWords["control"] = getWordTense("drive", tense);
            } else if (type === "defensive") {
                carrierWords["creation"] = getWordTense("concede", tense);
                carrierWords["conversion"] = getWordTense("allow", tense);
                carrierWords["control"] = getWordTense("allow", tense);
            }
            break;
        case "nba":
        case "wnba":
            statName["creation"] = "points";
            statField["creation"] = "%qualifier%.%type%.points";
            // carrierWords["creation"] = "scores";
            valueType["creation"] = "flat";
            
            statName["conversion"] = "free throws";
            statField["conversion"] = "%qualifier%.%type%.freeThrowsMade";
            // carrierWords["conversion"] = "scores";
            valueType["conversion"] = "flat";

            statName["control"] = "3 pointers";
            statField["control"] = "%qualifier%.%type%.threePointsMade";
            // carrierWords["control"] = "scores";
            valueType["control"] = "flat";

            if (type === "offensive") {
                carrierWords["creation"] = getWordTense("score", tense);
                carrierWords["conversion"] = getWordTense("score", tense);
                carrierWords["control"] = getWordTense("score", tense);
            } else if (type === "defensive") {
                carrierWords["creation"] = getWordTense("concede", tense);
                carrierWords["conversion"] = getWordTense("concede", tense);
                carrierWords["control"] = getWordTense("concede", tense);
            }
            break;
        case "mlb":
            statName["creation"] = "Runs";
            statField["creation"] = "%qualifier%.%type%.runs";
            valueType["creation"] = "flat";
            
            statName["conversion"] = "Hits";
            statField["conversion"] = "%qualifier%.%type%.hits";
            valueType["conversion"] = "flat";

            statName["control"] = "OPS";
            statField["control"] = "%qualifier%.%type%.onBasePlusSluggingPercentage";
            valueType["control"] = "flat";

            if (type === "offensive") {
                carrierWords["creation"] = getWordTense("score", tense);
                carrierWords["conversion"] = getWordTense("total", tense);
                carrierWords["control"] = getWordTense("generate", tense);
            } else if (type === "defensive") {
                carrierWords["creation"] = getWordTense("concede", tense);
                carrierWords["conversion"] = getWordTense("allow", tense);
                carrierWords["control"] = getWordTense("allow", tense);
            }
            break;
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        case "mls":
        case "euro":
            statName["creation"] = "goals";
            statField["creation"] = "%qualifier%.%type%.goals";
            // carrierWords["creation"] = "scores";
            valueType["creation"] = "flat";
            
            statName["conversion"] = "shots";
            statField["conversion"] = "%qualifier%.%type%.shotsTotal";
            // carrierWords["conversion"] = "generates";
            valueType["conversion"] = "flat";

            statName["control"] = "possession";
            statField["control"] = "%qualifier%.%type%.ballPossession";
            // carrierWords["control"] = "drives";
            valueType["control"] = "flat";

            if (type === "offensive") {
                carrierWords["creation"] = getWordTense("score", tense);
                carrierWords["conversion"] = getWordTense("generate", tense);
                carrierWords["control"] = getWordTense("control", tense);
            } else if (type === "defensive") {
                carrierWords["creation"] = getWordTense("concede", tense);
                carrierWords["conversion"] = getWordTense("allow", tense);
                carrierWords["control"] = getWordTense("allow", tense);
            }
            break;
        default:
            break;
    }

    
    statField["creation"] = statField["creation"].replace("%qualifier%", qualifier).replace("%type%", type);
    statField["conversion"] = statField["conversion"].replace("%qualifier%", qualifier).replace("%type%", type);
    statField["control"] = statField["control"].replace("%qualifier%", qualifier).replace("%type%", type);

    return {
        statName: statName,
        statField: statField,
        carrierWords: carrierWords,
        valueType: valueType
    };
}

function getNFLGameComparison(teamGameRecord) {
    return (
        <div className="record-away-team">
            <div className="match-result text-xs-upper">
                {teamGameRecord.win === 1 ? "WIN" : (teamGameRecord.loss === 1 ? "LOSS" : "TIE")} 
                &nbsp;•&nbsp;
                {parseDateFromString(teamGameRecord.timestamp).formattedDayMonthShort}
            </div>
            <div className="box-score text-base-upper">
                <div className="away" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.points : teamGameRecord.cumulativeStats.offensive.points) > (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.points : teamGameRecord.cumulativeStats.defensive.points) ? {} : {opacity: '0.5'}}> 
                    <span>{teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam}</span>  &nbsp;
                    <img src={getTeamLogoPath("nfl", teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam)} height={40} width={40} alt=""/>
                </div>
                <div className="score">
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.points : teamGameRecord.cumulativeStats.offensive.points
                    }
                        &nbsp;-&nbsp;
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.points : teamGameRecord.cumulativeStats.defensive.points
                    }
                </div>
                <div className="home" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.points : teamGameRecord.cumulativeStats.offensive.points) < (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.points : teamGameRecord.cumulativeStats.defensive.points) ? {} : {opacity: '0.5'}}>
                    <img src={getTeamLogoPath("nfl", teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam)} height={40} width={40} alt=""/>  &nbsp;
                    <span>{teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam}</span>
                </div>
            </div>
        </div>
    )
}

function getNHLGameComparison(teamGameRecord) {
    return (
        <div className="record-away-team">
            <div className="match-result text-xs-upper">
                {teamGameRecord.win === 1 ? "WIN" : (teamGameRecord.overtimeLoss === 1 ? "OTL" : "LOSS")} 
                &nbsp;•&nbsp;
                {parseDateFromString(teamGameRecord.timestamp).formattedDayMonthShort}
            </div>
            <div className="box-score text-base-upper">
                <div className="away" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.goals : teamGameRecord.cumulativeStats.offensive.goals) > (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.goals : teamGameRecord.cumulativeStats.defensive.goals) ? {} : {opacity: '0.5'}}> 
                    <span>{teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam}</span>  &nbsp;
                    <img src={getTeamLogoPath("nhl", teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam)} height={40} width={40}  alt=""/>
                </div>
                <div className="score">
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.goals : teamGameRecord.cumulativeStats.offensive.goals
                    }
                        &nbsp;-&nbsp;
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.goals : teamGameRecord.cumulativeStats.defensive.goals
                    }
                </div>
                <div className="home" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.goals : teamGameRecord.cumulativeStats.offensive.goals) < (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.goals : teamGameRecord.cumulativeStats.defensive.goals) ? {} : {opacity: '0.5'}}>
                    <img src={getTeamLogoPath("nhl", teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam)} height={40} width={40}  alt=""/>  &nbsp;
                    <span>{teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam}</span>
                </div>
            </div>
        </div>
    )
}

function getNBAGameComparison(teamGameRecord, league) {
    return (
        <div className="record-away-team">
            <div className="match-result text-xs-upper">
                {teamGameRecord.win === 1 ? "WIN" : "LOSS"} 
                &nbsp;•&nbsp;
                {parseDateFromString(teamGameRecord.timestamp).formattedDayMonthShort}
            </div>
            <div className="box-score text-base-upper">
                <div className="away" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.points : teamGameRecord.cumulativeStats.offensive.points) > (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.points : teamGameRecord.cumulativeStats.defensive.points) ? {} : {opacity: '0.5'}}> 
                    <span>{teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam}</span>  &nbsp;
                    <img src={getTeamLogoPath(league, teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam)} height={40} width={40}  alt=""/>
                </div>
                <div className="score">
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.points : teamGameRecord.cumulativeStats.offensive.points
                    }
                        &nbsp;-&nbsp;
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.points : teamGameRecord.cumulativeStats.defensive.points
                    }
                </div>
                <div className="home" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.points : teamGameRecord.cumulativeStats.offensive.points) < (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.points : teamGameRecord.cumulativeStats.defensive.points) ? {} : {opacity: '0.5'}}>
                    <img src={getTeamLogoPath(league, teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam)} height={40} width={40}  alt=""/>  &nbsp;
                    <span>{teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam}</span>
                </div>
            </div>
        </div>
    )
}

function getMLBGameComparison(teamGameRecord) {
    return (
        <div className="record-away-team">
            <div className="match-result text-xs-upper">
                {teamGameRecord.win === 1 ? "WIN" : "LOSS"} 
                &nbsp;•&nbsp;
                {parseDateFromString(teamGameRecord.timestamp).formattedDayMonthShort}
            </div>
            <div className="box-score text-base-upper">
                <div className="away" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.runs : teamGameRecord.cumulativeStats.offensive.runs) > (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.runs : teamGameRecord.cumulativeStats.defensive.runs) ? {} : {opacity: '0.5'}}> 
                    <span>{teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam}</span>  &nbsp;
                    <img src={getTeamLogoPath("mlb", teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam)} height={40} width={40}  alt=""/>
                </div>
                <div className="score">
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.runs : teamGameRecord.cumulativeStats.offensive.runs
                    }
                        &nbsp;-&nbsp;
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.runs : teamGameRecord.cumulativeStats.defensive.runs
                    }
                </div>
                <div className="home" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.runs : teamGameRecord.cumulativeStats.offensive.runs) < (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.runs : teamGameRecord.cumulativeStats.defensive.runs) ? {} : {opacity: '0.5'}}>
                    <img src={getTeamLogoPath("mlb", teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam)} height={40} width={40}  alt=""/>  &nbsp;
                    <span>{teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam}</span>
                </div>
            </div>
        </div>
    )
}

function getSoccerGameComparison(teamGameRecord, league) {
    return (
        <div className="record-away-team">
            <div className="match-result text-xs-upper">
                {teamGameRecord.win === 1 ? "WIN" : (teamGameRecord.loss === 1 ? "LOSS" : "DRAW")} 
                &nbsp;•&nbsp;
                {parseDateFromString(teamGameRecord.timestamp).formattedDayMonthShort}
            </div>
            <div className="box-score text-base-upper">
                <div className="away" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.goals : teamGameRecord.cumulativeStats.offensive.goals) > (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.goals : teamGameRecord.cumulativeStats.defensive.goals) ? {} : {opacity: '0.5'}}> 
                    <span>{teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam}</span>  &nbsp;
                    <img src={getTeamLogoPath(league, teamGameRecord.isHome ? teamGameRecord.opposingTeam : teamGameRecord.currentTeam)} height={40} width={40}  alt=""/>
                </div>
                <div className="score">
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.goals : teamGameRecord.cumulativeStats.offensive.goals
                    }
                        &nbsp;-&nbsp;
                    {
                        teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.goals : teamGameRecord.cumulativeStats.defensive.goals
                    }
                </div>
                <div className="home" style={(teamGameRecord.isHome ? teamGameRecord.cumulativeStats.defensive.goals : teamGameRecord.cumulativeStats.offensive.goals) < (teamGameRecord.isHome ? teamGameRecord.cumulativeStats.offensive.goals : teamGameRecord.cumulativeStats.defensive.goals) ? {} : {opacity: '0.5'}}>
                    <img src={getTeamLogoPath(league, teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam)} height={40} width={40}  alt=""/>  &nbsp;
                    <span>{teamGameRecord.isHome ? teamGameRecord.currentTeam : teamGameRecord.opposingTeam}</span>
                </div>
            </div>
        </div>
    )
}

function getNFLRecordComparison(homeTeamRecordComparison, awayTeamRecordComparison) {
    if (isDictEmpty(homeTeamRecordComparison) || isDictEmpty(awayTeamRecordComparison)) {
        return <></>;
    }
    var homeTeamStreak = "";
    if (homeTeamRecordComparison.streak.tie > 0) {
        homeTeamStreak = `T ${homeTeamRecordComparison.streak.tie}`;
    } else if (homeTeamRecordComparison.streak.loss > 0) {
        homeTeamStreak = `L ${homeTeamRecordComparison.streak.loss}`;
    } else {
        homeTeamStreak = `W ${homeTeamRecordComparison.streak.win}`;
    }
    var awayTeamStreak = "";
    if (awayTeamRecordComparison.streak.tie > 0) {
        awayTeamStreak = `T ${awayTeamRecordComparison.streak.tie}`;
    } else if (awayTeamRecordComparison.streak.loss > 0) {
        awayTeamStreak = `L ${awayTeamRecordComparison.streak.loss}`;
    } else {
        awayTeamStreak = `W ${awayTeamRecordComparison.streak.win}`;
    }
    return (
        <>
            {
                // Assuming home/away record comparisons should have the same keys
                // We're not showing fav/underdog in the individual timeframe records, those will live in the betting trends
                // Streak is done separately
                Object.keys(homeTeamRecordComparison).filter(x => x !== "favourite" && x !== "underdog" && x !== "streak").map((key) => 
                <div key={key} className="comparative-tbl-row text-base">
                    <div>{`${awayTeamRecordComparison[key].win}-${awayTeamRecordComparison[key].loss}-${awayTeamRecordComparison[key].tie}`}</div>
                    <div className="text-xs-upper">{key}</div>
                    <div>{`${homeTeamRecordComparison[key].win}-${homeTeamRecordComparison[key].loss}-${homeTeamRecordComparison[key].tie}`}</div>
                </div>
                )
            }
            <div className="comparative-tbl-row text-base">
                <div>{awayTeamStreak}</div>
                <div className="text-xs-upper">STREAK</div>
                <div>{homeTeamStreak}</div>
            </div>
        </>
    )
}

function getNHLRecordComparison(homeTeamRecordComparison, awayTeamRecordComparison) {
    if (isDictEmpty(homeTeamRecordComparison) || isDictEmpty(awayTeamRecordComparison)) {
        return <></>;
    }
    var homeTeamStreak = "";
    if (homeTeamRecordComparison.streak.otl > 0) {
        homeTeamStreak = `OTL ${homeTeamRecordComparison.streak.otl}`;
    } else if (homeTeamRecordComparison.streak.loss > 0) {
        homeTeamStreak = `L ${homeTeamRecordComparison.streak.loss}`;
    } else {
        homeTeamStreak = `W ${homeTeamRecordComparison.streak.win}`;
    }
    var awayTeamStreak = "";
    if (awayTeamRecordComparison.streak.otl > 0) {
        awayTeamStreak = `OTL ${awayTeamRecordComparison.streak.otl}`;
    } else if (awayTeamRecordComparison.streak.loss > 0) {
        awayTeamStreak = `L ${awayTeamRecordComparison.streak.loss}`;
    } else {
        awayTeamStreak = `W ${awayTeamRecordComparison.streak.win}`;
    }
    return (
        <>
            {
                // Assuming home/away record comparisons should have the same keys
                // We're not showing fav/underdog in the individual timeframe records, those will live in the betting trends
                // Streak is done separately
                Object.keys(homeTeamRecordComparison).filter(x => x !== "favourite" && x !== "underdog" && x !== "streak").map((key) => 
                <div key={key} className="comparative-tbl-row text-base">
                    <div>{`${awayTeamRecordComparison[key].win}-${awayTeamRecordComparison[key].loss}-${awayTeamRecordComparison[key].otl}`}</div>
                    <div className="text-xs-upper">{key}</div>
                    <div>{`${homeTeamRecordComparison[key].win}-${homeTeamRecordComparison[key].loss}-${homeTeamRecordComparison[key].otl}`}</div>
                </div>
                )
            }
            <div className="comparative-tbl-row text-base">
                <div>{awayTeamStreak}</div>
                <div className="text-xs-upper">STREAK</div>
                <div>{homeTeamStreak}</div>
            </div>
        </>
    )
}

function getNBARecordComparison(homeTeamRecordComparison, awayTeamRecordComparison) {
    if (isDictEmpty(homeTeamRecordComparison) || isDictEmpty(awayTeamRecordComparison)) {
        return <></>;
    }
    var homeTeamStreak = "";
    if (homeTeamRecordComparison.streak.loss > 0) {
        homeTeamStreak = `L ${homeTeamRecordComparison.streak.loss}`;
    } else {
        homeTeamStreak = `W ${homeTeamRecordComparison.streak.win}`;
    }
    var awayTeamStreak = "";
    if (awayTeamRecordComparison.streak.loss > 0) {
        awayTeamStreak = `L ${awayTeamRecordComparison.streak.loss}`;
    } else {
        awayTeamStreak = `W ${awayTeamRecordComparison.streak.win}`;
    }
    return (
        <>
            {
                // Assuming home/away record comparisons should have the same keys
                // We're not showing fav/underdog in the individual timeframe records, those will live in the betting trends
                // Streak is done separately
                Object.keys(homeTeamRecordComparison).filter(x => x !== "favourite" && x !== "underdog" && x !== "streak").map((key) => 
                <div key={key} className="comparative-tbl-row text-base">
                    <div>{`${awayTeamRecordComparison[key].win}-${awayTeamRecordComparison[key].loss}`}</div>
                    <div className="text-xs-upper">{key}</div>
                    <div>{`${homeTeamRecordComparison[key].win}-${homeTeamRecordComparison[key].loss}`}</div>
                </div>
                )
            }
            <div className="comparative-tbl-row text-base">
                <div>{awayTeamStreak}</div>
                <div className="text-xs-upper">STREAK</div>
                <div>{homeTeamStreak}</div>
            </div>
        </>
    )
}

function getMLBRecordComparison(homeTeamRecordComparison, awayTeamRecordComparison) {
    return getNBARecordComparison(homeTeamRecordComparison, awayTeamRecordComparison);
}

function getSoccerRecordComparison(homeTeamRecordComparison, awayTeamRecordComparison) {
    if (isDictEmpty(homeTeamRecordComparison) || isDictEmpty(awayTeamRecordComparison)) {
        return <></>;
    }
    var homeTeamStreak = "";
    if (homeTeamRecordComparison.streak.draw > 0) {
        homeTeamStreak = `D ${homeTeamRecordComparison.streak.draw}`;
    } else if (homeTeamRecordComparison.streak.loss > 0) {
        homeTeamStreak = `L ${homeTeamRecordComparison.streak.loss}`;
    } else {
        homeTeamStreak = `W ${homeTeamRecordComparison.streak.win}`;
    }
    var awayTeamStreak = "";
    if (awayTeamRecordComparison.streak.draw > 0) {
        awayTeamStreak = `D ${awayTeamRecordComparison.streak.draw}`;
    } else if (awayTeamRecordComparison.streak.loss > 0) {
        awayTeamStreak = `L ${awayTeamRecordComparison.streak.loss}`;
    } else {
        awayTeamStreak = `W ${awayTeamRecordComparison.streak.win}`;
    }
    return (
        <>
            {
                // Assuming home/away record comparisons should have the same keys
                // We're not showing fav/underdog in the individual timeframe records, those will live in the betting trends
                // Streak is done separately
                Object.keys(homeTeamRecordComparison).filter(x => x !== "favourite" && x !== "underdog" && x !== "streak").map((key) => 
                <div key={key} className="comparative-tbl-row text-base">
                    <div>{`${awayTeamRecordComparison[key].win}-${awayTeamRecordComparison[key].loss}-${awayTeamRecordComparison[key].draw}`}</div>
                    <div className="text-xs-upper">{key}</div>
                    <div>{`${homeTeamRecordComparison[key].win}-${homeTeamRecordComparison[key].loss}-${homeTeamRecordComparison[key].draw}`}</div>
                </div>
                )
            }
            <div className="comparative-tbl-row text-base">
                <div>{awayTeamStreak}</div>
                <div className="text-xs-upper">STREAK</div>
                <div>{homeTeamStreak}</div>
            </div>
        </>
    )
}

// For the streak of head to head, the API returns them ordered by most recent played, meaning for streak you just start from 0
function buildNFLHeadToHeadRecord(games, currentHomeTeamCode, homeTeamRecordComparison, awayTeamRecordComparison) {
    // Assuming this only happens once and that the starting point is initialized with defaults
    var homeTeamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            tie: 0
        }
    };
    var awayTeamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            tie: 0
        }
    };
    games.forEach((game) => {
        if (game.homeTeamCode === currentHomeTeamCode) {
            homeTeamRecordComparison["record"]["win"] += game.homeTeamData.outcome.win;
            homeTeamRecordComparison["record"]["loss"] += game.homeTeamData.outcome.loss;
            homeTeamRecordComparison["record"]["tie"] += game.homeTeamData.outcome.tie;
    
            awayTeamRecordComparison["record"]["win"] += game.awayTeamData.outcome.win;
            awayTeamRecordComparison["record"]["loss"] += game.awayTeamData.outcome.loss;
            awayTeamRecordComparison["record"]["tie"] += game.awayTeamData.outcome.tie;

            homeTeamRecordComparison["home"]["win"] += game.homeTeamData.outcome.win;
            homeTeamRecordComparison["home"]["loss"] += game.homeTeamData.outcome.loss;
            homeTeamRecordComparison["home"]["tie"] += game.homeTeamData.outcome.tie;

            awayTeamRecordComparison["away"]["win"] += game.awayTeamData.outcome.win;
            awayTeamRecordComparison["away"]["loss"] += game.awayTeamData.outcome.loss;
            awayTeamRecordComparison["away"]["tie"] += game.awayTeamData.outcome.tie;

            if (game.homeTeamData.odds.spread < 0) {
                // Home Favourite, Away Underdog
                homeTeamRecordComparison["favourite"]["win"] += game.homeTeamData.outcome.win;
                homeTeamRecordComparison["favourite"]["loss"] += game.homeTeamData.outcome.loss;
                homeTeamRecordComparison["favourite"]["tie"] += game.homeTeamData.outcome.tie;

                awayTeamRecordComparison["underdog"]["win"] += game.awayTeamData.outcome.win;
                awayTeamRecordComparison["underdog"]["loss"] += game.awayTeamData.outcome.loss;
                awayTeamRecordComparison["underdog"]["tie"] += game.awayTeamData.outcome.tie;
            } else if (game.homeTeamData.odds.spread > 0) {
                // Home Underdog, Away Favourite
                homeTeamRecordComparison["underdog"]["win"] += game.homeTeamData.outcome.win;
                homeTeamRecordComparison["underdog"]["loss"] += game.homeTeamData.outcome.loss;
                homeTeamRecordComparison["underdog"]["tie"] += game.homeTeamData.outcome.tie;

                awayTeamRecordComparison["favourite"]["win"] += game.awayTeamData.outcome.win;
                awayTeamRecordComparison["favourite"]["loss"] += game.awayTeamData.outcome.loss;
                awayTeamRecordComparison["favourite"]["tie"] += game.awayTeamData.outcome.tie;
            } else {
                //console.log("Missing spread");
            }
        } else {
            homeTeamRecordComparison["record"]["win"] += game.awayTeamData.outcome.win;
            homeTeamRecordComparison["record"]["loss"] += game.awayTeamData.outcome.loss;
            homeTeamRecordComparison["record"]["tie"] += game.awayTeamData.outcome.tie;
    
            awayTeamRecordComparison["record"]["win"] += game.homeTeamData.outcome.win;
            awayTeamRecordComparison["record"]["loss"] += game.homeTeamData.outcome.loss;
            awayTeamRecordComparison["record"]["tie"] += game.homeTeamData.outcome.tie;

            homeTeamRecordComparison["away"]["win"] += game.awayTeamData.outcome.win;
            homeTeamRecordComparison["away"]["loss"] += game.awayTeamData.outcome.loss;
            homeTeamRecordComparison["away"]["tie"] += game.awayTeamData.outcome.tie;

            awayTeamRecordComparison["home"]["win"] += game.homeTeamData.outcome.win;
            awayTeamRecordComparison["home"]["loss"] += game.homeTeamData.outcome.loss;
            awayTeamRecordComparison["home"]["tie"] += game.homeTeamData.outcome.tie;

            if (game.awayTeamData.odds.spread < 0) {
                // Home Favourite, Away Underdog
                homeTeamRecordComparison["favourite"]["win"] += game.awayTeamData.outcome.win;
                homeTeamRecordComparison["favourite"]["loss"] += game.awayTeamData.outcome.loss;
                homeTeamRecordComparison["favourite"]["tie"] += game.awayTeamData.outcome.tie;

                awayTeamRecordComparison["underdog"]["win"] += game.homeTeamData.outcome.win;
                awayTeamRecordComparison["underdog"]["loss"] += game.homeTeamData.outcome.loss;
                awayTeamRecordComparison["underdog"]["tie"] += game.homeTeamData.outcome.tie;
            } else if (game.awayTeamData.odds.spread > 0) {
                // Home Underdog, Away Favourite
                homeTeamRecordComparison["underdog"]["win"] += game.awayTeamData.outcome.win;
                homeTeamRecordComparison["underdog"]["loss"] += game.awayTeamData.outcome.loss;
                homeTeamRecordComparison["underdog"]["tie"] += game.awayTeamData.outcome.tie;

                awayTeamRecordComparison["favourite"]["win"] += game.homeTeamData.outcome.win;
                awayTeamRecordComparison["favourite"]["loss"] += game.homeTeamData.outcome.loss;
                awayTeamRecordComparison["favourite"]["tie"] += game.homeTeamData.outcome.tie;
            } else {
                //console.log("Missing spread");
            }
        }
    })
    var homeTeamStreak = "";
    var awayTeamStreak = "";
    // const lastGame = games[games.length - 1];
    const lastGame = games[0];
    if (lastGame.homeTeamCode === currentHomeTeamCode) {
        homeTeamStreak = (lastGame.homeTeamData.outcome.win ? "win" : (lastGame.homeTeamData.outcome.loss ? "loss" : "tie"));
        awayTeamStreak = (lastGame.awayTeamData.outcome.win ? "win" : (lastGame.awayTeamData.outcome.loss ? "loss" : "tie"));
    } else {
        awayTeamStreak = (lastGame.homeTeamData.outcome.win ? "win" : (lastGame.homeTeamData.outcome.loss ? "loss" : "tie"));
        homeTeamStreak = (lastGame.awayTeamData.outcome.win ? "win" : (lastGame.awayTeamData.outcome.loss ? "loss" : "tie"));
    }
    homeTeamRecordComparison["streak"][homeTeamStreak] = 1;
    awayTeamRecordComparison["streak"][awayTeamStreak] = 1;
    var homeTeamContinuous = true;
    var awayTeamContinuous = true;
    // Assumes it's ordered by most recent played
    // for (let index = games.length - 2; index >= 0; index--) {
    for (let index = 1; index < games.length; index++) {
        let game = games[index];
        var currentHomeTeamStreak = "";
        var currentAwayTeamStreak = "";
        if (game.homeTeamCode === currentHomeTeamCode) {
            currentHomeTeamStreak = (game.homeTeamData.outcome.win ? "win" : (game.homeTeamData.outcome.loss ? "loss" : "tie"));
            currentAwayTeamStreak = (game.awayTeamData.outcome.win ? "win" : (game.awayTeamData.outcome.loss ? "loss" : "tie"));
        } else {
            currentAwayTeamStreak = (game.homeTeamData.outcome.win ? "win" : (game.homeTeamData.outcome.loss ? "loss" : "tie"));
            currentHomeTeamStreak = (game.awayTeamData.outcome.win ? "win" : (game.awayTeamData.outcome.loss ? "loss" : "tie"));
        }

        if (homeTeamContinuous) {
            if (homeTeamStreak === currentHomeTeamStreak) {
                homeTeamRecordComparison["streak"][homeTeamStreak] += 1;   
            } else {
                homeTeamContinuous = false;
            }
        }

        if (awayTeamContinuous) {
            if (awayTeamStreak === currentAwayTeamStreak) {
                awayTeamRecordComparison["streak"][awayTeamStreak] += 1;   
            } else {
                awayTeamContinuous = false;
            }
        }
    }
    return {homeTeamRecordComparison: homeTeamRecordComparison, awayTeamRecordComparison: awayTeamRecordComparison};
}

function buildNHLHeadToHeadRecord(games, currentHomeTeamCode, homeTeamRecordComparison, awayTeamRecordComparison) {
    // Assuming this only happens once and that the starting point is initialized with defaults
    var homeTeamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            otl: 0
        }
    };
    var awayTeamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            otl: 0
        }
    };
    games.forEach((game) => {
        if (game.homeTeamCode === currentHomeTeamCode) {
            homeTeamRecordComparison["record"]["win"] += game.homeTeamData.outcome.win;
            homeTeamRecordComparison["record"]["loss"] += game.homeTeamData.outcome.loss;
            homeTeamRecordComparison["record"]["otl"] += game.homeTeamData.outcome.overtimeLoss;
    
            awayTeamRecordComparison["record"]["win"] += game.awayTeamData.outcome.win;
            awayTeamRecordComparison["record"]["loss"] += game.awayTeamData.outcome.loss;
            awayTeamRecordComparison["record"]["otl"] += game.awayTeamData.outcome.overtimeLoss;

            homeTeamRecordComparison["home"]["win"] += game.homeTeamData.outcome.win;
            homeTeamRecordComparison["home"]["loss"] += game.homeTeamData.outcome.loss;
            homeTeamRecordComparison["home"]["otl"] += game.homeTeamData.outcome.overtimeLoss;

            awayTeamRecordComparison["away"]["win"] += game.awayTeamData.outcome.win;
            awayTeamRecordComparison["away"]["loss"] += game.awayTeamData.outcome.loss;
            awayTeamRecordComparison["away"]["otl"] += game.awayTeamData.outcome.overtimeLoss;

            if (game.homeTeamData.odds.spread < 0) {
                // Home Favourite, Away Underdog
                homeTeamRecordComparison["favourite"]["win"] += game.homeTeamData.outcome.win;
                homeTeamRecordComparison["favourite"]["loss"] += game.homeTeamData.outcome.loss;
                homeTeamRecordComparison["favourite"]["otl"] += game.homeTeamData.outcome.overtimeLoss;

                awayTeamRecordComparison["underdog"]["win"] += game.awayTeamData.outcome.win;
                awayTeamRecordComparison["underdog"]["loss"] += game.awayTeamData.outcome.loss;
                awayTeamRecordComparison["underdog"]["otl"] += game.awayTeamData.outcome.overtimeLoss;
            } else if (game.homeTeamData.odds.spread > 0) {
                // Home Underdog, Away Favourite
                homeTeamRecordComparison["underdog"]["win"] += game.homeTeamData.outcome.win;
                homeTeamRecordComparison["underdog"]["loss"] += game.homeTeamData.outcome.loss;
                homeTeamRecordComparison["underdog"]["otl"] += game.homeTeamData.outcome.overtimeLoss;

                awayTeamRecordComparison["favourite"]["win"] += game.awayTeamData.outcome.win;
                awayTeamRecordComparison["favourite"]["loss"] += game.awayTeamData.outcome.loss;
                awayTeamRecordComparison["favourite"]["otl"] += game.awayTeamData.outcome.overtimeLoss;
            } else {
                //console.log("Missing spread");
            }
        } else {
            homeTeamRecordComparison["record"]["win"] += game.awayTeamData.outcome.win;
            homeTeamRecordComparison["record"]["loss"] += game.awayTeamData.outcome.loss;
            homeTeamRecordComparison["record"]["otl"] += game.awayTeamData.outcome.overtimeLoss;
    
            awayTeamRecordComparison["record"]["win"] += game.homeTeamData.outcome.win;
            awayTeamRecordComparison["record"]["loss"] += game.homeTeamData.outcome.loss;
            awayTeamRecordComparison["record"]["otl"] += game.homeTeamData.outcome.overtimeLoss;

            homeTeamRecordComparison["away"]["win"] += game.awayTeamData.outcome.win;
            homeTeamRecordComparison["away"]["loss"] += game.awayTeamData.outcome.loss;
            homeTeamRecordComparison["away"]["otl"] += game.awayTeamData.outcome.overtimeLoss;

            awayTeamRecordComparison["home"]["win"] += game.homeTeamData.outcome.win;
            awayTeamRecordComparison["home"]["loss"] += game.homeTeamData.outcome.loss;
            awayTeamRecordComparison["home"]["otl"] += game.homeTeamData.outcome.overtimeLoss;

            if (game.awayTeamData.odds.spread < 0) {
                // Home Favourite, Away Underdog
                homeTeamRecordComparison["favourite"]["win"] += game.awayTeamData.outcome.win;
                homeTeamRecordComparison["favourite"]["loss"] += game.awayTeamData.outcome.loss;
                homeTeamRecordComparison["favourite"]["otl"] += game.awayTeamData.outcome.overtimeLoss;

                awayTeamRecordComparison["underdog"]["win"] += game.homeTeamData.outcome.win;
                awayTeamRecordComparison["underdog"]["loss"] += game.homeTeamData.outcome.loss;
                awayTeamRecordComparison["underdog"]["otl"] += game.homeTeamData.outcome.overtimeLoss;
            } else if (game.awayTeamData.odds.spread > 0) {
                // Home Underdog, Away Favourite
                homeTeamRecordComparison["underdog"]["win"] += game.awayTeamData.outcome.win;
                homeTeamRecordComparison["underdog"]["loss"] += game.awayTeamData.outcome.loss;
                homeTeamRecordComparison["underdog"]["otl"] += game.awayTeamData.outcome.overtimeLoss;

                awayTeamRecordComparison["favourite"]["win"] += game.homeTeamData.outcome.win;
                awayTeamRecordComparison["favourite"]["loss"] += game.homeTeamData.outcome.loss;
                awayTeamRecordComparison["favourite"]["otl"] += game.homeTeamData.outcome.overtimeLoss;
            } else {
                //console.log("Missing spread");
            }
        }
    })
    var homeTeamStreak = "";
    var awayTeamStreak = "";
    // const lastGame = games[games.length - 1];
    const lastGame = games[0];
    if (lastGame.homeTeamCode === currentHomeTeamCode) {
        homeTeamStreak = (lastGame.homeTeamData.outcome.win ? "win" : (lastGame.homeTeamData.outcome.loss ? "loss" : "otl"));
        awayTeamStreak = (lastGame.awayTeamData.outcome.win ? "win" : (lastGame.awayTeamData.outcome.loss ? "loss" : "otl"));
    } else {
        awayTeamStreak = (lastGame.homeTeamData.outcome.win ? "win" : (lastGame.homeTeamData.outcome.loss ? "loss" : "otl"));
        homeTeamStreak = (lastGame.awayTeamData.outcome.win ? "win" : (lastGame.awayTeamData.outcome.loss ? "loss" : "otl"));
    }
    homeTeamRecordComparison["streak"][homeTeamStreak] = 1;
    awayTeamRecordComparison["streak"][awayTeamStreak] = 1;
    var homeTeamContinuous = true;
    var awayTeamContinuous = true;
    // Assumes it's ordered by most recent played
    // for (let index = games.length - 2; index >= 0; index--) {
    for (let index = 1; index < games.length; index++) {
        let game = games[index];
        var currentHomeTeamStreak = "";
        var currentAwayTeamStreak = "";
        if (game.homeTeamCode === currentHomeTeamCode) {
            currentHomeTeamStreak = (game.homeTeamData.outcome.win ? "win" : (game.homeTeamData.outcome.loss ? "loss" : "otl"));
            currentAwayTeamStreak = (game.awayTeamData.outcome.win ? "win" : (game.awayTeamData.outcome.loss ? "loss" : "otl"));
        } else {
            currentAwayTeamStreak = (game.homeTeamData.outcome.win ? "win" : (game.homeTeamData.outcome.loss ? "loss" : "otl"));
            currentHomeTeamStreak = (game.awayTeamData.outcome.win ? "win" : (game.awayTeamData.outcome.loss ? "loss" : "otl"));
        }

        if (homeTeamContinuous) {
            if (homeTeamStreak === currentHomeTeamStreak) {
                homeTeamRecordComparison["streak"][homeTeamStreak] += 1;   
            } else {
                homeTeamContinuous = false;
            }
        }

        if (awayTeamContinuous) {
            if (awayTeamStreak === currentAwayTeamStreak) {
                awayTeamRecordComparison["streak"][awayTeamStreak] += 1;   
            } else {
                awayTeamContinuous = false;
            }
        }
    }
    return {homeTeamRecordComparison: homeTeamRecordComparison, awayTeamRecordComparison, awayTeamRecordComparison};
}

function buildNBAHeadToHeadRecord(games, currentHomeTeamCode, homeTeamRecordComparison, awayTeamRecordComparison) {
    // Assuming this only happens once and that the starting point is initialized with defaults
    var homeTeamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0
        },
        "home" : {
            win: 0,
            loss: 0
        },
        "away" : {
            win: 0,
            loss: 0
        },
        "favourite" : {
            win: 0,
            loss: 0
        },
        "underdog" : {
            win: 0,
            loss: 0
        },
        "streak" : {
            win: 0,
            loss: 0
        }
    };
    var awayTeamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0
        },
        "home" : {
            win: 0,
            loss: 0
        },
        "away" : {
            win: 0,
            loss: 0
        },
        "favourite" : {
            win: 0,
            loss: 0
        },
        "underdog" : {
            win: 0,
            loss: 0
        },
        "streak" : {
            win: 0,
            loss: 0
        }
    };
    games.forEach((game) => {
        if (game.homeTeamCode === currentHomeTeamCode) {
            homeTeamRecordComparison["record"]["win"] += game.homeTeamData.outcome.win;
            homeTeamRecordComparison["record"]["loss"] += game.homeTeamData.outcome.loss;
    
            awayTeamRecordComparison["record"]["win"] += game.awayTeamData.outcome.win;
            awayTeamRecordComparison["record"]["loss"] += game.awayTeamData.outcome.loss;

            homeTeamRecordComparison["home"]["win"] += game.homeTeamData.outcome.win;
            homeTeamRecordComparison["home"]["loss"] += game.homeTeamData.outcome.loss;

            awayTeamRecordComparison["away"]["win"] += game.awayTeamData.outcome.win;
            awayTeamRecordComparison["away"]["loss"] += game.awayTeamData.outcome.loss;

            if (game.homeTeamData.odds.spread < 0) {
                // Home Favourite, Away Underdog
                homeTeamRecordComparison["favourite"]["win"] += game.homeTeamData.outcome.win;
                homeTeamRecordComparison["favourite"]["loss"] += game.homeTeamData.outcome.loss;

                awayTeamRecordComparison["underdog"]["win"] += game.awayTeamData.outcome.win;
                awayTeamRecordComparison["underdog"]["loss"] += game.awayTeamData.outcome.loss;
            } else if (game.homeTeamData.odds.spread > 0) {
                // Home Underdog, Away Favourite
                homeTeamRecordComparison["underdog"]["win"] += game.homeTeamData.outcome.win;
                homeTeamRecordComparison["underdog"]["loss"] += game.homeTeamData.outcome.loss;

                awayTeamRecordComparison["favourite"]["win"] += game.awayTeamData.outcome.win;
                awayTeamRecordComparison["favourite"]["loss"] += game.awayTeamData.outcome.loss;
            } else {
                //console.log("Missing spread");
            }
        } else {
            homeTeamRecordComparison["record"]["win"] += game.awayTeamData.outcome.win;
            homeTeamRecordComparison["record"]["loss"] += game.awayTeamData.outcome.loss;
    
            awayTeamRecordComparison["record"]["win"] += game.homeTeamData.outcome.win;
            awayTeamRecordComparison["record"]["loss"] += game.homeTeamData.outcome.loss;

            homeTeamRecordComparison["away"]["win"] += game.awayTeamData.outcome.win;
            homeTeamRecordComparison["away"]["loss"] += game.awayTeamData.outcome.loss;

            awayTeamRecordComparison["home"]["win"] += game.homeTeamData.outcome.win;
            awayTeamRecordComparison["home"]["loss"] += game.homeTeamData.outcome.loss;

            if (game.awayTeamData.odds.spread < 0) {
                // Home Favourite, Away Underdog
                homeTeamRecordComparison["favourite"]["win"] += game.awayTeamData.outcome.win;
                homeTeamRecordComparison["favourite"]["loss"] += game.awayTeamData.outcome.loss;

                awayTeamRecordComparison["underdog"]["win"] += game.homeTeamData.outcome.win;
                awayTeamRecordComparison["underdog"]["loss"] += game.homeTeamData.outcome.loss;
            } else if (game.awayTeamData.odds.spread > 0) {
                // Home Underdog, Away Favourite
                homeTeamRecordComparison["underdog"]["win"] += game.awayTeamData.outcome.win;
                homeTeamRecordComparison["underdog"]["loss"] += game.awayTeamData.outcome.loss;

                awayTeamRecordComparison["favourite"]["win"] += game.homeTeamData.outcome.win;
                awayTeamRecordComparison["favourite"]["loss"] += game.homeTeamData.outcome.loss;
            } else {
                //console.log("Missing spread");
            }
        }
    })
    var homeTeamStreak = "";
    var awayTeamStreak = "";
    const lastGame = games[0];
    if (lastGame.homeTeamCode === currentHomeTeamCode) {
        homeTeamStreak = (lastGame.homeTeamData.outcome.win ? "win" : "loss");
        awayTeamStreak = (lastGame.awayTeamData.outcome.win ? "win" : "loss");
    } else {
        awayTeamStreak = (lastGame.homeTeamData.outcome.win ? "win" : "loss");
        homeTeamStreak = (lastGame.awayTeamData.outcome.win ? "win" : "loss");
    }
    homeTeamRecordComparison["streak"][homeTeamStreak] = 1;
    awayTeamRecordComparison["streak"][awayTeamStreak] = 1;
    var homeTeamContinuous = true;
    var awayTeamContinuous = true;
    // Assumes it's ordered by most recent played
    for (let index = 1; index < games.length; index++) {
        let game = games[index];
        var currentHomeTeamStreak = "";
        var currentAwayTeamStreak = "";
        if (game.homeTeamCode === currentHomeTeamCode) {
            currentHomeTeamStreak = (game.homeTeamData.outcome.win ? "win" : "loss");
            currentAwayTeamStreak = (game.awayTeamData.outcome.win ? "win" : "loss");
        } else {
            currentAwayTeamStreak = (game.homeTeamData.outcome.win ? "win" : "loss");
            currentHomeTeamStreak = (game.awayTeamData.outcome.win ? "win" : "loss");
        }

        if (homeTeamContinuous) {
            if (homeTeamStreak === currentHomeTeamStreak) {
                homeTeamRecordComparison["streak"][homeTeamStreak] += 1;   
            } else {
                homeTeamContinuous = false;
            }
        }

        if (awayTeamContinuous) {
            if (awayTeamStreak === currentAwayTeamStreak) {
                awayTeamRecordComparison["streak"][awayTeamStreak] += 1;   
            } else {
                awayTeamContinuous = false;
            }
        }
    }
    return {homeTeamRecordComparison: homeTeamRecordComparison, awayTeamRecordComparison, awayTeamRecordComparison};
}

function buildMLBHeadToHeadRecord(games, currentHomeTeamCode, homeTeamRecordComparison, awayTeamRecordComparison) {
    return buildNBAHeadToHeadRecord(games, currentHomeTeamCode, homeTeamRecordComparison, awayTeamRecordComparison);
}

function buildSoccerHeadToHeadRecord(games, currentHomeTeamCode, homeTeamRecordComparison, awayTeamRecordComparison) {
    // Assuming this only happens once and that the starting point is initialized with defaults
    var homeTeamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            draw: 0
        }
    };
    var awayTeamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            draw: 0
        }
    };
    games.forEach((game) => {
        if (game.homeTeamCode === currentHomeTeamCode) {
            homeTeamRecordComparison["record"]["win"] += game.homeTeamData.outcome.win;
            homeTeamRecordComparison["record"]["loss"] += game.homeTeamData.outcome.loss;
            homeTeamRecordComparison["record"]["draw"] += game.homeTeamData.outcome.draw;
    
            awayTeamRecordComparison["record"]["win"] += game.awayTeamData.outcome.win;
            awayTeamRecordComparison["record"]["loss"] += game.awayTeamData.outcome.loss;
            awayTeamRecordComparison["record"]["draw"] += game.awayTeamData.outcome.draw;

            homeTeamRecordComparison["home"]["win"] += game.homeTeamData.outcome.win;
            homeTeamRecordComparison["home"]["loss"] += game.homeTeamData.outcome.loss;
            homeTeamRecordComparison["home"]["draw"] += game.homeTeamData.outcome.draw;

            awayTeamRecordComparison["away"]["win"] += game.awayTeamData.outcome.win;
            awayTeamRecordComparison["away"]["loss"] += game.awayTeamData.outcome.loss;
            awayTeamRecordComparison["away"]["draw"] += game.awayTeamData.outcome.draw;

            // if (game.homeTeamData.odds.spread < 0) {
            //     // Home Favourite, Away Underdog
            //     homeTeamRecordComparison["favourite"]["win"] += game.homeTeamData.outcome.win;
            //     homeTeamRecordComparison["favourite"]["loss"] += game.homeTeamData.outcome.loss;
            //     homeTeamRecordComparison["favourite"]["draw"] += game.homeTeamData.outcome.draw;

            //     awayTeamRecordComparison["underdog"]["win"] += game.awayTeamData.outcome.win;
            //     awayTeamRecordComparison["underdog"]["loss"] += game.awayTeamData.outcome.loss;
            //     awayTeamRecordComparison["underdog"]["draw"] += game.awayTeamData.outcome.draw;
            // } else if (game.homeTeamData.odds.spread > 0) {
            //     // Home Underdog, Away Favourite
            //     homeTeamRecordComparison["underdog"]["win"] += game.homeTeamData.outcome.win;
            //     homeTeamRecordComparison["underdog"]["loss"] += game.homeTeamData.outcome.loss;
            //     homeTeamRecordComparison["underdog"]["draw"] += game.homeTeamData.outcome.draw;

            //     awayTeamRecordComparison["favourite"]["win"] += game.awayTeamData.outcome.win;
            //     awayTeamRecordComparison["favourite"]["loss"] += game.awayTeamData.outcome.loss;
            //     awayTeamRecordComparison["favourite"]["draw"] += game.awayTeamData.outcome.draw;
            // } else {
            //     //console.log("Missing spread");
            // }
        } else {
            homeTeamRecordComparison["record"]["win"] += game.awayTeamData.outcome.win;
            homeTeamRecordComparison["record"]["loss"] += game.awayTeamData.outcome.loss;
            homeTeamRecordComparison["record"]["draw"] += game.awayTeamData.outcome.draw;
    
            awayTeamRecordComparison["record"]["win"] += game.homeTeamData.outcome.win;
            awayTeamRecordComparison["record"]["loss"] += game.homeTeamData.outcome.loss;
            awayTeamRecordComparison["record"]["draw"] += game.homeTeamData.outcome.draw;

            homeTeamRecordComparison["away"]["win"] += game.awayTeamData.outcome.win;
            homeTeamRecordComparison["away"]["loss"] += game.awayTeamData.outcome.loss;
            homeTeamRecordComparison["away"]["draw"] += game.awayTeamData.outcome.draw;

            awayTeamRecordComparison["home"]["win"] += game.homeTeamData.outcome.win;
            awayTeamRecordComparison["home"]["loss"] += game.homeTeamData.outcome.loss;
            awayTeamRecordComparison["home"]["draw"] += game.homeTeamData.outcome.draw;

            // if (game.awayTeamData.odds.spread < 0) {
            //     // Home Favourite, Away Underdog
            //     homeTeamRecordComparison["favourite"]["win"] += game.awayTeamData.outcome.win;
            //     homeTeamRecordComparison["favourite"]["loss"] += game.awayTeamData.outcome.loss;
            //     homeTeamRecordComparison["favourite"]["draw"] += game.awayTeamData.outcome.draw;

            //     awayTeamRecordComparison["underdog"]["win"] += game.homeTeamData.outcome.win;
            //     awayTeamRecordComparison["underdog"]["loss"] += game.homeTeamData.outcome.loss;
            //     awayTeamRecordComparison["underdog"]["draw"] += game.homeTeamData.outcome.draw;
            // } else if (game.awayTeamData.odds.spread > 0) {
            //     // Home Underdog, Away Favourite
            //     homeTeamRecordComparison["underdog"]["win"] += game.awayTeamData.outcome.win;
            //     homeTeamRecordComparison["underdog"]["loss"] += game.awayTeamData.outcome.loss;
            //     homeTeamRecordComparison["underdog"]["draw"] += game.awayTeamData.outcome.draw;

            //     awayTeamRecordComparison["favourite"]["win"] += game.homeTeamData.outcome.win;
            //     awayTeamRecordComparison["favourite"]["loss"] += game.homeTeamData.outcome.loss;
            //     awayTeamRecordComparison["favourite"]["draw"] += game.homeTeamData.outcome.draw;
            // } else {
            //     //console.log("Missing spread");
            // }
        }
    })
    var homeTeamStreak = "";
    var awayTeamStreak = "";
    // const lastGame = games[games.length - 1];
    const lastGame = games[0];
    if (lastGame.homeTeamCode === currentHomeTeamCode) {
        homeTeamStreak = (lastGame.homeTeamData.outcome.win ? "win" : (lastGame.homeTeamData.outcome.loss ? "loss" : "draw"));
        awayTeamStreak = (lastGame.awayTeamData.outcome.win ? "win" : (lastGame.awayTeamData.outcome.loss ? "loss" : "draw"));
    } else {
        awayTeamStreak = (lastGame.homeTeamData.outcome.win ? "win" : (lastGame.homeTeamData.outcome.loss ? "loss" : "draw"));
        homeTeamStreak = (lastGame.awayTeamData.outcome.win ? "win" : (lastGame.awayTeamData.outcome.loss ? "loss" : "draw"));
    }
    homeTeamRecordComparison["streak"][homeTeamStreak] = 1;
    awayTeamRecordComparison["streak"][awayTeamStreak] = 1;
    var homeTeamContinuous = true;
    var awayTeamContinuous = true;
    // Assumes it's ordered by most recent played
    // for (let index = games.length - 2; index >= 0; index--) {
    for (let index = 1; index < games.length; index++) {
        let game = games[index];
        var currentHomeTeamStreak = "";
        var currentAwayTeamStreak = "";
        if (game.homeTeamCode === currentHomeTeamCode) {
            currentHomeTeamStreak = (game.homeTeamData.outcome.win ? "win" : (game.homeTeamData.outcome.loss ? "loss" : "draw"));
            currentAwayTeamStreak = (game.awayTeamData.outcome.win ? "win" : (game.awayTeamData.outcome.loss ? "loss" : "draw"));
        } else {
            currentAwayTeamStreak = (game.homeTeamData.outcome.win ? "win" : (game.homeTeamData.outcome.loss ? "loss" : "draw"));
            currentHomeTeamStreak = (game.awayTeamData.outcome.win ? "win" : (game.awayTeamData.outcome.loss ? "loss" : "draw"));
        }

        if (homeTeamContinuous) {
            if (homeTeamStreak === currentHomeTeamStreak) {
                homeTeamRecordComparison["streak"][homeTeamStreak] += 1;   
            } else {
                homeTeamContinuous = false;
            }
        }

        if (awayTeamContinuous) {
            if (awayTeamStreak === currentAwayTeamStreak) {
                awayTeamRecordComparison["streak"][awayTeamStreak] += 1;   
            } else {
                awayTeamContinuous = false;
            }
        }
    }
    return {homeTeamRecordComparison: homeTeamRecordComparison, awayTeamRecordComparison, awayTeamRecordComparison};
}

// For the recent form, the API returns them ordered by most recent played, meaning for streak you just start from 0
// This uses TeamGameRecord instead of Game object
function buildIndividualNFLTeamGameRecord(teamGames, teamRecordComparison) {
    // console.log("teamGames: ", teamGames);
    // Assuming this only happens once and that the starting point is initialized with defaults
    var teamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            tie: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            tie: 0
        }
    };
    teamGames.forEach((teamData) => {
        var splitName = "";
        if (teamData.isHome) {
            splitName = "home";
        } else {
            splitName = "away";
        }

        teamRecordComparison["record"]["win"] += teamData.win;
        teamRecordComparison["record"]["loss"] += teamData.loss;
        teamRecordComparison["record"]["tie"] += teamData.tie;

        teamRecordComparison[splitName]["win"] += teamData.win;
        teamRecordComparison[splitName]["loss"] += teamData.loss;
        teamRecordComparison[splitName]["tie"] += teamData.tie;

        if (teamData.odds.spread < 0) {
            // Home Favourite, Away Underdog
            teamRecordComparison["favourite"]["win"] += teamData.win;
            teamRecordComparison["favourite"]["loss"] += teamData.loss;
            teamRecordComparison["favourite"]["tie"] += teamData.tie;
        } else if (teamData.odds.spread > 0) {
            // Home Underdog, Away Favourite
            teamRecordComparison["underdog"]["win"] += teamData.win;
            teamRecordComparison["underdog"]["loss"] += teamData.loss;
            teamRecordComparison["underdog"]["tie"] += teamData.tie;
        } else {
            //console.log("Missing spread");
        }
    })
    var teamStreak = "";
    // const lastGame = teamGames[teamGames.length - 1];
    const lastGame = teamGames[0];
    teamStreak = (lastGame.win ? "win" : (lastGame.loss ? "loss" : "tie"));
    teamRecordComparison["streak"][teamStreak] = 1;
    var continuous = true;
    // Assumes it's ordered by most recent played
    // for (let index = teamGames.length - 2; index >= 0; index--) {
    for (let index = 1; index < teamGames.length; index++) {
        let game = teamGames[index];
        var currentTeamStreak = "";
        currentTeamStreak = (game.win ? "win" : (game.loss ? "loss" : "tie"));

        if (continuous) {
            if (teamStreak === currentTeamStreak) {
                teamRecordComparison["streak"][teamStreak] += 1;   
            } else {
                continuous = false;
            }
        }
    }
    return teamRecordComparison;
}

function buildIndividualNHLTeamGameRecord(teamGames, teamRecordComparison) {
    // Assuming this only happens once and that the starting point is initialized with defaults
    var teamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            otl: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            otl: 0
        }
    };
    teamGames.forEach((teamData) => {
        var splitName = "";
        if (teamData.isHome) {
            splitName = "home";
        } else {
            splitName = "away";
        }

        teamRecordComparison["record"]["win"] += teamData.win;
        teamRecordComparison["record"]["loss"] += teamData.loss;
        teamRecordComparison["record"]["otl"] += teamData.overtimeLoss;

        teamRecordComparison[splitName]["win"] += teamData.win;
        teamRecordComparison[splitName]["loss"] += teamData.loss;
        teamRecordComparison[splitName]["otl"] += teamData.overtimeLoss;

        if (teamData.odds.spread < 0) {
            // Home Favourite, Away Underdog
            teamRecordComparison["favourite"]["win"] += teamData.win;
            teamRecordComparison["favourite"]["loss"] += teamData.loss;
            teamRecordComparison["favourite"]["otl"] += teamData.overtimeLoss;
        } else if (teamData.odds.spread > 0) {
            // Home Underdog, Away Favourite
            teamRecordComparison["underdog"]["win"] += teamData.win;
            teamRecordComparison["underdog"]["loss"] += teamData.loss;
            teamRecordComparison["underdog"]["otl"] += teamData.overtimeLoss;
        } else {
            //console.log("Missing spread");
        }
    })
    var teamStreak = "";
    // const lastGame = teamGames[teamGames.length - 1];
    const lastGame = teamGames[0];
    teamStreak = (lastGame.win ? "win" : (lastGame.loss ? "loss" : "otl"));
    teamRecordComparison["streak"][teamStreak] = 1;
    var continuous = true;
    // Assumes it's ordered by most recent played
    // for (let index = teamGames.length - 2; index >= 0; index--) {
    for (let index = 1; index < teamGames.length; index++) {
        let game = teamGames[index];
        var currentTeamStreak = "";
        currentTeamStreak = (game.win ? "win" : (game.loss ? "loss" : "otl"));

        if (continuous) {
            if (teamStreak === currentTeamStreak) {
                teamRecordComparison["streak"][teamStreak] += 1;   
            } else {
                continuous = false;
            }
        }
    }
    return teamRecordComparison;
}

function buildIndividualNBATeamGameRecord(teamGames, teamRecordComparison) {
    // Assuming this only happens once and that the starting point is initialized with defaults
    var teamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0
        },
        "home" : {
            win: 0,
            loss: 0
        },
        "away" : {
            win: 0,
            loss: 0
        },
        "favourite" : {
            win: 0,
            loss: 0
        },
        "underdog" : {
            win: 0,
            loss: 0
        },
        "streak" : {
            win: 0,
            loss: 0
        }
    };
    teamGames.forEach((teamData) => {
        var splitName = "";
        if (teamData.isHome) {
            splitName = "home";
        } else {
            splitName = "away";
        }

        teamRecordComparison["record"]["win"] += teamData.win;
        teamRecordComparison["record"]["loss"] += teamData.loss;

        teamRecordComparison[splitName]["win"] += teamData.win;
        teamRecordComparison[splitName]["loss"] += teamData.loss;

        if (teamData.odds.spread < 0) {
            // Home Favourite, Away Underdog
            teamRecordComparison["favourite"]["win"] += teamData.win;
            teamRecordComparison["favourite"]["loss"] += teamData.loss;
        } else if (teamData.odds.spread > 0) {
            // Home Underdog, Away Favourite
            teamRecordComparison["underdog"]["win"] += teamData.win;
            teamRecordComparison["underdog"]["loss"] += teamData.loss;
        } else {
            //console.log("Missing spread");
        }
    })
    var teamStreak = "";
    const lastGame = teamGames[0];
    teamStreak = (lastGame.win ? "win" : "loss");
    teamRecordComparison["streak"][teamStreak] = 1;
    var continuous = true;
    // Assumes it's ordered by most recent played
    for (let index = 1; index < teamGames.length; index++) {
        let game = teamGames[index];
        var currentTeamStreak = "";
        currentTeamStreak = (game.win ? "win" : "loss");

        if (continuous) {
            if (teamStreak === currentTeamStreak) {
                teamRecordComparison["streak"][teamStreak] += 1;   
            } else {
                continuous = false;
            }
        }
    }
    return teamRecordComparison;
}

function buildIndividualMLBTeamGameRecord(teamGames, teamRecordComparison) {
    return buildIndividualNBATeamGameRecord(teamGames, teamRecordComparison);
}

function buildIndividualSoccerTeamGameRecord(teamGames, teamRecordComparison) {
    // Assuming this only happens once and that the starting point is initialized with defaults
    var teamRecordComparison = {
        "record" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "home" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "away" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "favourite" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "underdog" : {
            win: 0,
            loss: 0,
            draw: 0
        },
        "streak" : {
            win: 0,
            loss: 0,
            draw: 0
        }
    };
    teamGames.forEach((teamData) => {
        var splitName = "";
        if (teamData.isHome) {
            splitName = "home";
        } else {
            splitName = "away";
        }

        teamRecordComparison["record"]["win"] += teamData.win;
        teamRecordComparison["record"]["loss"] += teamData.loss;
        teamRecordComparison["record"]["draw"] += teamData.draw;

        teamRecordComparison[splitName]["win"] += teamData.win;
        teamRecordComparison[splitName]["loss"] += teamData.loss;
        teamRecordComparison[splitName]["draw"] += teamData.draw;

        // if (teamData.odds.spread < 0) {
        //     // Home Favourite, Away Underdog
        //     teamRecordComparison["favourite"]["win"] += teamData.win;
        //     teamRecordComparison["favourite"]["loss"] += teamData.loss;
        //     teamRecordComparison["favourite"]["draw"] += teamData.draw;
        // } else if (teamData.odds.spread > 0) {
        //     // Home Underdog, Away Favourite
        //     teamRecordComparison["underdog"]["win"] += teamData.win;
        //     teamRecordComparison["underdog"]["loss"] += teamData.loss;
        //     teamRecordComparison["underdog"]["draw"] += teamData.draw;
        // } else {
        //     //console.log("Missing spread");
        // }
    })
    var teamStreak = "";
    // const lastGame = teamGames[teamGames.length - 1];
    if (teamGames.length > 0) {
        const lastGame = teamGames[0];
        teamStreak = (lastGame.win ? "win" : (lastGame.loss ? "loss" : "draw"));
        teamRecordComparison["streak"][teamStreak] = 1;
        var continuous = true;
        // Assumes it's ordered by most recent played
        // for (let index = teamGames.length - 2; index >= 0; index--) {
        for (let index = 1; index < teamGames.length; index++) {
            let game = teamGames[index];
            var currentTeamStreak = "";
            currentTeamStreak = (game.win ? "win" : (game.loss ? "loss" : "draw"));
    
            if (continuous) {
                if (teamStreak === currentTeamStreak) {
                    teamRecordComparison["streak"][teamStreak] += 1;   
                } else {
                    continuous = false;
                }
            }
        }
    } else {
        teamRecordComparison["streak"][teamStreak] = 0;
    }
    return teamRecordComparison;
}

class Playbook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        upcomingGamesLeftScroll: false,
        upcomingGamesRightScroll: false,
        hasReachedEndSportsBook: false,
        homeRssFeed: null,
        awayRssFeed: null,
        homeTeamStanding: {},
        awayTeamStanding: {},
        allStandings: [],
        game: {},
        upcomingGames: [],
        uptrendInsights: [],
        downtrendInsights: [],
        seasonFavoriteRecords: {},
        matchupRecords: {},
        matchupFavoriteRecords: {},
        recentRecords: {},
        recentFavoriteRecords: {},
    };

    this.userInitialized = false;
    // TODO: if a game is specified but it doesn't exist or has already been played, grab the next available game
    this.gameID = this.props.match.params.gameID;
    this.insights = [];
    // Used to calculate the percentage to win
    // Holds the matchup, season, form calculated insights
    this.homeTeamInsights = {};
    this.awayTeamInsights = {};

    this.moveMarker = this.moveMarker.bind(this);
    this.anchorHeightCounter = this.anchorHeightCounter.bind(this);
    this.addInsights = this.addInsights.bind(this);
    this.setSeasonFavoriteRecords = this.setSeasonFavoriteRecords.bind(this);
    this.setMatchupRecords = this.setMatchupRecords.bind(this);
    this.setMatchupFavoriteRecords = this.setMatchupFavoriteRecords.bind(this);
    this.setRecentRecords = this.setRecentRecords.bind(this);
    this.setRecentFavoriteRecords = this.setRecentFavoriteRecords.bind(this);
    this.loadGenericPlaybook = this.loadGenericPlaybook.bind(this);
    this.loadGameData = this.loadGameData.bind(this);
    this.scrollableSportbooks = React.createRef();
  }

  componentDidMount() {
    document.title = 'Playbook - Linemate';

    var moveMarkerFunc = this.moveMarker;

    window.addEventListener('scroll', (e) => {
        // e.preventDefault();
        // $('.side-nav li').removeClass('active');
        moveMarkerFunc();
    });

    // Desktop version
    $('.side-nav a').on("click", function(e) {
        var target = this.hash;
        // Might have to adjust this for the scrollable games on top
        var offset = 200; 
        // e.preventDefault();
        $('.side-nav li').removeClass('active');
        $(this).parents('li').addClass('active');
        $('html, body').stop().animate({
            'scrollTop': $(target).offset().top - offset
        }, 500, 'swing', function() {
            window.location.hash = target;
        });
    });

    // The react scrollspy messes up the wrapping component's class, need to add it back here
    $('nav').addClass('mobile-navbar');
    // Mobile version
    $('nav a').on("click", function(e) {
        var target = this.hash;
        // Might have to adjust this for the scrollable games on top
        var offset = 225; 
        // e.preventDefault();
        $('nav div').removeClass('active');
        $(this).parents('div').addClass('active');
        $('html, body').stop().animate({
            'scrollTop': $(target).offset().top - offset
        }, 500, 'swing', function() {
            window.location.hash = target;
        });
    });

    if (this.gameID) {
        this.loadGameData(null);
    } else {
        this.loadGenericPlaybook(null);
    }
  }

  loadGenericPlaybook(games) {
    if (games) {
        if (games.length > 0) {
            window.history.replaceState(null, null, `${this.props.activeLeague}/playbook/${games[0].id}`);
            this.gameID = games[0].id;
            this.loadGameData(games);
        } else {
            window.location.href = `${this.props.activeLeague}/gameday`;
        }
    } else {
        // This could be reduced to a single entry if we implement it on the API side to just return the next game
        var promises = [
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/current?reduced=true&recordType=${LEAGUE_CURRENT_RECORD_TYPE[this.props.activeLeague]}${isWeeklyCompetition(this.props.activeLeague) ? "&format=weekly" : ""}`).then(data => {return data.json();}),
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/upcoming?recordType=${LEAGUE_CURRENT_RECORD_TYPE[this.props.activeLeague]}${isWeeklyCompetition(this.props.activeLeague) ? "&format=weekly" : ""}`).then(data => {return data.json();})
        ];
        Promise.all(promises)
        .then(result => {
            const allGames = result[0].concat(result[1]).filter(game => game.status === "SCHEDULED");
            if (allGames.length == 0) {
                window.location.href = `${this.props.activeLeague}/gameday`;
            } else {
                // Update the URL
                window.history.replaceState(null, null, `${this.props.activeLeague}/playbook/${allGames[0].id}`);
                this.gameID = allGames[0].id;
                this.loadGameData(allGames);
            }
        })
        .catch(error => {
            console.log("Error loading next game for playbook: " + error);
            window.location.href = `${this.props.activeLeague}/gameday`;
        });
    }
}

  loadGameData(preloadedGames) {
    const gameIDChunks = this.gameID.split("-");
    this.awayTeamCode = gameIDChunks[1];
    this.homeTeamCode = gameIDChunks[2];

    var promises = [
        fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/${this.gameID}`).then(data => {return data.json();}),
        fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/all`).then(data => {return data.json();})
    ];
    if (!preloadedGames) {
        promises.push(fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/current?reduced=true&recordType=${LEAGUE_CURRENT_RECORD_TYPE[this.props.activeLeague]}${isWeeklyCompetition(this.props.activeLeague) ? "&format=weekly" : ""}`).then(data => {return data.json();}));
        promises.push(fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/upcoming?recordType=${LEAGUE_CURRENT_RECORD_TYPE[this.props.activeLeague]}${isWeeklyCompetition(this.props.activeLeague) ? "&format=weekly" : ""}`).then(data => {return data.json();}));
    }
    Promise.all(promises)
    .then(result => {
        const selectedGame = result[0];
        const allTeams = result[1];

        var allGames;
        if (!preloadedGames) {
            const currentGames = result[2];
            const upcomingGames = result[3];
            allGames = currentGames.concat(upcomingGames).filter(game => game.status === "SCHEDULED");
        } else {
            allGames = preloadedGames;
        }
        if (selectedGame.code === 404) {
            // Update the URL
            window.history.replaceState(null, null, `${this.props.activeLeague}/playbook`);
            this.loadGenericPlaybook(allGames);
        } else {
            var awayTeamStanding = null;
            var homeTeamStanding = null;
            for (var i = 0; i < allTeams.length; i++) {
                // If we have already found them, get out
                if (awayTeamStanding != null && homeTeamStanding != null) {
                    break;
                }
                const team = allTeams[i];
                if (team.code === this.awayTeamCode) {
                    awayTeamStanding = team;
                } else if (team.code === this.homeTeamCode) {
                    homeTeamStanding = team;
                }
            }
            var state = {
                awayTeamStanding: awayTeamStanding,
                homeTeamStanding: homeTeamStanding,
                game: selectedGame,
                upcomingGames: allGames,
                allStandings: allTeams
            };
            this.setState(state);
        }
    })
    .catch(error => {
        console.log("Error loading playbook data: " + error);
    });
  }

  moveMarker() {
    var activeNav = $('.active');
    var activeheight = $(activeNav).height();
    var activePadTop = parseFloat($(activeNav).css('padding-top'));
    var activePadBottom = parseFloat($(activeNav).css('padding-bottom'));
    var totalHeight = activeheight + activePadTop + activePadBottom;
    
    var precedingAnchorHeight = this.anchorHeightCounter();
    
    // TODO: 
    // Find the total widths of all of the anchors
    // to the left of the active anchor.

    var activeMarker = $('.active-marker');
    $(activeMarker).css('display','block');
    
    // This ends up setting height = 0 which makes it not visible
    //$(activeMarker).css('height', totalHeight);

    $(activeMarker).css('top', precedingAnchorHeight);
    // TODO: 
    // Using the calculated total widths of preceding anchors,
    // Set the left: css value to that number.
  }

  anchorHeightCounter() {
    var anchorHeight = 0;
    var a;
    var aHeight;
    var aPadTop;
    var aPadBottom;
    var aTotalHeight;
    $('.side-nav ul li').each(function(index, elem) {
      var activeTest = $(elem).hasClass('active');
      if(activeTest) {
        // Break out of the each function.
        return false;
      }

      a = $(elem);
      aHeight = a.height();
      aPadTop = parseFloat(a.css('padding-top'));
      aPadBottom = parseFloat(a.css('padding-bottom'));
      aTotalHeight = aHeight + aPadTop + aPadBottom;

      anchorHeight = anchorHeight + aTotalHeight;
    });
    return anchorHeight;
  }

  getLeagueSummary(league) {
    var snowflakeStatsTemplate = TEAM_MODAL_STATS[league.toLowerCase()];
    return <Summary   activeLeague={this.props.activeLeague} game={this.state.game} snowflakeStatsTemplate={snowflakeStatsTemplate} 
                    awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} allStandings={this.state.allStandings}
                    uptrends={this.state.uptrendInsights} downtrends={this.state.downtrendInsights}
                    homeTeamInsights={this.homeTeamInsights} awayTeamInsights={this.awayTeamInsights}/>
  }

  getHeadToHead(league) {
    var statsTemplate = {};
    var recordComparisonFn = () => {};
    var buildHeadToHeadFn = () => {};
    switch (league.toLowerCase()) {
        case "nfl":
            statsTemplate = {
                "PTS": "%qualifier%.offensive.points",
                "SCORE %": "%qualifier%.offensive.scorePercentage",
                "PLAYS": "%qualifier%.offensive.plays",
                "NET YDS": "%qualifier%.offensive.totalYards",
                "PASS YDS": "%qualifier%.offensive.passingYards",
                "PASS ATT": "%qualifier%.offensive.passingAttempts",
                "PASS COMPL": "%qualifier%.offensive.passingCompletions",
                "PASS TD": "%qualifier%.offensive.passingTouchdowns",
                "RUSH YDS": "%qualifier%.offensive.rushingYards",
                "RUSH ATT": "%qualifier%.offensive.rushingAttempts",
                "RUSH TD": "%qualifier%.offensive.rushingTouchdowns",
                "TOTAL TDS": "%qualifier%.offensive.totalTouchdowns",
                "RZ%": "%qualifier%.offensive.redzoneSuccessRate",
                "1D": "%qualifier%.offensive.firstDowns",
                "3D%": "%qualifier%.offensive.thirdDownConversionPercentage",
                "4D%": "%qualifier%.offensive.fourthDownConversionPercentage",
                "INT THROWN": "%qualifier%.offensive.interceptions",
                "TO": "%qualifier%.offensive.turnovers",
                "SACKS": "%qualifier%.defensive.sacks",
                "FUML": "%qualifier%.offensive.fumblesLost"
            };
            recordComparisonFn = getNFLRecordComparison;
            buildHeadToHeadFn = buildNFLHeadToHeadRecord;
            break;
        case "nhl":
            statsTemplate = {
                "G": "%qualifier%.offensive.goals",
                "SOG": "%qualifier%.offensive.shotsOnGoal",
                "SAT": "%qualifier%.offensive.shotAttempts",
                "CF%": "%qualifier%.offensive.corsiPercentage",
                "PP%": "%qualifier%.offensive.powerplayPercentage",
                "PIM": "%qualifier%.offensive.penaltyMinutes",
                // "HDC": "%qualifier%.offensive.passingCompletions",
                // "xG": "%qualifier%.offensive.passingTouchdowns"
            };
            recordComparisonFn = getNHLRecordComparison;
            buildHeadToHeadFn = buildNHLHeadToHeadRecord;
            break;
        case "nba":
        case "wnba":
            statsTemplate = {
                "PTS": "%qualifier%.offensive.points",
                "FT%": "%qualifier%.offensive.freeThrowPercentage",
                "FG%": "%qualifier%.offensive.fieldGoalPercentage",
                "3PT%": "%qualifier%.offensive.threePointPercentage",
                "TS%": "%qualifier%.offensive.trueShootingPercentage",
                "eFG%": "%qualifier%.offensive.effectiveFieldGoalPercentage",
                "TRB": "%qualifier%.offensive.rebounds",
                "AST": "%qualifier%.offensive.assists",
                "STL": "%qualifier%.defensive.steals",
                "TO": "%qualifier%.defensive.turnovers",
                "BLK": "%qualifier%.defensive.blocks"
            };
            recordComparisonFn = getNBARecordComparison;
            buildHeadToHeadFn = buildNBAHeadToHeadRecord;
            break;
        case "mlb":
            statsTemplate = {
                "R": "%qualifier%.offensive.runs",
                "H": "%qualifier%.offensive.hits",
                "1B": "%qualifier%.offensive.singles",
                "2B": "%qualifier%.offensive.doubles",
                "3B": "%qualifier%.offensive.triples",
                "TB": "%qualifier%.offensive.totalBases",
                "ER": "%qualifier%.offensive.earnedRuns",
                "HR": "%qualifier%.offensive.homeRuns",
                "RBI": "%qualifier%.offensive.runsBattedIn",
                "BB": "%qualifier%.offensive.walks",
                "SO": "%qualifier%.offensive.strikeouts",
                "E": "%qualifier%.offensive.errors",
                "AVG": "%qualifier%.offensive.battingAverage",
                "OBP": "%qualifier%.offensive.onBasePercentage",
                "SLG": "%qualifier%.offensive.sluggingPercentage",
                "OPS": "%qualifier%.offensive.onBasePlusSluggingPercentage"
            };
            recordComparisonFn = getMLBRecordComparison;
            buildHeadToHeadFn = buildMLBHeadToHeadRecord;
            break;
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        case "mls":
        case "euro":
            statsTemplate = {
                "G": "%qualifier%.offensive.goals",
                "POS": "%qualifier%.offensive.ballPossession",
                "SHO": "%qualifier%.offensive.shotsTotal",
                "SOT": "%qualifier%.offensive.shotsOnTarget",
                "COR": "%qualifier%.offensive.cornerKicks",
                "OFF": "%qualifier%.offensive.offsides",
                "FLS": "%qualifier%.offensive.fouls",
                "2YELRED": "%qualifier%.offensive.doubleYellowRedCards",
                "YEL": "%qualifier%.offensive.yellowCards",
                "RED": "%qualifier%.offensive.redCards"
            };
            recordComparisonFn = getSoccerRecordComparison;
            buildHeadToHeadFn = buildSoccerHeadToHeadRecord;
            break;
        default:
            return <></>;
    }
    return <HeadToHead activeLeague={this.props.activeLeague} awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} 
                          addInsightsFn={this.addInsights} userAttributes={this.props.userAttributes} setMatchupRecordsFn={this.setMatchupRecords}
                          recordComparisonFn={recordComparisonFn} buildHeadToHeadFn={buildHeadToHeadFn} setMatchupFavoriteRecordsFn={this.setMatchupFavoriteRecords}
                          statsTemplate={statsTemplate} userInitialized={this.props.userInitialized}/>
  }

  getSeason(league) {
    var statsTemplate = {};
    var categorizedStatsTemplate = {};
    var buildIndividualTeamRecordFn = () => {};
    var recordComparisonFn = () => {};
    switch (league.toLowerCase()) {
        case "nfl":
            statsTemplate = {
                "PTS": "%qualifier%.%type%.points",
                "SCORE%": "%qualifier%.%type%.scorePercentage",
                "PLAYS": "%qualifier%.%type%.plays",
                "NET YDS": "%qualifier%.%type%.totalYards",
                "PASS YDS": "%qualifier%.%type%.passingYards",
                "PASS ATT": "%qualifier%.%type%.passingAttempts",
                "PASS COMPL": "%qualifier%.%type%.passingCompletions",
                "PASS TD": "%qualifier%.%type%.passingTouchdowns",
                "RUSH YDS": "%qualifier%.%type%.rushingYards",
                "RUSH ATT": "%qualifier%.%type%.rushingAttempts",
                "RUSH TD": "%qualifier%.%type%.rushingTouchdowns",
                "TOTAL TDS": "%qualifier%.%type%.totalTouchdowns",
                "RZ%": "%qualifier%.%type%.redzoneSuccessRate",
                "1D": "%qualifier%.%type%.firstDowns",
                "3D%": "%qualifier%.%type%.thirdDownConversionPercentage",
                "4D%": "%qualifier%.%type%.fourthDownConversionPercentage",
                "INT": "%qualifier%.%type%.interceptions",
                "TO": "%qualifier%.%type%.turnovers",
                "SACKS": "%qualifier%.%type%.sacks",
                "FUML": "%qualifier%.%type%.fumblesLost"
            };
            categorizedStatsTemplate = {
                "PTS": {
                    for: {
                        field: "%qualifier%.offensive.points",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.points",
                        sortingOrder: "asc"
                    }
                },
                "SCORE%": {
                    for: {
                        field: "%qualifier%.offensive.scorePercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.scorePercentage",
                        sortingOrder: "asc"
                    }
                },
                "PLAYS": {
                    for: {
                        field: "%qualifier%.offensive.plays",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.plays",
                        sortingOrder: "asc"
                    }
                },
                "NET YDS": {
                    for: {
                        field: "%qualifier%.offensive.totalYards",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.totalYards",
                        sortingOrder: "asc"
                    }
                },
                "PASS YDS": {
                    for: {
                        field: "%qualifier%.offensive.passingYards",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.passingYards",
                        sortingOrder: "asc"
                    }
                },
                "PASS ATT": {
                    for: {
                        field: "%qualifier%.offensive.passingAttempts",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.passingAttempts",
                        sortingOrder: "asc"
                    }
                },
                "PASS COMPL": {
                    for: {
                        field: "%qualifier%.offensive.passingCompletions",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.passingCompletions",
                        sortingOrder: "asc"
                    }
                },
                "PASS TD": {
                    for: {
                        field: "%qualifier%.offensive.passingTouchdowns",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.passingTouchdowns",
                        sortingOrder: "asc"
                    }
                },
                "RUSH YDS": {
                    for: {
                        field: "%qualifier%.offensive.rushingYards",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.rushingYards",
                        sortingOrder: "asc"
                    }
                },
                "RUSH ATT": {
                    for: {
                        field: "%qualifier%.offensive.rushingAttempts",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.rushingAttempts",
                        sortingOrder: "asc"
                    }
                },
                "RUSH TD": {
                    for: {
                        field: "%qualifier%.offensive.rushingTouchdowns",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.rushingTouchdowns",
                        sortingOrder: "asc"
                    }
                },
                "TOTAL TDS": {
                    for: {
                        field: "%qualifier%.offensive.totalTouchdowns",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.totalTouchdowns",
                        sortingOrder: "asc"
                    }
                },
                "RZ%": {
                    for: {
                        field: "%qualifier%.offensive.redzoneSuccessRate",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.redzoneSuccessRate",
                        sortingOrder: "asc"
                    }
                },
                "1D": {
                    for: {
                        field: "%qualifier%.offensive.firstDowns",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.firstDowns",
                        sortingOrder: "asc"
                    }
                },
                "3D%": {
                    for: {
                        field: "%qualifier%.offensive.thirdDownConversionPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.thirdDownConversionPercentage",
                        sortingOrder: "asc"
                    }
                },
                "4D%": {
                    for: {
                        field: "%qualifier%.offensive.fourthDownConversionPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.fourthDownConversionPercentage",
                        sortingOrder: "asc"
                    }
                },
                "INT": {
                    for: {
                        field: "%qualifier%.offensive.interceptions",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.interceptions",
                        sortingOrder: "desc"
                    }
                },
                "TO": {
                    for: {
                        field: "%qualifier%.offensive.turnovers",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.turnovers",
                        sortingOrder: "desc"
                    }
                },
                "SACKS": {
                    for: {
                        field: "%qualifier%.defensive.sacks",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.offensive.sacks",
                        sortingOrder: "asc"
                    }
                },
                "FUML": {
                    for: {
                        field: "%qualifier%.offensive.fumblesLost",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.fumblesLost",
                        sortingOrder: "desc"
                    }
                }
            };
            buildIndividualTeamRecordFn = buildIndividualNFLTeamGameRecord;
            recordComparisonFn = getNFLRecordComparison;
            break;
        case "nhl":
            statsTemplate = {
                "G": "%qualifier%.%type%.goals",
                "SOG": "%qualifier%.%type%.shotsOnGoal",
                "SAT": "%qualifier%.%type%.shotAttempts",
                "CF%": "%qualifier%.%type%.corsiPercentage",
                "PPG": "%qualifier%.%type%.powerplayGoals",
                "PP%": "%qualifier%.%type%.powerplayPercentage",
                "SHG": "%qualifier%.%type%.shorthandedGoals",
                "SH%": "%qualifier%.%type%.penaltyKillPercentage",
                "BLK": "%qualifier%.%type%.defensiveBlocks",
                "PIM": "%qualifier%.%type%.penaltyMinutes",
                // HDC,
                // xG
            };
            categorizedStatsTemplate = {
                "G": {
                    for: {
                        field: "%qualifier%.offensive.goals",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.goals",
                        sortingOrder: "asc"
                    }
                },
                "SOG": {
                    for: {
                        field: "%qualifier%.offensive.shotsOnGoal",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.shotsOnGoal",
                        sortingOrder: "asc"
                    }
                },
                "SAT": {
                    for: {
                        field: "%qualifier%.offensive.shotAttempts",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.shotAttempts",
                        sortingOrder: "asc"
                    }
                },
                "CF%": {
                    for: {
                        field: "%qualifier%.offensive.corsiPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.corsiPercentage",
                        sortingOrder: "asc"
                    }
                },
                "PPG": {
                    for: {
                        field: "%qualifier%.offensive.powerplayGoals",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.powerplayGoals",
                        sortingOrder: "asc"
                    }
                },
                "PP%": {
                    for: {
                        field: "%qualifier%.offensive.powerplayPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.powerplayPercentage",
                        sortingOrder: "asc"
                    }
                },
                "SHG": {
                    for: {
                        field: "%qualifier%.offensive.shorthandedGoals",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.shorthandedGoals",
                        sortingOrder: "asc"
                    }
                },
                "SH%": {
                    for: {
                        field: "%qualifier%.offensive.penaltyKillPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.penaltyKillPercentage",
                        sortingOrder: "asc"
                    }
                },
                "BLK": {
                    for: {
                        field: "%qualifier%.offensive.defensiveBlocks",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.offensive.offensiveBlocks",
                        sortingOrder: "asc"
                    }
                },
                "PIM": {
                    for: {
                        field: "%qualifier%.offensive.penaltyMinutes",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.penaltyMinutes",
                        sortingOrder: "desc"
                    }
                },
            };
            buildIndividualTeamRecordFn = buildIndividualNHLTeamGameRecord;
            recordComparisonFn = getNHLRecordComparison;
            break;
        case "nba":
        case "wnba":
            statsTemplate = {
                "PTS": "%qualifier%.%type%.points",
                "FTM": "%qualifier%.%type%.freeThrowsMade",
                "FTA": "%qualifier%.%type%.freeThrowAttempts",
                "FT%": "%qualifier%.%type%.freeThrowPercentage",
                "FGM": "%qualifier%.%type%.fieldGoalsMade",
                "FGA": "%qualifier%.%type%.fieldGoalAttempts",
                "FG%": "%qualifier%.%type%.fieldGoalPercentage",
                "3PTM": "%qualifier%.%type%.threePointsMade",
                "3PTA": "%qualifier%.%type%.threePointAttempts",
                "3PT%": "%qualifier%.%type%.threePointPercentage",
                "TS%": "%qualifier%.%type%.trueShootingPercentage",
                "eFG%": "%qualifier%.%type%.effectiveFieldGoalPercentage",
                "TRB": "%qualifier%.%type%.rebounds",
                "AST": "%qualifier%.%type%.assists",
                "STL": "%qualifier%.%type%.steals",
                "TO": "%qualifier%.%type%.turnovers",
                "BLK": "%qualifier%.%type%.blocks"
            };
            categorizedStatsTemplate = {
                "PTS": {
                    for: {
                        field: "%qualifier%.offensive.points",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.points",
                        sortingOrder: "asc"
                    }
                },
                "FTM": {
                    for: {
                        field: "%qualifier%.offensive.freeThrowsMade",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.freeThrowsMade",
                        sortingOrder: "asc"
                    }
                },
                "FTA": {
                    for: {
                        field: "%qualifier%.offensive.freeThrowAttempts",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.freeThrowAttempts",
                        sortingOrder: "asc"
                    }
                },
                "FT%": {
                    for: {
                        field: "%qualifier%.offensive.freeThrowPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.freeThrowPercentage",
                        sortingOrder: "asc"
                    }
                },
                "FGM": {
                    for: {
                        field: "%qualifier%.offensive.fieldGoalsMade",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.fieldGoalsMade",
                        sortingOrder: "asc"
                    }
                },
                "FGA": {
                    for: {
                        field: "%qualifier%.offensive.fieldGoalAttempts",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.fieldGoalAttempts",
                        sortingOrder: "asc"
                    }
                },
                "FG%": {
                    for: {
                        field: "%qualifier%.offensive.fieldGoalPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.fieldGoalPercentage",
                        sortingOrder: "asc"
                    }
                },
                "3PTM": {
                    for: {
                        field: "%qualifier%.offensive.threePointsMade",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.threePointsMade",
                        sortingOrder: "asc"
                    }
                },
                "3PTA": {
                    for: {
                        field: "%qualifier%.offensive.threePointAttempts",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.threePointAttempts",
                        sortingOrder: "asc"
                    }
                },
                "3PT%": {
                    for: {
                        field: "%qualifier%.offensive.threePointPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.threePointPercentage",
                        sortingOrder: "asc"
                    }
                },
                "TS%": {
                    for: {
                        field: "%qualifier%.offensive.trueShootingPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.trueShootingPercentage",
                        sortingOrder: "asc"
                    }
                },
                "eFG%": {
                    for: {
                        field: "%qualifier%.offensive.effectiveFieldGoalPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.effectiveFieldGoalPercentage",
                        sortingOrder: "asc"
                    }
                },
                "TRB": {
                    for: {
                        field: "%qualifier%.offensive.rebounds",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.rebounds",
                        sortingOrder: "asc"
                    }
                },
                "AST": {
                    for: {
                        field: "%qualifier%.offensive.assists",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.assists",
                        sortingOrder: "asc"
                    }
                },
                "STL": {
                    for: {
                        field: "%qualifier%.defensive.steals",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.offensive.steals",
                        sortingOrder: "asc"
                    }
                },
                "TO": {
                    for: {
                        field: "%qualifier%.defensive.turnovers",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.offensive.turnovers",
                        sortingOrder: "asc"
                    }
                },
                "BLK": {
                    for: {
                        field: "%qualifier%.defensive.blocks",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.offensive.blocks",
                        sortingOrder: "asc"
                    }
                }
            }
            buildIndividualTeamRecordFn = buildIndividualNBATeamGameRecord;
            recordComparisonFn = getNBARecordComparison;
            break;
        case "mlb":
            statsTemplate = {
                "R": "%qualifier%.%type%.runs",
                "H": "%qualifier%.%type%.hits",
                "1B": "%qualifier%.%type%.singles",
                "2B": "%qualifier%.%type%.doubles",
                "3B": "%qualifier%.%type%.triples",
                "TB": "%qualifier%.%type%.totalBases",
                "ER": "%qualifier%.%type%.earnedRuns",
                "HR": "%qualifier%.%type%.homeRuns",
                "RBI": "%qualifier%.%type%.runsBattedIn",
                "BB": "%qualifier%.%type%.walks",
                "SO": "%qualifier%.%type%.strikeouts",
                "E": "%qualifier%.%type%.errors",
                "AVG": "%qualifier%.%type%.battingAverage",
                "OBP": "%qualifier%.%type%.onBasePercentage",
                "SLG": "%qualifier%.%type%.sluggingPercentage",
                "OPS": "%qualifier%.%type%.onBasePlusSluggingPercentage"
            };
            categorizedStatsTemplate = {
                "R": {
                    for: {
                        field: "%qualifier%.offensive.runs",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.runs",
                        sortingOrder: "asc"
                    }
                },
                "H": {
                    for: {
                        field: "%qualifier%.offensive.hits",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.hits",
                        sortingOrder: "asc"
                    }
                },
                "1B": {
                    for: {
                        field: "%qualifier%.offensive.singles",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.singles",
                        sortingOrder: "asc"
                    }
                },
                "2B": {
                    for: {
                        field: "%qualifier%.offensive.doubles",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.doubles",
                        sortingOrder: "asc"
                    }
                },
                "3B": {
                    for: {
                        field: "%qualifier%.offensive.triples",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.triples",
                        sortingOrder: "asc"
                    }
                },
                "TB": {
                    for: {
                        field: "%qualifier%.offensive.totalBases",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.totalBases",
                        sortingOrder: "asc"
                    }
                },
                "ER": {
                    for: {
                        field: "%qualifier%.offensive.earnedRuns",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.earnedRuns",
                        sortingOrder: "asc"
                    }
                },
                "HR": {
                    for: {
                        field: "%qualifier%.offensive.homeRuns",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.homeRuns",
                        sortingOrder: "asc"
                    }
                },
                "RBI": {
                    for: {
                        field: "%qualifier%.offensive.runsBattedIn",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.runsBattedIn",
                        sortingOrder: "asc"
                    }
                },
                "BB": {
                    for: {
                        field: "%qualifier%.offensive.walks",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.walks",
                        sortingOrder: "asc"
                    }
                },
                "SO": {
                    for: {
                        field: "%qualifier%.offensive.strikeouts",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.strikeouts",
                        sortingOrder: "desc"
                    }
                },
                "E": {
                    for: {
                        field: "%qualifier%.offensive.errors",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.errors",
                        sortingOrder: "asc"
                    }
                },
                "AVG": {
                    for: {
                        field: "%qualifier%.offensive.battingAverage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.battingAverage",
                        sortingOrder: "asc"
                    }
                },
                "OBP": {
                    for: {
                        field: "%qualifier%.offensive.onBasePercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.onBasePercentage",
                        sortingOrder: "asc"
                    }
                },
                "SLG": {
                    for: {
                        field: "%qualifier%.offensive.sluggingPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.sluggingPercentage",
                        sortingOrder: "asc"
                    }
                },
                "OPS": {
                    for: {
                        field: "%qualifier%.offensive.onBasePlusSluggingPercentage",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.onBasePlusSluggingPercentage",
                        sortingOrder: "asc"
                    }
                }
            }
            buildIndividualTeamRecordFn = buildIndividualMLBTeamGameRecord;
            recordComparisonFn = getMLBRecordComparison;
            break;
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        case "mls":
        case "euro":
            statsTemplate = {
                "G": "%qualifier%.%type%.goals",
                "POS": "%qualifier%.%type%.ballPossession",
                "SHO": "%qualifier%.%type%.shotsTotal",
                "SOT": "%qualifier%.%type%.shotsOnTarget",
                "COR": "%qualifier%.%type%.cornerKicks",
                "OFF": "%qualifier%.%type%.offsides",
                "FLS": "%qualifier%.%type%.fouls",
                "2YELRED": "%qualifier%.%type%.doubleYellowRedCards",
                "YEL": "%qualifier%.%type%.yellowCards",
                "RED": "%qualifier%.%type%.redCards"
            };
            categorizedStatsTemplate = {
                "G": {
                    for: {
                        field: "%qualifier%.offensive.goals",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.goals",
                        sortingOrder: "asc"
                    }
                },
                "POS": {
                    for: {
                        field: "%qualifier%.offensive.ballPossession",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.ballPossession",
                        sortingOrder: "asc"
                    }
                },
                "SHO": {
                    for: {
                        field: "%qualifier%.offensive.shotsTotal",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.shotsTotal",
                        sortingOrder: "asc"
                    }
                },
                "SOT": {
                    for: {
                        field: "%qualifier%.offensive.shotsOnTarget",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.shotsOnTarget",
                        sortingOrder: "asc"
                    }
                },
                "COR": {
                    for: {
                        field: "%qualifier%.offensive.cornerKicks",
                        sortingOrder: "desc"
                    },
                    against: {
                        field: "%qualifier%.defensive.cornerKicks",
                        sortingOrder: "asc"
                    }
                },
                "OFF": {
                    for: {
                        field: "%qualifier%.offensive.offsides",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.offsides",
                        sortingOrder: "desc"
                    }
                },
                "FLS": {
                    for: {
                        field: "%qualifier%.offensive.fouls",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.fouls",
                        sortingOrder: "desc"
                    }
                },
                "2YELRED": {
                    for: {
                        field: "%qualifier%.offensive.doubleYellowRedCards",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.doubleYellowRedCards",
                        sortingOrder: "desc"
                    }
                },
                "YEL": {
                    for: {
                        field: "%qualifier%.offensive.yellowCards",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.offensive.yellowCards",
                        sortingOrder: "desc"
                    }
                },
                "RED": {
                    for: {
                        field: "%qualifier%.offensive.redCards",
                        sortingOrder: "asc"
                    },
                    against: {
                        field: "%qualifier%.defensive.redCards",
                        sortingOrder: "desc"
                    }
                },
            };
            buildIndividualTeamRecordFn = buildIndividualSoccerTeamGameRecord;
            recordComparisonFn = getSoccerRecordComparison;
            break;
        default:
            return <></>;
    }
    return <Season activeLeague={this.props.activeLeague} awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} 
                    addInsightsFn={this.addInsights} userAttributes={this.props.userAttributes} allStandings={this.state.allStandings}
                    statsTemplate={statsTemplate} buildIndividualTeamRecordFn={buildIndividualTeamRecordFn} setSeasonFavoriteRecordsFn={this.setSeasonFavoriteRecords}
                    recordComparisonFn={recordComparisonFn} userInitialized={this.props.userInitialized} categorizedStatsTemplate={categorizedStatsTemplate}/>
  }

  getForm(league) {
    var statsTemplate={};
    switch (league.toLowerCase()) {
        case "nfl":
            statsTemplate = {
                "PTS": "%qualifier%.%type%.points",
                "SCORE%": "%qualifier%.%type%.scorePercentage",
                "PLAYS": "%qualifier%.%type%.plays",
                "NET YDS": "%qualifier%.%type%.totalYards",
                "PASS YDS": "%qualifier%.%type%.passingYards",
                "PASS ATT": "%qualifier%.%type%.passingAttempts",
                "PASS COMPL": "%qualifier%.%type%.passingCompletions",
                "PASS TD": "%qualifier%.%type%.passingTouchdowns",
                "RUSH YDS": "%qualifier%.%type%.rushingYards",
                "RUSH ATT": "%qualifier%.%type%.rushingAttempts",
                "RUSH TD": "%qualifier%.%type%.rushingTouchdowns",
                "TOTAL TDS": "%qualifier%.%type%.totalTouchdowns",
                "RZ%": "%qualifier%.%type%.redzoneSuccessRate",
                "1D": "%qualifier%.%type%.firstDowns",
                "3D%": "%qualifier%.%type%.thirdDownConversionPercentage",
                "4D%": "%qualifier%.%type%.fourthDownConversionPercentage",
                "INT": "%qualifier%.%type%.interceptions",
                "TO": "%qualifier%.%type%.turnovers",
                "SACKS": "%qualifier%.%type%.sacks",
                "FUML": "%qualifier%.%type%.fumblesLost"
            };
            return <RecentForm activeLeague={this.props.activeLeague} awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} 
                               addInsightsFn={this.addInsights} userAttributes={this.props.userAttributes} setRecentRecordsFn={this.setRecentRecords}
                               statsTemplate={statsTemplate} buildIndividualTeamGameRecordFn={buildIndividualNFLTeamGameRecord} userInitialized={this.props.userInitialized}
                               gameComparisonFn={getNFLGameComparison} recordComparisonFn={getNFLRecordComparison} setRecentFavoriteRecordsFn={this.setRecentFavoriteRecords}/>
        case "nhl":
            statsTemplate = {
                "G": "%qualifier%.%type%.goals",
                "SOG": "%qualifier%.%type%.shotsOnGoal",
                "SAT": "%qualifier%.%type%.shotAttempts",
                "CF%": "%qualifier%.%type%.corsiPercentage",
                "PPG": "%qualifier%.%type%.powerplayGoals",
                "PP%": "%qualifier%.%type%.powerplayPercentage",
                "SHG": "%qualifier%.%type%.shorthandedGoals",
                "SH%": "%qualifier%.%type%.penaltyKillPercentage",
                "BLK": "%qualifier%.%type%.defensiveBlocks",
                "PIM": "%qualifier%.%type%.penaltyMinutes",
                //HDC
                //xG
            };
            return <RecentForm  activeLeague={this.props.activeLeague} awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} 
                                addInsightsFn={this.addInsights} userAttributes={this.props.userAttributes} setRecentRecordsFn={this.setRecentRecords}
                                statsTemplate={statsTemplate} buildIndividualTeamGameRecordFn={buildIndividualNHLTeamGameRecord} userInitialized={this.props.userInitialized}
                                gameComparisonFn={getNHLGameComparison} recordComparisonFn={getNHLRecordComparison} setRecentFavoriteRecordsFn={this.setRecentFavoriteRecords}/>
        case "nba":
        case "wnba":
            statsTemplate = {
                "PTS": "%qualifier%.%type%.points",
                "FTM": "%qualifier%.%type%.freeThrowsMade",
                "FTA": "%qualifier%.%type%.freeThrowAttempts",
                "FT%": "%qualifier%.%type%.freeThrowPercentage",
                "FGM": "%qualifier%.%type%.fieldGoalsMade",
                "FGA": "%qualifier%.%type%.fieldGoalAttempts",
                "FG%": "%qualifier%.%type%.fieldGoalPercentage",
                "3PTM": "%qualifier%.%type%.threePointsMade",
                "3PTA": "%qualifier%.%type%.threePointAttempts",
                "3PT%": "%qualifier%.%type%.threePointPercentage",
                "TS%": "%qualifier%.%type%.trueShootingPercentage",
                "eFG%": "%qualifier%.%type%.effectiveFieldGoalPercentage",
                "TRB": "%qualifier%.%type%.rebounds",
                "AST": "%qualifier%.%type%.assists",
                "STL": "%qualifier%.%type%.steals",
                "TO": "%qualifier%.%type%.turnovers",
                "BLK": "%qualifier%.%type%.blocks"
            };
            return <RecentForm  activeLeague={this.props.activeLeague} awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} 
                                addInsightsFn={this.addInsights} userAttributes={this.props.userAttributes} setRecentRecordsFn={this.setRecentRecords}
                                statsTemplate={statsTemplate} buildIndividualTeamGameRecordFn={buildIndividualNBATeamGameRecord} userInitialized={this.props.userInitialized}
                                gameComparisonFn={getNBAGameComparison} recordComparisonFn={getNBARecordComparison} setRecentFavoriteRecordsFn={this.setRecentFavoriteRecords}/>
        case "mlb":
            statsTemplate = {
                "R": "%qualifier%.%type%.runs",
                "H": "%qualifier%.%type%.hits",
                "1B": "%qualifier%.%type%.singles",
                "2B": "%qualifier%.%type%.doubles",
                "3B": "%qualifier%.%type%.triples",
                "TB": "%qualifier%.%type%.totalBases",
                "ER": "%qualifier%.%type%.earnedRuns",
                "HR": "%qualifier%.%type%.homeRuns",
                "RBI": "%qualifier%.%type%.runsBattedIn",
                "BB": "%qualifier%.%type%.walks",
                "SO": "%qualifier%.%type%.strikeouts",
                "E": "%qualifier%.%type%.errors",
                "AVG": "%qualifier%.%type%.battingAverage",
                "OBP": "%qualifier%.%type%.onBasePercentage",
                "SLG": "%qualifier%.%type%.sluggingPercentage",
                "OPS": "%qualifier%.%type%.onBasePlusSluggingPercentage"
            };
            return <RecentForm  activeLeague={this.props.activeLeague} awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} 
                                addInsightsFn={this.addInsights} userAttributes={this.props.userAttributes} setRecentRecordsFn={this.setRecentRecords}
                                statsTemplate={statsTemplate} buildIndividualTeamGameRecordFn={buildIndividualMLBTeamGameRecord} userInitialized={this.props.userInitialized}
                                gameComparisonFn={getMLBGameComparison} recordComparisonFn={getMLBRecordComparison} setRecentFavoriteRecordsFn={this.setRecentFavoriteRecords}/>
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        case "mls":
        case "euro":
            statsTemplate = {
                "G": "%qualifier%.%type%.goals",
                "POS": "%qualifier%.%type%.ballPossession",
                "SHO": "%qualifier%.%type%.shotsTotal",
                "SOT": "%qualifier%.%type%.shotsOnTarget",
                "COR": "%qualifier%.%type%.cornerKicks",
                "OFF": "%qualifier%.%type%.offsides",
                "FLS": "%qualifier%.%type%.fouls",
                "2YELRED": "%qualifier%.%type%.doubleYellowRedCards",
                "YEL": "%qualifier%.%type%.yellowCards",
                "RED": "%qualifier%.%type%.redCards"
            };
            return <RecentForm  activeLeague={this.props.activeLeague} awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} 
                                addInsightsFn={this.addInsights} userAttributes={this.props.userAttributes} setRecentRecordsFn={this.setRecentRecords}
                                statsTemplate={statsTemplate} buildIndividualTeamGameRecordFn={buildIndividualSoccerTeamGameRecord} userInitialized={this.props.userInitialized}
                                gameComparisonFn={getSoccerGameComparison} recordComparisonFn={getSoccerRecordComparison} setRecentFavoriteRecordsFn={this.setRecentFavoriteRecords}/>
        default:
            return <></>;
    }
  }
  
  getBettingTrend(league) {
    if (isDictEmpty(this.state.game) || isDictEmpty(this.state.awayTeamStanding) || isDictEmpty(this.state.homeTeamStanding)) {
        return <></>;
    }
    // Because of missing/incomplete historical betting data we just won't show it for soccer
    if (isSoccer(league)) {
        return <></>;
    }
    return <BettingTrend activeLeague={this.props.activeLeague} game={this.state.game} userAttributes={this.props.userAttributes}
                         awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} 
                         matchupRecords={this.state.matchupRecords} recentRecords={this.state.recentRecords}
                         seasonFavoriteRecords={this.state.seasonFavoriteRecords} matchupFavoriteRecords={this.state.matchupFavoriteRecords}
                         recentFavoriteRecords={this.state.recentFavoriteRecords}/>
  }

  getLeagueScoutingReport(league) {
    switch(league.toLowerCase()) {
        case "nfl":
            return <NFLScoutingReport activeLeague={this.props.activeLeague}  userAttributes={this.props.userAttributes}
                                      awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} />
        case "nhl":
            return <NHLScoutingReport activeLeague={this.props.activeLeague}  userAttributes={this.props.userAttributes}
                                      awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding}/>
        case "nba":
        case "wnba":
            return <NBAScoutingReport activeLeague={this.props.activeLeague}  userAttributes={this.props.userAttributes}
                                      awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding}/>
        case "mlb":
            return <MLBScoutingReport activeLeague={this.props.activeLeague}  userAttributes={this.props.userAttributes}
                                      awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding}/>
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        case "mls":
        case "euro":
            return <SoccerScoutingReport activeLeague={this.props.activeLeague}  userAttributes={this.props.userAttributes}
                                      awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding}/>
        default:
            return <></>
    }
  }

  getLeagueDesktopScrollSpy(league) {
    return (
        <Scrollspy items={ 
            ['summary', 'head-to-head', 'edge-finder', 'game-by-game', 'season', 'season-overall', 'season-offence', 
            'season-defense', 'recent-form', 'rf-overall', 'rf-offence', 'rf-defense', 
            'betting-trends', 'scouting-report', 'injury-report'] } 
            offset={-174} 
            currentClassName="active">
                    <li className="main-section"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#summary`}>1. Summary</a></li>
                    <li className="main-section"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#head-to-head`}>2. Head to Head</a></li>
                    <li className="subsection"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#edge-finder`}>2.1 Edge Finder</a></li>
                    <li className="subsection"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#game-by-game`}>2.2 Game by Game</a></li>
                    <li className="main-section"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#season`}>3. Season</a></li>
                    <li className="subsection"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#season-overall`}>3.1 Rankings</a></li>
                    <li className="subsection"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#season-offence`}>3.2 Offense</a></li>
                    <li className="subsection"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#season-defense`}>3.3 Defense</a></li>
                    <li className="main-section"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#recent-form`}>4. Recent Form</a></li>
                    <li className="subsection"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#rf-overall`}>4.1 Last 5</a></li>
                    <li className="subsection"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#rf-offence`}>4.2 Offense</a></li>
                    <li className="subsection"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#rf-defense`}>4.3 Defense</a></li>
                    <li className="main-section"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#betting-trends`}>5. Betting Trends</a></li>
                    <li className="main-section"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#scouting-report`}>6. Players To Watch</a></li>
                    <li className="main-section"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#injury-report`}>7. Injury Report</a></li>
                    {/* <li className="main-section"><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#news-feed`}>8. News Feed</a></li> */}
            </Scrollspy>
    )
  }

  addInsights(insights) {
    // console.log("Adding insight", insights);
    this.insights.push(insights);
    // We are expecting 15 insights, 3 from 5 different sections (off/def of season and last 5 makes up 4, and then one more from season)
    // Although it's not a problem now, if it does become a problem we may want to filter out zero values if insights.length > 15 as long as we have 15 non-zero values
    if (this.insights.length <= 15) {
        var goodInsights = [];
        var badInsights = [];
        // TODO: review good/bad, seems it might not be correct
        this.insights.forEach((insight) => {
            if (insight.qualifier === "good") {
                goodInsights.push(insight);
            } else {
                badInsights.push(insight);
            }
        });
        // console.log("Good insights", goodInsights);
        // console.log("Bad insights", badInsights);

        orderListByField(goodInsights, "value", "desc");
        orderListByField(badInsights, "value", "desc");

        this.setState(
            {
                uptrendInsights: goodInsights.slice(0, 3),
                downtrendInsights: badInsights.slice(0, 3)
            }
        );
    }
  }

  setSeasonFavoriteRecords(records) {
    this.setState({seasonFavoriteRecords: records});
  }

  setMatchupRecords(records) {
    this.setState({matchupRecords: records});
  }

  setMatchupFavoriteRecords(records) {
    this.setState({matchupFavoriteRecords: records});
  }

  setRecentRecords(records) {
    this.setState({recentRecords: records})
  }

  setRecentFavoriteRecords(records) {
    this.setState({recentFavoriteRecords: records});
  }

  onScrollSportsbookRight = () => {
    const startingPoint = this.scrollableSportbooks.current.scrollLeft
    if(this.scrollableSportbooks && this.scrollableSportbooks.current) {
        this.scrollableSportbooks.current.scrollLeft += 120
    }
    if(startingPoint === this.scrollableSportbooks.current.scrollLeft) this.setState({ hasReachedEndSportsBook: true })
  }

  onScrollSportsbookLeft = () => {
    if(this.scrollableSportbooks && this.scrollableSportbooks.current) {
        this.scrollableSportbooks.current.scrollLeft -= 120
    }
    if(this.state.hasReachedEndSportsBook) this.setState({ hasReachedEndSportsBook: false })
  }

  render() {
    var paywallStyling = {};
    return(
        <>
        <HtmlHeaders canonicalRef={`https://www.linemate.io/${this.props.activeLeague}/playbook`}/>
        <ScrollableGamesBanner activeLeague={this.props.activeLeague} pagePath={"playbook"} currentGame={this.state.game} upcomingGames={this.state.upcomingGames} />
        <GamePreviewBanner activeLeague={this.props.activeLeague} game={this.state.game} type="individual"/>
        {/* <Scrollspy items={ 
                ['summary', 'head-to-head','season', 'recent-form', 'betting-trends', 'scouting-report', 'injury-report', 'news-feed'] }  */}
        <Scrollspy items={ 
                ['summary', 'head-to-head','season', 'recent-form', 'betting-trends', 'scouting-report', 'injury-report'] } 
                offset={-220} 
                currentClassName="active"
                componentTag="nav">
            <div><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#summary`}>Summary</a></div>
            <div><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#head-to-head`}>Head to Head</a></div>
            <div><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#season`}>Season</a></div>
            <div><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#recent-form`}>Recent Form</a></div>
            <div><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#betting-trends`}>Betting Trends</a></div>
            <div><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#scouting-report`}>Players to Watch</a></div>
            <div><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#injury-report`}>Injury Report</a></div>
            {/* <div><a href={`/${this.props.activeLeague}/playbook/${this.gameID}#news-feed`}>News Feed</a></div> */}
        </Scrollspy>
        <div className="playbook-content">
            <div className="side-nav text-sm-med colour-default">
                {
                    this.getLeagueDesktopScrollSpy(this.props.activeLeague)
                }
                <i className="active-marker"></i>
            </div>
            <div className="playbook-card-group">
                {
                    this.getLeagueSummary(this.props.activeLeague)
                }
                <div className="playbook-card" style={paywallStyling}>
                {
                    this.getHeadToHead(this.props.activeLeague)
                }
                </div>
                <div className="playbook-card" style={paywallStyling}>
                {
                    this.getSeason(this.props.activeLeague)
                }
                </div>
                <div className="playbook-card" style={paywallStyling}>
                {
                    this.getForm(this.props.activeLeague) 
                }
                </div>
                <div className="playbook-card" style={paywallStyling}>
                {
                    this.getBettingTrend(this.props.activeLeague)
                }
                </div>
                <div className="playbook-card playbook-scouting-report" style={paywallStyling}>
                {
                    this.getLeagueScoutingReport(this.props.activeLeague)
                }
                </div>
                <div id="injury-report" className="playbook-card" style={paywallStyling}>
                    <InjuryReport activeLeague={this.props.activeLeague} userAttributes={this.props.userAttributes}
                                  awayTeamStanding={this.state.awayTeamStanding} homeTeamStanding={this.state.homeTeamStanding} />
                </div>
            </div>
        </div>
        </>
    );
  }
}

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.loaded = false;
        this.homeTeamChanceToWin = 0;
        this.awayTeamChanceToWin = 0;
        this.pieChartData = [];
        this.snowflakeData = [];
    }

    render() {
        if (isDictEmpty(this.props.game)) {
            return (<></>)
        }
        if (!this.loaded) {
            this.loaded = true;
            this.homeTeamChanceToWin = decimalPrecision(this.props.game.homeTeamData.winPercentage, 0);
            this.awayTeamChanceToWin = decimalPrecision(this.props.game.awayTeamData.winPercentage, 0);
            this.pieChartData = [
                {
                    id: getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding),
                    label: getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding),
                    value: this.homeTeamChanceToWin,
                    color: TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.homeTeamStanding.code.toLowerCase()]
                },
                {
                    id: getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding),
                    label: getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding),
                    value: this.awayTeamChanceToWin,
                    color: TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.awayTeamStanding.code.toLowerCase()]
                }
            ];
    
            var snowflakeMaximums = {};
            this.props.allStandings.forEach((teamRecord) => {
                Object.keys(this.props.snowflakeStatsTemplate).map((key) => {
                    const teamStatValue = parseFloat(getDictionaryValue(teamRecord, this.props.snowflakeStatsTemplate[key]));
                    // If the entry is missing or its value is lower than the current one, set the current as max
                    if (!(key in snowflakeMaximums) || teamStatValue > snowflakeMaximums[key]) {
                        snowflakeMaximums[key] = teamStatValue;
                    }
                });
            });

            Object.keys(this.props.snowflakeStatsTemplate).map((key) => {
                const leader = snowflakeMaximums[key];
                const homeTeamValue = getDictionaryValue(this.props.homeTeamStanding, this.props.snowflakeStatsTemplate[key]);
                const awayTeamValue = getDictionaryValue(this.props.awayTeamStanding, this.props.snowflakeStatsTemplate[key]);
                var snowflakeEntry = {};
                snowflakeEntry["stat"] = key;
                if (leader === 0) {
                    snowflakeEntry[getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding)] = 0;
                    snowflakeEntry[getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding)] = 0;
                } else {
                    snowflakeEntry[getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding)] = decimalPrecision((homeTeamValue/leader) * 100, 1);
                    snowflakeEntry[getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding)] = decimalPrecision((awayTeamValue/leader) * 100, 1);
                }
                this.snowflakeData.push(snowflakeEntry);
            });
        }
        return (
            <div className="playbook-card">
                <h3 id="summary">1. Summary</h3>
                <div className="text-sm">
                    {
                        isSoccer(this.props.activeLeague) ?
                        <>{`${getTeamDisplayName(this.props.activeLeague, this.props.game.homeTeamData.info)}`} faces off against {`${getTeamDisplayName(this.props.activeLeague, this.props.game.awayTeamData.info)}`} at the {this.props.game.venue.name}.&nbsp;</>
                        :
                        <>The {`${getTeamDisplayName(this.props.activeLeague, this.props.game.homeTeamData.info)}`} faceoff against the {`${getTeamDisplayName(this.props.activeLeague, this.props.game.awayTeamData.info)}`} at the {this.props.game.venue.name}.&nbsp;</>
                    }
                    Let's find our edge!
                </div>
                <div className="summary-graphs">
                    <div className="playbook-summary-radar">
                        {/* <p className="font size-16 weight-600 spaced">TEAM PROFILES</p> */}
                        <ResponsiveRadar
                            data={this.snowflakeData}
                            keys={[getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding), getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding)]}
                            indexBy="stat"
                            // maxValue="auto"
                            maxValue={100}
                            // margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
                            margin={{ top: 40, right: 0, bottom: 60, left: 0 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={16}
                            enableDots={true}
                            dotSize={8}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={false}
                            dotLabel="value"
                            dotLabelYOffset={-12}
                            // colors={({ id, data }) => String(data[`${id}Color`])}
                            colors={[TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.homeTeamStanding.code.toLowerCase()], TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.awayTeamStanding.code.toLowerCase()]]}
                            fillOpacity={0.25}
                            blendMode="multiply"
                            animate={true}
                            motionConfig="wobbly"
                            isInteractive={false}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: -60,
                                    translateY: -40,
                                    itemsSpacing: 8,
                                    itemWidth: 100,
                                    itemHeight: 32,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className="trends-container">
                    <div className="trends">
                        <h5> <img src="assets/playbook/icon-uptrend.svg" alt="Uptrend"/> Key Uptrends</h5>
                        <ol>
                        {
                            this.props.uptrends.map((trend, index) =>
                                <li key={index}>{trend.text}</li>
                            )
                        }
                        </ol>
                    </div>
                    <div className="trends">
                        <h5><img src="assets/playbook/icon-downtrend.svg" alt="Downtrend"/> Key Downtrends</h5>
                        <ol>
                        {
                            this.props.downtrends.map((trend, index) =>
                                <li key={index}>{trend.text}</li>
                            )
                        }
                        </ol>
                    </div>
                </div>
            </div>
        )
    }
}

class HeadToHead extends React.Component {
    constructor(props) {
        super(props);
        this.edgeFinderStatsTemplate = this.props.statsTemplate;

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;

        this.state = {
            games: [],
            homeTeamRecordComparison: {},
            awayTeamRecordComparison: {}
        }

        this.loaded = false;
        this.emptyMatchupPropagated = false;
    }

    // TODO: switch from matchup with Game records to just TeamGameRecord
    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const sinceSeason = LEAGUE_CURRENT_SEASON[this.props.activeLeague] - 2;
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/matchups?code1=${this.awayTeamStanding.code}&code2=${this.homeTeamStanding.code}&sinceSeason=${sinceSeason}`)
            .then(data => {return data.json();})
            .then(data => {
                const recordResult = this.props.buildHeadToHeadFn(data, this.homeTeamStanding.code, this.state.homeTeamRecordComparison, this.state.awayTeamRecordComparison);
                this.props.setMatchupFavoriteRecordsFn({
                    home: recordResult.homeTeamRecordComparison,
                    away: recordResult.awayTeamRecordComparison
                })
                this.setState(Object.assign(recordResult, {games: data}), this.forceUpdate);
            })
            .catch((error) => console.log(`Error loading ${this.props.activeLeague} Head to head data: ${error}`))
        }
    }

    render() {
        if (isDictEmpty(this.awayTeamStanding) || isDictEmpty(this.homeTeamStanding)) {
            return (<></>)
        }
        // No previous matchups in the last 2 seasons
        if (this.loaded && this.state.games.length === 0) {
            if (!this.emptyMatchupPropagated) {
                // console.log("Propagating empty matchup");
                // console.log("Props", this.props);
                // console.log("this", this);
                // console.log("state", this.state);
                this.emptyMatchupPropagated = true;
                this.props.addInsightsFn({
                    type: 'creation',
                    value: 0,
                    qualifier: "good",
                    text: ``
                });
                this.props.addInsightsFn({
                    type: 'conversion',
                    value: 0,
                    qualifier: "good",
                    text: ``
                });
                this.props.addInsightsFn({
                    type: 'control',
                    value: 0,
                    qualifier: "good",
                    text: ``
                });  
            }
            return (
                <>
                    <h3 id="head-to-head">2. Head to Head</h3>
                    {/* TBD if this is the right way to do it */}
                    <div id="edge-finder" style={{height: '0px'}}></div>
                    <div id="game-by-game" style={{height: '0px'}}></div>
                    <NoResultsFrame text={"There is no matchup history between these two teams within the last 2 seasons."} />
                </>
            )
        }
        return (
            <>
            <h3 id="head-to-head">2. Head to Head</h3>
            {
                (isDictEmpty(this.awayTeamStanding) || isDictEmpty(this.homeTeamStanding)) ? <></> : 
                <>
                    <div className="text-sm">How have these two teams performed in opposition?</div>
                    <div className="comparative-table-body">
                        <div className="comparative-tbl-header">
                            <div className="tbl-header-away">
                                <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding)}</span>
                                <span className="short-text text-base-upper color-fig-default">{this.awayTeamStanding.code}</span>
                                <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamStanding.code)} height={32} width={32} alt=""/></div>
                            <div className="tbl-header-home" >
                                <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamStanding.code)} height={32} width={32} alt=""/>
                                <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)}</span>
                                <span className="short-text text-base-upper color-fig-default">{this.homeTeamStanding.code}</span>
                            </div>
                        </div>
                        {
                            this.props.recordComparisonFn(this.state.homeTeamRecordComparison, this.state.awayTeamRecordComparison)
                        }
                    </div>
                    <hr className="card-divider"/>
                    <EdgeFinder activeLeague={this.props.activeLeague} statsTemplate={this.edgeFinderStatsTemplate}
                                awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding} userInitialized={this.props.userInitialized}
                                calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn} 
                                userAttributes={this.props.userAttributes} setMatchupRecordsFn={this.props.setMatchupRecordsFn}/>
                    <hr className="card-divider"/>
                    <GameByGame activeLeague={this.props.activeLeague} games={this.state.games} statsTemplate={this.edgeFinderStatsTemplate} userInitialized={this.props.userInitialized}
                                awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding} userAttributes={this.props.userAttributes}/>
                </>
            }
            </>
        )
    }
}

class EdgeFinder extends React.Component {
    constructor(props) {
        super(props);

        this.statsTemplate = this.props.statsTemplate;

        this.loaded = false;
        this.creationInsightsComplete = false;
        this.conversionInsightsComplete = false;
        this.controlInsightsComplete = false;

        this.selectStatQuantifier = this.selectStatQuantifier.bind(this);
        this.comparisonShowButtonClicked = this.comparisonShowButtonClicked.bind(this);

        this.state = {
            expandStatComparison: false,
            statQuantifier: "averageStats",
            // Combined team game records
            homeTeamData: {},
            awayTeamData: {}
        };
    }

    componentDidUpdate() {
        if (!isDictEmpty(this.props.awayTeamStanding) && !isDictEmpty(this.props.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const sinceSeason = LEAGUE_CURRENT_SEASON[this.props.activeLeague] - 2;
            var promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.homeTeamStanding.code}?opponent=${this.props.awayTeamStanding.code}&sinceSeason=${sinceSeason}`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.awayTeamStanding.code}?opponent=${this.props.homeTeamStanding.code}&sinceSeason=${sinceSeason}`).then(data => {return data.json();})
            ];
            Promise.all(promises)
            .then(result => {
                this.props.setMatchupRecordsFn({
                    home: result[0],
                    away: result[1]
                });
                this.setState(
                    {
                        homeTeamData: result[0],
                        awayTeamData: result[1]
                    }
                );
            })
            .catch(error => {
                console.log("Error loading edge finder data: " + error);
            });
        }
    }

    selectStatQuantifier(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({statQuantifier: selection});
    }

    comparisonShowButtonClicked() {
        this.setState({expandStatComparison: !this.state.expandStatComparison});
    }

    getDifferential(statField, valueType) {
        var leadingTeam = "";
        var leadingValue = 0;
        var trailingTeam = "";
        var trailingValue = 0;
        var differential = 0;
        const homeTeamValue = getDictionaryValue(this.state.homeTeamData, statField);
        const awayTeamValue = getDictionaryValue(this.state.awayTeamData, statField);
        if (homeTeamValue > awayTeamValue) {
            leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.state.homeTeamData);
            leadingValue = homeTeamValue;
            trailingTeam = getTeamCityDisplayName(this.props.activeLeague, this.state.awayTeamData);
            trailingValue = awayTeamValue;
        } else {
            leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.state.awayTeamData);
            leadingValue = awayTeamValue;
            trailingTeam = getTeamCityDisplayName(this.props.activeLeague, this.state.homeTeamData);
            trailingValue = homeTeamValue;
        }

        if (valueType === "percentage") {
            // When comparing percentages we can just do the difference between the two
            differential = leadingValue - trailingValue;
        } else if (valueType === "flat") {
            if (trailingValue === 0) {
                // Setting to 100% differential, though it's debatable what it should be if the opposing team has nothing
                differential = 100;
            } else {
                differential = ((leadingValue / trailingValue) - 1) * 100;
            }
        }
        differential = decimalPrecision(parseFloat(differential), 0);

        return {
            leadingTeam: leadingTeam,
            trailingTeam: trailingTeam,
            differential: differential
        }
    }

    // Assumes the validation of checking if we have insights numbers is done prior to this point
    generateCreationSentence(statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.state.homeTeamData) || isDictEmpty(this.state.awayTeamData)) {
            return "";
        }
        var {leadingTeam, trailingTeam, differential} = this.getDifferential(statField, valueType);
        if (!this.creationInsightsComplete) {
            this.creationInsightsComplete = true;
            this.props.addInsightsFn({
                type: 'creation',
                value: differential,
                // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                qualifier: "good",
                text: `${leadingTeam} ${carrierWords} ${differential}% more ${statName} than ${trailingTeam} in opposition`
            });
        }
        return `${leadingTeam} ${carrierWords} ${differential}% more ${statName} than ${trailingTeam} in opposition`
    }

    generateConversionSentence(statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.state.homeTeamData) || isDictEmpty(this.state.awayTeamData)) {
            return "";
        }
        var {leadingTeam, trailingTeam, differential} = this.getDifferential(statField, valueType);
        if (!this.conversionInsightsComplete) {
            this.conversionInsightsComplete = true; 
            this.props.addInsightsFn({
                type: 'conversion',
                value: differential,
                // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                qualifier: "good",
                text: `${leadingTeam} ${carrierWords} ${differential}% more ${statName} than ${trailingTeam} in opposition`
            });
        } 
        return `${leadingTeam} ${carrierWords} ${differential}% more ${statName} than ${trailingTeam} in opposition`
    }

    // Control is the only one that needs differentiation between flat difference and percentage difference for now
    generateControlSentence(statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.state.homeTeamData) || isDictEmpty(this.state.awayTeamData)) {
            return "";
        }
        var {leadingTeam, trailingTeam, differential} = this.getDifferential(statField, valueType);
        if (!this.controlInsightsComplete) {
            this.controlInsightsComplete = true; 
            this.props.addInsightsFn({
                type: 'control',
                value: differential,
                // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                qualifier: "good",
                text: `${leadingTeam} ${carrierWords} ${differential}% more ${statName} than ${trailingTeam} in opposition`
            });
        } 
        return `${leadingTeam} ${carrierWords} ${differential}% more ${statName} than ${trailingTeam} in opposition`
    }

    render() {
        if (isDictEmpty(this.state.homeTeamData) || isDictEmpty(this.state.awayTeamData) || isDictEmpty(this.props.homeTeamStanding) || isDictEmpty(this.props.awayTeamStanding)) { //} || !this.props.userInitialized) {
            return (<></>)
        }
        const metadata = getLeagueInsightsMetadata(this.props.activeLeague, "present", "offensive", "cumulativeStats");
        const comparisonPointsCount = this.state.expandStatComparison ? Object.keys(this.statsTemplate).length : 6;
        return (
            <>
            <div id="edge-finder" className='playbook-section-wrapper' style={{display: 'block', position: 'relative', width: '100%'}}>
                <div>
                    <p className="font size-24 weight-700">2.1 Edge Finder</p>
                    <div className='playbook-picker-wrapper'>
                        <div className="picker playbook-picker">
                            <div className={this.state.statQuantifier === "cumulativeStats" ? "picker-active" : "picker-inactive"}>
                                <span className="clickable" style={{color: 'inherit', textDecoration: 'none'}} 
                                        data-selection="cumulativeStats" onClick={this.selectStatQuantifier}>
                                <div className="vertically-centered horizontally-centered">
                                    <p className={this.state.statQuantifier === "cumulativeStats" ? "font size-14 bold" : "font size-14"}>Total</p>
                                </div>
                                </span>
                            </div>
                            <div className={this.state.statQuantifier === "averageStats" ? "picker-active" : "picker-inactive"}>
                                <span className="clickable" style={{color: 'inherit', textDecoration: 'none'}} 
                                        data-selection="averageStats" onClick={this.selectStatQuantifier}>
                                <div className="vertically-centered horizontally-centered">
                                    <p className={this.state.statQuantifier === "averageStats" ? "font size-14 bold" : "font size-14"}>Per game</p>
                                </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comparative-table-body">
                    <div className="comparative-tbl-header">
                        <div className="tbl-header-away">
                            <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding)}</span>
                            <span className="short-text text-base-upper color-fig-default">{this.props.awayTeamStanding.code}</span>
                            <img src={getTeamLogoPath(this.props.activeLeague, this.props.awayTeamStanding.code)} height={32} width={32} alt=""/></div>
                        <div className="tbl-header-home">
                            <img src={getTeamLogoPath(this.props.activeLeague, this.props.homeTeamStanding.code)} height={32} width={32} alt=""/>
                            <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding)}</span>
                            <span className="short-text text-base-upper color-fig-default">{this.props.homeTeamStanding.code}</span>
                        </div>
                    </div>
                    {
                        Object.keys(this.statsTemplate).slice(0,comparisonPointsCount).map((comparisonPoint,index) =>
                            <div key={comparisonPoint} className="edge-finder-row text-base">
                                <HorizontalComparisonBar key={index} 
                                    leftValue={getDictionaryValue(this.state.awayTeamData, this.statsTemplate[comparisonPoint].replaceAll("%qualifier%", this.state.statQuantifier))} 
                                    rightValue={getDictionaryValue(this.state.homeTeamData, this.statsTemplate[comparisonPoint].replaceAll("%qualifier%", this.state.statQuantifier))} 
                                    leftColorHex={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.awayTeamStanding.code.toLowerCase()]} rightColorHex={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.homeTeamStanding.code.toLowerCase()]} 
                                    text={comparisonPoint} showPercentage={false} />
                            </div>
                        )
                    }
                    {
                        this.state.expandStatComparison ? <></> : <div className="playbook-edge-finder-show-button-gradient"></div>
                    }
                    <div onClick={this.comparisonShowButtonClicked} 
                            className={this.state.expandStatComparison ? "playbook-edge-finder-show-button-expanded unselectable" : "playbook-edge-finder-show-button unselectable "}>
                        <p className="font size-14">{this.state.expandStatComparison ? "Show less" : "Show more"}</p>
                    </div>
                </div>
                {/* Margin top of 82 = (size of button) + (margin above button) + (margin below button) */}
                <div className="insights" style={this.state.expandStatComparison ? {marginTop: '82px'} : {}}>
                    <h5><img src="assets/insights-icon.svg" alt="insights" style={{paddingBottom: '2px', marginRight: '8px'}}/>Insights</h5>
                    <ol>
                        <li>{this.generateCreationSentence(metadata.statName["creation"], metadata.statField["creation"], metadata.carrierWords["creation"], metadata.valueType["creation"])}</li>
                        <li>{this.generateConversionSentence(metadata.statName["conversion"], metadata.statField["conversion"], metadata.carrierWords["conversion"], metadata.valueType["conversion"])}</li>
                        <li>{this.generateControlSentence(metadata.statName["control"], metadata.statField["control"], metadata.carrierWords["control"], metadata.valueType["control"])}</li>
                    </ol>
                </div>
            </div>
            </>
        )
    }
}

class GameByGame extends React.Component {
    constructor(props) {
        super(props);

        this.filterOptions = swapDictionaryKeyValue(this.props.statsTemplate);
        this.awayTeamStanding = this.props.awayTeamStanding || {};
        this.homeTeamStanding = this.props.homeTeamStanding || {};
        this.games = this.props.games || [];
        this.loaded = false;
        this.gameByGameBarData = [];
        if (this.games.length > 0 && !isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding)) {
            this.loaded = true;
            this.gameByGameBarData = this.buildGameByGameData(this.games, this.homeTeamStanding, this.awayTeamStanding, Object.keys(this.filterOptions)[0], "cumulativeStats");
        }

        this.filterRef = React.createRef();
        this.clickableElements = [this.filterRef];
        this.filterStyling = {};

        this.filterClicked = this.filterClicked.bind(this);
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateFilterStyling = this.generateFilterStyling.bind(this);
        this.handleGeneralClick = this.handleGeneralClick.bind(this);
        this.addClickableElement = this.addClickableElement.bind(this);

        
        this.state = {
            filterActive: false,
            filterSelection: Object.keys(this.filterOptions)[0],
            gameByGameBarData: this.gameByGameBarData
        };
    }

    buildGameByGameData(games, homeTeamStanding, awayTeamStanding, statField, statQuantifier) {
        var result = [];
        // TODO: check with Matt if there was a reason for capping this to 5, is it just to have a smaller graph?
        games.slice(0, 5).forEach((game, index) => {
            var gameEntry = {};
            // The team that was at home during the game is not necessarily the team that is at home here, need to check that
            var currentHomeTeamData = (this.homeTeamStanding.code === game.homeTeamCode) ? game.homeTeamData : game.awayTeamData;
            var currentAwayTeamData = (this.awayTeamStanding.code === game.homeTeamCode) ? game.homeTeamData : game.awayTeamData;
            gameEntry["date"] = `${String(parseDateFromString(game.timestamp).month).padStart(2, '0')}/${String(parseDateFromString(game.timestamp).day).padStart(2, '0')}/${parseDateFromString(game.timestamp).year % 100}`;
            const gameIdParts = game.id.split("-");
            if (this.props.activeLeague === "mlb" && gameIdParts.length > 3) {
                const gameNumber = gameIdParts[3];
                gameEntry["date"] += ` (${gameNumber})`;
            }
            gameEntry[getTeamCityDisplayName(this.props.activeLeague, homeTeamStanding)] = getDictionaryValue(currentHomeTeamData, statField.replaceAll("%qualifier%", statQuantifier));
            gameEntry[`${getTeamCityDisplayName(this.props.activeLeague, homeTeamStanding)}Color`] = TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.homeTeamStanding.code.toLowerCase()];
            gameEntry[getTeamCityDisplayName(this.props.activeLeague, awayTeamStanding)] = getDictionaryValue(currentAwayTeamData, statField.replaceAll("%qualifier%", statQuantifier));
            gameEntry[`${getTeamCityDisplayName(this.props.activeLeague, awayTeamStanding)}Color`] = TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.awayTeamStanding.code.toLowerCase()];
            result.push(gameEntry);
        })
        return result;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.generateFilterStyling);
        window.addEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
        this.generateFilterStyling();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.generateFilterStyling);
        window.removeEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
    }

    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding || {};
        this.homeTeamStanding = this.props.homeTeamStanding || {};
        this.games = this.props.games || [];
        if (this.games.length > 0 && !isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            this.setState({gameByGameBarData: this.buildGameByGameData(this.games, this.homeTeamStanding, this.awayTeamStanding, this.state.filterSelection, "cumulativeStats")}, this.forceUpdate);
        }
    }

    filterClicked() {
        this.setState({filterActive: !this.state.filterActive});
    }

    filterOptionSelected(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState(
            {
                filterActive: false, 
                filterSelection: selection,
                gameByGameBarData: this.buildGameByGameData(this.games, this.homeTeamStanding, this.awayTeamStanding, selection, "cumulativeStats")
            }, 
            this.generateFilterStyling
        );
    }

    generateFilterStyling() {
        if (this.filterRef.current == null) {
            return;
        }
        this.filterStyling = {
            left: `${this.filterRef.current.offsetLeft}px`,
            top: `${this.filterRef.current.offsetTop + this.filterRef.current.offsetHeight}px`,
            width: `${this.filterRef.current.offsetWidth}px`
        };
    }

    handleGeneralClick(event) {
        const scrollbarClicked = (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight)
        var foundTarget = false;
        for(const element of this.clickableElements) {
          if (element.current && element.current.contains(event.target)) {
            foundTarget = true;
            break;
          }
        }
        if (!foundTarget && !scrollbarClicked) {
          this.setState({
            filterActive: false
          });
        }
    }

    addClickableElement(element) {
        this.clickableElements.push(element);
    }

    render() {
        // if (isDictEmpty(this.state.homeTeamInsights) || isDictEmpty(this.state.awayTeamInsights)) {
        //     return (<></>)
        // }
        var legends = [
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 4,
                itemWidth: 100,
                itemHeight: 32,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 12,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ];
        var margin = { top: 50, right: 100, bottom: 50, left: 45 };
        if (window.innerWidth < 576) {
            legends = [
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 50,
                    itemsSpacing: 4,
                    itemWidth: 100,
                    itemHeight: 32,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 12,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ];
            margin = { top: 50, right: 20, bottom: 50, left: 45 };
        }
        return (
            <div id="game-by-game" className='game-by-game' style={{display: 'block', position: 'relative', width: '100%'}}>
                <div>
                    <p className="font size-24 weight-700">2.2 Game by Game</p>
                    <div>
                        <p className='text-style-label-medium'>Stat</p>
                        <div className='game-by-game-filter-wrapper'>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.filterOptions} 
                                selection={this.state.filterSelection} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {selection: selection}}})}
                            />
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', height: "372px", position: 'absolute', bottom: '0px'}}>
                    {
                        this.loaded ? 
                        <ResponsiveBar
                        theme={RESPONSIVE_BAR_THEME}
                        data={this.state.gameByGameBarData}
                        keys={[getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding), getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)]}
                        indexBy="date"
                        margin={margin}
                        padding={0.35}
                        innerPadding={10}
                        groupMode="grouped"
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        valueFormat={{ format: '', enabled: false }}
                        // colors={{ scheme: 'nivo' }}
                        colors={({ id, data }) => String(data[`${id}Color`])}
                        defs={[]}
                        fill={[]}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        enableLabel={false}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                        legends={legends}
                        motionConfig={{ mass: 1, tension: 170, friction: 26, clamp: false, precision: 0.01, velocity: 0 }}
                        />
                        :
                        <></>
                    }
                    
                </div>
            </div>
        );
    }
}

class Season extends React.Component {
    constructor(props) {
        super(props);

        this.statsTemplate = this.props.statsTemplate;

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;

        this.state = {
            homeTeamRecordComparison: {},
            awayTeamRecordComparison: {},
            leagueStandings: this.props.allStandings,
            leagueAverages: {},
            awayTeamType: "offensive",
            homeTeamType: "defensive",
            expandStatComparison: false
        }

        this.loaded = false;

        this.swapStatsButtonClicked = this.swapStatsButtonClicked.bind(this);
        this.comparisonShowButtonClicked = this.comparisonShowButtonClicked.bind(this);
    }

    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?teamCode=${this.homeTeamStanding.code}&sortingOrder=desc`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?teamCode=${this.awayTeamStanding.code}&sortingOrder=desc`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/averages`).then(data => {return data.json();})
            ];
            // console.log("Season fetch promises", promises);
            Promise.all(promises)
            .then(result => {
                // console.log("Season fetch result", result);
                const homeTeamRecordComparison = this.props.buildIndividualTeamRecordFn(result[0], this.homeTeamStanding.code, this.state.homeTeamRecordComparison);
                const awayTeamRecordComparison = this.props.buildIndividualTeamRecordFn(result[1], this.awayTeamStanding.code, this.state.awayTeamRecordComparison);
                this.props.setSeasonFavoriteRecordsFn({
                    home: homeTeamRecordComparison,
                    away: awayTeamRecordComparison
                });
                this.setState(
                    {
                        homeTeamRecordComparison: homeTeamRecordComparison,
                        awayTeamRecordComparison: awayTeamRecordComparison,
                        // Setting it again here just in case
                        leagueStandings: this.props.allStandings,
                        leagueAverages: result[2]
                    },
                    this.forceUpdate
                );
            })
            .catch(error => {
                console.log(`Error loading ${this.props.activeLeague} season data: ${error}`);
                console.log(error.stack)
            });
        }
    }

    orderAndFindPosition(leagueStandings, teamCode, fieldPath, order) {
        orderListByField(leagueStandings, fieldPath, order);
        var position = 1;
        for (let standing of leagueStandings) {
            if (standing.code === teamCode) {
                return position;
            }
            position += 1;
        }
        return position;
    }

    swapStatsButtonClicked() {
        const previousAwayTeamType = this.state.awayTeamType;
        const previousHomeTeamType = this.state.homeTeamType;
        this.setState(
            {
                awayTeamType: previousHomeTeamType,
                homeTeamType: previousAwayTeamType
            }
        );
    }

    comparisonShowButtonClicked() {
        this.setState({expandStatComparison: !this.state.expandStatComparison});
    }

    render() {
        if (isDictEmpty(this.awayTeamStanding) || isDictEmpty(this.homeTeamStanding) || isDictEmpty(this.state.leagueAverages)) {
            return (<></>)
        }
        if (shouldBlockUserAccess(this.props.userAttributes)) {
            return (
                <>
                    <SeasonCard id={"season-offence"} title={"3.2 Offense"}
                                activeLeague={this.props.activeLeague} statsTemplate={this.statsTemplate} type={"offensive"}
                                leagueAverages={this.state.leagueAverages} awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                                calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn} userInitialized={this.props.userInitialized}
                                userAttributes={this.props.userAttributes}/>
                    <SeasonCard id={"season-defense"} title={"3.3 Defense"}
                                activeLeague={this.props.activeLeague} statsTemplate={this.statsTemplate} type={"defensive"}
                                leagueAverages={this.state.leagueAverages} awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                                calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn} userInitialized={this.props.userInitialized}
                                userAttributes={this.props.userAttributes}/>
                </>
            )
        }
        const comparisonPointsCount = this.state.expandStatComparison ? Object.keys(this.statsTemplate).length : 6;
        return (
            <>
                <p className="font size-24 weight-700" id="season">3. Season</p>
                {
                    (isDictEmpty(this.awayTeamStanding) || isDictEmpty(this.homeTeamStanding)) ? <></> : 
                    <>
                        <div className="text-sm">How have these two teams performed this season?</div>
                        <div className="comparative-table-body">
                            <div className="comparative-tbl-header">
                                <div className="tbl-header-away">
                                    <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding)}</span>
                                    <span className="short-text text-base-upper color-fig-default">{this.awayTeamStanding.code}</span>
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamStanding.code)} height={32} width={32} alt=""/>
                                </div>
                                <div className="tbl-header-home" >
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamStanding.code)} height={32} width={32} alt=""/>
                                    <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)}</span>
                                    <span className="short-text text-base-upper color-fig-default">{this.homeTeamStanding.code}</span>
                                </div>
                            </div>
                            {
                                this.props.recordComparisonFn(this.state.homeTeamRecordComparison, this.state.awayTeamRecordComparison)
                            }
                        </div>
                        <hr className="card-divider"/>
                        <p className="font size-24 weight-700" id="season-overall">3.1 Rankings</p>
                        <div className="comparative-table-body">
                            <div className="comparative-tbl-header">
                                <div className="lr-away">
                                    <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding)}</span>
                                    <span className="short-text text-base-upper color-fig-default">{this.awayTeamStanding.code}</span>
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamStanding.code)} height={32} width={32} alt=""/>
                                </div>
                                <div className="lr-swap">
                                    <div>
                                        <Button icon="assets/playbook/icon-swap.svg" type="secondary" enabled={true} onClick={this.swapStatsButtonClicked}/>
                                    </div>
                                    {/* <button onClick={this.swapStatsButtonClicked}><img src="assets/playbook/icon-swap.svg" style={{paddingBottom: '2px'}} alt="swap"/></button> */}
                                </div>
                                <div className="lr-home" >
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamStanding.code)} height={32} width={32} alt=""/>
                                    <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)}</span>
                                    <span className="short-text text-base-upper color-fig-default">{this.homeTeamStanding.code}</span>
                                </div>
                            </div>
                            <div className="advanced-stats-sub-header text-xs-upper">
                                <div>{this.state.awayTeamType === "offensive" ? "FOR" : "AGAINST"}</div>
                                {/* <div>total offence</div> */}
                                <div></div>
                                <div>{this.state.homeTeamType === "offensive" ? "FOR" : "AGAINST"}</div>
                            </div>
                            {
                                Object.keys(this.props.categorizedStatsTemplate).slice(0, comparisonPointsCount).map((key) =>
                                <div key={key} className="advanced-stats-tbl-row text-base">
                                    <div className="font size-16">{addOrdinalNumberSuffix(this.orderAndFindPosition(this.state.leagueStandings, this.awayTeamStanding.code, this.props.categorizedStatsTemplate[key][this.state.awayTeamType === "offensive" ? "for" : "against"].field.replaceAll("%qualifier%", "averageStats"), this.props.categorizedStatsTemplate[key][this.state.awayTeamType === "offensive" ? "for" : "against"].sortingOrder))}</div>
                                    <div className="text-xs-upper">
                                        <span className="full-text">{key}</span>
                                        <span className="short-text">{key}</span>
                                    </div>
                                    <div className="font size-16">{addOrdinalNumberSuffix(this.orderAndFindPosition(this.state.leagueStandings, this.homeTeamStanding.code, this.props.categorizedStatsTemplate[key][this.state.homeTeamType === "offensive" ? "for" : "against"].field.replaceAll("%qualifier%", "averageStats"), this.props.categorizedStatsTemplate[key][this.state.homeTeamType === "offensive" ? "for" : "against"].sortingOrder))}</div>
                                </div>
                                )
                            }
                            {
                                this.state.expandStatComparison ? <></> : <div className="playbook-edge-finder-show-button-gradient" style={{bottom: '22px'}}></div>
                            }
                            <div onClick={this.comparisonShowButtonClicked} 
                                    className={this.state.expandStatComparison ? "playbook-edge-finder-show-button-expanded unselectable" : "playbook-edge-finder-show-button unselectable "}
                                    style={this.state.expandStatComparison ? {bottom: '-58px'} : {bottom: '-20px'}}>
                                <p className="font size-14">{this.state.expandStatComparison ? "Show less" : "Show more"}</p>
                            </div>
                        </div>
                        <hr className="card-divider" style={this.state.expandStatComparison ? {marginTop: '80px'} : {}}/>
                        <SeasonCard id={"season-offence"} title={"3.2 Offense"}
                                    activeLeague={this.props.activeLeague} statsTemplate={this.statsTemplate} type={"offensive"}
                                    leagueAverages={this.state.leagueAverages} awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                                    calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn} userInitialized={this.props.userInitialized}
                                    userAttributes={this.props.userAttributes}/>
                        <hr className="card-divider"/>
                        <SeasonCard id={"season-defense"} title={"3.3 Defense"}
                                    activeLeague={this.props.activeLeague} statsTemplate={this.statsTemplate} type={"defensive"}
                                    leagueAverages={this.state.leagueAverages} awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                                    calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn} userInitialized={this.props.userInitialized}
                                    userAttributes={this.props.userAttributes}/>
                    </>
                }
            </>
        )
    }
}

class SeasonCard extends React.Component {
    constructor(props) {
        super(props);

        this.filterOptions = swapDictionaryKeyValue(this.props.statsTemplate);
        this.statsTemplate = this.props.statsTemplate;
        this.type = this.props.type;

        this.insightsRef = React.createRef();
        this.filterRef = React.createRef();
        this.clickableElements = [this.filterRef];
        this.filterStyling = {};
        this.creationInsightsComplete = false;
        this.conversionInsightsComplete = false;
        this.controlInsightsComplete = false;

        this.selectStatQuantifier = this.selectStatQuantifier.bind(this);
        this.filterClicked = this.filterClicked.bind(this);
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateFilterStyling = this.generateFilterStyling.bind(this);
        this.handleGeneralClick = this.handleGeneralClick.bind(this);
        this.addClickableElement = this.addClickableElement.bind(this);
        this.buildBarGraphData = this.buildBarGraphData.bind(this);

        this.state = {
            statQuantifier: "averageStats",
            filterActive: false,
            filterSelection: Object.keys(this.filterOptions)[0]
        };
    }

    buildBarGraphData(stat, quantifier, type) {
        if (isDictEmpty(this.props.awayTeamStanding) || isDictEmpty(this.props.homeTeamStanding) || isDictEmpty(this.statsTemplate)) {
            return [];
        }
        var awayTeamEntry = {};
        var homeTeamEntry = {};
        var leagueAverageEntry = {};
        // entry["stat"] = stat;

        awayTeamEntry["team"] = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
        awayTeamEntry[this.filterOptions[stat]] = getDictionaryValue(this.props.awayTeamStanding, stat.replaceAll("%qualifier%", quantifier).replaceAll("%type%", type));
        awayTeamEntry[`backgroundColor`] = TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.awayTeamStanding.code.toLowerCase()];
        awayTeamEntry[`textColor`] = "#ffffff"

        homeTeamEntry["team"] = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
        homeTeamEntry[this.filterOptions[stat]] = getDictionaryValue(this.props.homeTeamStanding, stat.replaceAll("%qualifier%", quantifier).replaceAll("%type%", type));
        homeTeamEntry[`backgroundColor`] = TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.homeTeamStanding.code.toLowerCase()];
        homeTeamEntry[`textColor`] = "#ffffff"

        leagueAverageEntry["team"] = "League avg.";
        leagueAverageEntry[this.filterOptions[stat]] = getDictionaryValue(this.props.leagueAverages, stat.replaceAll("%qualifier%", quantifier).replaceAll("%type%", type));
        // leagueAverageEntry["League avg.Color"] = "#D1D5DB";
        // leagueAverageEntry["League avg.TextColor"] = "#000000";
        leagueAverageEntry["backgroundColor"] = "#D1D5DB";
        leagueAverageEntry["textColor"] = "#000000";
        return [awayTeamEntry, homeTeamEntry, leagueAverageEntry];
    }

    buildChartData(stat, quantifier, type) {
        var chartData = [
            {
                "id": getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding),
                "label": getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding),
                "value": getDictionaryValue(this.props.awayTeamStanding, stat.replaceAll("%qualifier%", quantifier).replaceAll("%type%", type)),
                "color": TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.awayTeamStanding.code.toLowerCase()]
            },
            {
                "id": getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding),
                "label": getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding),
                "value": getDictionaryValue(this.props.homeTeamStanding, stat.replaceAll("%qualifier%", quantifier).replaceAll("%type%", type)),
                "color": TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.homeTeamStanding.code.toLowerCase()]
            },
            {
                "id": "League avg.",
                "label": "League avg.",
                "value": getDictionaryValue(this.props.leagueAverages, stat.replaceAll("%qualifier%", quantifier).replaceAll("%type%", type)),
                "color": "#D1D5DB"
            }
        ];
        return chartData;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.generateFilterStyling);
        window.addEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
        this.generateFilterStyling();
        // Triggering one update to initialize the ref(s)
        this.forceUpdate();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.generateFilterStyling);
        window.removeEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
    }

    componentDidUpdate() {
        const windowWidth = window.innerWidth;
        var fontSize = '18px';
        var lineHeight = '24px';
        if (windowWidth < 576) {
            fontSize = '14px';
            lineHeight = '20px';
        }
        // Workaround to change the font size of the nivo legend since I couldn't find a way to do it through their API, isntead we're changing the inline style directly
        const textNodesList = document.querySelectorAll("#legend-container text");
        // We'll just set all of them even if we don't need to instead of hardcoding the three we need just in case we make an adjustment that changes the order
        if (textNodesList.length > 0) {
            textNodesList.forEach((node) => {
                node.style.fontSize = fontSize;
                node.style.lineHeight = lineHeight;
                node.style.font = 'Inter';
                // Not sure the color actually works...
                node.style.color = '#111827';
            })
        }
    }

    selectStatQuantifier(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({statQuantifier: selection});
    }

    filterClicked() {
        this.setState({filterActive: !this.state.filterActive});
    }

    filterOptionSelected(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({filterActive: false, filterSelection: selection}, this.generateFilterStyling);
    }

    generateFilterStyling() {
        if (this.filterRef.current == null) {
            return;
        }
        this.filterStyling = {
            left: `${this.filterRef.current.offsetLeft}px`,
            top: `${this.filterRef.current.offsetTop + this.filterRef.current.offsetHeight}px`,
            width: `${this.filterRef.current.offsetWidth}px`
        };
        // We have set this function on resize, we're adding this to trigger a render in order to have the graph and legend properly styled
        this.forceUpdate();
    }

    handleGeneralClick(event) {
        const scrollbarClicked = (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight)
        var foundTarget = false;
        for(const element of this.clickableElements) {
          if (element.current && element.current.contains(event.target)) {
            foundTarget = true;
            break;
          }
        }
        if (!foundTarget && !scrollbarClicked) {
          this.setState({
            filterActive: false
          });
        }
    }

    addClickableElement(element) {
        this.clickableElements.push(element);
    }

    getDifferential(highValue, lowValue, valueType) {
        var differential = 0;
        if (valueType === "percentage") {
            // When comparing percentages we can just do the difference between the two
            differential = highValue - lowValue;
        } else if (valueType === "flat") {
            if (lowValue === 0) {
                // Setting to 100% differential, though it's debatable what it should be if the opposing team has nothing
                differential = 100;
            } else {
                differential = ((highValue / lowValue) - 1) * 100;
            }
        }
        return decimalPrecision(parseFloat(differential), 0);
    }

    // Assumes the validation of checking if we have insights numbers is done prior to this point
    // TODO: extract common functionality from creation, conversion, control (same one with different tense can also be used in the Form section)
    generateCreationSentence(type, statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.props.awayTeamStanding) || isDictEmpty(this.props.homeTeamStanding) || isDictEmpty(this.props.leagueAverages)) {
            return "";
        }
        var leadingTeam = "";
        var qualifier = "";
        var differential = 0;

        const homeTeamValue = getDictionaryValue(this.props.homeTeamStanding, statField);
        const awayTeamValue = getDictionaryValue(this.props.awayTeamStanding, statField);
        const leagueAverageValue = getDictionaryValue(this.props.leagueAverages, statField);

        // TODO: should check if the insight is complete at a higher level to avoid re-calculating it
        if (type === "offensive") {
            if (homeTeamValue > leagueAverageValue || awayTeamValue > leagueAverageValue) {
                qualifier = "more";
            } else {
                qualifier = "less";
            }
            // TODO: division by zero check
            if (homeTeamValue > awayTeamValue) {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(homeTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, homeTeamValue, valueType);
                }
            } else {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(awayTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, awayTeamValue, valueType);
                }
            }

            if (!this.creationInsightsComplete) {
                this.creationInsightsComplete = true;
                this.props.addInsightsFn({
                    type: 'creation',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "good" : "bad"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
                });
            }
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
        } else if (type === "defensive") {
            if (homeTeamValue < leagueAverageValue || awayTeamValue < leagueAverageValue) {
                qualifier = "less";
            } else {
                qualifier = "more";
            }
            // TODO: division by zero check
            if (homeTeamValue < awayTeamValue) {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(homeTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, homeTeamValue, valueType);
                }
            } else {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(awayTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, awayTeamValue, valueType);
                }
            }
            if (!this.creationInsightsComplete) {
                this.creationInsightsComplete = true;
                this.props.addInsightsFn({
                    type: 'creation',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "bad" : "good"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
                });
            }
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
        } else {
            return ""
        }
    }

    generateConversionSentence(type, statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.props.awayTeamStanding) || isDictEmpty(this.props.homeTeamStanding) || isDictEmpty(this.props.leagueAverages)) {
            return "";
        }
        var leadingTeam = "";
        var qualifier = "";
        var differential = 0;

        const homeTeamValue = getDictionaryValue(this.props.homeTeamStanding, statField);
        const awayTeamValue = getDictionaryValue(this.props.awayTeamStanding, statField);
        const leagueAverageValue = getDictionaryValue(this.props.leagueAverages, statField);

        if (type === "offensive") {
            if (homeTeamValue > leagueAverageValue || awayTeamValue > leagueAverageValue) {
                qualifier = "more";
            } else {
                qualifier = "less";
            }
            // TODO: division by zero check
            if (homeTeamValue > awayTeamValue) {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(homeTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, homeTeamValue, valueType);
                }
            } else {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(awayTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, awayTeamValue, valueType);
                }
            }

            differential = decimalPrecision(parseFloat(differential), 0);
            if (!this.conversionInsightsComplete) {
                this.conversionInsightsComplete = true; 
                this.props.addInsightsFn({
                    type: 'conversion',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "good" : "bad"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
                });
            }        
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
        } else if (type === "defensive") {
            if (homeTeamValue < leagueAverageValue || awayTeamValue < leagueAverageValue) {
                qualifier = "less";
            } else {
                qualifier = "more";
            }
            // TODO: division by zero check
            if (homeTeamValue < awayTeamValue) {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(homeTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, homeTeamValue, valueType);
                }
            } else {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(awayTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, awayTeamValue, valueType);
                }
            }

            differential = decimalPrecision(parseFloat(differential), 0); 
            if (!this.conversionInsightsComplete) {
                this.conversionInsightsComplete = true; 
                this.props.addInsightsFn({
                    type: 'conversion',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "bad" : "good"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
                });
            } 
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
        } else {
            return ""
        }
    }
    // Control is the only one that needs differentiation between flat difference and percentage difference for now
    generateControlSentence(type, statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.props.awayTeamStanding) || isDictEmpty(this.props.homeTeamStanding) || isDictEmpty(this.props.leagueAverages)) {
            return "";
        }
        var leadingTeam = "";
        var qualifier = "";
        var differential = 0;

        const homeTeamValue = getDictionaryValue(this.props.homeTeamStanding, statField);
        const awayTeamValue = getDictionaryValue(this.props.awayTeamStanding, statField);
        const leagueAverageValue = getDictionaryValue(this.props.leagueAverages, statField);

        if (type === "offensive") {
            if (homeTeamValue > leagueAverageValue || awayTeamValue > leagueAverageValue) {
                qualifier = "more";
            } else {
                qualifier = "less";
            }
            // TODO: division by zero check
            if (homeTeamValue > awayTeamValue) {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(homeTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, homeTeamValue, valueType);
                }
            } else {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(awayTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, awayTeamValue, valueType);
                }
            }

            differential = decimalPrecision(parseFloat(differential), 0);
            if (!this.controlInsightsComplete) {
                this.controlInsightsComplete = true; 
                this.props.addInsightsFn({
                    type: 'control',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "good" : "bad"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
                });
            }       
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
        } else if (type === "defensive") {
            if (homeTeamValue < leagueAverageValue || awayTeamValue < leagueAverageValue) {
                qualifier = "less";
            } else {
                qualifier = "more";
            }
            // TODO: division by zero check
            if (homeTeamValue < awayTeamValue) {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(homeTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, homeTeamValue, valueType);
                }
            } else {
                leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
                if (qualifier === "more") {
                    differential = this.getDifferential(awayTeamValue, leagueAverageValue, valueType);
                } else {
                    differential = this.getDifferential(leagueAverageValue, awayTeamValue, valueType);
                }
            }

            differential = decimalPrecision(parseFloat(differential), 0); 
            if (!this.controlInsightsComplete) {
                this.controlInsightsComplete = true; 
                this.props.addInsightsFn({
                    type: 'control',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "bad" : "good"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
                });
            } 
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} than the league average`
        } else {
            return ""
        }
    }

    render() {
        const metadata = getLeagueInsightsMetadata(this.props.activeLeague, "present", this.props.type, "cumulativeStats");
        const chartBottom = ((this.insightsRef.current != null) ? this.insightsRef.current.offsetHeight + 20 : 0);
        const windowWidth = window.innerWidth;
        var margin = { top: 35, right: 20, bottom: 45, left: 20 };
        var chartContainerStyle = {height: "250px", width: '100%', position: 'absolute', bottom: `${chartBottom}px`};
        // wtf did we need this for?
        if (windowWidth < 768) {
            chartContainerStyle["height"] = '200px';
            chartContainerStyle["bottom"] = `${chartBottom + 40}px`;
        }
        if (windowWidth < 576) {
            chartContainerStyle["width"] = '100%';
            chartContainerStyle["height"] = '180px';
            chartContainerStyle["bottom"] = `${chartBottom + 25}px`;
            margin = {
                top: 0, right: 20, bottom: 20, left: 20
            };
        }
        return (
            <div id={this.props.id} className='playbook-section-wrapper playbook-season-card-wrapper'>
                <div>
                    <p className="font size-24 weight-700">{this.props.title}</p>
                    <div className='playbook-picker-wrapper'>
                        <div className="picker playbook-picker">
                            <div className={this.state.statQuantifier === "cumulativeStats" ? "picker-active" : "picker-inactive"}>
                                <span className="clickable" style={{color: 'inherit', textDecoration: 'none'}} 
                                        data-selection="cumulativeStats" onClick={this.selectStatQuantifier}>
                                <div className="vertically-centered horizontally-centered">
                                    <p className={this.state.statQuantifier === "cumulativeStats" ? "font size-14 bold" : "font size-14"}>Total</p>
                                </div>
                                </span>
                            </div>
                            <div className={this.state.statQuantifier === "averageStats" ? "picker-active" : "picker-inactive"}>
                                <span className="clickable" style={{color: 'inherit', textDecoration: 'none'}} 
                                        data-selection="averageStats" onClick={this.selectStatQuantifier}>
                                <div className="vertically-centered horizontally-centered">
                                    <p className={this.state.statQuantifier === "averageStats" ? "font size-14 bold" : "font size-14"}>Per game</p>
                                </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='text-style-label-medium'>Stat</p>
                    <div className='game-by-game-filter-wrapper'>
                        <InputSelection 
                            enabled={true} 
                            type="single" 
                            typography="md" 
                            options={this.filterOptions} 
                            selection={this.state.filterSelection} 
                            selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {selection: selection}}})}
                        />
                    </div>
                </div>
                <div style={chartContainerStyle} className="playbook-season-graph-wrapper">
                    <ResponsiveBar
                        theme={RESPONSIVE_BAR_THEME}
                        data={this.buildBarGraphData(this.state.filterSelection, this.state.statQuantifier, this.props.type)}
                        keys={[this.filterOptions[this.state.filterSelection]]}
                        indexBy={"team"}
                        margin={margin}
                        padding={0.1}
                        innerPadding={0}
                        groupMode="grouped"
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        valueFormat={{ format: '', enabled: false }}
                        // colors={{ scheme: 'nivo' }}
                        colors={({ id, data }) => String(data[`backgroundColor`])}
                        defs={[]}
                        fill={[]}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                        borderRadius={6}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 10,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={null}
                        enableGridY={false}
                        enableLabel={true}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        // labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                        labelTextColor="#ffffff"
                        // labelTextColor={({ id, data }) => String(data[`${id}TextColor`])}
                        legends={[]}
                        motionConfig={{ mass: 1, tension: 170, friction: 26, clamp: false, precision: 0.01, velocity: 0 }}
                    />
                </div>
                <div ref={this.insightsRef} className="insights" style={{position: 'absolute', bottom: '0px', width: '100%'}}>
                    <h5><img src="assets/insights-icon.svg" alt="insights" style={{paddingBottom: '2px', marginRight: '8px'}}/>Insights</h5>
                    <ol>
                        <li>{this.generateCreationSentence(this.props.type, metadata.statName["creation"], metadata.statField["creation"], metadata.carrierWords["creation"], metadata.valueType["creation"])}</li>
                        <li>{this.generateConversionSentence(this.props.type, metadata.statName["conversion"], metadata.statField["conversion"], metadata.carrierWords["conversion"], metadata.valueType["conversion"])}</li>
                        <li>{this.generateControlSentence(this.props.type, metadata.statName["control"], metadata.statField["control"], metadata.carrierWords["control"], metadata.valueType["control"])}</li>
                    </ol>
                </div>
            </div>
        )
    }
}

class RecentForm extends React.Component {
    constructor(props) {
        super(props);

        this.statsTemplate = this.props.statsTemplate;

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;

        this.loaded = false;

        this.state = {
            homeTeamRecordComparison: {},
            awayTeamRecordComparison: {},
            homeTeamGameRecords: [],
            awayTeamGameRecords: [],
            homeTeamRecentRecord: {},
            awayTeamRecentRecord: {}
        }
    }

    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/recentForTeam?teamCode=${this.homeTeamStanding.code}&timeframe=LAST_5`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/recentForTeam?teamCode=${this.awayTeamStanding.code}&timeframe=LAST_5`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/${this.homeTeamStanding.code}/getRecentRecord`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/${this.awayTeamStanding.code}/getRecentRecord`).then(data => {return data.json();})
            ];
            Promise.all(promises)
            .then(result => {
                const homeTeamRecordComparison = this.props.buildIndividualTeamGameRecordFn(result[0], this.state.homeTeamRecordComparison);
                const awayTeamRecordComparison = this.props.buildIndividualTeamGameRecordFn(result[1], this.state.awayTeamRecordComparison);
                this.props.setRecentRecordsFn({
                    home: result[2],
                    away: result[3]
                });
                this.props.setRecentFavoriteRecordsFn({
                    home: homeTeamRecordComparison,
                    away: awayTeamRecordComparison
                })
                this.setState(
                    {
                        homeTeamRecordComparison: homeTeamRecordComparison,
                        awayTeamRecordComparison: awayTeamRecordComparison,
                        homeTeamGameRecords: result[0],
                        awayTeamGameRecords: result[1],
                        homeTeamRecentRecord: result[2],
                        awayTeamRecentRecord: result[3]
                    },
                    this.forceUpdate
                );
            })
            .catch(error => {
                console.log(`Error loading ${this.props.activeLeague} season data: ${error}`);
                console.log(error.stack);
            });
        }
    }

    render() {
        if (isDictEmpty(this.awayTeamStanding) || isDictEmpty(this.homeTeamStanding)) {
            return (<></>)
        }
        if (shouldBlockUserAccess(this.props.userAttributes)) {
            return (
                <>
                <FormCard id={"rf-offence"} title={"4.2 Offense (per game)"} type={"offensive"}
                            activeLeague={this.props.activeLeague} statsTemplate={this.statsTemplate} 
                            awayTeamRecentRecord={this.state.awayTeamRecentRecord} homeTeamRecentRecord={this.state.homeTeamRecentRecord}
                            awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding} userInitialized={this.props.userInitialized}
                            calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn}
                            userAttributes={this.props.userAttributes}/>
                <FormCard id={"rf-defense"} title={"4.3 Defense (per game)"} type={"defensive"}
                            activeLeague={this.props.activeLeague} statsTemplate={this.statsTemplate} 
                            awayTeamRecentRecord={this.state.awayTeamRecentRecord} homeTeamRecentRecord={this.state.homeTeamRecentRecord}
                            awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding} userInitialized={this.props.userInitialized}
                            calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn}
                            userAttributes={this.props.userAttributes}/>
                </>
            )
        }
        return (
            <>
                <p className="font size-24 weight-700" id="recent-form">4. Recent Form</p>
                {
                    (isDictEmpty(this.awayTeamStanding) || isDictEmpty(this.homeTeamStanding)) ? <></> : 
                    <>
                        <div>How have these two teams performed in their last 5 games?</div>
                        <div className="comparative-table-body">
                            <div className="comparative-tbl-header">
                                <div className="tbl-header-away">
                                    <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding)}</span>
                                    <span className="short-text text-base-upper color-fig-default">{this.awayTeamStanding.code}</span>
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamStanding.code)} height={32} width={32} alt=""/>
                                </div>
                                <div className="tbl-header-home" >
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamStanding.code)} height={32} width={32} alt=""/>
                                    <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)}</span>
                                    <span className="short-text text-base-upper color-fig-default">{this.homeTeamStanding.code}</span>
                                </div>
                            </div>
                            {
                                this.props.recordComparisonFn(this.state.homeTeamRecordComparison, this.state.awayTeamRecordComparison)
                            }
                        </div>
                        <hr className="card-divider"/>
                        <p className="font size-24 weight-700" id="rf-overall">4.1 Last 5</p>
                        <div className="comparative-table-body">
                            <div className="comparative-tbl-header">
                                <div className="tbl-header-away">
                                    <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding)}</span>
                                    <span className="short-text text-base-upper color-fig-default">{this.awayTeamStanding.code}</span>
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamStanding.code)} height={32} width={32} alt=""/>
                                </div>
                                <div className="tbl-header-home" >
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamStanding.code)} height={32} width={32} alt=""/>
                                    <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)}</span>
                                    <span className="short-text text-base-upper color-fig-default">{this.homeTeamStanding.code}</span>
                                </div>
                            </div>
                            <div className="team-records">
                                <div style={{width: '50%'}}>
                                    {
                                        this.state.awayTeamGameRecords.length == 0 ? <></> :
                                        this.state.awayTeamGameRecords.map((teamGameRecord) => <>{this.props.gameComparisonFn(teamGameRecord, this.props.activeLeague)}</>)
                                    }
                                </div>
                                <div style={{width: '50%'}}>
                                    {
                                        this.state.homeTeamGameRecords.length == 0 ? <></> :
                                        this.state.homeTeamGameRecords.map((teamGameRecord) => <>{this.props.gameComparisonFn(teamGameRecord, this.props.activeLeague)}</>)                                
                                    }
                                </div>
                            </div>
                        </div>
                        <hr className="card-divider"/>
                        <FormCard id={"rf-offence"} title={"4.2 Offense (per game)"} type={"offensive"}
                                    activeLeague={this.props.activeLeague} statsTemplate={this.statsTemplate} 
                                    awayTeamRecentRecord={this.state.awayTeamRecentRecord} homeTeamRecentRecord={this.state.homeTeamRecentRecord}
                                    awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding} userInitialized={this.props.userInitialized}
                                    calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn}
                                    userAttributes={this.props.userAttributes}/>
                        <hr className="card-divider"/>
                        <FormCard id={"rf-defense"} title={"4.3 Defense (per game)"} type={"defensive"}
                                    activeLeague={this.props.activeLeague} statsTemplate={this.statsTemplate} 
                                    awayTeamRecentRecord={this.state.awayTeamRecentRecord} homeTeamRecentRecord={this.state.homeTeamRecentRecord}
                                    awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding} userInitialized={this.props.userInitialized}
                                    calculateInsightsFn={this.props.calculateInsightsFn} addInsightsFn={this.props.addInsightsFn}
                                    userAttributes={this.props.userAttributes}/>
                    </>
                }
            </>
        )
    }
}

class FormCard extends React.Component {
    constructor(props) {
        super(props);
        this.filterOptions = swapDictionaryKeyValue(this.props.statsTemplate);
        this.statsTemplate = this.props.statsTemplate;
        this.type = this.props.type;

        this.insightsRef = React.createRef();
        this.filterRef = React.createRef();
        this.clickableElements = [this.filterRef];
        this.filterStyling = {};
        this.creationInsightsComplete = false;
        this.conversionInsightsComplete = false;
        this.controlInsightsComplete = false;

        this.filterClicked = this.filterClicked.bind(this);
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateFilterStyling = this.generateFilterStyling.bind(this);
        this.handleGeneralClick = this.handleGeneralClick.bind(this);
        this.addClickableElement = this.addClickableElement.bind(this);

        this.state = {
            filterActive: false,
            filterSelection: Object.keys(this.filterOptions)[0]
        };
    }

    buildBarGraphData(stat, quantifier, type) {
        return [
            {
                "team": getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding),
                "Last 5": getDictionaryValue(this.props.awayTeamRecentRecord, stat.replaceAll("%qualifier%", quantifier).replaceAll("%type%", type)),
                "Last 5Color": TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.awayTeamStanding.code.toLowerCase()],
                "Season": getDictionaryValue(this.props.awayTeamStanding, stat.replaceAll("%qualifier%", "averageStats").replaceAll("%type%", type)),
                "SeasonColor": TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.awayTeamStanding.code.toLowerCase()]
            },
            {
                "team": getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding),
                "Last 5": getDictionaryValue(this.props.homeTeamRecentRecord, stat.replaceAll("%qualifier%", quantifier).replaceAll("%type%", type)),
                "Last 5Color": TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.homeTeamStanding.code.toLowerCase()],
                "Season": getDictionaryValue(this.props.homeTeamStanding, stat.replaceAll("%qualifier%", "averageStats").replaceAll("%type%", type)),
                "SeasonColor": TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.homeTeamStanding.code.toLowerCase()]
            }
        ];
    }

    componentDidMount() {
        window.addEventListener('scroll', this.generateFilterStyling);
        window.addEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
        this.generateFilterStyling();
        // Triggering initial update for refs
        this.forceUpdate();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.generateFilterStyling);
        window.removeEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
    }

    filterClicked() {
        this.setState({filterActive: !this.state.filterActive});
    }

    filterOptionSelected(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({filterActive: false, filterSelection: selection}, this.generateFilterStyling);
    }

    generateFilterStyling() {
        if (this.filterRef.current == null) {
            return;
        }
        this.filterStyling = {
            left: `${this.filterRef.current.offsetLeft}px`,
            top: `${this.filterRef.current.offsetTop + this.filterRef.current.offsetHeight}px`,
            width: `${this.filterRef.current.offsetWidth}px`
        };
    }

    handleGeneralClick(event) {
        const scrollbarClicked = (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight)
        var foundTarget = false;
        for(const element of this.clickableElements) {
          if (element.current && element.current.contains(event.target)) {
            foundTarget = true;
            break;
          }
        }
        if (!foundTarget && !scrollbarClicked) {
          this.setState({
            filterActive: false
          });
        }
    }

    addClickableElement(element) {
        this.clickableElements.push(element);
    }

    getDifferential(highValue, lowValue, valueType) {
        var differential = 0;
        if (valueType === "percentage") {
            // When comparing percentages we can just do the difference between the two
            differential = highValue - lowValue;
        } else if (valueType === "flat") {
            if (lowValue === 0) {
                // Setting to 100% differential, though it's debatable what it should be if the opposing team has nothing
                differential = 100;
            } else {
                differential = ((highValue / lowValue) - 1) * 100;
            }
        }
        return decimalPrecision(parseFloat(differential), 0);
    }

    // Assumes the validation of checking if we have insights numbers is done prior to this point
    // TODO: creation and conversion are very similar, might be able to extract common logic relatively easily (and in the case of recent form insights, control might fit in as well)
    generateCreationSentence(type, statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.props.awayTeamRecentRecord) || isDictEmpty(this.props.homeTeamRecentRecord)) {
            return "";
        }
        var leadingTeam = "";
        var qualifier = "";
        var differential = 0;

        var homeTeamDifferential = 0;
        var awayTeamDifferential = 0;

        const homeTeamRecentValue = getDictionaryValue(this.props.homeTeamRecentRecord, statField);
        const homeTeamStandingValue = getDictionaryValue(this.props.homeTeamStanding, statField);

        const awayTeamRecentValue = getDictionaryValue(this.props.awayTeamRecentRecord, statField);
        const awayTeamStandingValue = getDictionaryValue(this.props.awayTeamStanding, statField);

        // Te negation is used to represent a degradation in performance between the recent stats and the seasonal stats
        if (homeTeamRecentValue > homeTeamStandingValue) {
            homeTeamDifferential = parseFloat(this.getDifferential(homeTeamRecentValue, homeTeamStandingValue, valueType)); 
        } else {
            homeTeamDifferential = parseFloat(this.getDifferential(homeTeamStandingValue, homeTeamRecentValue, valueType)) * -1; 
        }
        if (awayTeamRecentValue > awayTeamStandingValue) {
            awayTeamDifferential = parseFloat(this.getDifferential(awayTeamRecentValue, awayTeamStandingValue, valueType)); 
        } else {
            awayTeamDifferential = parseFloat(this.getDifferential(awayTeamStandingValue, awayTeamRecentValue, valueType)) * -1; 
        }

        if (Math.abs(homeTeamDifferential) > Math.abs(awayTeamDifferential)) {
            leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
            differential = homeTeamDifferential;
        } else {
            leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
            differential = awayTeamDifferential;
        }
        if (differential < 0) {
            qualifier = "less";
        } else {
            qualifier = "more";
        }
        differential = Math.abs(decimalPrecision(parseFloat(differential), 0));
        
        if (type === "offensive") {
            if (!this.creationInsightsComplete) {
                this.creationInsightsComplete = true;
                this.props.addInsightsFn({
                    type: 'creation',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "good" : "bad"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
                });
            }
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
        } else if (type === "defensive") {
            if (!this.creationInsightsComplete) {
                this.creationInsightsComplete = true;
                this.props.addInsightsFn({
                    type: 'creation',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "bad" : "good"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
                });
            }
            return`${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
        } else {
            return ""
        }
    }

    generateConversionSentence(type, statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.props.awayTeamRecentRecord) || isDictEmpty(this.props.homeTeamRecentRecord)) {
            return "";
        }
        var leadingTeam = "";
        var qualifier = "";
        var differential = 0;

        var homeTeamDifferential = 0;
        var awayTeamDifferential = 0;

        const homeTeamRecentValue = getDictionaryValue(this.props.homeTeamRecentRecord, statField);
        const homeTeamStandingValue = getDictionaryValue(this.props.homeTeamStanding, statField);

        const awayTeamRecentValue = getDictionaryValue(this.props.awayTeamRecentRecord, statField);
        const awayTeamStandingValue = getDictionaryValue(this.props.awayTeamStanding, statField);

        // Te negation is used to represent a degradation in performance between the recent stats and the seasonal stats
        if (homeTeamRecentValue > homeTeamStandingValue) {
            homeTeamDifferential = parseFloat(this.getDifferential(homeTeamRecentValue, homeTeamStandingValue, valueType)); 
        } else {
            homeTeamDifferential = parseFloat(this.getDifferential(homeTeamStandingValue, homeTeamRecentValue, valueType)) * -1; 
        }
        if (awayTeamRecentValue > awayTeamStandingValue) {
            awayTeamDifferential = parseFloat(this.getDifferential(awayTeamRecentValue, awayTeamStandingValue, valueType)); 
        } else {
            awayTeamDifferential = parseFloat(this.getDifferential(awayTeamStandingValue, awayTeamRecentValue, valueType)) * -1; 
        }

        if (Math.abs(homeTeamDifferential) > Math.abs(awayTeamDifferential)) {
            leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
            differential = homeTeamDifferential;
        } else {
            leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
            differential = awayTeamDifferential;
        }
        if (differential < 0) {
            qualifier = "less";
        } else {
            qualifier = "more";
        }
        differential = Math.abs(decimalPrecision(parseFloat(differential), 0));

        if (type === "offensive") {    
            if (!this.conversionInsightsComplete) {
                this.conversionInsightsComplete = true; 
                this.props.addInsightsFn({
                    type: 'conversion',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "good" : "bad"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
                });
            }  
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
        } else if (type === "defensive") {
            if (!this.conversionInsightsComplete) {
                this.conversionInsightsComplete = true; 
                this.props.addInsightsFn({
                    type: 'conversion',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "bad" : "good"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
                });
            }   
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
        } else {
            return ""
        }
    }

    // Control is the only one that needs differentiation between flat difference and percentage difference for now
    generateControlSentence(type, statName, statField, carrierWords, valueType) {
        if (isDictEmpty(this.props.awayTeamRecentRecord) || isDictEmpty(this.props.homeTeamRecentRecord)) {
            return "";
        }
        var leadingTeam = "";
        var qualifier = "";
        var differential = 0;

        var homeTeamDifferential = 0;
        var awayTeamDifferential = 0;

        const homeTeamRecentValue = getDictionaryValue(this.props.homeTeamRecentRecord, statField);
        const homeTeamStandingValue = getDictionaryValue(this.props.homeTeamStanding, statField);

        const awayTeamRecentValue = getDictionaryValue(this.props.awayTeamRecentRecord, statField);
        const awayTeamStandingValue = getDictionaryValue(this.props.awayTeamStanding, statField);

        // Te negation is used to represent a degradation in performance between the recent stats and the seasonal stats
        if (homeTeamRecentValue > homeTeamStandingValue) {
            homeTeamDifferential = parseFloat(this.getDifferential(homeTeamRecentValue, homeTeamStandingValue, valueType)); 
        } else {
            homeTeamDifferential = parseFloat(this.getDifferential(homeTeamStandingValue, homeTeamRecentValue, valueType)) * -1; 
        }
        if (awayTeamRecentValue > awayTeamStandingValue) {
            awayTeamDifferential = parseFloat(this.getDifferential(awayTeamRecentValue, awayTeamStandingValue, valueType)); 
        } else {
            awayTeamDifferential = parseFloat(this.getDifferential(awayTeamStandingValue, awayTeamRecentValue, valueType)) * -1; 
        }

        if (Math.abs(homeTeamDifferential) > Math.abs(awayTeamDifferential)) {
            leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding);
            differential = homeTeamDifferential;
        } else {
            leadingTeam = getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding);
            differential = awayTeamDifferential;
        }
        if (differential < 0) {
            qualifier = "less";
        } else {
            qualifier = "more";
        }
        differential = Math.abs(decimalPrecision(parseFloat(differential), 0));

        if (type === "offensive") {    
            if (!this.controlInsightsComplete) {
                this.controlInsightsComplete = true; 
                this.props.addInsightsFn({
                    type: 'control',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "good" : "bad"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
                });
            }  
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
        } else if (type === "defensive") {
            if (!this.controlInsightsComplete) {
                this.controlInsightsComplete = true; 
                this.props.addInsightsFn({
                    type: 'control',
                    value: differential,
                    // Not re-using the less/more because the meaning of less/more is inverted between offensive and defensive
                    qualifier: (qualifier === "more" ? "bad" : "good"),
                    text: `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
                });
            }   
            return `${leadingTeam} ${carrierWords} ${differential}% ${qualifier} ${statName} in their last 5 games`
        } else {
            return ""
        }
    }

    render() {
        const metadata = getLeagueInsightsMetadata(this.props.activeLeague, "past", this.props.type, "averageStats");
        const barGraphData = this.buildBarGraphData(this.state.filterSelection, "averageStats", this.type);
        const chartBottom = ((this.insightsRef.current != null) ? this.insightsRef.current.offsetHeight + 20 : 0);
        return (
            <div id={this.props.id} className='playbook-section-wrapper game-by-game'>
                <div>
                    <p className="font size-24 weight-700">{this.props.title}</p>
                    <div>
                        <p className='text-style-label-medium'>Stat</p>
                        <div className='game-by-game-filter-wrapper'>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.filterOptions} 
                                selection={this.state.filterSelection} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {selection: selection}}})}
                            />
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', height: "325px", position: 'absolute', bottom: `${chartBottom}px`}}>
                    <ResponsiveBar
                        theme={RESPONSIVE_BAR_THEME}
                        data={barGraphData}
                        keys={[ 'Last 5', 'Season']}
                        indexBy="team"
                        margin={{ top: 50, right: 0, bottom: 40, left: 0 }}
                        innerPadding={10}
                        groupMode="grouped"
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        valueFormat={{ format: '', enabled: false }}
                        // colors={{ datum: 'data.color' }}
                        colors={({ id, data }) => String(data[`${id}Color`])}
                        defs={[]}
                        fill={[]}
                        borderRadius={6}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 10,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={null}
                        enableGridY={false}
                        enableLabel={true}
                        labelSkipWidth={0}
                        labelSkipHeight={0}
                        label={d => `${d.id}: ${d.value}`}
                        // labelTextColor={{ from: 'color', modifiers: [ [ 'brighter', 3 ] ] }}
                        labelTextColor="#ffffff"
                        legends={[]}
                        motionConfig={{ mass: 1, tension: 170, friction: 26, clamp: false, precision: 0.01, velocity: 0 }}
                    />
                </div>
                <div ref={this.insightsRef} className="insights" style={{position: 'absolute', bottom: '0px', width: '100%'}}>
                    <h5><img src="assets/insights-icon.svg" alt="insights" style={{paddingBottom: '2px', marginRight: '8px'}}/>Insights</h5>
                    <ol>
                        <li>{this.generateCreationSentence(this.props.type, metadata.statName["creation"], metadata.statField["creation"], metadata.carrierWords["creation"], metadata.valueType["creation"])}</li>
                        <li>{this.generateConversionSentence(this.props.type, metadata.statName["conversion"], metadata.statField["conversion"], metadata.carrierWords["conversion"], metadata.valueType["conversion"])}</li>
                        <li>{this.generateControlSentence(this.props.type, metadata.statName["control"], metadata.statField["control"], metadata.carrierWords["control"], metadata.valueType["control"])}</li>
                    </ol>
                </div>
            </div>
        )
    }
}

class BettingTrend extends React.Component {
    constructor(props) {
        super(props);
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        this.loaded = false;

        this.filterOptions = Object.fromEntries(["SEASON", "MATCHUP", "LAST 5"].map(x => [x, x]));
        this.filterRef = React.createRef();
        this.clickableElements = [this.filterRef];
        this.filterStyling = {};

        this.selectTeam = this.selectTeam.bind(this);
        this.filterClicked = this.filterClicked.bind(this);
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateFilterStyling = this.generateFilterStyling.bind(this);
        this.handleGeneralClick = this.handleGeneralClick.bind(this);
        this.addClickableElement = this.addClickableElement.bind(this);
        this.bettingTrendShowButtonClicked = this.bettingTrendShowButtonClicked.bind(this);

        this.oddRecordFields = {
            // For fav/underdog we don't track under hit records, need to use the data from other sections, will probably do separately
            "ATS": "hitRecords.againstTheSpread",
            "ATS home": "hitRecords.homeAgainstTheSpread",
            "ATS away": "hitRecords.awayAgainstTheSpread",
            "o / u": "hitRecords.totalOver",
            "o / u home": "hitRecords.homeTotalOver",
            "o / u away": "hitRecords.awayTotalOver"
        }

        this.state = {
            team: "away",
            filterActive: false,
            filterSelection: Object.keys(this.filterOptions)[0],
            expandBettingTrends: false,
            bettingTrends: {
                away: {
                    "SEASON": {},
                    "MATCHUP": {},
                    "LAST 5": {}
                },
                home: {
                    "SEASON": {},
                    "MATCHUP": {},
                    "LAST 5": {}
                }
            }
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.generateFilterStyling);
        window.addEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
        this.generateFilterStyling();
        // Triggering one update to initialize the ref(s)
        this.forceUpdate();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.generateFilterStyling);
        window.removeEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
    }

    selectTeam(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({team: selection});
    }

    filterClicked() {
        this.setState({filterActive: !this.state.filterActive});
    }

    filterOptionSelected(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({filterActive: false, filterSelection: selection}, this.generateFilterStyling);
    }

    generateFilterStyling() {
        if (this.filterRef.current == null) {
            return;
        }
        this.filterStyling = {
            left: `${this.filterRef.current.offsetLeft}px`,
            top: `${this.filterRef.current.offsetTop + this.filterRef.current.offsetHeight}px`,
            width: `${this.filterRef.current.offsetWidth}px`
        };
    }

    handleGeneralClick(event) {
        const scrollbarClicked = (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight)
        var foundTarget = false;
        for(const element of this.clickableElements) {
          if (element.current && element.current.contains(event.target)) {
            foundTarget = true;
            break;
          }
        }
        if (!foundTarget && !scrollbarClicked) {
          this.setState({
            filterActive: false
          });
        }
    }

    addClickableElement(element) {
        this.clickableElements.push(element);
    }

    bettingTrendShowButtonClicked() {
        this.setState({
          expandBettingTrends: !this.state.expandBettingTrends
        });
    }

    render() {
        if (isDictEmpty(this.homeTeamStanding) || isDictEmpty(this.awayTeamStanding)) {
            return (<div id="betting-trends" className="playbook-betting-trends"></div>)
        }
        var timeframeRecords = {};
        var favoriteRecords = {};
        switch (this.state.filterSelection) {
            case "MATCHUP":
                timeframeRecords = this.props.matchupRecords;
                favoriteRecords = this.props.matchupFavoriteRecords;
                break;
            case "SEASON":
                timeframeRecords = {
                    home: this.homeTeamStanding,
                    away: this.awayTeamStanding
                };
                favoriteRecords = this.props.seasonFavoriteRecords;
                break;
            case "LAST 5":
                timeframeRecords = this.props.recentRecords;
                favoriteRecords = this.props.recentFavoriteRecords;
                break;
            default:
                break;
        }
        return (
            <>
                <div id="betting-trends" className="playbook-betting-trends">
                    <div>
                        <p className="font size-24 weight-700">5. Betting Trends</p>
                        <div>
                            <p className='text-style-label-medium'>Stat</p>
                            <div className='game-by-game-filter-wrapper'>
                                <InputSelection 
                                    enabled={true} 
                                    type="single" 
                                    typography="md" 
                                    options={this.filterOptions} 
                                    selection={this.state.filterSelection} 
                                    selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {selection: selection}}})}
                                />
                            </div>
                        </div>
                    </div>
                    <p className="font size-14 weight-400">How do these teams fair in the betting market?</p>
                    <div className="comparative-table-body">
                        {
                            isDictEmpty(timeframeRecords) && isDictEmpty(favoriteRecords) ?
                            <NoResultsFrame text={"There is no matchup history between these two teams within the last 2 seasons."} /> :
                            <>
                                <div className="comparative-tbl-header">
                                    <div className="tbl-header-away">
                                        <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding)}</span>
                                        <span className="short-text text-base-upper color-fig-default">{this.awayTeamStanding.code}</span>
                                        <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamStanding.code)} height={32} width={32} alt=""/>
                                    </div>
                                    <div className="tbl-header-home" >
                                        <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamStanding.code)} height={32} width={32} alt=""/>
                                        <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)}</span>
                                        <span className="short-text text-base-upper color-fig-default">{this.homeTeamStanding.code}</span>
                                    </div>
                                </div>
                                {
                                    isDictEmpty(favoriteRecords) ? <></> :
                                    <>
                                        <div className="comparative-tbl-row text-base">
                                            <div>{buildRecordStringFn[this.props.activeLeague](favoriteRecords.away.favourite)}</div>
                                            <div className="text-xs-upper">favourite</div>
                                            <div>{buildRecordStringFn[this.props.activeLeague](favoriteRecords.home.favourite)}</div>
                                        </div>
                                        <div className="comparative-tbl-row text-base">
                                            <div>{buildRecordStringFn[this.props.activeLeague](favoriteRecords.away.underdog)}</div>
                                            <div className="text-xs-upper">underdog</div>
                                            <div>{buildRecordStringFn[this.props.activeLeague](favoriteRecords.home.underdog)}</div>
                                        </div>
                                    </>
                                }
                                {
                                    Object.keys(this.oddRecordFields).map((key) => 
                                        <div key={key} className="comparative-tbl-row text-base">
                                            <div>{`${getDictionaryValue(timeframeRecords.away, this.oddRecordFields[key]).hits}-${getDictionaryValue(timeframeRecords.away, this.oddRecordFields[key]).misses}`}</div>
                                            <div className="text-xs-upper">{key}</div>
                                            <div>{`${getDictionaryValue(timeframeRecords.home, this.oddRecordFields[key]).hits}-${getDictionaryValue(timeframeRecords.home, this.oddRecordFields[key]).misses}`}</div>
                                        </div>
                                    )
                                }
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }
}

class NFLScoutingReport extends React.Component {
    constructor(props) {
        super(props);

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        this.loaded = false;

        this.filterRef = React.createRef();
        this.clickableElements = [this.filterRef];
        this.filterStyling = {};

        this.filterClicked = this.filterClicked.bind(this);
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateFilterStyling = this.generateFilterStyling.bind(this);
        this.handleGeneralClick = this.handleGeneralClick.bind(this);
        this.addClickableElement = this.addClickableElement.bind(this);

        this.sections = {
            // top 2 per team
            "PASSING": {
                "PASS RTG": "%quantifier%.rating",
                "COMP": "%quantifier%.passingCompletions",
                "ATT": "%quantifier%.passingAttempts",
                "COMP %": "%quantifier%.completionPercentage",
                "YDS": "%quantifier%.passingYards",
                "TD": "%quantifier%.passingTouchdowns",
                "INT": "%quantifier%.interceptions",
                "RZ ATT": "%quantifier%.redzonePassingAttempts",
                "RZ COMP": "%quantifier%.redzoneCompletions",
                "RZ TD": "%quantifier%.redzonePassingTouchdowns",
                "RZ COMP %": "%quantifier%.redzoneCompletionPercentage"
            },
            // top 2 per team
            "RUSHING": {
                "ATT": "%quantifier%.rushingAttempts",
                "YDS": "%quantifier%.rushingYards",
                "TD": "%quantifier%.rushingTouchdowns",
                "YDS/ATT": "%quantifier%.rushingYardsPerAttempt",
                "RZ ATT": "%quantifier%.redzoneRushingAttempts",
                "RZ TD": "%quantifier%.redzoneRushingTouchdowns"
            },
            // top 4 per team
            "RECEIVING": {
                "TGT": "%quantifier%.targets",
                "REC": "%quantifier%.receptions",
                "YDS": "%quantifier%.receivingYards",
                "TD": "%quantifier%.receivingTouchdowns",
                "YDS/REC": "%quantifier%.receivingYardsPerReception",
                "RZ TGT": "%quantifier%.redzoneTargets",
                "RZ REC": "%quantifier%.redzoneReceptions",
                "RZ TD": "%quantifier%.redzoneReceivingTouchdowns"
            },
            // top 1 per team
            "KICKING": {
                "PTS": "%quantifier%.pointsKicked",
                "FGA": "%quantifier%.fieldGoalAttempts",
                "FGM": "%quantifier%.fieldGoalsMade",
                "FG%": "%quantifier%.fieldGoalPercentage",
                "XPA": "%quantifier%.extraPointAttempts",
                "XPM": "%quantifier%.extraPointsMade",
                "XP%": "%quantifier%.extraPointPercentage",
                "FG (0-19)": "%quantifier%.extraShortRangeFieldGoals",
                "FG (20-29)": "%quantifier%.shortRangeFieldGoals",
                "FG (30-39)": "%quantifier%.mediumRangeFieldGoals",
                "FG (40-49)": "%quantifier%.longRangeFieldGoals",
                "FG (50+)": "%quantifier%.extraLongRangeFieldGoals"
            }
        };

        
        this.filterOptions = ["PASSING", "RUSHING", "RECEIVING", "KICKING"];

        this.state = {
            sectionAwayPlayers: {
                "PASSING": {},
                "RUSHING": {},
                "RECEIVING": {},
                "KICKING": {}
            },
            sectionHomePlayers: {
                "PASSING": {},
                "RUSHING": {},
                "RECEIVING": {},
                "KICKING": {}
            },
            filterActive: false,
            filterSelection: this.filterOptions[0],
        };
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.handleGeneralClick);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleGeneralClick);
    }
    
    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.awayTeamStanding.code}&opponent=${this.homeTeamStanding.code}`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.homeTeamStanding.code}&opponent=${this.awayTeamStanding.code}`).then(data => {return data.json();})
            ];
            Promise.all(promises)
            .then(result => {
                // TODO parse out result[LAST 5], result[SEASON], result[MATCHUP]

                const awayPassingPlayers = {};                
                const awayRushingPlayers = {};
                const awayReceivingPlayers = {};
                const awayKickingPlayers = {};
                const homePassingPlayers = {};             
                const homeRushingPlayers = {};
                const homeReceivingPlayers = {};
                const homeKickingPlayers = {};
                ["MATCHUP", "SEASON", "LAST 5"].forEach((split, index) => {
                    const awayPlayers = result[0][split];
                    const homePlayers = result[1][split];

                    awayPassingPlayers[split] = [];              
                    awayRushingPlayers[split] = [];
                    awayReceivingPlayers[split] = [];
                    awayKickingPlayers[split] = [];
                    awayPlayers.forEach((player, index) => {
                        if (player.info.position === "QB") {
                            awayPassingPlayers[split].push(player);
                        }
                        //"QB", "RB", "FB", "HB"
                        if (player.info.position === "QB" || player.info.position === "RB" || player.info.position === "FB" || player.info.position === "HB") {
                            awayRushingPlayers[split].push(player);
                        }
                        //"RB", "FB", "HB", "WR", "TE"
                        if (player.info.position === "RB" || player.info.position === "FB" || player.info.position === "HB" || player.info.position === "WR" || player.info.position === "TE") {
                            awayReceivingPlayers[split].push(player);
                        }
                        //"P", "K"
                        if (player.info.position === "P" || player.info.position === "K") {
                            awayKickingPlayers[split].push(player);
                        }
                    });
                    // orderListByField(awayPassingPlayers[split], this.sections["Passing"][Object.keys(this.sections["Passing"])[0]], "desc");
                    // orderListByField(awayRushingPlayers[split], this.sections["Passing"][Object.keys(this.sections["Passing"])[0]], "desc");
                    // orderListByField(awayReceivingPlayers[split], this.sections["Passing"][Object.keys(this.sections["Passing"])[0]], "desc");
                    // orderListByField(awayKickingPlayers[split], this.sections["Passing"][Object.keys(this.sections["Passing"])[0]], "desc");
    
                    homePassingPlayers[split] = [];              
                    homeRushingPlayers[split] = [];
                    homeReceivingPlayers[split] = [];
                    homeKickingPlayers[split] = [];
                    homePlayers.forEach((player, index) => {
                        if (player.info.position === "QB") {
                            homePassingPlayers[split].push(player);
                        }
                        //"QB", "RB", "FB", "HB"
                        if (player.info.position === "QB" || player.info.position === "RB" || player.info.position === "FB" || player.info.position === "HB") {
                            homeRushingPlayers[split].push(player);
                        }
                        //"RB", "FB", "HB", "WR", "TE"
                        if (player.info.position === "RB" || player.info.position === "FB" || player.info.position === "HB" || player.info.position === "WR" || player.info.position === "TE") {
                            homeReceivingPlayers[split].push(player);
                        }
                        //"P", "K"
                        if (player.info.position === "P" || player.info.position === "K") {
                            homeKickingPlayers[split].push(player);
                        }
                    });
                });

                this.setState(
                    {
                        // awayPlayers: awayPlayers,
                        // homePlayers: homePlayers,
                        sectionAwayPlayers: {
                            "PASSING": awayPassingPlayers,
                            "RUSHING": awayRushingPlayers,
                            "RECEIVING": awayReceivingPlayers,
                            "KICKING": awayKickingPlayers
                        },
                        sectionHomePlayers: {
                            "PASSING": homePassingPlayers,
                            "RUSHING": homeRushingPlayers,
                            "RECEIVING": homeReceivingPlayers,
                            "KICKING": homeKickingPlayers
                        }
                    }, 
                    this.forceUpdate
                );
            })
            .catch(error => {
                console.log(`Error loading NFL scouting report: ${error}`);
            });
        }
    }

    filterClicked() {
        this.setState({filterActive: !this.state.filterActive});
    }

    filterOptionSelected(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({filterActive: false, filterSelection: selection});
    }

    generateFilterStyling() {
        if (this.filterRef.current == null) {
            return;
        }
        this.filterStyling = {
            left: `${this.filterRef.current.offsetLeft}px`,
            top: `${this.filterRef.current.offsetTop + this.filterRef.current.offsetHeight}px`,
            width: `${this.filterRef.current.offsetWidth}px`
        };
    }

    handleGeneralClick(event) {
        const scrollbarClicked = (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight)
        var foundTarget = false;
        for(const element of this.clickableElements) {
          if (element.current && element.current.contains(event.target)) {
            foundTarget = true;
            break;
          }
        }
        if (!foundTarget && !scrollbarClicked) {
          this.setState({
            filterActive: false
          });
        }
    }

    addClickableElement(element) {
        this.clickableElements.push(element);
    }

    render() {
        this.generateFilterStyling();
        return (
            <>
                <span   ref={this.filterRef} onClick={this.filterClicked}
                        className="clickable filter-button playbook-stackable-dropdown-swapped unselectable playbook-nfl-scouting-type-filter">
                    <span className="font size-14">TYPE: </span>
                    <span className="font size-14">{this.state.filterSelection}</span>
                    <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{marginBottom: '2px', marginTop: '4px', float: 'right'}}/>
                </span>
                <Dropdown
                    name="team"
                    enabled={this.state.filterActive}
                    currentSelection={this.state.filterSelection}
                    options={this.filterOptions}
                    customContainerStyling={this.filterStyling}
                    textStylingClasses="size-14 spaced"
                    onClickHandler={this.filterOptionSelected}                    
                    clickableElementAddition={this.addClickableElement} />
                <ScoutingReportEntry 
                    stats={[this.sections[this.state.filterSelection]]} activeLeague={this.props.activeLeague}
                    awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                    awayPlayers={[this.state.sectionAwayPlayers[this.state.filterSelection]]} 
                    homePlayers={[this.state.sectionHomePlayers[this.state.filterSelection]]}
                />
            </>
        )
    }
}

class NHLScoutingReport extends React.Component {
    constructor(props) {
        super(props);

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        this.loaded = false;

        this.skatersSection = {
            "PTS": "%quantifier%.points",
            "G": "%quantifier%.goals",
            "A": "%quantifier%.assists",
            "SOG": "%quantifier%.shotsOnGoal",
            "SAT": "%quantifier%.shotAttempts",
            "+/-": "%quantifier%.plusMinus",
            "PPP": "%quantifier%.powerplayPoints",
            "SHP": "%quantifier%.shorthandedPoints",
            "PIM": "%quantifier%.penaltyMinutes",
            "BLK": "%quantifier%.defensiveBlocks",
            //HDC
            //xG
        };

        this.goaliesSection = {
            "WINS": "%quantifier%.wins",
            "SA": "%quantifier%.shotsAgainst",
            "SAVES": "%quantifier%.shotsSaved",
            "SV%": "%quantifier%.savePercentage",
            "GA": "%quantifier%.goalsAllowed",
            "SHUTOUTS": "%quantifier%.shutouts"
        };

        this.state = {
            filterActive: false,
            awaySkaters: {},
            awayGoalies: {},
            homeSkaters: {},
            homeGoalies: {}
        };
    }

    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.awayTeamStanding.code}&opponent=${this.homeTeamStanding.code}`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.homeTeamStanding.code}&opponent=${this.awayTeamStanding.code}`).then(data => {return data.json();})
            ];
            Promise.all(promises)
            .then(result => {
                const awaySkaters = {};
                const awayGoalies = {};

                const homeSkaters = {};
                const homeGoalies = {};

                ["MATCHUP", "SEASON", "LAST 5"].forEach((split, index) => {
                    const awayPlayers = result[0][split];
                    const homePlayers = result[1][split];

                    awaySkaters[split] = [];              
                    awayGoalies[split] = [];
                    awayPlayers.forEach((player, index) => {
                        if (player.info.position === "G") {
                            awayGoalies[split].push(player);
                        } else {
                            awaySkaters[split].push(player);
                        }
                    });
    
                    homeSkaters[split] = [];              
                    homeGoalies[split] = [];
                    homePlayers.forEach((player, index) => {
                        if (player.info.position === "G") {
                            homeGoalies[split].push(player);
                        } else {
                            homeSkaters[split].push(player);
                        }
                    });
                });

                this.setState(
                    {
                        awaySkaters: awaySkaters,
                        awayGoalies: awayGoalies,
                        homeSkaters: homeSkaters,
                        homeGoalies: homeGoalies
                    }, 
                    this.forceUpdate
                );
            })
            .catch(error => {
                console.log(`Error loading NHL scouting report: ${error}`);
            });
        }
    }

    render() {
        return (
            <ScoutingReportEntry 
                stats={[this.skatersSection, this.goaliesSection]} activeLeague={this.props.activeLeague}
                awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                awayPlayers={[this.state.awaySkaters, this.state.awayGoalies]} 
                homePlayers={[this.state.homeSkaters, this.state.homeGoalies]}
            />
        )
    }
}

class NBAScoutingReport extends React.Component {
    constructor(props) {
        super(props);

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        this.loaded = false;

        this.playersSection = {
            "PTS": "%quantifier%.points",
            "FT%": "%quantifier%.freeThrowPercentage",
            "FG%": "%quantifier%.fieldGoalPercentage",
            "3PT%": "%quantifier%.threePointPercentage",
            "REB": "%quantifier%.rebounds",
            "AST": "%quantifier%.assists",
            "STL": "%quantifier%.steals",
            "BLK": "%quantifier%.blocks"
        };

        this.state = {
            filterActive: false,
            awayPlayers: {},
            homePlayers: {}
        };
    }
    
    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.awayTeamStanding.code}&opponent=${this.homeTeamStanding.code}`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.homeTeamStanding.code}&opponent=${this.awayTeamStanding.code}`).then(data => {return data.json();})
            ];
            Promise.all(promises)
            .then(result => {
                this.setState(
                    {
                        awayPlayers: result[0],
                        homePlayers: result[1]
                    }, 
                    this.forceUpdate
                );
            })
            .catch(error => {
                console.log(`Error loading NBA scouting report: ${error}`);
            });
        }
    }

    render() {
        return (
            <ScoutingReportEntry 
                stats={[this.playersSection]} activeLeague={this.props.activeLeague}
                awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                awayPlayers={[this.state.awayPlayers]} homePlayers={[this.state.homePlayers]}
            />
        )
    }
}

class MLBScoutingReport extends React.Component {
    constructor(props) {
        super(props);

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        this.loaded = false;

        this.playersSection = {
            "AB": "%quantifier%.hitting.atBats",
            "R": "%quantifier%.hitting.runs",
            "H": "%quantifier%.hitting.hits",
            "1B": "%quantifier%.hitting.singles",
            "2B": "%quantifier%.hitting.doubles",
            "3B": "%quantifier%.hitting.triples",
            "HR": "%quantifier%.hitting.homeRuns",
            "ISO": "%quantifier%.hitting.isolatedPower",
            "RBI": "%quantifier%.hitting.runsBattedIn",
            "TB": "%quantifier%.hitting.totalBases",
            "BB": "%quantifier%.hitting.walks",
            "SO": "%quantifier%.hitting.strikeouts",
            "AVG": "%quantifier%.hitting.battingAverage",
            "OBP": "%quantifier%.hitting.onBasePercentage",
            "SLG": "%quantifier%.hitting.sluggingPercentage",
            "OPS": "%quantifier%.hitting.onBasePlusSluggingPercentage"
        };

        this.state = {
            filterActive: false,
            awayPlayers: {},
            homePlayers: {}
        };
    }
    
    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.awayTeamStanding.code}&opponent=${this.homeTeamStanding.code}`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.homeTeamStanding.code}&opponent=${this.awayTeamStanding.code}`).then(data => {return data.json();})
            ];
            Promise.all(promises)
            .then(result => {
                this.setState(
                    {
                        awayPlayers: result[0],
                        homePlayers: result[1]
                    }, 
                    this.forceUpdate
                );
            })
            .catch(error => {
                console.log(`Error loading MLB scouting report: ${error}`);
            });
        }
    }

    render() {
        return (
            <ScoutingReportEntry 
                stats={[this.playersSection]} activeLeague={this.props.activeLeague}
                awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                awayPlayers={[this.state.awayPlayers]} homePlayers={[this.state.homePlayers]}
            />
        )
    }
}

class SoccerScoutingReport extends React.Component {
    constructor(props) {
        super(props);

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        this.loaded = false;

        this.outfieldersSection = {
            "G": "%quantifier%.goals",
            "A": "%quantifier%.assists",
            "SOT": "%quantifier%.shotsOnTarget",
            "SHO": "%quantifier%.totalShots",
            "PAS": "%quantifier%.totalPasses",
            // "CC": "%quantifier%.chancesCreated",
            "DRI": "%quantifier%.dribblesCompleted",
            "TCK": "%quantifier%.totalTackles",
            "FLS": "%quantifier%.foulsCommitted",
            "YEL": "%quantifier%.yellowCards",
            "RED": "%quantifier%.redCards"
        };

        this.goaliesSection = {
            "GA": "%quantifier%.goalsConceded",
            "SAV": "%quantifier%.shotsFacedSaved",
            "SA": "%quantifier%.shotsFacedTotal",
            "YEL": "%quantifier%.yellowCards",
            "RED": "%quantifier%.redCards"
        };

        this.state = {
            filterActive: false,
            awayOutfielders: {},
            awayGoalkeepers: {},
            homeOutfielders: {},
            homeGoalkeepers: {}
        };
    }

    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.awayTeamStanding.code}&opponent=${this.homeTeamStanding.code}`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forPlaybookComparison?teamCode=${this.homeTeamStanding.code}&opponent=${this.awayTeamStanding.code}`).then(data => {return data.json();})
            ];
            Promise.all(promises)
            .then(result => {
                const awayOutfielders = {};
                const awayGoalkeepers = {};

                const homeOutfielders = {};
                const homeGoalkeepers = {};

                ["MATCHUP", "SEASON", "LAST 5"].forEach((split, index) => {
                    const awayPlayers = result[0][split];
                    const homePlayers = result[1][split];

                    awayOutfielders[split] = [];              
                    awayGoalkeepers[split] = [];
                    awayPlayers.forEach((player, index) => {
                        if (player.info.position === "G") {
                            awayGoalkeepers[split].push(player);
                        } else {
                            awayOutfielders[split].push(player);
                        }
                    });
    
                    homeOutfielders[split] = [];              
                    homeGoalkeepers[split] = [];
                    homePlayers.forEach((player, index) => {
                        if (player.info.position === "G") {
                            homeGoalkeepers[split].push(player);
                        } else {
                            homeOutfielders[split].push(player);
                        }
                    });
                });

                this.setState(
                    {
                        awayOutfielders: awayOutfielders,
                        awayGoalkeepers: awayGoalkeepers,
                        homeOutfielders: homeOutfielders,
                        homeGoalkeepers: homeGoalkeepers
                    }, 
                    this.forceUpdate
                );
            })
            .catch(error => {
                console.log(`Error loading NHL scouting report: ${error}`);
            });
        }
    }

    render() {
        return (
            <ScoutingReportEntry 
                stats={[this.outfieldersSection, this.goaliesSection]} activeLeague={this.props.activeLeague}
                awayTeamStanding={this.awayTeamStanding} homeTeamStanding={this.homeTeamStanding}
                awayPlayers={[this.state.awayOutfielders, this.state.awayGoalkeepers]} 
                homePlayers={[this.state.homeOutfielders, this.state.homeGoalkeepers]}
            />
        )
    }
}

// TODO: remove the hardcoded splits list and at least extract it as a variable instead of repeating it everywhere
class ScoutingReportEntry extends React.Component {
    constructor(props) {
        super(props);

        // simple or expanded
        this.reportType = this.props.reportType || "expanded";
        this.loaded = false;
        
        this.filterRef = React.createRef();
        this.clickableElements = [this.filterRef];
        this.filterStyling = {};

        this.filterClicked = this.filterClicked.bind(this);
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateFilterStyling = this.generateFilterStyling.bind(this);
        this.handleGeneralClick = this.handleGeneralClick.bind(this);
        this.addClickableElement = this.addClickableElement.bind(this);
        this.selectStatQuantifier = this.selectStatQuantifier.bind(this);
        this.sortData = this.sortData.bind(this);

        this.filterOptions = Object.fromEntries(["SEASON", "MATCHUP", "LAST 5"].map(x => [x, x]));

        this.state = {
            statQuantifier: "cumulativeStats",
            filterActive: false,
            filterSelection: Object.keys(this.filterOptions)[0],
            expandStatsList: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.generateFilterStyling);
        window.addEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
        // Triggering one update to initialize the ref(s) and re-render with the filter styling
        this.forceUpdate();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.generateFilterStyling);
        window.removeEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('resize', this.generateFilterStyling);
    }

    filterClicked() {
        this.setState({filterActive: !this.state.filterActive});
    }

    filterOptionSelected(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({filterActive: false, filterSelection: selection});
    }

    generateFilterStyling() {
        if (this.filterRef.current == null) {
            return;
        }
        this.filterStyling = {
            left: `${this.filterRef.current.offsetLeft}px`,
            top: `${this.filterRef.current.offsetTop + this.filterRef.current.offsetHeight}px`,
            width: `${this.filterRef.current.offsetWidth}px`
        };
    }

    handleGeneralClick(event) {
        const scrollbarClicked = (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight)
        var foundTarget = false;
        for(const element of this.clickableElements) {
          if (element.current && element.current.contains(event.target)) {
            foundTarget = true;
            break;
          }
        }
        if (!foundTarget && !scrollbarClicked) {
          this.setState({
            filterActive: false
          });
        }
    }

    addClickableElement(element) {
        this.clickableElements.push(element);
    }

    selectStatQuantifier(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({statQuantifier: selection});
    }

    sortData(inputState, awayPlayers, homePlayers) {
        var sortedPlayers = {};
        this.props.stats.map((statCategory, index) =>{
            Object.keys(statCategory).map((stat) => {
                if (!isDictEmpty(awayPlayers[index]) && !isDictEmpty(homePlayers[index]) &&
                    awayPlayers[index][inputState.filterSelection].length > 0 && homePlayers[index][inputState.filterSelection].length > 0) {
                    var tempAwayPlayers = Object.assign([], awayPlayers[index][inputState.filterSelection]);
                    var tempHomePlayers = Object.assign([], homePlayers[index][inputState.filterSelection]);
        
                    const fieldPath = statCategory[stat].replaceAll("%quantifier%", inputState.statQuantifier);
                    orderListByField(tempAwayPlayers, fieldPath, "desc");
                    orderListByField(tempHomePlayers, fieldPath, "desc");

                    const topAwayPlayer = tempAwayPlayers[0];
                    const topHomePlayer = tempHomePlayers[0];
                    sortedPlayers[stat] = {
                        away: {
                            name: getPlayerName(topAwayPlayer),
                            value: getDictionaryValue(topAwayPlayer, fieldPath)
                        },
                        home: {
                            name: getPlayerName(topHomePlayer),
                            value: getDictionaryValue(topHomePlayer, fieldPath)
                        }
                    }
                }
            })
        });
        return sortedPlayers;
    }

    render() {
        if (this.props.awayPlayers.length === 0 || this.props.homePlayers.length === 0 || 
            isDictEmpty(this.props.awayTeamStanding) || isDictEmpty(this.props.homeTeamStanding)) {
            return (<></>);
        }
        const maxStats = 6;
        const sortedPlayers = this.sortData(this.state, this.props.awayPlayers, this.props.homePlayers);
        const statsCount = this.state.expandStatsList ? Object.keys(sortedPlayers).length : maxStats;
        const shouldShowButton = (Object.keys(sortedPlayers).length !== maxStats && Object.keys(sortedPlayers).length !== 0);
        this.generateFilterStyling();
        return (
            <>
                <div>
                    <p className="font size-24 weight-700" id="scouting-report">6. Players To Watch</p>
                    <div className='playbook-picker-wrapper'>
                        <div className="picker playbook-picker">
                            <div className={this.state.statQuantifier === "cumulativeStats" ? "picker-active" : "picker-inactive"}>
                                <span className="clickable" style={{color: 'inherit', textDecoration: 'none'}} 
                                        data-selection="cumulativeStats" onClick={this.selectStatQuantifier}>
                                <div className="vertically-centered horizontally-centered">
                                    <p className={this.state.statQuantifier === "cumulativeStats" ? "font size-14 bold" : "font size-14"}>Total</p>
                                </div>
                                </span>
                            </div>
                            <div className={this.state.statQuantifier === "averageStats" ? "picker-active" : "picker-inactive"}>
                                <span className="clickable" style={{color: 'inherit', textDecoration: 'none'}} 
                                        data-selection="averageStats" onClick={this.selectStatQuantifier}>
                                <div className="vertically-centered horizontally-centered">
                                    <p className={this.state.statQuantifier === "averageStats" ? "font size-14 bold" : "font size-14"}>Per game</p>
                                </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>Who are the top players to watch?</div>
                    <div>
                        <p className='text-style-label-medium'>Timeframe</p>
                        <div className='game-by-game-filter-wrapper'>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.filterOptions} 
                                selection={this.state.filterSelection} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {selection: selection}}})}
                            />
                        </div>
                    </div>
                </div>
                {/* Setting the button before in the HTML hierarchy to make the table body be usable with a sibling (~) selector */}
                {
                    shouldShowButton ?
                    <>
                        <div className="playbook-scouting-report-stats-button-gradient" style={this.state.expandStatsList ? {display: 'none'} : {}}></div>
                        <div className={`playbook-scouting-report-stats-button${this.state.expandStatsList ? "-expanded" : ""} clickable unselectable`} onClick={() => {this.setState({expandStatsList: !this.state.expandStatsList})}}>
                            <p className="font size-14 display-block full-width align-center">
                                {this.state.expandStatsList ? "Show less" : "Show more"}
                            </p>
                        </div>
                    </>
                    :
                    <>
                    </>
                }
                <div className="comparative-table-body">
                    <div className="comparative-tbl-header">
                        <div className="tbl-header-away">
                            <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.props.awayTeamStanding)}</span>
                            <span className="short-text text-base-upper color-fig-default">{this.props.awayTeamStanding.code}</span>
                            <img src={getTeamLogoPath(this.props.activeLeague, this.props.awayTeamStanding.code)} height={32} width={32} alt=""/>
                        </div>
                        <div className="tbl-header-home">
                            <img src={getTeamLogoPath(this.props.activeLeague, this.props.homeTeamStanding.code)} height={32} width={32} alt=""/>
                            <span className="full-text text-xl-semi color-fig-default">{getTeamCityDisplayName(this.props.activeLeague, this.props.homeTeamStanding)}</span>
                            <span className="short-text text-base-upper color-fig-default">{this.props.homeTeamStanding.code}</span>
                        </div>
                    </div>
                    {
                        Object.keys(sortedPlayers).length === 0 ?
                        <>
                            <NoResultsFrame text={"There is no result within the search criteria."} />
                        </>
                        :
                        <>
                            {
                                Object.keys(sortedPlayers).slice(0, statsCount).map((key) => 
                                    <React.Fragment key={key}>
                                        <div className="comparative-tbl-row text-base desktop">
                                            <div className="font size-16">{sortedPlayers[key].away.name}</div>
                                            <div className="font size-16">{decimalPrecision(sortedPlayers[key].away.value, 1)}</div>
                                            <div className="font size-12 spaced">{key}</div>
                                            <div className="font size-16">{decimalPrecision(sortedPlayers[key].home.value, 1)}</div>
                                            <div className="font size-16">{sortedPlayers[key].home.name}</div>
                                        </div>
                                        <div className="scouting-report-mobile-table-row mobile">
                                            <div className="scouting-report-mobile-table-row-header">
                                                <p className="font size-12 spaced">{key}</p>
                                            </div>
                                            <div className="scouting-report-mobile-table-row-column">
                                                <p className="font size-14">{sortedPlayers[key].away.name}</p>
                                                <p className="font size-14">{decimalPrecision(sortedPlayers[key].away.value, 1)}</p>
                                            </div>
                                            <div className="scouting-report-mobile-table-row-column">
                                                <p className="font size-14">{sortedPlayers[key].home.name}</p>
                                                <p className="font size-14">{decimalPrecision(sortedPlayers[key].home.value, 1)}</p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </>
                    }
                </div>
                <div className="playbook-scouting-report-link-wrapper">
                    <a href={`/${this.props.activeLeague}/leaderboard?tab=player`}>
                        <p className="font size-14 weight-600">View Leaderboard</p>
                        <img src="assets/trailing-icon-primary.svg" height={20} width={20} alt=""/>
                    </a>
                </div>
            </>
        )
    }
}

class InjuryReport extends React.Component {
    constructor(props) {
        super(props);

        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        this.loaded = false;

        this.state = {
            awayInjuries: [],
            homeInjuries: []
        }
    }

    componentDidUpdate() {
        this.awayTeamStanding = this.props.awayTeamStanding;
        this.homeTeamStanding = this.props.homeTeamStanding;
        if (!isDictEmpty(this.awayTeamStanding) && !isDictEmpty(this.homeTeamStanding) && !this.loaded) {
            this.loaded = true;
            const promises = [
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/${this.awayTeamStanding.code}/injuries`).then(data => {return data.json();}),
                fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/${this.homeTeamStanding.code}/injuries`).then(data => {return data.json();})
            ];
            Promise.all(promises)
            .then(result => {
                this.setState(
                    {
                        awayInjuries: result[0],
                        homeInjuries: result[1]
                    }
                );
            })
            .catch(error => {
                console.log(`Error loading injury report data: ${error}`);
            });

        }
    }

    render() {
        if (shouldBlockUserAccess(this.props.userAttributes)) {
            return (<></>)
        }
        return(
            <>
                <h3>7. Injury Report</h3>
                <div>Any players on these teams not playing?</div>
                {
                    this.state.awayInjuries.length === 0 ? <></> :
                    <>
                        <div className="simple-table">
                            <div className="simple-tbl-header away-team-bg text-xl text-light font size-20 weight-600">
                                {getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding)} 
                                <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamStanding.code)} height={32} width={32} alt=""/>
                            </div>
                            <div className="injury-tbl-columns">
                                <div className="injured-player font size-12 spaced">Player</div>
                                <div className="injury-details font size-12 spaced">Injury</div>
                                <div className="injury-details font size-12 spaced">Status</div>
                            </div>
                            {
                                this.state.awayInjuries.map((player, index) =>
                                    <div key={index} className="player-info">
                                        <div className="p-name font size-14">{`${getPlayerName(player)} • ${player.position}`}</div>
                                        <div className="injury-type font size-14">{capitalizeFirstLetter(player.injury)}</div>
                                        <div className="injury-type font size-14 spaced">{player.status}</div>
                                    </div>
                                )
                            }
                        </div>
                        <table className="simple-table-mobile">
                            <tr>
                                <th className="away-team-bg font size-20 weight-600"  colspan="4">
                                    {getTeamCityDisplayName(this.props.activeLeague, this.awayTeamStanding)} 
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamStanding.code)} height={32} width={32} alt=""/>
                                </th>
                            </tr>
                            <tr className="column-headers">
                                <td className="font size-12 spaced">Player</td>
                                <td className="font size-12 spaced">Injury</td>
                                <td className="font size-12 spaced">Status</td>
                            </tr>
                            {
                                this.state.awayInjuries.map((player, index) =>
                                    <tr key={index} className="row-data injury-report-row">
                                        <td className="font size-14">{`${getPlayerName(player)} • ${player.position}`}</td>
                                        <td className="font size-14">{capitalizeFirstLetter(player.injury)}</td>
                                        <td className="font size-14 spaced">{player.status}</td>
                                    </tr>
                                )
                            }
                        </table>
                    </>
                }
                {
                    this.state.homeInjuries.length === 0 ? <></> : 
                    <>
                        <div className="simple-table">
                            <div className="simple-tbl-header home-team-bg text-xl text-light font size-20 weight-600" >
                                {getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)}  
                                <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamStanding.code)} height={32} width={32} alt=""/>
                            </div>
                            <div className="injury-tbl-columns">
                                <div className="injured-player font size-12 spaced">Player</div>
                                <div className="injury-details font size-12 spaced">Injury</div>
                                <div className="injury-details font size-12 spaced">Status</div>
                            </div>
                            {
                                this.state.homeInjuries.map((player, index) =>
                                    <div key={index} className="player-info">
                                        <div className="p-name font size-14">{`${getPlayerName(player)} • ${player.position}`}</div>
                                        <div className="injury-type font size-14">{capitalizeFirstLetter(player.injury)}</div>
                                        <div className="injury-type font size-14 spaced">{player.status}</div>
                                    </div>
                                )
                            }
                        </div>
                        <table className="simple-table-mobile">
                            <tr>
                                <th className="home-team-bg font size-20 weight-600"  colspan="4">
                                    {getTeamCityDisplayName(this.props.activeLeague, this.homeTeamStanding)} 
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamStanding.code)} height={32} width={32} alt=""/>
                                </th>
                            </tr>
                            <tr className="column-headers">
                                <td className="font size-12 spaced">Player</td>
                                <td className="font size-12 spaced">Injury</td>
                                <td className="font size-12 spaced">Status</td>
                            </tr>
                            {
                                this.state.homeInjuries.map((player, index) =>
                                    <tr key={index} className="row-data injury-report-row">
                                        <td className="font size-14">{`${getPlayerName(player)} • ${player.position}`}</td>
                                        <td className="font size-14">{capitalizeFirstLetter(player.injury)}</td>
                                        <td className="font size-14 spaced">{player.status}</td>
                                    </tr>
                                )
                            }
                        </table>
                    </>
                }
            </>
        )
    }
}

export default withRouter(Playbook);
