import ReactDOM from "react-dom";
import React from 'react';
import StatsLegend from './stats-legend.js';
import Dropdown from './dropdown.js';
import Filters from './filters.js';
import TeamProfileModal from './team-profile-modal.js';
import PlayerProfileModal from './player-profile-modal.js';
import {TEAM_CITY_TO_CODE, LEGENDS, TEAM_MODAL_STATS} from './linemate-react-common/src/constants.js';
import {API_HOST} from './react-web-constants.js';
import {getDictionaryValue, orderListByField, isDictEmpty, 
        buildQueryParams, getPlayerLeaderStats, fetchPlayerLeaders,
        getLeaguePlayerLeaders,
        getPlayerName,
        getPlayerId,
        capitalizeFirstLetter} from './linemate-react-common/src/util.js';

import './fonts.css';
import './leaderboard.css';
import { getTeamLogoPath } from "./react-web-utils.js";
import HtmlHeaders from "./html-headers.js";
import Picker from "./picker.js";
import TabToggle from "./components/TabToggle.jsx";
import InputSelection from "./components/InputSelection.jsx";
import Table from "./components/Table.jsx";
import { buildStandings } from "./linemate-react-common/src/gameday-utils.js";
import Button from "./components/Button.jsx";
import { Buffer } from 'buffer';

let pos = { top: 0, left: 0, x: 0, y: 0 };

class InYearSoccerLeaderboard extends React.Component {
  constructor(props) {
    super(props);

    this.teamStatsColumns = {
      "GP": "gamesPlayed",
      "G": "%qualifier%.%TYPE%.goals",
      "POS": "%qualifier%.%TYPE%.ballPossession",
      "SHO": "%qualifier%.%TYPE%.shotsTotal",
      "SOT": "%qualifier%.%TYPE%.shotsOnTarget",
      "COR": "%qualifier%.%TYPE%.cornerKicks",
      "OFF": "%qualifier%.%TYPE%.offsides",
      "FLS": "%qualifier%.%TYPE%.fouls",
      "2YELRED": "%qualifier%.%TYPE%.doubleYellowRedCards",
      "YEL": "%qualifier%.%TYPE%.yellowCards",
      "RED": "%qualifier%.%TYPE%.redCards"
    }

    this.forwardsPositionName = "Forwards";
    this.midfieldersPositionName = "Midfielders";
    this.defendersPositionName = "Defenders";
    this.outfieldersPositionName = "Outfielders";
    this.outfielderStatsColumns = {
        "GP": "gamesPlayed",
        "G": "%qualifier%.goals",
        "A": "%qualifier%.assists",
        "SOT": "%qualifier%.shotsOnTarget",
        "SHO": "%qualifier%.totalShots",
        "MIN": "%qualifier%.minutesPlayed",
        // "CC": "%qualifier%.chancesCreated",
        "PAS": "%qualifier%.totalPasses",
        "DRI": "%qualifier%.dribblesCompleted",
        "LOP": "%qualifier%.lossOfPossession",
        "TCK": "%qualifier%.totalTackles",
        "FLS": "%qualifier%.foulsCommitted",
        "INT": "%qualifier%.interceptions",
        "YEL": "%qualifier%.yellowCards",
        "2YELRED": "%qualifier%.yellowRedCards",
        "RED": "%qualifier%.redCards"
    };

    this.goaliesPositionName = "Goalies"
    this.goaliesStatsColumns = {
        "GP": "gamesPlayed",
        "GA": "%qualifier%.goalsConceded",
        "SAV": "%qualifier%.shotsFacedSaved",
        "SA": "%qualifier%.shotsFacedTotal",
        "PS": "%qualifier%.penaltiesSaved",
        "DSAV": "%qualifier%.divingSaves",
        "CS": "%qualifier%.cleanSheet",
        "YEL": "%qualifier%.yellowCards",
        "2YELRED": "%qualifier%.doubleYellowRedCards",
        "RED": "%qualifier%.redCards"
    }

    this.filters = {
      team: {
        timeframe: {
            isActive: false,
            options: Object.fromEntries(["2024 SEASON", "LAST 5", "LAST 10"].map(x => [x, x])),
            selectedValue: "2024 SEASON"
        },
        splits: {
            isActive: false,
            options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
            selectedValue: "HOME+AWAY"
        }
      },
      player: {
        team: {
            isActive: false,
            options: Object.fromEntries(Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague]).map(x => [x, x])),
            selectedValues: ["ALL"],
            type: "multipleSelection"
        },
        timeframe: {
            isActive: false,
            options: Object.fromEntries(["2024 SEASON", "LAST 5", "LAST 10"].map(x => [x, x])),
            selectedValue: "2024 SEASON"
        },
        position: {
          isActive: false,
          options: Object.fromEntries([this.outfieldersPositionName, this.forwardsPositionName, this.midfieldersPositionName, this.defendersPositionName, this.goaliesPositionName].map(x => [x, x])),
          selectedValue: this.outfieldersPositionName
        },
        splits: {
            isActive: false,
            options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
            selectedValue: "HOME+AWAY"
        }
      }
    }

    this.tabs = {
      "team": "Team Stats",
      "player": "Player Stats",
      "standings": "Standings"
    }

    this.pickerOptions = {
      "cumulativeStats": "Total",
      "averageStats": "Per Game"
    }

    this.standingsKey = "overall";
    this.standingsColumns = {
      'Rank': '', 
      'Team': '', 
      'GP': 'gamesPlayed', 
      'W': 'standings.wins', 
      'L': 'standings.losses',
      'D': 'standings.draws',
      'PTS': 'standings.points'
    }

    this.teamModalStats = TEAM_MODAL_STATS.nba;

    this.legend = LEGENDS[this.props.activeLeague.toLowerCase()];

    this.defaultTeamSortingColumn = "G";
    this.teamSortingOrder = {
      offensive: {
        "GP": "desc",
        "G": "desc",
        "POS": "desc",
        "SHO": "desc",
        "SOT": "desc",
        "COR": "desc",
        "OFF": "asc",
        "FLS": "asc",
        "2YELRED": "asc",
        "YEL": "asc",
        "RED": "asc"
      },
      defensive: {
        "GP": "desc",
        "G": "asc",
        "POS": "asc",
        "SHO": "asc",
        "SOT": "asc",
        "COR": "asc",
        "OFF": "desc",
        "FLS": "desc",
        "2YELRED": "desc",
        "YEL": "desc",
        "RED": "desc"
      }
    };

    this.defaultOutfielderSortingColumn = "G";
    this.defaultOutfielderSortingOrder = "desc";

    this.defaultGoalieSortingColumn = "SAV";
    this.defaultGoalieSortingOrder = "desc";

    // Since we are only tracking offensive positions for now, and since all the stats are positively impacted by a higher number, generalzie to 'desc' for now
    this.defaultPlayerSortingOrder = "desc";

    this.windowScrolled = this.windowScrolled.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.handleGeneralClick = this.handleGeneralClick.bind(this);
    this.addClickableElement = this.addClickableElement.bind(this);

    this.legendClicked = this.legendClicked.bind(this);
    this.legendClosed = this.legendClosed.bind(this);
    this.childLegendClosed = this.childLegendClosed.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.headerClicked = this.headerClicked.bind(this);
    this.filterOptionSelected = this.filterOptionSelected.bind(this);
    this.generateFilterStyling = this.generateFilterStyling.bind(this);
    this.selectStatQualifier = this.selectStatQualifier.bind(this);
    this.filtersModalButtonClicked = this.filtersModalButtonClicked.bind(this);
    this.filtersModalCloseHandler = this.filtersModalCloseHandler.bind(this);

    this.mouseDownHandler = this.mouseDownHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.mouseUpHandler = this.mouseUpHandler.bind(this);

    this.openTeamProfile = this.openTeamProfile.bind(this);
    this.openPlayerProfile = this.openPlayerProfile.bind(this);
    this.closeProfile = this.closeProfile.bind(this);
    this.setStateFunction = this.setStateFunction.bind(this);

    this.rankColumnRef = React.createRef();
    this.typeFilterRef = React.createRef();
    this.typeMobileFilterRef = React.createRef();
    this.clickableElements = [this.typeFilterRef, this.typeMobileFilterRef];
    this.filterRefs = {};

    this.typeFilterClicked = this.typeFilterClicked.bind(this);
    this.typeFilterOptionSelected = this.typeFilterOptionSelected.bind(this);
    this.typeFilterContainerStyling = {};
    this.typeMobileFilterContainerStyling = {};

    this.tableScrolled = this.tableScrolled.bind(this);
    this.tableRef = React.createRef();
    this.tableGradientRef = React.createRef();

    const searchParams = new URLSearchParams(window.location.search);

    var tab = 'team';
    if (searchParams.has('tab')) {
      const category = searchParams.get('tab').toLowerCase();
      if (category === "player" || category === "team") {
        tab = category;
      }
    }

    var typeFilterOptions = ["OFFENSIVE", "DEFENSIVE"];
    var typeFilterSelection = "OFFENSIVE";
    if (searchParams.has('type') && typeFilterOptions.includes(searchParams.get('type').toUpperCase())) {
      typeFilterSelection = searchParams.get('type').toUpperCase();
    }

    var filters = this.filters[tab];
    if (searchParams.has('filters')) {
      try{
        const filterQueryParam = JSON.parse((Buffer.from(searchParams.get('filters'), 'base64')).toString('ascii'));
        Object.keys(filterQueryParam).map((key) => {
          if (key in filters && filterQueryParam[key] in filters[key].options) {
            filters[key].selectedValue = filterQueryParam[key];
          }
        });
      } catch(err) {
        console.log("Unable to parse filters", err);
      }
    }

    var statColumns = {};
    if (tab === "team") {
      statColumns = this.teamStatsColumns;
    } else if(tab === "player"){
        if (filters.position.selectedValue === this.goaliesPositionName) {
            statColumns = this.goaliesStatsColumns;
        } else {
            statColumns = this.outfielderStatsColumns;
        }
    }

    var sortingColumn = "";
    if (tab === "player") {
      if (filters.position.selectedValue === this.goaliesPositionName) {
        sortingColumn = this.defaultGoalieSortingColumn;
      } else {
        sortingColumn = this.defaultOutfielderSortingColumn;
      }
    } else {
      sortingColumn = this.defaultTeamSortingColumn;
    }
    if (searchParams.has('stat')) {
      const sortingColumnQueryParam = searchParams.get('stat').toUpperCase();
      if (sortingColumnQueryParam in statColumns) {
        sortingColumn = sortingColumnQueryParam;
      }
    }

    var sortingOrder = "";
    if (tab === "player") {
      if (filters.position.selectedValue === this.goaliesPositionName) {
        sortingOrder = this.defaultGoalieSortingOrder;
      } else {
        sortingOrder = this.defaultOutfielderSortingOrder;
      }
    } else {
      sortingOrder = this.teamSortingOrder.offensive[sortingColumn.toUpperCase()];
    }
    if (searchParams.has('order')) {
      const sortingOrderQueryParam = searchParams.get('order').toLowerCase();
      if (sortingOrderQueryParam === "asc" || sortingOrderQueryParam === "desc") {
        sortingOrder = searchParams.get('order').toLowerCase();
      }
    }

    this.state = {
      legendEnabled: false,
      tab: tab,
      legendItems: this.legend[tab],
      filterSticky: false,
      sortingColumn: sortingColumn,
      sortingOrder: sortingOrder,
      scrollToTop: false,

      statColumns: statColumns,

      // For the new leaderboard implementation
      data: {
        team: [],
        player: [],
      },
      // Keep the team and players loaded if we have them so that we don't re-load on tab switch for no reason
      //  and then separately keep track of which list of obejcts you want to show in the table
      activeData: [],
      // We could probably add the type filter in the 'filters' group but we already started it separately, will leave for later 
      // TODO
      typeFilterActive: false,
      typeFilterSelection: typeFilterSelection,
      typeFilterOptions: typeFilterOptions,
      qualifier: "cumulativeStats",

      filters: filters,

      filtersModalActive: false,

      teamProfileModalActive: null,
      playerProfileModalActive: null,
      // Used to keep a separate list of seasonal team related data to be used to determine the leader per stats for the profile pop-up
      // Using a dictionary to be able to select a specific team
      currentSeasonTeamsStats: {},
      playerLeaders: {},
      // Dictionary of player ID -> seasonal player record
      currentSeasonPlayerStats: {},
      pickerSelection: Object.keys(this.pickerOptions)[0],
      standings: {
        division: []
      }
    }
  }

  componentDidMount() {
    document.title = 'Leaderboard - Linemate';
    window.addEventListener('scroll', this.windowScrolled);
    window.addEventListener('resize', this.windowResized);
    window.addEventListener('mousedown', this.handleGeneralClick);
    const ele = document.getElementById('leaderboard-table-content');
    ele.addEventListener('mousedown', this.mouseDownHandler);
    this.tableScrolled();
    this.loadData(this.state);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.windowScrolled);
    window.removeEventListener('resize', this.windowResized);
    window.removeEventListener('mousedown', this.handleGeneralClick);
  }

  componentDidUpdate() {
    // Adding the filters as clickable elements with refs
    // This could probably be optimized by only doing it once at setup
    for (var key in this.state.filters) {
      if (!(key in this.filterRefs)) {
        this.filterRefs[key] = React.createRef();
        this.clickableElements.push(this.filterRefs[key]);
      }
    }

    if (this.state.scrollToTop) {
      window.scrollTo(0,0);
      this.setState(
        {
          scrollToTop: false
        }
      );
    }
  }

  setStateFunction(state) {
    this.setState(state);
  }

  windowScrolled() {
    const windowWidth = window.innerWidth;
    if (windowWidth < 576) {
      return;
    }
    const node = ReactDOM.findDOMNode(this);
    var leaderboardFilters = node.querySelector('.leaderboard-filters');
    if (!leaderboardFilters) {
      leaderboardFilters = node.querySelector('.leaderboard-filters-sticky');
    }
    var tempState = {};
    if (window.scrollY > 124 && !this.state.filterSticky) {
      tempState = {
        // If we want to put the sticky filters back, just set this to true
        // filterSticky: true,
        filterSticky: false,
        typeFilterActive: false
      };
    } else if (window.scrollY < 124 && this.state.filterSticky) {
      tempState = {
        filterSticky: false,
        typeFilterActive: false
      };
    } else {
      tempState = {
        filterSticky: this.state.filterSticky,
        typeFilterActive: false
      };
    }

    if (isDictEmpty(tempState)) {
      return;
    }

    if (this.state.filterSticky !== tempState.filterSticky || this.state.typeFilterActive !== tempState.typeFilterActive) {
      this.setState(tempState);
    }
  }

  windowResized() {
    this.generateFilterStyling();
  }

  //https://htmldom.dev/drag-to-scroll/
  mouseDownHandler(e) {
    const ele = document.getElementById('leaderboard-table-content');
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';

    pos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
    };

    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
  }

  mouseMoveHandler(e) {
    const ele = document.getElementById('leaderboard-table-content');
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  }

  mouseUpHandler() {
    const ele = document.getElementById('leaderboard-table-content');
    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');

    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  };

  // We should probably look into generalizing this behavior with
  // an input list of elements which have custom clicking behavior
  // as well as a callback function for those who don't
  // REVIEW TODO: instead of maintaining a single lsit of clickable elements,
  // we should contextualize it and have a new list dependent on what 'menu/dropdown' is opened at a given time

  // !!!TODO!!!: this is problematic, whenever we switch tabs more items are added to this.clickableElements and poluting it
  //  More stuff gets added just on any click which is also bad
  handleGeneralClick(event) {
    // console.log(this.clickableElements);
    var foundTarget = false;
    for(const element of this.clickableElements) {
      if (element.current && element.current.contains(event.target)) {
        foundTarget = true;
        break;
      }
    }
    if (!foundTarget) {
      this.setState({
        typeFilterActive: false
      });
    }
  }

  addClickableElement(element) {
    this.clickableElements.push(element);
  }

  tableScrolled() {
    const scrollWidth = document.getElementById("leaderboard-table-content").scrollWidth;
    const clientWidth = document.getElementById("leaderboard-table-content").clientWidth;
    const scrollLeft = document.getElementById("leaderboard-table-content").scrollLeft
    if ((scrollLeft + clientWidth) === scrollWidth) {
      // this.tableGradientRef.current.style.display = 'none';
    } else {
      // this.tableGradientRef.current.style.display = 'block';
    }
  }

  legendClicked() {
    document.body.style.overflow = "hidden";
    this.setState({
      legendEnabled: true
    });
  }

  legendClosed(event) {
    // Need to do this otherwise clicking anywhere on the child popup will trigger this
    if (event.target === event.currentTarget) {
      document.body.style.overflow = "visible";
      this.setState({
        legendEnabled: false
      });
    }
  }

  // We could probably just pass the regular legendClosed function and add the child buttons as acceptable events, but this will do for now
  childLegendClosed() {
    document.body.style.overflow = "visible";
    this.setState({
      legendEnabled: false
    });
  }

  selectTab(event) {
    const selection = event.currentTarget.dataset.selection;
    if (this.state.tab === selection) {
      return;
    }
    if (selection === "standings") {
      this.setState({tab: selection});
      return;
    }
    var sortingColumn;
    var sortingOrder;
    var state = this.state;

    state["filters"] = this.filters[selection];
    var statColumns = {};
    //var playerType = this.filters.player.
    if (selection === "team") {
      statColumns = this.teamStatsColumns;
      sortingColumn = this.defaultTeamSortingColumn;
      sortingOrder = this.teamSortingOrder.offensive[sortingColumn.toUpperCase()];
    } else if(selection === "player"){
      if (state.filters.position.selectedValue === this.goaliesPositionName) {
        statColumns = this.goaliesStatsColumns;
        sortingColumn = this.defaultGoalieSortingColumn;
        sortingOrder = this.defaultGoalieSortingOrder;
      } else {
        statColumns = this.outfielderStatsColumns;
        sortingColumn = this.defaultOutfielderSortingColumn;
        sortingOrder = this.defaultOutfielderSortingOrder;
      }
    }

    state["tab"] = selection;
    state["legendItems"] = this.legend[selection];
    state["activeData"] = [];
    state["sortingColumn"] = sortingColumn;
    state["sortingOrder"] = sortingOrder.toUpperCase();
    state["statColumns"] = statColumns;
    this.loadData(state);
  }

  headerClicked(event) {
    const selection = event.currentTarget.dataset.selection;
    var tempState = this.state;
    if (this.state.tab === "team") {
      if (selection === this.state.sortingColumn) {
        if (this.state.sortingOrder === "asc") {
          tempState["sortingOrder"] = "desc";
        } else {
          tempState["sortingOrder"] = "asc";
        }
      } else {
        tempState["sortingColumn"] = selection;
        tempState["sortingOrder"] = this.teamSortingOrder[this.state.typeFilterSelection.toLowerCase()][selection.toUpperCase()];
      }
      const sortingColumnPath = tempState.statColumns[tempState.sortingColumn.toUpperCase()].replaceAll("%qualifier%", this.state.qualifier).replaceAll("%TYPE%", this.state.typeFilterSelection.toLowerCase());
      orderListByField(tempState.activeData, sortingColumnPath, tempState.sortingOrder);
      this.setState(tempState);
    } else {
      if (selection === this.state.sortingColumn) {
        if (this.state.sortingOrder === "asc") {
          tempState["sortingOrder"] = "desc";
        } else {
          tempState["sortingOrder"] = "asc";
        }
      } else {
        tempState["sortingColumn"] = selection;
        // TODO: stat specific sorting order
        tempState["sortingOrder"] = this.defaultPlayerSortingOrder;
      }
      if (tempState.activeData.length >= 50) {
        // console.log("Server side sorting");
        // If we have data size equal to the limit we have to assume it's possible there's more data and we need to query the API to re-sort
        this.loadData(tempState);
      } else {
        // console.log("Client side sorting");
        // If we have data size less than the limit, it means we have already filtered down the number of players and we can sort in place
        const sortingColumnPath = tempState.statColumns[selection].replaceAll("%qualifier%", tempState.qualifier);
        orderListByField(tempState.activeData, sortingColumnPath, tempState.sortingOrder);
        this.setState(tempState);
      }
    }
  }

  generateFilterStyling() {
    if (this.typeFilterRef.current) {
      this.typeFilterContainerStyling = {
        left: `${this.typeFilterRef.current.offsetLeft}px`,
        top: `${this.typeFilterRef.current.offsetTop + this.typeFilterRef.current.offsetHeight}px`,
        width: `${this.typeFilterRef.current.offsetWidth}px`
      };
    }
    if(this.typeMobileFilterRef.current) {
      this.typeMobileFilterContainerStyling = {
        left: `${this.typeMobileFilterRef.current.offsetLeft}px`,
        top: `${this.typeMobileFilterRef.current.offsetTop + this.typeMobileFilterRef.current.offsetHeight}px`,
        width: `${this.typeMobileFilterRef.current.offsetWidth}px`
      };
    }
    this.forceUpdate();
  }

  typeFilterClicked() {
    this.setState(
        {
            typeFilterActive: !this.state.typeFilterActive
        }
    )
  }

  // The type filter is only present for team stats, shouldn't have to worry about checking for the tab
  typeFilterOptionSelected(event) {
    const selection = event.currentTarget.dataset.selection;
    var state = this.state;
    state["typeFilterSelection"] = selection;
    state["typeFilterActive"] = false;
    state["sortingOrder"] = this.teamSortingOrder[selection.toLowerCase()][state.sortingColumn.toUpperCase()];
    const sortingColumnPath = state.statColumns[state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", selection.toLowerCase());
    orderListByField(state.activeData, sortingColumnPath, state.sortingOrder); 
    this.setState(state, this.generateFilterStyling);
  }

  // Adding another argument here for multiple selection checkboxes to provide the latest values at all times
  //  This is needed as a workaround to a yet unknown issue with just using state + event dataset which would sometimes end up being outdated
  filterOptionSelected(event, optionalCheckedItems) {
    const selectedFilter = event.currentTarget.dataset.name;
    const selectedValue = event.currentTarget.dataset.selection;
    var state = this.state;
    state.filters[selectedFilter].isActive = false;
    if (state.filters[selectedFilter].type === "multipleSelection") {
      state.filters[selectedFilter].selectedValues = optionalCheckedItems;
    } else {
      state.filters[selectedFilter].selectedValue = selectedValue;
    }

    if (state.tab === "player") {
        const position = state.filters.position.selectedValue;
        if (position === this.goaliesPositionName) {
            state["statColumns"] = this.goaliesStatsColumns;
            state["sortingColumn"] = this.defaultGoalieSortingColumn;
            state["sortingOrder"] = this.defaultGoalieSortingOrder;
        } else {
            state["statColumns"] = this.outfielderStatsColumns;
            state["sortingColumn"] = this.defaultOutfielderSortingColumn;
            state["sortingOrder"] = this.defaultOutfielderSortingOrder;
        }
      }

    this.loadData(state);
  }

  selectStatQualifier(event) {
    const selection = event.currentTarget.dataset.selection;
    var tempState = this.state;
    tempState.pickerSelection = selection;
    tempState['qualifier'] = selection;
    if (this.state.tab === "team") {
      const sortingColumnPath = tempState.statColumns[tempState.sortingColumn.toUpperCase()].replaceAll("%qualifier%", tempState.qualifier).replaceAll("%TYPE%", tempState.typeFilterSelection.toLowerCase());
      orderListByField(tempState.activeData, sortingColumnPath, tempState.sortingOrder);
      this.setState(tempState);
    } else {
      if (tempState.activeData.length >= 50) {
        // console.log("Server side sorting");
        // If we have data size equal to the limit we have to assume it's possible there's more data and we need to query the API to re-sort
        this.loadData(tempState);
      } else {
        // console.log("Client side sorting");
        // If we have data size less than the limit, it means we have already filtered down the number of players and we can sort in place
        const sortingColumnPath = tempState.statColumns[tempState.sortingColumn.toUpperCase()].replaceAll("%qualifier%", tempState.qualifier);
        orderListByField(tempState.activeData, sortingColumnPath, tempState.sortingOrder);
        this.setState(tempState);
      }
    }
  }

  filtersModalButtonClicked() {
    // Assuming if you can click on the button your only option is to enable it (disabling it happens via another interaction)
    this.setState({filtersModalActive: true}, this.generateFilterStyling);
  }

  filtersModalCloseHandler() {
    this.setState({filtersModalActive: false});
  }

  openTeamProfile(event) {
    const teamCode = event.currentTarget.dataset.team;
    if (teamCode !== "") {
      this.setState({teamProfileModalActive: teamCode});
    }
  }

  openPlayerProfile(event) {
    const playerID = event.currentTarget.dataset.playerid;
    if (`${playerID}` in this.state.currentSeasonPlayerStats) {
      if (isDictEmpty(this.state.playerLeaders)) {
        fetchPlayerLeaders(API_HOST, this.props.activeLeague, {playerProfileModalActive: this.state.currentSeasonPlayerStats[`${playerID}`]}, this.setStateFunction);
      } else {
        this.setState({playerProfileModalActive: this.state.currentSeasonPlayerStats[`${playerID}`]});
      }
    } else {
      // Here we could be more efficient by doing both promises at the same time, 
      // but we already extracted fetchPlayerLeaders to get the players separately so we'll reuse it for the time being
      fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/bySRGUID/${playerID}`)
      .then(data => data.json())
      .then(result => {
        var currentSeasonPlayerStats = this.state.currentSeasonPlayerStats;
        currentSeasonPlayerStats[`${playerID}`] = result;
        var tempState = {
          playerProfileModalActive: result,
          currentSeasonPlayerStats: currentSeasonPlayerStats
        }
        if (isDictEmpty(this.state.playerLeaders)) {
          fetchPlayerLeaders(API_HOST, this.props.activeLeague, tempState, this.setStateFunction);
        } else {
          this.setState(tempState);
        }
      })
    }
  }

  closeProfile() {
    this.setState(
      {
        teamProfileModalActive: null,
        playerProfileModalActive: null
      }
    );
  }

  loadData(state) {
    // TODO: url encode
    // TODO: we can use a query params builder
    const filters = state.filters;
    const currentSeasonTimeframe = "SEASON_2024";
    var timeframe = "";
    const timeframeFilterValue = filters.timeframe.selectedValue;
    switch(timeframeFilterValue) {
        case "2024 SEASON":
          timeframe = "SEASON_2024";
          break;
        case "2023 SEASON":
          timeframe = "SEASON_2023";
          break;
        case "2022 SEASON":
          timeframe = "SEASON_2022";
          break;
        case "LAST 5":
          timeframe = "LAST_5";
          break;
        case "LAST 10":
          timeframe = "LAST_10";
          break;
        default:
          break;
    }

    var split = "ALL";
    if (filters.splits.selectedValue !== "HOME+AWAY") {
        split = filters.splits.selectedValue;
    }

    const defaultFilterURL = API_HOST + '/api/' + this.props.activeLeague + '/v1/teams/all';
    if (state.tab === "team") {
      if (timeframe === currentSeasonTimeframe && split === "ALL") {
        if (isDictEmpty(state.currentSeasonTeamsStats)) {
          fetch(defaultFilterURL)
          .then(response => response.json())
          .then((data) => {
              state['activeData'] = data;
              var teamsDictionary = {};
              data.forEach(element => {
                teamsDictionary[element.code] = element;
              });
              state['currentSeasonTeamsStats'] = teamsDictionary;
              const sortingColumnPath = state.statColumns[state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", state.typeFilterSelection.toLowerCase());
              orderListByField(state.activeData, sortingColumnPath, state.sortingOrder);
              state.standings = buildStandings(data)
              this.setState(state, this.generateFilterStyling);
            }
          )
          .catch(error => {
            this.generateFilterStyling();
            console.log("Error loading nba team leaderboard stats: " + error);
          });
        } else {
          // If we already have teams stats for the profiles, use that instead of making a separate call
          var teamsList = Object.values(state.currentSeasonTeamsStats);
          const sortingColumnPath = state.statColumns[state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", state.typeFilterSelection.toLowerCase());
          orderListByField(teamsList, sortingColumnPath, state.sortingOrder);
          state['activeData'] = teamsList;
          this.setState(state, this.generateFilterStyling);
        }
      } else {
        var promises = [
          // Team stats based on filters
          fetch(API_HOST + '/api/' + this.props.activeLeague + '/v1/teams/top?' + buildQueryParams({indexed: false, timeframe: timeframe, split: split})).then(response => response.json())
        ];
        if (isDictEmpty(state.currentSeasonTeamsStats)) {
          // If we're getting data for a specific set of filters on initial load, meaning we don't have the stats loaded for the current season, also load those
          promises.push(fetch(defaultFilterURL).then(response => response.json()));
        }
        Promise.all(promises)
        .then(result => {
            state['activeData'] = result[0];
            const sortingColumnPath = state.statColumns[state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", state.typeFilterSelection.toLowerCase());
            orderListByField(state.activeData, sortingColumnPath, state.sortingOrder);
            if (result.length > 0) {
              var teamsDictionary = {};
              result[1].forEach(element => {
                teamsDictionary[element.code] = element;
              });
              state['currentSeasonTeamsStats'] = teamsDictionary;
            }
            this.setState(state, this.generateFilterStyling);
        })
        .catch(error => {
          this.generateFilterStyling();
          console.log("Error loading nba team leaderboard stats: " + error);
        });
      }
    } else if (state.tab === "player") {
      // Player stats
      const positionValue = filters.position.selectedValue;
      var position = "";
      switch (positionValue) {
        case this.goaliesPositionName:
            position = "goalkeeper";
            break;
        case this.forwardsPositionName:
            position = "forward";
            break;
        case this.midfieldersPositionName:
            position = "midfielder";
            break;
        case this.defendersPositionName:
            position = "defender";
            break;
        case this.outfieldersPositionName:
        default:
            position = "forward,midfielder,defender";
            break;
      }
      const teamFilterValues = filters.team.selectedValues;
      var team = "";
      if (teamFilterValues.indexOf("ALL") === -1 && teamFilterValues.indexOf("") === -1 && teamFilterValues.length > 0) {
        team = teamFilterValues.map(x => TEAM_CITY_TO_CODE[this.props.activeLeague][x]);
      }
      const sortingColumnPath = state.statColumns[state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier);
      fetch(API_HOST + '/api/' + this.props.activeLeague + '/v1/players/top?' + buildQueryParams({
        team: team, 
        position: position,
        timeframe: timeframe,
        split: split,
        stat: sortingColumnPath,
        order: state.sortingOrder.toUpperCase()
      }))
      .then(response => {
          return response.json();
        }
      )
      .then((data) => {
          // console.log(" Fetched player data", data);
          state['activeData'] = data;
          this.setState(state, this.generateFilterStyling);
        }
      )
      .catch(error => {
        this.generateFilterStyling();
        console.log("Error loading player leaderboard stats: " + error);
      });
    }
  }

  render() {
    // console.log("Rendering");
    const windowWidth = window.innerWidth;
    const headerTextClasses = "font size-12 spaced";
    const contentTextClasses = "font size-14";
    var filtersClass = "full leaderboard-filters";
    const isFilterSticky = this.state.filterSticky;
    if (isFilterSticky && windowWidth > 576) {
      filtersClass = "full leaderboard-filters-sticky";
    }
    const columnCount = Object.keys(this.state.statColumns).length;

    var rankColumnWidthInPixels = 50;
    var nameColumnWidthInPixels = 100;
    if (windowWidth <= 576) {
      rankColumnWidthInPixels = 30;
    }
    if (this.state.tab === "player") {
      nameColumnWidthInPixels = 140;
    }
    const statColumnWidthInPixels = 75;

    const rankColumnStyle = {
      width: `${rankColumnWidthInPixels}px`,
      left: '0',
      position: 'sticky',
      zIndex: '2'
    };
    const nameColumnStyle = {
      width: `${nameColumnWidthInPixels}px`,
      left: `${rankColumnWidthInPixels}px`,
      position: 'sticky',
      zIndex: '2',
      boxShadow: '10px 0px 5px -10px #888888',
      WebkitBoxShadow: '10px 0px 5px -10px #888888',
      MozBoxShadow: '10px 0px 5px -10px #888888'
    };
    const statColumnStyle = {
      // The css has left: 0, what could that be for?
      width: `${statColumnWidthInPixels}px`,
      zIndex: '1'
    };

    const teamCodeField = this.state.tab.toLowerCase() === "team" ? "code" : "teamCode";

    var tableHeight = 0;
    var tableTopOffset = 0;
    if (this.tableRef.current) {
      tableHeight = this.tableRef.current.offsetHeight;
      tableTopOffset = this.tableRef.current.offsetTop;
    }
    return(
      <>
        <HtmlHeaders canonicalRef={`https://www.linemate.io/${this.props.activeLeague}/leaderboard`}/>
        {
          this.state.teamProfileModalActive !== null && Object.keys(this.state.currentSeasonTeamsStats).length > 0 ?
            <TeamProfileModal team={this.state.currentSeasonTeamsStats[this.state.teamProfileModalActive]} 
                              activeLeague={this.props.activeLeague} closeProfileFn={this.closeProfile}
                              stats={this.teamModalStats} allStandings={Object.values(this.state.currentSeasonTeamsStats)} /> 
            :
            <></>            
        }
        {
          this.state.playerProfileModalActive !== null && !isDictEmpty(this.state.playerLeaders)
          ?
            <PlayerProfileModal player={this.state.playerProfileModalActive} activeLeague={this.props.activeLeague} 
                                closeProfileFn={this.closeProfile} stats={getPlayerLeaderStats(this.props.activeLeague, this.state.playerProfileModalActive)} 
                                leaders={getLeaguePlayerLeaders(this.state.playerLeaders, this.props.activeLeague, this.state.playerProfileModalActive)}/> 
          : 
          <></>
        }
        <div className="content-container" onScroll={this.windowScrolled}>
          <div className="section-heading">
            <p className="font size-30 bold">Leaderboard</p>
            {
              (this.state.tab === "team" || this.state.tab === "player") && (
                <Picker options={this.pickerOptions} selection={this.state.pickerSelection} selectionHandlerFn={this.selectStatQualifier} fullWidthThreshold={990}/>
              )
            }
          </div>
          <div className='leaderboard-tab-toggle'>
            <TabToggle options={this.tabs} selection={this.state.tab} selectionHandler={(selection) => this.selectTab({currentTarget: {dataset: {selection: selection}}})} includeLegend={true} onLegendOpen={this.legendClicked}/>
          </div>
          {
            this.state.tab === "standings" ?
            <div className="leaderboard-standings-wrapper">
            {
              Object.keys(this.state.standings[this.standingsKey]).sort().map((key) =>
                <div key={key} style={{width: '100%'}}>
                  <Table 
                    columns={Object.keys(this.standingsColumns)} 
                    rowCount={this.state.standings[this.standingsKey][key].length} 
                    columnStyling={(columnIndex, columnName) => {
                      if (columnIndex === 1) {
                        return {width: 80};
                      }
                    }}
                    dataExtractor={(rowIndex, columnIndex, columnName) => {
                      if (columnIndex === 0) {
                        return rowIndex + 1;
                      }
                      const team = this.state.standings[this.standingsKey][key][rowIndex]
                      if (columnIndex === 1) {
                        const teamCode = team.code;
                        return (
                          // Wrapping the content of the cell in a div allows us to then use flex inside
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src={getTeamLogoPath(this.props.activeLeague, teamCode)} height={32} width={32} alt={teamCode} style={{marginRight: 8}}/>
                            <p className="text-style-label-medium">{teamCode}</p>
                          </div>
                        )
                      }
                      return getDictionaryValue(team, this.standingsColumns[columnName]);
                    }}
                  />
                </div>
              )
            }
            </div>
            :
            <>
              <div className={filtersClass}>
                <div className="betting-leaderboard-filter-wrapper">
                  {
                      Object.keys(this.state.filters).map((filter) => 
                          <div key={`leaderboard-${this.state.tab}-${filter}`} className='betting-leaderboard-filter-wrapper-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                              <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                              <div>
                                  <InputSelection 
                                      enabled={true} 
                                      type={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? "multiple" : "single"}
                                      typography="md" 
                                      options={this.state.filters[filter].options} 
                                      selection={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? this.state.filters[filter].selectedValues : this.state.filters[filter].selectedValue} 
                                      selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}}, selection)}
                                  />
                              </div>
                          </div>
                      )
                  }
                </div>
              </div>
              <div className="screener-btn-group-lg mobile" style={{width: '100%', margin: '0', marginBottom: '24px'}}>
                <button className="screener-btn-filter-lg" onClick={this.filtersModalButtonClicked}>
                  <img src="assets/filters.svg" width="16" height="16" alt=""/> 
                  <p>Filters</p>
                </button>
              </div>
              <div className="screener-modal-overlay" style={this.state.filtersModalActive ? {display: 'block'} : {display: 'none'}}
                                                  onClick={this.filtersModalCloseHandler}></div>
                {
                  this.state.filtersModalActive ?
                  <div className="screener-filters-modal" style={this.state.filtersModalActive ? {display: 'block'} : {display: 'none'}}>
                    <div className="screener-modal-header">
                        <h3>Filters</h3>
                        <div className="screener-close-modal" onClick={this.filtersModalCloseHandler}>
                            <img src="assets/playbook/close-major.svg" width="20" height="20" alt=""/>
                        </div>
                    </div>
                    <div className="screener-modal-body leaderboard-filters-modal">
                        <Picker options={this.pickerOptions} selection={this.state.pickerSelection} selectionHandlerFn={this.selectStatQualifier} fullWidthThreshold={990}/>
                        <div className="betting-leaderboard-filter-wrapper">
                            {
                                Object.keys(this.state.filters).map((filter) => 
                                    <div key={`leaderboard-${this.state.tab}-${filter}`} className='betting-leaderboard-filter-wrapper-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                                        <div>
                                            <InputSelection 
                                                enabled={true} 
                                                type={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? "multiple" : "single"}
                                                typography="md" 
                                                options={this.state.filters[filter].options} 
                                                selection={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? this.state.filters[filter].selectedValues : this.state.filters[filter].selectedValue} 
                                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}}, selection)}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                      </div>
                      <div className="screener-modal-footer">
                        <div className="screener-modal-btn-group">
                            <div>
                                <Button text="Cancel" typography="md" type="secondary" enabled={true} onClick={this.filtersModalCloseHandler}/>
                            </div>
                            <div>
                                <Button text="Apply Filters" typography="md" type="primary" enabled={true} onClick={this.filtersModalCloseHandler}/>
                            </div>
                        </div>
                      </div>
                  </div>
                  :
                  <>
                  </>
                }

              <div id="leaderboard-table-content" className="leaderboard-table-content" onScroll={this.tableScrolled}>
                <table ref={this.tableRef} className="leaderboard-table">
                  <colgroup>
                    <col style={rankColumnStyle}/>
                    <col style={nameColumnStyle}/>
                    {
                      Object.keys(this.state.statColumns).map((stat, statIndex) =>
                      <col key={stat + statIndex} style={statColumnStyle}/>
                      )
                    }
                  </colgroup>
                  <thead>
                    <tr className="leaderboard-table-stats-tr">
                      <th className={headerTextClasses + " align-center"} ref={this.rankColumnRef} style={rankColumnStyle}>
                        <span className="full" style={{paddingLeft: '0', paddingRight: '0'}}>
                          Rank
                        </span>
                        <span className="mobile" style={{paddingLeft: '0', paddingRight: '0'}}>
                          #
                        </span>
                      </th>
                      <th className={headerTextClasses + " align-left"} style={nameColumnStyle}>{this.state.tab}</th>
                      {
                        Object.keys(this.state.statColumns).map((stat, statIndex) =>
                        <th key={stat} className={
                          this.state.sortingColumn === stat?
                          headerTextClasses + " align-center clickable unselectable bold" :
                          headerTextClasses + " align-center clickable unselectable"
                        } data-selection={stat} onClick={this.headerClicked} 
                          style={ statIndex === 0 ? Object.assign({borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : statColumnStyle}>
                          <span className="thead-tooltip-wrapper">
                            <span className="font size-14 undersized">
                              {this.state.legendItems[stat.toUpperCase()]}
                            </span>
                            {stat}
                            {
                              this.state.sortingColumn === stat?
                              <img src={"assets/order-" + this.state.sortingOrder + "-icon.svg"} /> :
                              <>
                              </>
                            }
                          </span>
                        </th>
                        )
                      }
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.activeData.map((entry,index) =>
                      <tr key={index + 1}>
                        <td className={contentTextClasses + " align-center"} style={Object.assign({paddingLeft: '0', paddingRight: '0'}, rankColumnStyle)}>{index + 1}</td>
                        <td className={contentTextClasses + " align-left clickable"} style={nameColumnStyle} 
                            data-team={this.state.tab.toLowerCase() === "team" ? entry.code : ""}
                            data-playerid={this.state.tab.toLowerCase() === "player" ? getPlayerId(entry) : ""}
                            onClick={this.state.tab.toLowerCase() === "team" ? this.openTeamProfile : this.openPlayerProfile}>
                          <div style={{display: 'inline-block', marginRight: '5px'}}>
                            <img src={getTeamLogoPath(this.props.activeLeague, entry[teamCodeField])} height={32} width={32} alt="" style={{marginRight: '5%'}}/>
                          </div>
                          <div className="full" style={this.state.tab.toLowerCase() === "team" ? {} : {textOverflow: 'ellipsis', width: '90px', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
                            {
                              this.state.tab.toLowerCase() === "team" ?
                              entry.code :
                              getPlayerName(entry)
                            }
                          </div>
                          <div className="mobile" style={this.state.tab.toLowerCase() === "team" ? {} : {textOverflow: 'ellipsis', width: '90px', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
                            {
                              this.state.tab.toLowerCase() === "team" ?
                              entry.code :
                              getPlayerName(entry)
                            }
                          </div>
                        </td>
                        {
                          Object.keys(this.state.statColumns).map((stat, statIndex) =>
                          <td key={stat}
                              className={contentTextClasses + " align-center"}
                              style={this.state.sortingColumn === stat ? 
                              (statIndex === 0 ? Object.assign({background: 'var(--color-surface-sorted)', borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : Object.assign({background: 'var(--color-surface-sorted)'}, statColumnStyle) )  : 
                              (statIndex === 0 ? Object.assign({borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : statColumnStyle)}>
                              {getDictionaryValue(entry, this.state.statColumns[stat].replaceAll("%qualifier%", this.state.qualifier).replaceAll("%TYPE%", this.state.typeFilterSelection.toLowerCase())) || "0"}
                          </td>
                          )
                        }
                      </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>
            </>
          }
        </div>
        <span className="legend-modal" onClick={this.legendClosed} style={this.state.legendEnabled ? {} : {display: 'none'}}>
          <StatsLegend legendCloseHandler={this.childLegendClosed} legendItems={this.state.legendItems} category={this.state.tab} />
        </span>
      </>
    );
  }
}


export default InYearSoccerLeaderboard;
