import React from 'react';
import { withRouter } from "react-router";
import {TEAM_CODE_TO_CITY, LEAGUE_CURRENT_SEASON, LEAGUE_CURRENT_RECORD_TYPE, TEAM_CITY_TO_CODE,
        getPlayerPropInfo, getPlayerPositionalBetTypes, PLAYER_BET_TYPE_ID_TO_NAME, PLAYER_BET_TYPES_REVERSE_MAPPING,
        HOME_AWAY_SPLIT, BETTING_TOOL_LEGEND, getSituationalProps, getGamelogSupportingStats, SCREENER_ONLY_PROPS, SITUATIONS, BETTING_TIMEFRAME_OPTIONS, DEFAULT_SITUATIONS, 
        BET_TYPE_FILTER, TIMEFRAME_FILTER, SITUATION_FILTER, SPLITS_FILTER, TEAM_FILTER, PLAYER_FILTER, OVER_UNDER_FILTER, MIN_ODDS_FILTER, GAMES_FILTER, ALL_GAMES_FILTER_OPTION, ALL_BETS_MARKET_NAME, 
        BETTING_LEADERBOARD_TIMEFRAMES, TEAM_BET_TYPE_REVERSE_MAPPING, SPREAD_MARKET_NAME, TEAM_BETS, GAME_BET_TYPE, TEAM_BET_TYPE, OUTCOME_TYPE_OVER_UNDER, OUTCOME_TYPE_COVER, OUTCOME_TYPE_COVER_YES,
        TEAM_COLOR_CODES} from './linemate-react-common/src/constants.js';
import {API_HOST, RESPONSIVE_BAR_THEME} from './react-web-constants.js';
import { getPlayerTitle, getTeamLogoPath, teamLogoPathOnErrorFallback, getTheme } from './react-web-utils.js';
import { isDictEmpty, parseDateFromString, buildQueryParams, decimalPrecision, getDictionaryValue, orderListByField, 
         getBookEntryBasedOnPrecedence, getPreferredFormat,
         getPlayerName, addOrdinalNumberSuffix, getMultiFieldValue, 
         getGameResultText, getPlayerId, getTeamCityDisplayName, buildTeamPropRankings, 
         capitalizeFirstLetter,
         isWeeklyCompetition} from './linemate-react-common/src/util.js';
import { ResponsiveBar } from '@nivo/bar';
import ScrollableGamesBanner from './scrollable-games-banner.js';
import GamePreviewBanner from './game-preview-banner.js';
import Picker from './picker.js';
import Drawer from './drawer.js';
import Tag from './tag.js';
import InitialsImage from './initials-image.js';
import NoResultsFrame from './no-results-frame.js';
import GlobalContext from './components/GlobalContext.jsx';

import './fonts.css';
import './generic.css';
import './playbook.css';
import './buttons.css';
import './betting-page.css';
import './standings.css'
import Legend from './legend.js';
import InformationContainer from './information-container.js';
import { buildPlayerBettingLeaderboard, buildTeamLeaderboard } from './linemate-react-common/src/betting-utils.js';
import _ from 'lodash';
import HtmlHeaders from './html-headers.js';
import InputSelection from './components/InputSelection.jsx';
import Button from './components/Button.jsx';

class BettingPage extends React.Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.selectTab = this.selectTab.bind(this);
    this.pickerSelected = this.pickerSelected.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.updateLine = this.updateLine.bind(this);
    this.lineInputChanged = this.lineInputChanged.bind(this);
    this.getGamesDataFetch = this.getGamesDataFetch.bind(this);
    this.updateGames = this.updateGames.bind(this);
    this.buildTrendsData = this.buildTrendsData.bind(this);
    this.buildGraphData = this.buildGraphData.bind(this);
    this.getBetTypeLineValue = this.getBetTypeLineValue.bind(this);
    this.getGameLineValue = this.getGameLineValue.bind(this);
    this.openFiltersDrawer = this.openFiltersDrawer.bind(this);
    this.closeFiltersDrawer = this.closeFiltersDrawer.bind(this);
    this.filterGames = this.filterGames.bind(this);
    this.buildTeamLeaderboard = this.buildTeamLeaderboard.bind(this);
    this.buildPlayerLeaderboard = this.buildPlayerLeaderboard.bind(this);
    this.leaderboardRowClicked = this.leaderboardRowClicked.bind(this);
    this.leaderboardRowActionClicked = this.leaderboardRowActionClicked.bind(this);
    this.leaderboardFiltersUpdated = this.leaderboardFiltersUpdated.bind(this);
    this.openLegend = this.openLegend.bind(this);
    this.closeLegend = this.closeLegend.bind(this);
    this.leaderboardPageChanged = this.leaderboardPageChanged.bind(this);
    this.setPlayerBetType = this.setPlayerBetType.bind(this);
    this.fetchSupportingPlayerData = this.fetchSupportingPlayerData.bind(this);
    this.buildSupportingStatsRankings = this.buildSupportingStatsRankings.bind(this);
    this.currentSituationalPickerSelected = this.currentSituationalPickerSelected.bind(this);
    this.comparableSituationalPickerSelected = this.comparableSituationalPickerSelected.bind(this);
    this.determineOpposingFieldSelection = this.determineOpposingFieldSelection.bind(this);
    this.leftComparablePageChanged = this.leftComparablePageChanged.bind(this);
    this.rightComparablePageChanged = this.rightComparablePageChanged.bind(this);
    this.fetchSupportingTeamData = this.fetchSupportingTeamData.bind(this);
    this.setSupportingData = this.setSupportingData.bind(this);

    this.lineInputRef = React.createRef();
    this.graphWrapperRef = React.createRef();
    this.mobileTrendsRef = React.createRef();
    this.trendsHeaderRef = React.createRef();


    this.teamLeaderboardTimeframeFieldMapping = BETTING_LEADERBOARD_TIMEFRAMES
    this.playerLeaderboardTimeframeFieldMapping = BETTING_LEADERBOARD_TIMEFRAMES

    // I don't think it's 100% necessary but we're going to filter the screener ones from here too
    this.playerBetTypeMappings = Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME[this.props.activeLeague]).filter((entry) => !SCREENER_ONLY_PROPS[this.props.activeLeague].includes(entry[1])));

    // Here we are filtering the screener props
    this.playerBetTypeReverseMappings = Object.fromEntries(Object.entries(PLAYER_BET_TYPES_REVERSE_MAPPING[this.props.activeLeague]).filter(([key]) => !SCREENER_ONLY_PROPS[this.props.activeLeague].includes(key)));
    const timeframeOptions = BETTING_TIMEFRAME_OPTIONS;

    // TODO: if a game is specified but it doesn't exist or has already been played, grab the next available game
    this.gameID = this.props.match.params.gameID;

    this.userAttributesLoaded = false;

    // We could also use a single format and do the conversion but since we only have a few static thresholds we'll keep it pre-defined
    this.minOddsOptions = {
        american: ["-400", "-200", "-135", "+100"],
        decimal: ["1.25", "1.5", "1.75", "2.0"],
        // When comparing to these, will have to parse out the string and convert it to a number
        fractional: ["1/4", "1/2", "3/4", "1"]
    };

    this.situations = SITUATIONS;

    // TODO: extract to constants
    this.defaultSituation = DEFAULT_SITUATIONS;

    this.lineFormat = getPreferredFormat(this.props.userAttributes);

    this.teamCityOptions = Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague]);

    const teamBetTypes = Object.keys(TEAM_BET_TYPE_REVERSE_MAPPING[this.props.activeLeague]);

    const filters = {
        team: {},
        player: {}
    }

    filters.team[BET_TYPE_FILTER] = {
        grouped: true,
        isActive: false,
        options: Object.fromEntries(teamBetTypes.map(x => [x, x])),
        selectedValue: teamBetTypes[0]
    }
    filters.team[TIMEFRAME_FILTER] = {
        grouped: true,
        isActive: false,
        options: Object.fromEntries(Object.keys(this.teamLeaderboardTimeframeFieldMapping).map(x => [x, x])),
        selectedValue: Object.keys(this.teamLeaderboardTimeframeFieldMapping)[0]
    }
    filters.team[SITUATION_FILTER] = {
        grouped: true,
        isActive: false,
        options: Object.fromEntries(["ALL", "FAVORITE", "UNDERDOG"].map(x => [x, x])),
        selectedValue: "ALL"
    }
    filters.team[SPLITS_FILTER] = {
        grouped: true,
        isActive: false,
        options: Object.fromEntries([HOME_AWAY_SPLIT, "HOME", "AWAY"].map(x => [x, x])),
        selectedValue: HOME_AWAY_SPLIT
    }
    filters.team[TEAM_FILTER] = {
        grouped: false,
        isActive: false,
        options: Object.fromEntries(this.teamCityOptions.map(x => [x, x])),
        selectedValue: this.teamCityOptions[0]
    }

    // TODO: for NFL (and potentially MLB) will have to adjust it based on position. NHL since we don't have goalie stats we should filter the goalies from the players list
    filters.player[BET_TYPE_FILTER] = {
        grouped: true,
        isActive: false,
        options: Object.fromEntries([""].map(x => [x, x])),
        selectedValue: ""
    }
    filters.player[TIMEFRAME_FILTER] = {
        grouped: true,
        isActive: false,
        options: Object.fromEntries(Object.keys(this.playerLeaderboardTimeframeFieldMapping).map(x => [x, x])),
        selectedValue: Object.keys(this.playerLeaderboardTimeframeFieldMapping)[0]
    }
    filters.player[SPLITS_FILTER] = {
        grouped: true,
        isActive: false,
        options: Object.fromEntries([HOME_AWAY_SPLIT, "HOME", "AWAY"].map(x => [x, x])),
        selectedValue: HOME_AWAY_SPLIT
    }
    filters.player[TEAM_FILTER] = {
        grouped: false,
        isActive: false,
        options: Object.fromEntries(this.teamCityOptions.map(x => [x, x])),
        selectedValue: this.teamCityOptions[0]
    }
    filters.player[PLAYER_FILTER] = {
        grouped: false,
        isActive: false,
        options: Object.fromEntries([""].map(x => [x, x])),
        selectedValue: ""
    }

    const leaderboardFilters = {
        team: {},
        player: {}
    };

    leaderboardFilters.team[BET_TYPE_FILTER] = {
        isActive: false,
        options: Object.fromEntries([ALL_BETS_MARKET_NAME].concat(teamBetTypes).map(x => [x, x])),
        selectedValue: ALL_BETS_MARKET_NAME
    }
    leaderboardFilters.team[TIMEFRAME_FILTER] = {
        isActive: false,
        options: Object.fromEntries(Object.keys(this.teamLeaderboardTimeframeFieldMapping).map(x => [x, x])),
        selectedValue: Object.keys(this.teamLeaderboardTimeframeFieldMapping)[0]
    }

    leaderboardFilters.player[OVER_UNDER_FILTER] = {
        isActive: false,
        options: Object.fromEntries(["ALL", "OVER", "UNDER"].map(x => [x, x])),
        selectedValue: "ALL"
    }
    leaderboardFilters.player[MIN_ODDS_FILTER] = {
        isActive: false,
        options: Object.fromEntries(["ALL"].concat(this.minOddsOptions[this.lineFormat]).map(x => [x, x])),
        selectedValue: "ALL"
    }
    leaderboardFilters.player[BET_TYPE_FILTER] = {
        isActive: false,
        options: Object.fromEntries([ALL_BETS_MARKET_NAME].concat(Object.keys(this.playerBetTypeReverseMappings)).map(x => [x, x])),
        selectedValue: ALL_BETS_MARKET_NAME
    }
    leaderboardFilters.player[TIMEFRAME_FILTER] = {
        isActive: false,
        options: Object.fromEntries(Object.keys(this.playerLeaderboardTimeframeFieldMapping).map(x => [x, x])),
        selectedValue: Object.keys(this.playerLeaderboardTimeframeFieldMapping)[0]
    }

    this.state = {
        upcomingGamesLeftScroll: false,
        upcomingGamesRightScroll: false,
        pickerOptions: {},
        teams: [],
        // prop -> teamRankMapping
        // teamRankMapping: team -> rank
        teamPropRankings: {},
        teamCode: "",
        opposingTeamCode: "",
        // list of players for filters
        players: {},
        // list of seasonal player records
        playerRecords: {},
        game: {},
        currentGames: [],
        upcomingGames: [],
        tab: "player",
        filtersDrawerOpen: false,
        filters: filters,
        leaderboardFilters: leaderboardFilters,
        // allGames is used to store all the data from the timeframe, historicalGames is used to store the games we are going to use (for example: situation can filter it down to fav/dog only)
        allGames: [],
        historicalGames: [],
        hits: 0,
        push: 0,
        lineValue: 0,
        resultAverage: 0,
        resultHomeAverage: 0,
        resultAwayAverage: 0,
        legendOpen: false,
        expandedLeaderboardRow: -1,
        leaderboardPage: 0,
        // situation -> stat
        // stat -> teamRankMapping
        // teamRankMapping: team -> rank
        supportingStatsRankings: {},
        currentSupportingStatsSituation: this.defaultSituation[this.props.activeLeague],
        comparableSupportingStatsSituation: this.defaultSituation[this.props.activeLeague],
        // playerID -> list<game record>
        playerSeasonalGameRecords: {},
        // teamCode -> list<game record>
        teamSeasonalGameRecords: {},
        leftComparablePage: 0,
        rightComparablePage: 0,
        opponentFieldSelection: "team",
        // This can be either a player id or a team code and will be used in a specific way based on the opponent field selection above
        opponentIdentifier: "",
        leaderboardData: {team:[], player: []}
    };
  }

  componentDidMount() {
    document.title = 'Props - Linemate';

    if (this.gameID) {
        const gameIDChunks = this.gameID.split("-");
        this.awayTeamCode = gameIDChunks[1];
        this.homeTeamCode = gameIDChunks[2];

        var tempState = this.state;

        var pickerOptions = {};
        pickerOptions[this.awayTeamCode] = TEAM_CODE_TO_CITY[this.props.activeLeague][this.awayTeamCode];
        pickerOptions[this.homeTeamCode] = TEAM_CODE_TO_CITY[this.props.activeLeague][this.homeTeamCode];
        tempState['pickerOptions'] = pickerOptions;
        tempState['teamCode'] = Object.keys(pickerOptions)[0];
        tempState['opposingTeamCode'] = Object.keys(pickerOptions)[1];
    
        var promises = [
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/${this.gameID}`).then(data => data.json()),
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/current?reduced=true&recordType=${LEAGUE_CURRENT_RECORD_TYPE[this.props.activeLeague]}${isWeeklyCompetition(this.props.activeLeague) ? "&format=weekly" : ""}`).then(data => data.json()),
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/upcoming?recordType=${LEAGUE_CURRENT_RECORD_TYPE[this.props.activeLeague]}${isWeeklyCompetition(this.props.activeLeague) ? "&format=weekly" : ""}`).then(data => data.json()),
            this.getGamesDataFetch(tempState),
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/all`).then(data => data.json())
        ];
        Promise.all(promises)
            .then(result => {
                if (result[0].code === 404) {
                    window.location.href = `${this.props.activeLeague}/props`;
                }
                tempState['game'] = result[0];
                tempState.players = this.buildPlayersDict(tempState.game, tempState['teamCode']);
                // I know this is stupid doing Object.keys and then Object.fromEntries but it's the quickest way to use the existing implementation
                tempState.filters.player[PLAYER_FILTER].options = Object.fromEntries(Object.keys(tempState.players).map(x => [x, x]));
                tempState.filters.player[PLAYER_FILTER].selectedValue = Object.keys(tempState.players)[0];
                this.setSupportingData(tempState);
                this.setPlayerBetType(tempState, true);
                tempState.leaderboardData.player = this.buildPlayerLeaderboard([result[0]], tempState);
                tempState['currentGames'] = result[1].filter(game => game.status === "SCHEDULED");
                tempState['upcomingGames'] = result[1].concat(result[2]).filter(game => game.status === "SCHEDULED");
                tempState['allGames'] = result[3];
                tempState = this.filterGames(tempState);
                tempState['lineValue'] = this.getBetTypeLineValue(tempState);
                Object.assign(tempState, this.buildTrendsData(tempState));
                tempState['teams'] = result[4];
                tempState['teamPropRankings'] = buildTeamPropRankings(tempState['teams'], this.props.activeLeague);
                tempState['supportingStatsRankings'] = this.buildSupportingStatsRankings(tempState['teams']);
                this.setState(tempState);
            })
            .catch(error => {
                console.log("Error loading betting data: " + error);
                console.log(error.stack);
            });
    } else {
        var tempState = this.state;
        // This could be reduced to a single entry if we implement it on the API side to just return the next game
        var promises = [
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/current?recordType=${LEAGUE_CURRENT_RECORD_TYPE[this.props.activeLeague]}${isWeeklyCompetition(this.props.activeLeague) ? "&format=weekly" : ""}`).then(data => data.json()),
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/upcoming?recordType=${LEAGUE_CURRENT_RECORD_TYPE[this.props.activeLeague]}${isWeeklyCompetition(this.props.activeLeague) ? "&format=weekly" : ""}`).then(data => data.json()),
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/all`).then(data => data.json())
        ];
        Promise.all(promises)
        .then(result => {
            const allGames = result[0].concat(result[1]).filter(game => game.status === "SCHEDULED");
            if (allGames.length == 0) {
                window.location.href = `${this.props.activeLeague}/gameday`;
            } else {
                // window.location.href = `${this.props.activeLeague}/props/${allGames[0].id}`;
            }
            tempState['game'] = allGames[0];
            tempState['currentGames'] = result[0].filter(game => game.status === "SCHEDULED");
            tempState['upcomingGames'] = allGames;
            // Filter the team city options to only those playing today if there are games today based on the team code (to avoid mismatches where multiple teams play in the same city)
            if (tempState['currentGames'].length > 0) {
                const teamCodeOptions = Object.keys(TEAM_CODE_TO_CITY[this.props.activeLeague]).filter(teamCode => tempState.currentGames.some(game => game.homeTeamData.info.code.toUpperCase() === teamCode.toUpperCase() || game.awayTeamData.info.code.toUpperCase() === teamCode.toUpperCase()));
                this.teamCityOptions = [];
                teamCodeOptions.map((teamCode) => {
                    this.teamCityOptions.push(TEAM_CODE_TO_CITY[this.props.activeLeague][teamCode]);
                });
                tempState.filters.team[TEAM_FILTER].options = Object.fromEntries(this.teamCityOptions.map(x => [x, x]));
                tempState.filters.team[TEAM_FILTER].selectedValue = this.teamCityOptions[0];
                tempState.filters.player[TEAM_FILTER].options = Object.fromEntries(this.teamCityOptions.map(x => [x, x]));
                tempState.filters.player[TEAM_FILTER].selectedValue = this.teamCityOptions[0];
            }
            tempState['teams'] = result[2];
            tempState['teamPropRankings'] = buildTeamPropRankings(tempState['teams'], this.props.activeLeague);
            tempState['supportingStatsRankings'] = this.buildSupportingStatsRankings(tempState['teams']);
            this.setState(tempState, () => {
                const awayTeamCode = allGames[0].awayTeamData.info.code;
                const homeTeamCode = allGames[0].homeTeamData.info.code;
                tempState['teamCode'] = awayTeamCode;
                tempState['opposingTeamCode'] = homeTeamCode;
                tempState.filters.team[TEAM_FILTER].selectedValue = TEAM_CODE_TO_CITY[this.props.activeLeague][tempState['teamCode']];
                tempState.filters.player[TEAM_FILTER].selectedValue = TEAM_CODE_TO_CITY[this.props.activeLeague][tempState['teamCode']];
                tempState.players = this.buildPlayersDict(tempState.game, tempState['teamCode']);
                var pickerOptions = {};
                pickerOptions[awayTeamCode] = TEAM_CODE_TO_CITY[this.props.activeLeague][awayTeamCode];
                pickerOptions[homeTeamCode] = TEAM_CODE_TO_CITY[this.props.activeLeague][homeTeamCode];
                tempState['pickerOptions'] = pickerOptions;
                tempState.filters.player[PLAYER_FILTER].options = Object.fromEntries(Object.keys(tempState.players).map(x => [x, x]));
                tempState.filters.player[PLAYER_FILTER].selectedValue = Object.keys(tempState.players)[0];
                this.setSupportingData(tempState);
                this.setPlayerBetType(tempState, true);
                this.getGamesDataFetch(tempState)
                    .then((data) => {
                        tempState['allGames'] = data;
                        tempState = this.filterGames(tempState);
                        tempState['lineValue'] = this.getBetTypeLineValue(tempState);
                        tempState.leaderboardData.team = this.buildTeamLeaderboard(tempState);
                        tempState.leaderboardData.player = this.buildPlayerLeaderboard(tempState['currentGames'], tempState);
                        if (!isDictEmpty(this.props.userAttributes)) {
                            this.userAttributesLoaded = true;
                        }
                        Object.assign(tempState, this.buildTrendsData(tempState));
                        this.setState(tempState);
                    })
                    .catch((error) => {
                        this.setState(tempState);
                        console.log(error.stack);
                    })
            });
        })
        .catch(error => {
            console.log("Error loading next game for betting page: " + error);
            // window.location.href = `${this.props.activeLeague}/gameday`;
            console.log(error.stack);
        });
    }

  }

  componentDidUpdate() {
    const preferredLineFormat = getPreferredFormat(this.props.userAttributes);
    if (preferredLineFormat !== this.lineFormat) {
        this.lineFormat = preferredLineFormat;
        var leaderboardFilters = this.state.leaderboardFilters;
        leaderboardFilters.player[MIN_ODDS_FILTER].options = Object.fromEntries(["ALL"].concat(this.minOddsOptions[this.lineFormat]).map(x => [x, x]))
        leaderboardFilters.player[MIN_ODDS_FILTER].selectedValue = "ALL";

        this.setState(
            {
                leaderboardFilters: leaderboardFilters,
                // Team doesn't currently have min odds but will re-build it now in case we support that in the future not to forget about it
                leaderboardData: {
                    team: this.buildTeamLeaderboard(this.state),
                    player: this.buildPlayerLeaderboard(this.gameID ? [this.state.game] : this.state.currentGames, this.state)
                }
            }
        );
    }
  }

  setSupportingData(state) {
    // Figure out the current player (if there is one)
    // Figure out the opponent
    // If opponent is player fetch both players (fetchSupportingPlayerData)
    // If opponent is team fetch player and fetch team (fetchSupportingTeamData)
    if (state.filters.player[PLAYER_FILTER].selectedValue != null && state.filters.player[PLAYER_FILTER].selectedValue !== "") {
        var playerIds = [state.players[state.filters.player[PLAYER_FILTER].selectedValue].info].map((playerInfo) => getPlayerId(playerInfo));
        const opposingTeamData = [state.game.homeTeamData, state.game.awayTeamData].find(x => x && x.info && x.info.code === state.opposingTeamCode);
        let { fieldSelection, opposingPlayerId } = this.determineOpposingFieldSelection(opposingTeamData);
        state['opponentFieldSelection'] = fieldSelection;
        if (opposingPlayerId) {
            state['opponentIdentifier'] = opposingPlayerId;
            playerIds.push(opposingPlayerId);
            this.fetchSupportingPlayerData(playerIds);
        } else {
            state['opponentIdentifier'] = state.opposingTeamCode;
            this.fetchSupportingPlayerData(playerIds);
            this.fetchSupportingTeamData(state.opposingTeamCode);
        }
    }
  }

  // Also going to fetch the seasonal games here
  // For now we're going to get all seasonal games, eventually we should add the ability to do a cursor starting from somewhere to get it in chunks
  // May 10th: Removing the fetch for games since we're going to re-use the ones from the graph which are based on the filters
  fetchSupportingPlayerData(ids) {
    var promises = [];
    var idsToFetch = ids.filter(id => !this.state.playerRecords[id]);
    idsToFetch.forEach((id) => {
        promises.push(fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/bySRGUID/${id}`).then(data => data.json()));
        // promises.push(fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForPlayer?timeframe=LAST_10&SRGUID=${id}`).then(data => data.json()));
    })
    Promise.all(promises)
    .then((data) => {
        var playerRecords = this.state.playerRecords;
        // var playerSeasonalGameRecords = this.state.playerSeasonalGameRecords;
        idsToFetch.forEach((id, index) => {
            // const playerRecordIndex = index * 2;
            const playerRecordIndex = index;
            // const playerGameRecordsIndex = (index * 2) + 1;
            // For 404
            if (!data[playerRecordIndex].code) {
                playerRecords[id] = data[playerRecordIndex];
            }
            // Have to reverse in order to keep the same order as the main data fetch for graph and benefit from the cache
            // playerSeasonalGameRecords[id] = data[playerGameRecordsIndex].reverse();
        })
        this.setState(
            {
                playerRecords: playerRecords
                // ,
                // playerSeasonalGameRecords: playerSeasonalGameRecords
            }
        );
    })
    .catch((error) => {
        console.log("Error fetching supporting player data");
        console.log(error.stack);
    });
  }

  fetchSupportingTeamData(teamCode) {
    // Originally had the team game log but we're going to remove it for now, we won't be showing that on the page
    return;
    if (!this.state.teamSeasonalGameRecords[teamCode]) {
        Promise.all([
            fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?timeframe=LAST_10&teamCode=${teamCode}`).then(data => data.json())
        ])
        .then((data) => {
            var teamSeasonalGameRecords = this.state.teamSeasonalGameRecords;
            // Have to reverse in order to keep the same order as the main data fetch for graph and benefit from the cache
            teamSeasonalGameRecords[teamCode] =  data[0].reverse();
            this.setState({teamSeasonalGameRecords: teamSeasonalGameRecords});
        })
        .catch((error) => {
            console.log("Error fetching supporting team data");
            console.log(error.stack);
        });
    }
  }

  setPlayerBetType(tempState, setSelectedValue) {
    if (!isDictEmpty(tempState.players) && Object.keys(tempState.players).length > 0) {
        const firstPlayer = tempState.filters.player[PLAYER_FILTER].selectedValue;
        tempState.filters.player[BET_TYPE_FILTER].options = Object.fromEntries(getPlayerPositionalBetTypes[this.props.activeLeague](tempState.players[firstPlayer].info.position).filter(prop => !SCREENER_ONLY_PROPS[this.props.activeLeague].includes(prop)).map(x => [x, x]));
        if (setSelectedValue) {
            tempState.filters.player[BET_TYPE_FILTER].selectedValue = Object.keys(tempState.filters.player[BET_TYPE_FILTER].options)[0];
        }
    } else {
        // Here get a default list of bet types for when there's no players
        tempState.filters.player[BET_TYPE_FILTER].options = Object.fromEntries(getPlayerPositionalBetTypes[this.props.activeLeague](null).filter(prop => !SCREENER_ONLY_PROPS[this.props.activeLeague].includes(prop)).map(x => [x, x]));
        if (setSelectedValue && Object.keys(tempState.filters.player[BET_TYPE_FILTER].options).length > 0) {
            tempState.filters.player[BET_TYPE_FILTER].selectedValue = Object.keys(tempState.filters.player[BET_TYPE_FILTER].options)[0];
        }
    }
    return tempState;
  }

  buildPlayersDict(game, teamCode) {
    var teamPlayersList = [];
    if (teamCode === game.homeTeamData.info.code) {
        teamPlayersList = game.homeTeamData.players;
    } else {
        teamPlayersList = game.awayTeamData.players;
    }

    if (!teamPlayersList) {
        return {};
    }

    var players = {};
    teamPlayersList.forEach((player) => {
        players[getPlayerName(player).toUpperCase()] = player;
    });

    return players;
  }

  // Ranking teams by the required supporting stats
  // Might be able to combine with the team prop rankings
  buildSupportingStatsRankings(teams) {
    var result = {};

    Object.keys(this.situations[this.props.activeLeague]).map((situation) => {
        var situationalResult = {};
        const props = getSituationalProps[this.props.activeLeague](situation);
        Object.keys(props).map((prop) => {
            var propsResult = {};
            const supportingStats = props[prop].opposingSupportingStats;
            Object.keys(supportingStats).map((stat) => {
                const value = supportingStats[stat];
                const fields = value.teamFields;
                // Creating a local copy of the teams list
                var localTeams = teams.map(a => ({...a}));
        
                // stat -> teamRankMapping
                // teamRankMapping: team -> rank
                var teamRankMapping = {};
                if (value.ranked) {
                    // Need to iterate to build the value since we have props with multiple fields
                    localTeams.map((team) => {
                        // Adding a new field to track the final value based on which we're going to sort
                        team.statValue = getMultiFieldValue(team, fields);
                    })
                    const sortingOrder = value.sortingOrder;
                    orderListByField(localTeams, 'statValue', sortingOrder);
                    localTeams.map((team, index) => {
                        teamRankMapping[team.code] = index + 1;
                    })
                } else {
                    // For ease of use we will keep the original value if it's not to be ranked and add it as-is
                    localTeams.map((team) => {
                        // Adding a new field to track the final value based on which we're going to sort
                        teamRankMapping[team.code] = getMultiFieldValue(team, fields);
                    })
                }
                propsResult[stat] = teamRankMapping;
            });
            situationalResult[prop] = propsResult;
        })
        result[situation] = situationalResult;
    })
    return result;
  }

  selectTab(event) {
    const selection = event.currentTarget.dataset.selection;
    var tempState = this.state;
    tempState.expandedLeaderboardRow = -1;
    tempState.leaderboardPage = 0;
    tempState.tab = selection;
    // Probably need to get the right game updated here
    tempState.players = this.buildPlayersDict(tempState.game, tempState.teamCode);
    tempState.filters.player[PLAYER_FILTER].options = Object.fromEntries(Object.keys(tempState.players).map(x => [x, x]));
    tempState.filters.player[PLAYER_FILTER].selectedValue = Object.keys(tempState.players)[0];
    this.setSupportingData(tempState);
    this.setPlayerBetType(tempState, true);
    tempState.lineValue = this.getBetTypeLineValue(tempState);
    // First setting the state to switch the tab and then fetching the new data. This will help make it a bit more responsive
    this.setState(tempState, () => this.updateGames(tempState));
  }

  pickerSelected(event) {
    var tempState = this.state;
    const teamCode = event.currentTarget.dataset.selection;

    tempState.players = this.buildPlayersDict(tempState.game, teamCode);
    tempState.filters.player[PLAYER_FILTER].options = Object.fromEntries(Object.keys(tempState.players).map(x => [x, x]));
    tempState.filters.player[PLAYER_FILTER].selectedValue = Object.keys(tempState.players)[0];
    this.setPlayerBetType(tempState, true);

    tempState['teamCode'] = teamCode;
    var pickerCopy = Object.assign({}, this.state.pickerOptions);
    delete pickerCopy[teamCode];
    tempState['opposingTeamCode'] = Object.keys(pickerCopy)[0];
    const betType = tempState.filters[tempState.tab][BET_TYPE_FILTER].selectedValue;
    // Not updating the line if it's game total since that applies to both teams
    if (betType !== "Game Total") {
        tempState['lineValue'] = this.getBetTypeLineValue(tempState);
    }
    this.setSupportingData(tempState);
    this.updateGames(tempState);
  }

  currentSituationalPickerSelected(event) {
    const situation = event.currentTarget.dataset.selection;
    this.setState({currentSupportingStatsSituation: situation});
  }

  comparableSituationalPickerSelected(event) {
    const situation = event.currentTarget.dataset.selection;
    this.setState({comparableSupportingStatsSituation: situation});
  }

  filtersUpdated(event) {
    const selectedFilter = event.currentTarget.dataset.name;
    const selectedValue = event.currentTarget.dataset.selection;
    var tempState = this.state;
    tempState.filters[tempState.tab][selectedFilter].selectedValue = selectedValue;
    Object.keys(tempState.filters[tempState.tab]).map((key) => {
        tempState.filters[tempState.tab][key].isActive = false;
    });
    
    tempState['leftComparablePage'] = 0;
    tempState['rightComparablePage'] = 0;

    // The following could be done in a switch but it gets tricky with certain fall through
    if (selectedFilter === TEAM_FILTER || selectedFilter === PLAYER_FILTER || selectedFilter === TIMEFRAME_FILTER) {
        if (selectedFilter === TEAM_FILTER) {
            tempState['teamCode'] = TEAM_CITY_TO_CODE[this.props.activeLeague][selectedValue];
        }
        tempState.filters.team[TEAM_FILTER].selectedValue = TEAM_CODE_TO_CITY[this.props.activeLeague][tempState['teamCode']];
        tempState.filters.player[TEAM_FILTER].selectedValue = TEAM_CODE_TO_CITY[this.props.activeLeague][tempState['teamCode']];

        if (!this.gameID && tempState.currentGames.length > 0) {
            // We're going to recycle the picker internal mechanism to keep track of the current team and the opponent in the case of matchup for the all games page
            const team = tempState.teamCode;
            // Get the game in which the current team is playing to get its opponent
            const game = tempState.currentGames.filter(game => game.homeTeamData.info.code === team || game.awayTeamData.info.code === team)[0];
            tempState.game = game;
            var opponent = "";
            if (game.homeTeamData.info.code === team) {
                opponent = game.awayTeamData.info.code;
            } else {
                opponent = game.homeTeamData.info.code;
            }
            tempState['opposingTeamCode'] = opponent;
            var pickerOptions = {};
            pickerOptions[team] = TEAM_CODE_TO_CITY[this.props.activeLeague][team];
            pickerOptions[tempState.opposingTeamCode] = TEAM_CODE_TO_CITY[this.props.activeLeague][tempState.opposingTeamCode];
            tempState.pickerOptions = pickerOptions;
        }

        if (selectedFilter === TEAM_FILTER) {
            tempState.players = this.buildPlayersDict(tempState.game, tempState.teamCode);
            tempState.filters.player[PLAYER_FILTER].options = Object.fromEntries(Object.keys(tempState.players).map(x => [x, x]));
            tempState.filters.player[PLAYER_FILTER].selectedValue = Object.keys(tempState.players)[0];
        }
        if (selectedFilter === TEAM_FILTER || selectedFilter === PLAYER_FILTER) {
            this.setSupportingData(tempState);
            // Whether we change the team or player, set the right bet type
            this.setPlayerBetType(tempState, true);
        }

        tempState['lineValue'] = this.getBetTypeLineValue(tempState);
        this.updateGames(tempState);
    } else if (selectedFilter === BET_TYPE_FILTER) {
        tempState['lineValue'] = this.getBetTypeLineValue(tempState);
        tempState = this.filterGames(tempState);
        Object.assign(tempState, this.buildTrendsData(tempState));
        this.setState(tempState);
    } else if (selectedFilter === SPLITS_FILTER && tempState.filters[tempState.tab][TIMEFRAME_FILTER].selectedValue.startsWith("LAST")) {
        // If the timeframe is LAST_X we need to re-fetch the games to make sure there are X amount of games 
        // as opposed to getting the data from the timeframe and then filtering down to the split
        this.updateGames(tempState);
    } else if (selectedFilter === SPLITS_FILTER || selectedFilter === SITUATION_FILTER) {
        tempState = this.filterGames(tempState);
        Object.assign(tempState, this.buildTrendsData(tempState));
        this.setState(tempState);
    }
  }

  leaderboardFiltersUpdated(event) {
    const selectedFilter = event.currentTarget.dataset.name;
    const selectedValue = event.currentTarget.dataset.selection;
    var tempState = this.state;
    tempState.expandedLeaderboardRow = -1;
    tempState.leaderboardPage = 0;
    tempState.leaderboardFilters[tempState.tab][selectedFilter].selectedValue = selectedValue;
    Object.keys(tempState.leaderboardFilters[tempState.tab]).map((key) => {
        tempState.leaderboardFilters[tempState.tab][key].isActive = false;
    });

    if (tempState.tab === "player") {
        if (this.gameID) {
            tempState.leaderboardData.player = this.buildPlayerLeaderboard([tempState.game], tempState);
        } else {
            tempState.leaderboardData.player = this.buildPlayerLeaderboard(tempState.currentGames, tempState);
        }
    } else if (tempState.tab === "team") {
        tempState.leaderboardData.team = this.buildTeamLeaderboard(tempState);
    }

    this.setState(tempState);
  }

  updateLine(event) {
    const change = parseFloat(event.currentTarget.dataset.change);
    var tempState = this.state;
    tempState['lineValue'] = tempState.lineValue + change
    Object.assign(tempState, this.buildTrendsData(tempState));
    this.setState(tempState);
  }

  lineInputChanged(event) {
    const newValue = parseFloat(event.target.value);
    var tempState = this.state;
    tempState['lineValue'] = newValue;
    Object.assign(tempState, this.buildTrendsData(tempState));
    this.setState(tempState);
  }

  async getGamesDataFetch(state) {
    const inputTimeframe = state.filters[state.tab][TIMEFRAME_FILTER].selectedValue;

    var queryParams = {};
    if (state.filters[state.tab][SPLITS_FILTER].selectedValue !== HOME_AWAY_SPLIT) {
        queryParams['split'] = state.filters[state.tab][SPLITS_FILTER].selectedValue;
    }
    if (inputTimeframe.toUpperCase() === "SEASON") {
        // Default values for all the query params other than teamcode
    } else if (inputTimeframe.toUpperCase() === "MATCHUP" || inputTimeframe.toUpperCase() === "HEAD TO HEAD") {
        queryParams['opponent'] = state.opposingTeamCode;
        queryParams['timeframe'] = `RANGE_${LEAGUE_CURRENT_SEASON[this.props.activeLeague] - 2}_${LEAGUE_CURRENT_SEASON[this.props.activeLeague]}`
    } else if (inputTimeframe.toUpperCase().startsWith("LAST")) {
        queryParams['timeframe'] = inputTimeframe.toUpperCase().replace(" ", "_");
    }

    // TODO: review the order of query params since those would affect the potential use of cache across different pages
    //  One alternative would be to parse out the query params in the fetch proxy, sort them, and re-build the URL to make it the same format
    var endpoint = "";
    if (state.tab === "team") {
        queryParams['teamCode'] = state.teamCode;
        endpoint = "filteredForTeam";
    } else if (state.tab === "player") {
        // In the case that we don't have any players with props, return an empty list of the games list
        if (isDictEmpty(state.players)) {
            console.log("No player props, returning empty list of games");
            return new Promise((resolve, reject) => {
                resolve([]);
            })
        }
        endpoint = "filteredForPlayer";
        const selectedPlayerName = state.filters.player[PLAYER_FILTER].selectedValue;
        const selectedPlayer = state.players[selectedPlayerName];
        queryParams['SRGUID'] = getPlayerId(selectedPlayer.info);
    }
    // console.log(`${API_HOST}/api/${this.props.activeLeague}/v1/games/${endpoint}?${buildQueryParams(queryParams)}`);
    // const data = await fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/${endpoint}?${buildQueryParams(queryParams)}`);
    //   return await data.json();
    return fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/games/${endpoint}?${buildQueryParams(queryParams)}`).then(data => data.json());
  }

  updateGames(state) {
    this.getGamesDataFetch(state)
    .then(result => {
        state['allGames'] = result;
        state = this.filterGames(state);
        Object.assign(state, this.buildTrendsData(state));
        this.setState(state);
    })
    .catch(error => {
        console.log("Error fetching game data: " + error)
        console.log(error.stack);
        this.setState(state);
    })
  }

  buildTrendsData(state) {
    const betType = state.filters[this.state.tab][BET_TYPE_FILTER].selectedValue;
    var hits = 0;
    var push = 0;
    var total = 0;
    var homeTotal = 0;
    var awayTotal = 0;
    var homeGames = 0;
    var awayGames = 0;
    state.historicalGames.forEach((game) => {
        var gameValue = this.getGameLineValue(game, state);
        total += gameValue;
        if (game.isHome) {
            homeGames++;
            homeTotal += gameValue;
        } else {
            awayGames++;
            awayTotal += gameValue;
        }
        // It's less than idea but since right now spread is the only bet type we have that requires it to be 'under' for the cover to hit
        // If we implement more bet types that work in a similar way we should look into a better solution here
        if (gameValue === state.lineValue) {
            push++;
        } else if ((gameValue > state.lineValue && betType !== SPREAD_MARKET_NAME) || 
                (betType === SPREAD_MARKET_NAME && gameValue < state.lineValue)) {
            hits++;
        }
    });

    state['hits'] = hits;
    state['push'] = push;
    if (state.historicalGames.length > 0) {
        state['resultAverage'] = decimalPrecision(total / state.historicalGames.length, 1);
    } else {
        state['resultAverage'] = 0;
    }
    if (homeGames > 0) {
        state['resultHomeAverage'] = decimalPrecision(homeTotal / homeGames, 1);
    } else {
        state['resultHomeAverage'] = 0;
    }
    if (awayGames > 0) {
        state['resultAwayAverage'] = decimalPrecision(awayTotal / awayGames, 1);
    } else {
        state['resultAwayAverage'] = 0;
    }
  }

  buildGraphData(games) {
    const betType = this.state.filters[this.state.tab][BET_TYPE_FILTER].selectedValue;
    var trendsGraphData = [];
    games.forEach((game, index) => {
        var gameEntry = {};
        gameEntry["key"] = `${game.opposingTeam} (${String(parseDateFromString(game.timestamp).month).padStart(2, '0')}/${String(parseDateFromString(game.timestamp).day).padStart(2, '0')}/${String(parseDateFromString(game.timestamp).year)})`;
        // Would be cool if we tracked double header on a team-game level but I guess we forgot to? Will use the game ID instead
        // Standard game id is 3 pieces <date>-<away>-<home>, for MLB with double header we have <date>-<away>-<home>-<game number>
        const gameIdParts = game.gameID.split("-");
        if (this.props.activeLeague === "mlb" && gameIdParts.length > 3) {
            const gameNumber = gameIdParts[3];
            gameEntry["key"] += ` (${gameNumber})`;
        }
        gameEntry[`color`] = TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.state.teamCode.toLowerCase()];
        gameEntry[betType] = this.getGameLineValue(game, this.state);
        trendsGraphData.push(gameEntry);
    })
    return trendsGraphData;
  }

  getOutcomeValueFromBook(book, outcome) {
    // Assumption is if there's an outcome entry, at the very least the 'current' should be populated, hence skipping check for presence of 'current'
    if (isDictEmpty(book) || !(outcome in book) || !book[outcome] || !('value' in book[outcome].current) || !book[outcome].current.value) {
        return 0;
    } else {
        return book[outcome].current.value;
    }
  }

  getBetTypeLineValue(state) {
    if (state.tab === "team") {
        var teamPath = "";
        if (state.game.awayTeamData.info.code === state.teamCode) {
            teamPath = "awayTeamData";
        } else {
            teamPath = "homeTeamData";
        }
        const betType = state.filters.team[BET_TYPE_FILTER].selectedValue;
        const betId = TEAM_BET_TYPE_REVERSE_MAPPING[this.props.activeLeague][betType];
        if (!(betId in TEAM_BETS[this.props.activeLeague])) {
            return 0;
        }
        const betDetails = TEAM_BETS[this.props.activeLeague][betId]
        if (betDetails.outcomeType === OUTCOME_TYPE_COVER_YES) {
            return 0.5;
        }
        const outcome = betDetails.outcomeType === OUTCOME_TYPE_OVER_UNDER ? 'over' : 'cover';
        if (betDetails.type === GAME_BET_TYPE && 'markets' in state.game && state.game.markets && betId in state.game.markets) {
            const book = getBookEntryBasedOnPrecedence(state.game.markets[betId].books);
            return this.getOutcomeValueFromBook(book, outcome);
        } else if (betDetails.type === TEAM_BET_TYPE && 'markets' in state.game[teamPath] && state.game[teamPath].markets && betId in state.game[teamPath].markets) {
            const book = getBookEntryBasedOnPrecedence(state.game[teamPath].markets[betId].books);
            return this.getOutcomeValueFromBook(book, outcome);
        } else {
            return 0;
        }
    } else if (state.tab === "player") {
        const propName = state.filters.player[BET_TYPE_FILTER].selectedValue;
        if (!propName || propName == null || propName === "") {
            return 0;
        }
        const propID = this.playerBetTypeReverseMappings[propName];
        // We could go through the leaderboard but that would be much less efficient so we'll re-fetch the top book's line value based on percedence
        const playerRecord = state.players[state.filters.player[PLAYER_FILTER].selectedValue];
        if (!playerRecord || isDictEmpty(playerRecord)) {
            return 0;
        }
        const playerProp = playerRecord.props[propID];
        if (playerProp) {
            const book = getBookEntryBasedOnPrecedence(playerProp.books);
            if (isDictEmpty(book)) {
                return 0;
            } else {
                return book.over.current.value;
            }
        } else {
            return 0;
        }
    }
  }

  // Returns the line-related game value
  getGameLineValue(game, state) {
    const tab = state.tab;
    const betType = this.state.filters[tab][BET_TYPE_FILTER].selectedValue;
    if (tab === "team") {
        const betId = TEAM_BET_TYPE_REVERSE_MAPPING[this.props.activeLeague][betType];
        return TEAM_BETS[this.props.activeLeague][betId].gameValueFunction(game);
    } else if (tab === "player") {
      const propID = this.playerBetTypeReverseMappings[betType];
      const playerRecord = state.players[state.filters.player[PLAYER_FILTER].selectedValue];
      if (!playerRecord) {
          return 0;
      }
      const statFields = getPlayerPropInfo[this.props.activeLeague](propID).fields;
      var value = 0;
      statFields.map((field) => {
          value += getDictionaryValue(game, `cumulativeStats.${field}`);
      })
      return value;
    }

  }

  openFiltersDrawer() {
    this.setState({filtersDrawerOpen: true});
  }

  closeFiltersDrawer() {
    this.setState({filtersDrawerOpen: false});
  }

  filterGames(state) {
    // Start off with all the games
    state['historicalGames'] = state['allGames'];

    // Filter home/away if applicable
    if (state.filters[state.tab][SPLITS_FILTER].selectedValue === "HOME") {
        state['historicalGames'] = state['historicalGames'].filter(game => game.isHome);
    } else if (state.filters[state.tab][SPLITS_FILTER].selectedValue === "AWAY") {
        state['historicalGames'] = state['historicalGames'].filter(game => !game.isHome);
    }

    if (state.tab === "team") {
        if (state.filters.team[BET_TYPE_FILTER].selectedValue !== "Spread") {
            return state;
        }
        if (state.filters.team[SITUATION_FILTER].selectedValue === "FAVORITE") {
            state['historicalGames'] = state['historicalGames'].filter(game => game.odds.spread < 0);
        } else if (state.filters.team[SITUATION_FILTER].selectedValue === "UNDERDOG") {
            state['historicalGames'] = state['historicalGames'].filter(game => game.odds.spread > 0);
        } else {
            state['historicalGames'] = state['historicalGames'];
        }
    }

    return state;
  }

  buildTeamLeaderboard(state) {
    var tempFilters = {}
    // Workaround for it to work with the common library
    tempFilters[GAMES_FILTER] = {selectedValue: [ALL_GAMES_FILTER_OPTION], options: Object.fromEntries(new Map([[ALL_GAMES_FILTER_OPTION, ALL_GAMES_FILTER_OPTION]]))}
    const teamLeaderboardData = buildTeamLeaderboard(state.currentGames, Object.assign(tempFilters, state.leaderboardFilters.team), this.props.userAttributes, this.props.activeLeague)
    return teamLeaderboardData.leaderboard
  }

  buildPlayerLeaderboard(games, state) {
    var tempFilters = {}
    // Workaround for it to work with the common library
    tempFilters[GAMES_FILTER] = {selectedValue: [ALL_GAMES_FILTER_OPTION], options: Object.fromEntries(new Map([[ALL_GAMES_FILTER_OPTION, ALL_GAMES_FILTER_OPTION]]))}
    return buildPlayerBettingLeaderboard(games, Object.assign(tempFilters, state.leaderboardFilters.player), this.props.activeLeague, state.teamPropRankings, this.props.userAttributes).leaderboard
  }

  // This might be broken when there's nothing on current day, only the following day -- to be reviewed
  getDateRangeString(games) {
    if (games.length === 0) {
        return "";
    }

    const firstGameDate = parseDateFromString(games[0].timestamp);
    const lastGameDate = parseDateFromString(games[games.length - 1].timestamp);

    // All the games are on the same day
    if (firstGameDate.formattedDayMonthShort === lastGameDate.formattedDayMonthShort) {
        return firstGameDate.formattedDayMonthShort;   
    }

    // Games are within the same month
    if (firstGameDate.month === lastGameDate.month) {
        return `${firstGameDate.formattedMonthShort} ${firstGameDate.day}-${lastGameDate.day}`;
    }

    // Games span multiple months
    return `${firstGameDate.formattedMonthShort} ${firstGameDate.day} - ${lastGameDate.formattedMonthShort} ${lastGameDate.day}`;
  }

  leaderboardRowClicked(event) {
    // This will prevent the action item click (to expand the row on mobile) to trigger this
    if (event.target.matches(".player-action-column") || event.target.matches(".player-action-column > *")) {
        return;
    }
    const team = event.currentTarget.dataset.team;
    const opponent = event.currentTarget.dataset.opponent;
    const betType = event.currentTarget.dataset.betType;
    const lineValue = event.currentTarget.dataset.lineValue;
    const gameID = event.currentTarget.dataset.gameId;
    var tempState = this.state;
    tempState.teamCode = team;
    tempState.opposingTeamCode = opponent;
    if (!this.gameID) {
        tempState.game = tempState.upcomingGames.filter(game => game.id === gameID)[0];
    }
    // Setting the team on both team and player tabs to keep that on sync
    tempState.filters.team[TEAM_FILTER].selectedValue = TEAM_CODE_TO_CITY[this.props.activeLeague][team];
    tempState.filters.player[TEAM_FILTER].selectedValue = TEAM_CODE_TO_CITY[this.props.activeLeague][team];
    // We're going to recycle the picker internal mechanism to keep track of the current team and the opponent in the case of matchup for the all games page

    // We are re-using the picker mechanism in the all games page for team & player leaderboard
    // but for player individual game it doesn't work so we need to skip setting it in that case and keep the existing one
    if (tempState.tab === "team" || !this.gameID) {
        var pickerOptions = {};
        pickerOptions[team] = TEAM_CODE_TO_CITY[this.props.activeLeague][team];
        pickerOptions[opponent] = TEAM_CODE_TO_CITY[this.props.activeLeague][opponent];
        tempState.pickerOptions = pickerOptions;
    }
    // TODO: review this
    tempState.filters[tempState.tab][TIMEFRAME_FILTER].selectedValue = tempState.leaderboardFilters[tempState.tab][TIMEFRAME_FILTER].selectedValue;
    tempState.filters[tempState.tab][BET_TYPE_FILTER].selectedValue = betType;
    if (tempState.tab === "player") {
        tempState.players = this.buildPlayersDict(tempState.game, tempState['teamCode']);
        tempState.filters.player[PLAYER_FILTER].options = Object.fromEntries(Object.keys(tempState.players).map(x => [x, x]));
        const playerName = event.currentTarget.dataset.player;
        tempState.filters.player[PLAYER_FILTER].selectedValue = playerName.toUpperCase();
        this.setSupportingData(tempState);
        this.setPlayerBetType(tempState, false);
    }

    // Reset the splits to both home and away since that's the one we pre-calculate on the leaderbaord
    tempState.filters[tempState.tab][SPLITS_FILTER].selectedValue = HOME_AWAY_SPLIT;
    tempState.lineValue = parseFloat(lineValue);
    this.lineInputRef.current.value = parseFloat(lineValue);
    this.updateGames(tempState);
    // if (getComputedStyle(this.mobileTrendsRef.current).display !== 'none') {
    //     this.trendsHeaderRef.current.scrollIntoView({behavior: 'smooth', block: 'end'});
    // } else {
    //     this.trendsHeaderRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
    // }
    const yOffset = -175;
    const y = this.trendsHeaderRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  leaderboardRowActionClicked(event) {
    if (this.state.expandedLeaderboardRow === -1) {
        this.setState({expandedLeaderboardRow: parseInt(event.currentTarget.dataset.index)})
    } else {
        this.setState({expandedLeaderboardRow: -1});
    }
  }

  openLegend() {
    document.body.style.overflow = "hidden";
    this.setState({legendOpen: true});
  }

  closeLegend() {
    document.body.style.overflow = "visible";
    this.setState({legendOpen: false});
  }

  leaderboardPageChanged(event) {
    const change = parseInt(event.currentTarget.dataset.change);
    var updatedPage = this.state.leaderboardPage + change;
    // Guarding against getting out of range
    if (updatedPage < 0 || updatedPage >= (this.state.leaderboardData[this.state.tab].length/10)) {
        return;
    }
    this.setState(
        {
            leaderboardPage: updatedPage, 
            expandedLeaderboardRow: -1
        }
    );
  }

  determineOpposingFieldSelection(opposingTeamData) {
    if (this.props.activeLeague !== "mlb") {
        return {fieldSelection: "team", opposingPlayerId: null};
    }
    if (this.state.filters.player[PLAYER_FILTER].selectedValue == null || this.state.filters.player[PLAYER_FILTER].selectedValue === "") {
        return {fieldSelection: "team", opposingPlayerId: null};
    }
    const selectedPlayer = this.state.players[this.state.filters.player[PLAYER_FILTER].selectedValue];
    if (!selectedPlayer || selectedPlayer.info.position === "P" || selectedPlayer.info.position === "SP" || selectedPlayer.info.position === "RP" || !opposingTeamData.startingPitcher) {
        return {fieldSelection: "team", opposingPlayerId: null};
    } else {
        return {fieldSelection: "player", opposingPlayerId: opposingTeamData.startingPitcher.SRGUID};
    }
  }

  leftComparablePageChanged(event) {
    const change = parseInt(event.currentTarget.dataset.change);
    var updatedPage = this.state.leftComparablePage + change;
    // Guarding against getting out of range
    var pages = 1;
    if (this.state.historicalGames) {
        pages = this.state.historicalGames.length / 5;
    }
    if (updatedPage < 0 || updatedPage >= pages) {
        return;
    }
    this.setState({leftComparablePage: updatedPage});
  }

  // Could have merged it with the left but this one can be either team or player so the logic would vary slightly
  rightComparablePageChanged(event) {
    var gamelogList = [];
    if (this.state.opponentFieldSelection === "team") {
        gamelogList = this.state.teamSeasonalGameRecords[this.state.opponentIdentifier];
    } else {
        gamelogList = this.state.playerSeasonalGameRecords[this.state.opponentIdentifier];
    }

    const change = parseInt(event.currentTarget.dataset.change);
    var updatedPage = this.state.rightComparablePage + change;
    // Guarding against getting out of range
    var pages = 1;
    if (gamelogList) {
        pages = gamelogList.length / 5;
    }
    if (updatedPage < 0 || updatedPage >= pages) {
        return;
    }
    this.setState({rightComparablePage: updatedPage});
  }

  render() {
    if (this.gameID && isDictEmpty(this.state.game)) {
        return (<></>)
    }

    const trendsGraphData = this.buildGraphData(this.state.historicalGames);
    const betType = this.state.filters[this.state.tab][BET_TYPE_FILTER].selectedValue;
    const overHits = this.state.hits;
    const underHits = this.state.historicalGames.length - this.state.hits - this.state.push;
    var overPercentage = 0;
    var underPercentage = 0;

    if (this.state.historicalGames.length !== 0) {
        underPercentage = decimalPrecision((this.state.historicalGames.length - this.state.hits - this.state.push)/this.state.historicalGames.length * 100, 1);
        overPercentage = decimalPrecision(this.state.hits/this.state.historicalGames.length * 100, 1);
    }

    var homeTeamInsights = [];
    var awayTeamInsights = [];
    if (this.gameID || this.state.tab === "player") {
        if (!isDictEmpty(this.state.game) && !isDictEmpty(this.state.game.homeTeamData.insights) && !isDictEmpty(this.state.game.awayTeamData.insights)) {
            homeTeamInsights = this.state.game.homeTeamData.insights.bettingSnapshot;
            awayTeamInsights = this.state.game.awayTeamData.insights.bettingSnapshot;
        }
    }

    var filtersToUse = {};
    if (this.state.tab === "team") {
        if (!this.gameID && this.state.filters.team[TEAM_FILTER].selectedValue !== "") {
            filtersToUse[TEAM_FILTER] = this.state.filters.team[TEAM_FILTER];
        }
        if (this.state.filters.team[BET_TYPE_FILTER].selectedValue === "Spread") {
            Object.keys(this.state.filters.team).map((filter) => {
                if (this.state.filters.team[filter].grouped) {
                    filtersToUse[filter] = this.state.filters.team[filter];
                }
            })
        } else {
            // Don't show the situation for non-spread
            filtersToUse[BET_TYPE_FILTER] = this.state.filters.team[BET_TYPE_FILTER];
            filtersToUse[TIMEFRAME_FILTER] = this.state.filters.team[TIMEFRAME_FILTER];
            filtersToUse[SPLITS_FILTER] = this.state.filters.team[SPLITS_FILTER]
        }
    } else if (this.state.tab === "player") {
        if (this.state.filters.player[TEAM_FILTER].selectedValue !== "") {
            filtersToUse[TEAM_FILTER] = this.state.filters.player[TEAM_FILTER];
        }
        if (this.state.filters.player[PLAYER_FILTER].selectedValue !== "") {
            filtersToUse[PLAYER_FILTER] = this.state.filters.player[PLAYER_FILTER];
        }
        Object.keys(this.state.filters.player).map((filter) => {
            if (this.state.filters.player[filter].grouped && this.state.filters.player[filter].selectedValue !== "") {
                filtersToUse[filter] = this.state.filters.player[filter];
            }
        });
    }
    // console.log(filtersToUse)

    var averageStats = {};
    var opponent = this.state.opposingTeamCode;
    if (this.state.teamCode) {
        var pregameAverages = {
            SEASON: 0,
            HOME: 0,
            AWAY: 0,
            MATCHUP: 0,
            LAST_5: 0,
            LAST_10: 0
        };
        if (this.state.tab === "team") {
            var currentTeamData = {};
            if (this.state.game.homeTeamData.info.code === this.state.teamCode) {
                currentTeamData = this.state.game.homeTeamData;
            } else {
                currentTeamData = this.state.game.awayTeamData;
            }
            if (currentTeamData.pregameAverages && !isDictEmpty(currentTeamData.pregameAverages)) {
                if (this.state.filters.team[BET_TYPE_FILTER].selectedValue === "Spread") {
                    pregameAverages = currentTeamData.pregameAverages.SPREAD;
                } else if (this.state.filters.team[BET_TYPE_FILTER].selectedValue === "Game Total") {
                    pregameAverages = currentTeamData.pregameAverages.GAME_TOTAL;
                }
            }
        } else if (this.state.tab === "player") {
            const propID = this.playerBetTypeReverseMappings[this.state.filters.player[BET_TYPE_FILTER].selectedValue];
            const playerRecord = this.state.players[this.state.filters.player[PLAYER_FILTER].selectedValue];
            // Case where we don't have player props for that team's players
            if (!playerRecord || isDictEmpty(playerRecord)) {
                pregameAverages = {
                    SEASON: 0,
                    HOME: 0,
                    AWAY: 0,
                    MATCHUP: 0,
                    LAST_5: 0,
                    LAST_10: 0
                }
            } else {
                const playerProp = playerRecord.props[propID];
                if (!playerProp || isDictEmpty(playerProp) || !playerProp.pregameAverages || isDictEmpty(playerProp.pregameAverages)) {
                    pregameAverages = {
                        SEASON: 0,
                        HOME: 0,
                        AWAY: 0,
                        MATCHUP: 0,
                        LAST_5: 0,
                        LAST_10: 0
                    }
                } else {
                    pregameAverages = playerProp.pregameAverages;
                }
            }
        }
        averageStats['SEASON'] = pregameAverages['SEASON'];
        averageStats['HOME'] = pregameAverages['HOME'];
        averageStats['AWAY'] = pregameAverages['AWAY'];
        averageStats[`VS ${opponent}`] = pregameAverages['MATCHUP'];
        averageStats['LAST 5'] = pregameAverages['LAST_5'];
        averageStats['LAST 10'] = pregameAverages['LAST_10'];
    } 
    const propID = this.playerBetTypeReverseMappings[this.state.filters.player[BET_TYPE_FILTER].selectedValue];
    var supportingStats = {};
    var gamelogSupportingStats = {};
    var opposingSupportingStats = {};
    var supportingStatsRankings = {};
    if (propID) {
        const playerPropInfo = getPlayerPropInfo[this.props.activeLeague](propID, this.state.currentSupportingStatsSituation)
        supportingStats = playerPropInfo.supportingStats;
        gamelogSupportingStats = getGamelogSupportingStats[this.props.activeLeague](playerPropInfo);
        const comparablePlayerPropInfo = getPlayerPropInfo[this.props.activeLeague](propID, this.state.comparableSupportingStatsSituation);
        opposingSupportingStats = comparablePlayerPropInfo.opposingSupportingStats;
        supportingStatsRankings = this.state.supportingStatsRankings[this.state.comparableSupportingStatsSituation][propID];
    }
    const shouldDisplayComparablePicker = Object.keys(this.situations[this.props.activeLeague]).length > 1;
    var betOutcomeType = OUTCOME_TYPE_OVER_UNDER;
    if (this.state.tab === "team") {
        const betType = this.state.filters.team[BET_TYPE_FILTER].selectedValue;
        const betId = TEAM_BET_TYPE_REVERSE_MAPPING[this.props.activeLeague][betType];
        const betDetails = TEAM_BETS[this.props.activeLeague][betId]
        betOutcomeType = betDetails.outcomeType;
    }
    const isCoverOutcome = (betOutcomeType === OUTCOME_TYPE_COVER || betOutcomeType === OUTCOME_TYPE_COVER_YES)
    const userRegionCode = this.context.location.region.code;
    return(
        <>
        <HtmlHeaders canonicalRef={`https://www.linemate.io/${this.props.activeLeague}/props`}/>
        <ScrollableGamesBanner activeLeague={this.props.activeLeague} pagePath={"betting"} currentGame={this.state.game} upcomingGames={this.state.upcomingGames} 
                                showAllGames={true} allGamesSelected={!this.gameID} allGamesDateText={this.getDateRangeString(this.state.currentGames)}/>
        <GamePreviewBanner activeLeague={this.props.activeLeague} game={this.state.game} 
                            type={this.gameID ? "individual" : "all"} allGamesDateText={this.getDateRangeString(this.state.currentGames)}/>
        <div className="content-container betting-container">
            {/* In theory we could split this up in a separate component, passing a 'selectTab' function handler, the current selection, and any suffix */}
            <div className="betting-team-player-toggle">
                <div className="betting-team-player-toggle-left">
                    <div className={this.state.tab === "player" ? "betting-team-player-toggle-left-selected" : ""} data-selection="player" onClick={this.selectTab}>
                        <p className='text-style-label-medium'>Player</p>
                    </div>
                    <div className={this.state.tab === "team" ? "betting-team-player-toggle-left-selected" : ""} data-selection="team" onClick={this.selectTab}>
                        <p className='text-style-label-medium'>Team</p>
                    </div>
                </div>
                <div className="betting-team-player-toggle-right" onClick={this.openLegend}>
                    <p className='text-style-caption-medium'>Legend</p>
                </div>
            </div>
            <div className="betting-content-card">
                <p className="font size-30 weight-700 display-block full-width" style={{marginBottom: '24px'}}>{this.gameID && this.state.tab === "team" ? "Snapshot": "Hit Rates"}</p>
                {/* <p className="font size-14 weight-600 green clickable betting-legend-button mobile" onClick={this.openLegend}>Legend</p> */}
                {
                    this.gameID && this.state.tab === "team"
                    ? 
                    <div className="betting-content-card-snapshot-body">
                        <div className="desktop">
                            <table className="betting-snapshot-intro-table">
                                <colgroup>
                                    <col className="betting-snapshot-title-column"/>
                                    <col className="betting-snapshot-away-column"/>
                                    <col className="betting-snapshot-home-column"/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className="betting-snapshot-title-column"></th>
                                        <th className="betting-snapshot-heading-team-left betting-snapshot-away-column">
                                            <div className="betting-snapshot-title-group">
                                                <p className="font size-20 weight-600">{TEAM_CODE_TO_CITY[this.props.activeLeague][this.awayTeamCode]}</p>
                                                <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamCode)} height={32} width={32} />
                                            </div>
                                        </th>
                                        <th className="betting-snapshot-heading-team-right betting-snapshot-home-column">
                                            <div className="betting-snapshot-title-group">
                                                <p className="font size-20 weight-600">{TEAM_CODE_TO_CITY[this.props.activeLeague][this.homeTeamCode]}</p>
                                                <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamCode)} height={32} width={32} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="betting-snapshot-title-column">
                                            <p className="font size-16 weight-600 spaced">{this.state.game.tags && this.state.game.tags.length > 0 ? "Team Tags" : "Tags"}</p>
                                        </td>
                                        <td className="betting-snapshot-away-column">
                                            {
                                                this.state.game.awayTeamData.tags ? 
                                                this.state.game.awayTeamData.tags.map((tag) =>
                                                    <Tag text={tag} color={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.awayTeamCode.toLowerCase()]} textColor={"#FFFFFF"}/>
                                                )
                                                :
                                                <></>
                                            }
                                        </td>
                                        <td className="betting-snapshot-home-column">
                                            {
                                                this.state.game.homeTeamData.tags ? 
                                                this.state.game.homeTeamData.tags.map((tag) =>
                                                    <Tag text={tag} color={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.homeTeamCode.toLowerCase()]} textColor={"#FFFFFF"}/>
                                                )
                                                :
                                                <></>
                                            }
                                        </td>
                                    </tr>
                                    {
                                        this.state.game.tags && this.state.game.tags.length > 0 ?
                                        <tr>
                                            <td className="betting-snapshot-title-column">
                                                <p className="font size-16 weight-600 spaced">Game Tags</p>
                                            </td>
                                            <td className="betting-snapshot-away-column" colspan={2}>
                                                {
                                                    this.state.game.tags ? 
                                                    this.state.game.tags.map((tag) =>
                                                        <Tag text={tag} color={"#FFFFFF"} textColor={"#374151"} borderColor={"#D1D5DB"}/>
                                                    )
                                                    :
                                                    <></>
                                                }
                                            </td>
                                        </tr>
                                        :
                                        <></>
                                    }
                                </tbody>
                            </table>

                            <table className="betting-snapshot-data-table">
                                <colgroup>
                                    <col className="betting-snapshot-title-column"/>
                                    <col className="betting-snapshot-away-column"/>
                                    <col className="betting-snapshot-home-column"/>
                                </colgroup>
                                <tbody>
                                    {
                                        !isDictEmpty(this.state.game.awayTeamData.matchupGrades) ? 
                                        Object.keys(this.state.game.awayTeamData.matchupGrades).map((category) => 
                                            <tr>
                                                <td className="betting-snapshot-title-column">
                                                    <p className="font size-16 weight-600 spaced">{category}</p>
                                                </td>
                                                <td className="betting-snapshot-away-column">
                                                    <InitialsImage firstLetter={this.state.game.awayTeamData.matchupGrades[category]} textclassName={"size-24 weight-700"} backgroundColor={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.awayTeamCode.toLowerCase()]} />
                                                </td>
                                                <td className="betting-snapshot-home-column">
                                                    <InitialsImage firstLetter={this.state.game.homeTeamData.matchupGrades[category]} textclassName={"size-24 weight-700"} backgroundColor={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.homeTeamCode.toLowerCase()]} />
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <></>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="mobile mobile-betting-snapshot-container">
                            <div className="mobile-betting-snapshot-container-header">
                                <div>
                                    <div className="betting-snapshot-title-group">
                                        <p className="font size-20 weight-600">{this.awayTeamCode}</p>
                                        <img src={getTeamLogoPath(this.props.activeLeague, this.awayTeamCode)} height={24} width={24} />
                                    </div>
                                </div>
                                <div>
                                    <div className="betting-snapshot-title-group">
                                        <p className="font size-20 weight-600">{this.homeTeamCode}</p>
                                        <img src={getTeamLogoPath(this.props.activeLeague, this.homeTeamCode)} height={24} width={24} />
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-betting-snapshot-container-section-heading">
                                <p className="font size-14 weight-600 spaced display-block full-width align-center">{this.state.game.tags && this.state.game.tags.length > 0 ? "Team Tags" : "Tags"}</p>
                            </div>
                            {/* Height auto on this. Keeping the outer div to have the same overall height on both sides */}
                            <div style={{width: '100%', position: 'relative', display: 'flex'}}>
                                <div className="mobile-betting-snapshot-tags-container">
                                    {
                                        this.state.game.awayTeamData.tags ? 
                                        this.state.game.awayTeamData.tags.map((tag) =>
                                            <Tag text={tag} color={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.awayTeamCode.toLowerCase()]} textColor={"#FFFFFF"}/>
                                        )
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="mobile-betting-snapshot-tags-container">
                                    {
                                        this.state.game.homeTeamData.tags ? 
                                        this.state.game.homeTeamData.tags.map((tag) =>
                                            <Tag text={tag} color={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.homeTeamCode.toLowerCase()]} textColor={"#FFFFFF"}/>
                                        )
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            {
                                this.state.game.tags && this.state.game.tags.length > 0 ?
                                <>
                                    <div className="mobile-betting-snapshot-container-section-heading">
                                        <p className="font size-14 weight-600 spaced spaced display-block full-width align-center">Game Tags</p>
                                    </div>
                                    <div className="mobile-betting-snapshot-tags-container" style={{width: '100%', position: 'relative', display: 'inline-block', float: 'none'}}>
                                        {
                                            this.state.game.tags.map((tag) =>
                                                <Tag text={tag} color={"#FFFFFF"} textColor={"#374151"} borderColor={"#D1D5DB"}/>
                                            )
                                        }
                                    </div>
                                </>
                                :
                                <></>
                            }
                            {
                                !isDictEmpty(this.state.game.awayTeamData.matchupGrades) ? 
                                Object.keys(this.state.game.awayTeamData.matchupGrades).map((category) => 
                                    <>
                                        <div className="mobile-betting-snapshot-container-section-heading">
                                            <p className="font size-14 weight-600 spaced spaced display-block full-width align-center">{category}</p>
                                        </div>
                                        {/* Height 80px on this */}
                                        <div style={{height: '80px', width: '50%', position: 'relative', display: 'inline-block'}}>
                                            <InitialsImage firstLetter={this.state.game.awayTeamData.matchupGrades[category]} textclassName={"size-24 weight-700"} backgroundColor={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.awayTeamCode.toLowerCase()]} />
                                        </div>
                                        <div style={{height: '80px', width: '50%', position: 'relative', display: 'inline-block'}}>
                                            <InitialsImage firstLetter={this.state.game.homeTeamData.matchupGrades[category]} textclassName={"size-24 weight-700"} backgroundColor={TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.homeTeamCode.toLowerCase()]} />
                                        </div>
                                    </>
                                )
                                :
                                <></>
                            }
                        </div>
                    </div>
                    :
                    <>
                        <div className="betting-leaderboard-filter-wrapper">
                            {
                                Object.keys(this.state.leaderboardFilters[this.state.tab]).map((filter) => 
                                    <div key={`leaderboard-${this.state.tab}-${filter}`} className='betting-leaderboard-filter-wrapper-filter' style={{'--filters-count': Object.keys(this.state.leaderboardFilters[this.state.tab]).length}}>
                                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                                        <div>
                                            <InputSelection 
                                                enabled={true} 
                                                type="single" 
                                                typography="md" 
                                                options={this.state.leaderboardFilters[this.state.tab][filter].options} 
                                                selection={this.state.leaderboardFilters[this.state.tab][filter].selectedValue} 
                                                selectionHandler={(selection) => this.leaderboardFiltersUpdated({currentTarget: {dataset: {name: filter, selection: selection}}})}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {
                            this.state.leaderboardData[this.state.tab].length === 0 ? <InformationContainer imageSource={"assets/clock-icon.svg"} text={"Pending odds. Stay tuned!"} /> :
                            <>
                                <div className="betting-content-card-leaderboard-wrapper">
                                    {
                                        this.state.tab === "team" ?
                                        <>
                                        <table className="betting-content-card-leaderboard leaderboard-table desktop">
                                            {/* Colgroup with all the columns is needed to make the table overflow work when the screen size is reduced */}
                                            <colgroup>
                                                {/* Team section */}
                                                <col className="rank-column"/>
                                                <col className="team-name-column"/>
                                                <col className="standard-column"/>
                                                <col className="standard-column"/>
                                                <col className="standard-column"/>
                                                <col className="standard-column"/>
                                                <col className="standard-column"/>

                                                {/* Opening section */}
                                                <col className="standard-column"/>
                                                <col className="standard-column"/>

                                                {/* Vegas section */}
                                                <col className="vegas-movement-column"/>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th colspan="7" className="font size-12 spaced">team</th>
                                                    <th colspan="2" className="font size-12 spaced">opening</th>
                                                    <th colspan="1" className="font size-12 spaced">vegas</th>
                                                </tr>
                                                <tr>
                                                    {/* Team section */}
                                                    <th className="font size-12 spaced rank-column">rank</th>
                                                    <th className="font size-12 spaced team-name-column">name</th>
                                                    <th className="font size-12 spaced standard-column">bet type</th>
                                                    <th className="font size-12 spaced standard-column">line</th>
                                                    <th className="font size-12 spaced standard-column">odds</th>
                                                    <th className="font size-12 spaced standard-column">hit rate</th>
                                                    <th className="font size-12 spaced standard-column">
                                                        hit %
                                                        <img src={"assets/order-desc-icon-grey.svg"} height={20} width={20} alt="" style={{marginBottom: '3px'}}/>
                                                    </th>

                                                    {/* Opening section */}
                                                    <th className="font size-12 spaced standard-column">line</th>
                                                    <th className="font size-12 spaced standard-column">odds</th>

                                                    {/* Vegas section */}
                                                    <th className="font size-12 spaced vegas-movement-column">line movement</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.leaderboardData[this.state.tab].slice(10 * this.state.leaderboardPage, 10 * (this.state.leaderboardPage + 1)).map((entry, index) =>
                                                        <tr className="clickable" data-team={entry.team} data-opponent={entry.opponent} data-bet-type={entry[BET_TYPE_FILTER]} 
                                                                            data-line-value={entry.line} data-game-id={entry.gameID}
                                                                            onClick={this.leaderboardRowClicked}>
                                                            {/* Team section */}
                                                            <td className="font size-14 rank-column">{index + 1 + (10 * this.state.leaderboardPage)}</td>
                                                            <td className="font size-14 spaced team-name-column">
                                                                {
                                                                    entry.isCombined ? 
                                                                    // Game-level markets
                                                                    <>
                                                                        <img src={getTeamLogoPath(this.props.activeLeague, entry.awayTeam)} height={32} width={32} alt={entry.awayTeam} style={{padding: '2px 0px'}}/>
                                                                        <p>{entry.awayTeam}</p>
                                                                        &nbsp;@&nbsp;
                                                                        <img src={getTeamLogoPath(this.props.activeLeague, entry.homeTeam)} height={32} width={32} alt={entry.homeTeam} style={{padding: '2px 0px'}}/>
                                                                        <p>{entry.homeTeam}</p>
                                                                    </>
                                                                    :
                                                                    // Team-level markets
                                                                    <>
                                                                        <img src={getTeamLogoPath(this.props.activeLeague, entry.team)} height={32} width={32} alt={entry.team} style={{padding: '2px 0px'}}/>
                                                                        <p>{entry.team}</p>
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className="font size-14 standard-column">{entry[BET_TYPE_FILTER]}</td>
                                                            <td className="font size-14 standard-column">{entry.formattedLine}</td>
                                                            <td className="font size-14 standard-column">{entry.odds}</td>
                                                            <td className="font size-14 standard-column">{`${entry.hits}/${entry.games}`}</td>
                                                            <td className="font size-14 standard-column hit-percentage-column-highlighted">{decimalPrecision(entry.hitPercentage, 1)}</td>

                                                            {/* Opponent section */}
                                                            <td className="font size-14 standard-column">{entry.openingFormattedLine}</td>
                                                            <td className="font size-14 standard-column">{entry.openingOdds}</td>

                                                            {/* Vegas section */}
                                                            <td className="font size-14 vegas-movement-column">{entry.lineMovement}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <table className="betting-content-card-leaderboard leaderboard-table mobile">
                                            <colgroup>
                                                <col className="rank-column"/>
                                                <col className="team-name-column"/>
                                                <col className="line-column"/>
                                                <col className="hit-percentage-column"/>
                                                <col className="player-action-column" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th className="font size-12 spaced rank-column">#</th>
                                                    <th className="font size-12 spaced team-name-column">name</th>
                                                    <th className="font size-12 spaced line-column">line</th>
                                                    <th className="font size-12 spaced hit-percentage-column">
                                                        hit %
                                                        <img src={"assets/order-desc-icon-grey.svg"} height={20} width={20} alt="" style={{marginBottom: '3px'}}/>
                                                    </th>
                                                    <th className="player-action-column"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.leaderboardData[this.state.tab].slice(10 * this.state.leaderboardPage, 10 * (this.state.leaderboardPage + 1)).map((entry, index) =>
                                                        <>
                                                            <tr className="clickable" data-team={entry.team} data-opponent={entry.opponent} data-bet-type={entry[BET_TYPE_FILTER]} 
                                                                                data-line-value={entry.line} data-game-id={entry.gameID}
                                                                                onClick={this.leaderboardRowClicked}>
                                                                <td className="font size-12 rank-column">{index + 1 + (10 * this.state.leaderboardPage)}</td>
                                                                <td className="font size-12 spaced team-name-column">
                                                                {
                                                                    entry.isCombined ? 
                                                                    // Game-level markets
                                                                    <>
                                                                        <img src={getTeamLogoPath(this.props.activeLeague, entry.awayTeam)} height={24} width={24} alt={entry.awayTeam} style={{padding: '2px 0px'}}/>
                                                                        <p>{entry.awayTeam}</p>
                                                                        &nbsp;@&nbsp;
                                                                        <img src={getTeamLogoPath(this.props.activeLeague, entry.homeTeam)} height={24} width={24} alt={entry.homeTeam} style={{padding: '2px 0px'}}/>
                                                                        <p>{entry.homeTeam}</p>
                                                                    </>
                                                                    :
                                                                    // Team-level markets
                                                                    <>
                                                                        <img src={getTeamLogoPath(this.props.activeLeague, entry.team)} height={24} width={24} alt={entry.team} style={{padding: '2px 0px'}}/>
                                                                        <p>{entry.team}</p>
                                                                    </>
                                                                }
                                                                </td>
                                                                <td className="font size-12 line-column">{entry.formattedLine}</td>
                                                                <td className="font size-12 hit-percentage-column-highlighted">{decimalPrecision(entry.hitPercentage, 1)}</td>
                                                                <td className="player-action-column" onClick={this.leaderboardRowActionClicked} data-index={index}>
                                                                    <img src={`assets/${this.state.expandedLeaderboardRow === index ? "dropdown-state-closed-grey" : "trailing-icon-grey"}.svg`} height={20} width={20} alt="action"/>
                                                                </td>
                                                            </tr>
                                                            {
                                                                this.state.expandedLeaderboardRow !== index ? <></> :
                                                                <>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Bet Type</p>
                                                                            <p className="font size-12">{entry[BET_TYPE_FILTER]}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Odds</p>
                                                                            <p className="font size-12">{entry.odds}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Hit Rate</p>
                                                                            <p className="font size-12">{`${entry.hits}/${entry.games}`}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Opening Line</p>
                                                                            <p className="font size-12">{entry.openingFormattedLine}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Opening Odds</p>
                                                                            <p className="font size-12">{entry.openingOdds}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Vegas Movement</p>
                                                                            <p className="font size-12">{entry.lineMovement}</p>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        </>
                                        :
                                        <>
                                        <table className="betting-content-card-leaderboard leaderboard-table desktop">
                                            {/* Colgroup with all the columns is needed to make the table overflow work when the screen size is reduced */}
                                            <colgroup>
                                                {/* Team section */}
                                                <col className="player-rank-column"/>
                                                <col className="player-name-column"/>
                                                <col className="position-column"/>
                                                <col className="player-bet-type-column"/>
                                                <col className="player-line-column"/>
                                                <col className="player-odds-column"/>
                                                <col className="player-hit-rate-column"/>
                                                <col className="player-hit-percentage-column"/>

                                                {/* Opponent section */}
                                                <col className="player-opponent-team-name-column"/>
                                                <col className="player-opponent-rank"/>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th colspan="8" className="font size-12 spaced">player</th>
                                                    <th colspan="2" className="font size-12 spaced">opponent</th>
                                                </tr>
                                                <tr>
                                                    {/* Player section */}
                                                    <th className="font size-12 spaced player-rank-column">rank</th>
                                                    <th className="font size-12 spaced player-name-column">name</th>
                                                    <th className="font size-12 spaced position-column">position</th>
                                                    <th className="font size-12 spaced player-bet-type-column">bet type</th>
                                                    <th className="font size-12 spaced player-line-column">line</th>
                                                    <th className="font size-12 spaced player-odds-column">odds</th>
                                                    <th className="font size-12 spaced player-hit-rate-column">hit rate</th>
                                                    <th className="font size-12 spaced player-hit-percentage-column">
                                                        hit %
                                                        <img src={"assets/order-desc-icon-grey.svg"} height={20} width={20} alt="" style={{marginBottom: '3px'}}/>
                                                    </th>

                                                    {/* Opponent section */}
                                                    <th className="font size-12 spaced player-opponent-team-name-column">name</th>
                                                    <th className="font size-12 spaced player-opponent-rank">def rank</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.leaderboardData[this.state.tab].slice(10 * this.state.leaderboardPage, 10 * (this.state.leaderboardPage + 1)).map((entry, index) =>
                                                        <tr className="clickable" data-team={entry.team} data-opponent={entry.opponent} data-bet-type={entry[BET_TYPE_FILTER]} 
                                                                            data-line-value={entry.line} data-game-id={entry.gameID} data-player={entry.playerName}
                                                                            onClick={this.leaderboardRowClicked}>
                                                            {/* Team section */}
                                                            <td className="font size-14 player-rank-column">{index + 1 + (10 * this.state.leaderboardPage)}</td>
                                                            <td className="font size-14 player-name-column">
                                                                <img src={getTeamLogoPath(this.props.activeLeague, entry.team)} height={32} width={32} alt={entry.team} style={{padding: '2px 0px'}}/>
                                                                <p>{entry.playerName}</p>
                                                            </td>
                                                            <td className="font size-14 position-column">{entry.position}</td>
                                                            <td className="font size-14 player-bet-type-column">{entry[BET_TYPE_FILTER]}</td>
                                                            <td className="font size-14 player-line-column">{entry.formattedLine}</td>
                                                            <td className="font size-14 player-odds-column">{entry.odds}</td>
                                                            <td className="font size-14 player-hit-rate-column">{`${entry.hits}/${entry.games}`}</td>
                                                            <td className="font size-14 hit-percentage-column-highlighted">{decimalPrecision(entry.hitPercentage, 1)}</td>

                                                            {/* Opponent section */}
                                                            <td className="font size-14 player-opponent-team-name-column">
                                                                <p className="size-12">{entry.home ? "VS " : "@ "}</p>
                                                                <p>{entry.opponent}</p>
                                                            </td>
                                                            <td className="font size-14 player-opponent-rank">{entry.opponentRank}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <table className="betting-content-card-leaderboard leaderboard-table mobile">
                                            <colgroup>
                                                <col className="player-rank-column"/>
                                                <col className="player-name-column"/>
                                                <col className="player-line-column"/>
                                                <col className="player-hit-percentage-column"/>
                                                <col className="player-action-column" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th className="font size-12 spaced player-rank-column">#</th>
                                                    <th className="font size-12 spaced player-name-column">name</th>
                                                    <th className="font size-12 spaced player-line-column">line</th>
                                                    <th className="font size-12 spaced player-hit-percentage-column">
                                                        hit %
                                                        <img src={"assets/order-desc-icon-grey.svg"} height={20} width={20} alt="" style={{marginBottom: '3px'}}/>
                                                    </th>
                                                    <th className="player-action-column"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.leaderboardData[this.state.tab].slice(10 * this.state.leaderboardPage, 10 * (this.state.leaderboardPage + 1)).map((entry, index) =>
                                                        <>
                                                            <tr className="clickable" data-team={entry.team} data-opponent={entry.opponent} data-bet-type={entry[BET_TYPE_FILTER]} 
                                                                                data-line-value={entry.line} data-game-id={entry.gameID} data-player={entry.playerName}
                                                                                onClick={this.leaderboardRowClicked}>
                                                                <td className="font size-12 player-rank-column">{index + 1 + (10 * this.state.leaderboardPage)}</td>
                                                                <td className="font size-12 player-name-column">
                                                                    <img src={getTeamLogoPath(this.props.activeLeague, entry.team)} height={24} width={24} alt={entry.team} style={{padding: '2px 0px'}}/>
                                                                    <p>{entry.playerName}</p>
                                                                </td>
                                                                <td className="font size-12 player-line-column">{entry.formattedLine}</td>
                                                                <td className="font size-12 hit-percentage-column-highlighted">{decimalPrecision(entry.hitPercentage, 1)}</td>
                                                                <td className="player-action-column" onClick={this.leaderboardRowActionClicked} data-index={index}>
                                                                    <img src={`assets/${this.state.expandedLeaderboardRow === index ? "dropdown-state-closed-grey" : "trailing-icon-grey"}.svg`} height={20} width={20} alt="action"/>
                                                                </td>
                                                            </tr>
                                                            {
                                                                this.state.expandedLeaderboardRow !== index ? <></> :
                                                                <>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Bet Type</p>
                                                                            <p className="font size-12">{entry[BET_TYPE_FILTER]}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Odds</p>
                                                                            <p className="font size-12">{entry.odds}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Hit Rate</p>
                                                                            <p className="font size-12">{`${entry.hits}/${entry.games}`}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Position</p>
                                                                            <p className="font size-12">{entry.position}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Opponent Name</p>
                                                                            <p className="font size-12">
                                                                                <p className="">{entry.home ? "VS " : "@ "}</p>
                                                                                <p>{entry.opponent}</p>
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="betting-leaderboard-expanded-section-row">
                                                                        <td colspan="5">
                                                                            <p className="font size-12">Opponent Defensive Rank</p>
                                                                            <p className="font size-12">{entry.opponentRank}</p>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        </>
                                    }
                                </div>
                                <div className="betting-leaderbaord-pagination-row">
                                    <div className="betting-leaderbaord-pagination-buttons-container">
                                        <div className='betting-leaderboard-pagination-button'>
                                            <Button icon='assets/chevron-left.svg' type='secondary' enabled={this.state.leaderboardPage > 0} onClick={() => this.leaderboardPageChanged({currentTarget: {dataset: {change: -1}}})}/>
                                        </div>
                                        <div className='betting-leaderboard-pagination-button'>
                                            <Button icon='assets/chevron-right.svg' type='secondary' enabled={this.state.leaderboardPage < ((this.state.leaderboardData[this.state.tab].length/10) - 1)} onClick={() => this.leaderboardPageChanged({currentTarget: {dataset: {change: +1}}})}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
                {
                    !this.gameID || this.state.tab === "player" ? <></> :            
                    <div className="betting-content-insights">
                        <img src="assets/insights-icon.svg" alt="insights" />
                        <p className="font size-16 weight-600" style={{marginBottom: '16px'}}>Insights</p>
                        <img src={getTeamLogoPath(this.props.activeLeague, this.state.game.awayTeamData.info.code)} style={{height: '32px', width: '32px', marginRight: '4px'}} alt=""/>
                        <p className="font size-16 weight-600">{getTeamCityDisplayName(this.props.activeLeague, this.state.game.awayTeamData.info)}</p>
                        <ol className="font size-14 weight-400 betting-content-insights-away">
                            {
                                awayTeamInsights.map((insight) =>
                                    <li>{insight}</li>
                                )
                            }
                        </ol>

                        <img src={getTeamLogoPath(this.props.activeLeague, this.state.game.homeTeamData.info.code)} style={{height: '32px', width: '32px', marginRight: '4px'}} alt=""/>
                        <p className="font size-16 weight-600" style={{display: 'inline'}}>{getTeamCityDisplayName(this.props, this.state.game.homeTeamData.info)}</p>
                        <ol className="font size-14 weight-400 betting-content-insights-home">
                            {
                                homeTeamInsights.map((insight) =>
                                    <li>{insight}</li>
                                )
                            }
                        </ol>
                    </div>
                }
                {
                    this.state.tab === "player" && this.state.leaderboardData.player.lengt === 0 ? <></> :
                    <>
                    <div ref={this.trendsHeaderRef} className="betting-content-card-trends-header">
                        <p className="font size-30 weight-700 display-block full-width">Trends</p>
                        <div className="betting-content-card-filters-wrapper desktop">
                            {
                                Object.keys(filtersToUse).map((filter) => 
                                    <div key={filter} className='betting-content-card-filters-wrapper-filter' style={{'--filters-count': Object.keys(filtersToUse).length}}>
                                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                                        <div>
                                            <InputSelection 
                                                enabled={true} 
                                                type="single" 
                                                typography="md" 
                                                options={filtersToUse[filter].options} 
                                                selection={filtersToUse[filter].selectedValue} 
                                                selectionHandler={(selection) => this.filtersUpdated({currentTarget: {dataset: {name: filter, selection: selection}}})}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {
                            this.state.tab === "team" ?
                            <div className="betting-content-card-picker-wrapper desktop" style={this.gameID ? {} : {top: '68px'}}>
                                {
                                    this.gameID ?
                                    <Picker options={this.state.pickerOptions} selection={this.state.teamCode} selectionHandlerFn={this.pickerSelected} fullWidthThreshold={990}/>:
                                    <>
                                    </>
                                    // <Filters filters={_.pick(this.state.filters.team, TEAM_FILTER)} addClickableElementHandler={() => {}} selectionHandler={this.filtersUpdated} />
                                }   
                            </div>
                            :
                            // Player tab
                            <>
                                {
                                    this.gameID ?
                                    <div className="betting-player-tab-team-picker-wrapper desktop">
                                        <Picker options={this.state.pickerOptions} selection={this.state.teamCode} selectionHandlerFn={this.pickerSelected} fullWidthThreshold={990}/>
                                    </div>
                                    :
                                    <div className="betting-player-tab-team-picker-wrapper betting-player-filter-wrapper desktop">
                                        {/* <Filters filters={_.pick(this.state.filters.player, TEAM_FILTER)} addClickableElementHandler={() => {}} selectionHandler={this.filtersUpdated} /> */}
                                    </div>
                                }  
                                <div className="betting-content-card-picker-wrapper betting-player-filter-wrapper desktop">
                                    {/* <Filters filters={_.pick(this.state.filters.player, PLAYER_FILTER)} addClickableElementHandler={() => {}} selectionHandler={this.filtersUpdated} /> */}
                                </div>
                            </>
                        }
                        {/* <div className="betting-content-card-filters-button clickable mobile" onClick={this.openFiltersDrawer}>
                            <div>
                                <img src="assets/playbook/icon-filter.svg" width="14" height="14" alt=""/> 
                                <p className="font size-14">Filters</p>
                            </div>
                        </div> */}
                        {
                            !isDictEmpty(this.state.players) && this.state.tab === "player" ?
                            <div className="betting-content-card-trend-averages">
                                <p className="font size-18 weight-600 display-block">Season Stats</p>
                                {/* Current player */}
                                <div className="betting-content-supporting-stats-table-container">
                                    <div className="betting-content-supporting-stats-top-table-header">
                                        <img src={getTeamLogoPath(this.props.activeLeague, this.state.teamCode)} height={32} width={32} />
                                        <p className="font size-20 weight-600">{getPlayerTitle(this.state.players[this.state.filters.player[PLAYER_FILTER].selectedValue], this.props.activeLeague, true, true)}</p>
                                    </div>
                                    {
                                        !shouldDisplayComparablePicker ? <></> :
                                        <div className="betting-content-supporting-stats-picker-container">
                                            <Picker options={this.situations[this.props.activeLeague]} selection={this.state.currentSupportingStatsSituation} selectionHandlerFn={this.currentSituationalPickerSelected} fullWidth={true}/>
                                        </div>
                                    }
                                    <div className="betting-content-supporting-stats-top-table-column-headers">
                                        {
                                            Object.keys(supportingStats).map((stat) =>
                                                <div>
                                                    <p className="font size-12 weight-600 spaced display-block full-width align-center">{stat}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="betting-content-supporting-stats-top-table-column-data">
                                        {
                                            Object.keys(supportingStats).map((stat) =>
                                                <div>
                                                    <p className="font size-16 display-block full-width align-center">{getDictionaryValue(this.state.playerRecords[getPlayerId(this.state.players[this.state.filters.player[PLAYER_FILTER].selectedValue].info)], supportingStats[stat])}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                {/* Opposing comparable */}
                                <div className="betting-content-supporting-stats-table-container">
                                    <div className="betting-content-supporting-stats-top-table-header">
                                        <img src={getTeamLogoPath(this.props.activeLeague, opponent)} height={32} width={32} />
                                        {
                                            this.state.opponentFieldSelection === "team" || !this.state.playerRecords[this.state.opponentIdentifier] ? 
                                            <p className="font size-20 weight-600">{opponent}</p> :
                                            <p className="font size-20 weight-600">{getPlayerTitle(this.state.playerRecords[this.state.opponentIdentifier], this.props.activeLeague, true, true)}</p>
                                        }
                                    </div>
                                    {
                                        !shouldDisplayComparablePicker ? <></> :
                                        <div className="betting-content-supporting-stats-picker-container">
                                            <Picker options={this.situations[this.props.activeLeague]} selection={this.state.comparableSupportingStatsSituation} selectionHandlerFn={this.comparableSituationalPickerSelected} fullWidth={true}/>
                                        </div>
                                    }
                                    <div className="betting-content-supporting-stats-top-table-column-headers">
                                        {
                                            Object.keys(opposingSupportingStats).map((stat) =>
                                                <div>
                                                    <p className="font size-12 weight-600 spaced display-block full-width align-center">{stat}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {/* Here we can leave opposingSupportingStats but we need to set the right value above in the render before returning the JSX */}
                                    {/* Also, to get the supporting stats we need to get player prop info method but also updating the situation */}
                                    <div className="betting-content-supporting-stats-top-table-column-data">
                                        {
                                            Object.keys(opposingSupportingStats).map((stat) =>
                                                <div>
                                                    <p className="font size-16 display-block full-width align-center">
                                                        {
                                                            this.state.opponentFieldSelection === "team" || !this.state.playerRecords[this.state.opponentIdentifier] ? 
                                                            <>
                                                            {
                                                                opposingSupportingStats[stat].ranked ?
                                                                addOrdinalNumberSuffix(supportingStatsRankings[stat][opponent]) :
                                                                decimalPrecision(supportingStatsRankings[stat][opponent], 1)
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                            {
                                                                decimalPrecision(getMultiFieldValue(this.state.playerRecords[this.state.opponentIdentifier], opposingSupportingStats[stat].playerFields), 1)
                                                            }
                                                            </>
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>  
                    <div className="betting-content-card-trends-body">
                        <div ref={this.mobileTrendsRef} className="betting-content-card-trends-body-info-wrapper mobile">
                            {
                                this.state.tab === "team" ? 
                                <div className="betting-content-card-flex-img-left">
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.state.teamCode)} height={24} width={24} />
                                    <p className="font size-18 weight-600">Hit Rate</p>
                                </div>
                                :
                                <div className="betting-content-card-flex-img-left">
                                    <img src={getTeamLogoPath(this.props.activeLeague, this.state.teamCode)} height={24} width={24} />
                                    <p className="font size-18 weight-600">{`${getPlayerTitle(this.state.players[this.state.filters.player[PLAYER_FILTER].selectedValue], this.props.activeLeague, false, false)} - Hit Rate`}</p>
                                </div>
                            }
                            {/* <p className="font size-18 weight-600 display-block">Hit Rate</p> */}
                            <div className="betting-content-card-trends-body-info-hit-rate" style={isCoverOutcome ? {width: '100%'} : {width: '50%'}}>
                                <p className="font size-14 display-block full-width align-center">{isCoverOutcome ? "Covered" : "Under"}</p>
                                <div className="betting-content-card-trends-body-info-hit-rate-text-fields" style={isCoverOutcome ? {left: '50%', transform: 'translateX(-50%'} : {}}>
                                    <p className="font size-20 weight-600">{isCoverOutcome ? `${overPercentage}%` : `${underPercentage}%`}</p>
                                    <div className="betting-content-card-trends-body-info-hit-rate-fractional">
                                        <p className="font size-16 color-placeholder">{`${isCoverOutcome ? overHits : underHits}/${this.state.historicalGames.length}`}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Optional 'over' value not applicable to spread */}
                            <div className="betting-content-card-trends-body-info-hit-rate-over" style={isCoverOutcome ? {display: 'none'} : {display: 'inline-block'}}>
                                <p className="font size-14 display-block full-width align-center">Over</p>
                                <div className="betting-content-card-trends-body-info-hit-rate-text-fields">
                                    <p className="font size-20 weight-600">{`${overPercentage}%`}</p>
                                    <div className="betting-content-card-trends-body-info-hit-rate-fractional">
                                        <p className="font size-16 color-placeholder">{`${overHits}/${this.state.historicalGames.length}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="betting-content-card-trends-body-info-line">
                                <p className="font size-18 weight-600 display-block full-width align-center">Line</p>
                                <div className="betting-content-card-trends-body-info-line-selection">
                                    {/* <div className="betting-content-card-trends-body-info-line-selection-text">
                                        <p className="font size-16 weight-600 spaced color-placeholder">{betType}</p>
                                    </div> */}
                                    <div className="betting-content-card-trends-body-info-line-selection-input">
                                        {/* Leaving the two images next to each other since they are absolute to allow for the div > nth child to work */}
                                        <img className="clickable" src="assets/input-minus-icon.svg" alt="" data-change={-0.5} onClick={this.updateLine}/>
                                        <img className="clickable" src="assets/input-plus-icon.svg" alt="" data-change={0.5} onClick={this.updateLine}/>
                                        {/* <p className="font size-16 weight-400">{this.state.lineValue}</p> */}
                                        <input type="number" ref={this.lineInputRef} value={this.state.lineValue} onChange={this.lineInputChanged}/>
                                    </div>
                                </div>
                            </div>
                            {/* Divider */}
                            {/* <div style={{width: '100%', height: '1px', background: '#D1D5DB', borderRadius: '2px'}}></div>
                            <p className="font size-18 weight-600 display-block" style={{marginTop: '12px'}}>Average</p>
                            <div className="betting-content-card-trends-body-info-average">
                                <p className="font size-14 display-block">Overall</p>
                                <p className="font size-20 weight-400">{this.state.resultAverage}</p>
                            </div>
                            <div className="betting-content-card-trends-body-info-home-average">
                                <p className="font size-14 display-block">Home</p>
                                <p className="font size-20 weight-400">{this.state.resultHomeAverage}</p>
                            </div>
                            <div className="betting-content-card-trends-body-info-away-average">
                                <p className="font size-14 display-block">Away</p>
                                <p className="font size-20 weight-400">{this.state.resultAwayAverage}</p>
                            </div> */}
                        </div>
                        <div ref={this.graphWrapperRef} className="betting-content-card-trends-body-graph-wrapper">
                            {
                                this.state.historicalGames.length > 0 ?
                                <ResponsiveBar
                                    theme={RESPONSIVE_BAR_THEME}
                                    // A bit of a hack to make it re-draw the graph to avoid some ghosting effect in MLB
                                    key={this.state.tab + JSON.stringify(this.state.filters[this.state.tab])}
                                    data={trendsGraphData}
                                    keys={[this.state.filters[this.state.tab][BET_TYPE_FILTER].selectedValue]}
                                    indexBy="key"
                                    margin={{ top: 60, right: 37.5, bottom: 25, left: 50 }}
                                    padding={0.35}
                                    innerPadding={10}
                                    groupMode="grouped"
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    valueFormat={{ format: '', enabled: false }}
                                    colors={({ id, data }) => String(data[`color`])}
                                    defs={[]}
                                    fill={[]}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: -40
                                    }}
                                    enableLabel={false}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'top-right',
                                            direction: 'row',
                                            justify: false,
                                            translateX: 30,
                                            translateY: -50,
                                            itemsSpacing: 4,
                                            itemWidth: 100,
                                            itemHeight: 32,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 12,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                    markers={[
                                        {
                                            axis: 'y',
                                            value: this.state.lineValue,
                                            lineStyle: { stroke: 'var(--color-opposite-border-default)', strokeWidth: 2 }
                                        },
                                    ]}
                                    motionConfig={{ mass: 1, tension: 170, friction: 26, clamp: false, precision: 0.01, velocity: 0 }}
                                    />
                                    :
                                    <NoResultsFrame text={"There is no result within the search criteria."} />
                            }
                        </div>
                        <div className="betting-content-card-trends-body-info-def-rank mobile" style={this.state.tab === "team" ? {display: 'none'} : {}}>
                            <div className="betting-content-card-flex-img-left">
                                <p className="font size-18 weight-600">Average</p>
                            </div>
                            <p className="font size-14 display-block full-width align-center" style={{marginTop: '16px'}}>{this.state.filters.player[BET_TYPE_FILTER].selectedValue}</p>
                            {
                                <p className="font size-24 weight-600 display-block full-width align-center" style={{marginTop: '12px'}}>{this.state.resultAverage}</p>
                            }
                        </div>
                        <div className='betting-content-card-trends-body-wrapper'>
                            <div className={`betting-content-card-trends-body-info-wrapper desktop ${this.state.tab === "team" ? " centered " : ""}`}
                                // style={isCoverOutcome ? {width: '560px'} : {width: '848px'}}
                            >
                                <div style={isCoverOutcome ? {width: '50%', height: '100%'} : {width: '66%', height: '100%'}}>
                                    {
                                        this.state.tab === "team" ? 
                                        <div className="betting-content-card-flex-img-left">
                                            <img src={getTeamLogoPath(this.props.activeLeague, this.state.teamCode)} height={24} width={24} />
                                            <p className="font size-18 weight-600">Hit Rate</p>
                                        </div>
                                        :
                                        <div className="betting-content-card-flex-img-left">
                                            <img src={getTeamLogoPath(this.props.activeLeague, this.state.teamCode)} height={24} width={24} />
                                            <p className="font size-18 weight-600">{`${getPlayerTitle(this.state.players[this.state.filters.player[PLAYER_FILTER].selectedValue], this.props.activeLeague, false, false)} - Hit Rate`}</p>
                                        </div>
                                    }
                                    <div className="betting-content-card-trends-body-info-hit-rate" style={isCoverOutcome ? {width: '100%'} : {width: '50%'}}>
                                        <p className="font size-14 display-block full-width align-center">{isCoverOutcome ? "Covered" : "Under"}</p>
                                        <div className="betting-content-card-trends-body-info-hit-rate-text-fields">
                                            <p className="font size-24 weight-600">{isCoverOutcome ? `${overPercentage}%` : `${underPercentage}%`}</p>
                                            <div className="betting-content-card-trends-body-info-hit-rate-fractional">
                                                <p className="font size-18 color-placeholder">{`${isCoverOutcome ? overHits : underHits}/${this.state.historicalGames.length}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Optional 'over' value not applicable to spread */}
                                    <div className="betting-content-card-trends-body-info-hit-rate-over" style={isCoverOutcome ? {display: 'none'} : {display: 'inline-block', width: '50%'}}>
                                        <p className="font size-14 display-block full-width align-center">Over</p>
                                        <div className="betting-content-card-trends-body-info-hit-rate-text-fields">
                                            <p className="font size-24 weight-600">{`${overPercentage}%`}</p>
                                            <div className="betting-content-card-trends-body-info-hit-rate-fractional">
                                                <p className="font size-18 color-placeholder">{`${overHits}/${this.state.historicalGames.length}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="betting-content-card-trends-body-info-line" style={isCoverOutcome ? {width: '50%'} : {width: '33%'}}>
                                    <p className="font size-18 weight-600 display-block full-width align-center">Line</p>
                                    <div className="betting-content-card-trends-body-info-line-selection">
                                        <div className="betting-content-card-trends-body-info-line-selection-input">
                                            {/* Leaving the two images next to each other since they are absolute to allow for the div > nth child to work */}
                                            <img className="clickable" src="assets/input-minus-icon.svg" alt="" data-change={-0.5} onClick={this.updateLine}/>
                                            <img className="clickable" src="assets/input-plus-icon.svg" alt="" data-change={0.5} onClick={this.updateLine}/>
                                            <input type="number" ref={this.lineInputRef} value={this.state.lineValue} onChange={this.lineInputChanged}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="betting-content-card-trends-body-info-def-rank desktop" style={this.state.tab === "team" ? {display: 'none'} : {}}>
                                <div className="betting-content-card-flex-img-left">
                                    <p className="font size-18 weight-600">Average</p>
                                </div>
                                <p className="font size-14 display-block full-width align-center" style={{marginTop: '16px'}}>{this.state.filters.player[BET_TYPE_FILTER].selectedValue}</p>
                                {
                                    <p className="font size-24 weight-600 display-block full-width align-center" style={{marginTop: '12px'}}>{this.state.resultAverage}</p>
                                }
                            </div>
                        </div>
                    </div>
                    </>
                }
                {
                    this.state.tab !== "player" || isDictEmpty(this.state.players) || !this.state.historicalGames || this.state.historicalGames.length == 0 || this.state.leaderboardData[this.state.tab].length === 0 ? <></> :
                    <>
                        {/* Current player */}
                        <div className="betting-content-supporting-stats-table-container" style={{width: '100%'}}>
                            <div className="betting-content-supporting-stats-header" style={{marginTop: '24px'}}>
                                <p className="font size-24 weight-600 full-width display-block">Gamelog</p>
                            </div>
                            <div className="betting-content-supporting-stats-gamelog">
                                <table className="bordered-table">
                                    <thead>
                                        <tr>
                                            <th className="font size-12 spaced">date</th>
                                            <th className="font size-12 spaced">opponent</th>
                                            <th className="font size-12 spaced">result</th>
                                            {
                                                Object.keys(gamelogSupportingStats).map((stat) =>
                                                    <th className="font size-12 spaced">{stat}</th>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // Slicing first (creating a shallow copy) to avoid reversing the original data otherwise everytime the page on the gamelog is changed it will swap the data on the graph as well
                                            // Ideally we should do this only once when we fetch the data
                                            this.state.historicalGames.slice().reverse().slice(5 * this.state.leftComparablePage, 5 * (this.state.leftComparablePage + 1)).map((gameRecord) => 
                                                <tr>
                                                    <td className="font size-14">
                                                    {
                                                        String(parseDateFromString(gameRecord.timestamp).month).padStart(2, '0') + "/" + 
                                                        String(parseDateFromString(gameRecord.timestamp).day).padStart(2, '0')
                                                    }    
                                                    </td>
                                                    <td>
                                                        <img src={getTeamLogoPath(this.props.activeLeague, gameRecord.opposingTeam)} onError={({currentTarget}) => teamLogoPathOnErrorFallback(currentTarget, this.props.activeLeague)} height="32" width="32" alt={gameRecord.opposingTeam} />
                                                        &nbsp;&nbsp;
                                                        <p className="font size-14 spaced">{gameRecord.opposingTeam.toUpperCase()}</p>
                                                    </td>
                                                    <td className="font size-14">
                                                        {getGameResultText(this.props.activeLeague, gameRecord)}
                                                    </td>
                                                    {
                                                        Object.keys(gamelogSupportingStats).map((stat) =>
                                                            <td className="font size-14">{getDictionaryValue(gameRecord, gamelogSupportingStats[stat])}</td>
                                                        )
                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="betting-leaderbaord-pagination-row">
                                <div className="betting-leaderbaord-pagination-buttons-container">
                                    <div className='betting-leaderboard-pagination-button'>
                                        <Button icon='assets/chevron-left.svg' type='secondary' enabled={this.state.leftComparablePage > 0} onClick={() => this.leftComparablePageChanged({currentTarget: {dataset: {change: -1}}})}/>
                                    </div>
                                    <div className='betting-leaderboard-pagination-button'>
                                        <Button icon='assets/chevron-right.svg' type='secondary' enabled={this.state.leftComparablePage < ((this.state.historicalGames.length/5) - 1)} onClick={() => this.leftComparablePageChanged({currentTarget: {dataset: {change: +1}}})}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            {
                this.state.filtersDrawerOpen ?
                <Drawer open={this.state.filtersDrawerOpen} closeHandler={this.closeFiltersDrawer} applyHandler={this.closeFiltersDrawer}>
                    {
                        this.gameID ? 
                        <>
                            <Picker options={this.state.pickerOptions} selection={this.state.teamCode} selectionHandlerFn={this.pickerSelected} fullWidth={true}/>
                            <div style={{width: '100%', height: '16px'}}></div>
                        </>
                        :
                        <></>
                        // <Filters filters={_.pick(this.state.filters[this.state.tab], TEAM_FILTER)} addClickableElementHandler={() => {}} selectionHandler={this.filtersUpdated} />
                    }
                    {/* { this.state.tab === "player" ? <Filters filters={_.pick(this.state.filters.player, PLAYER_FILTER)} addClickableElementHandler={() => {}} selectionHandler={this.filtersUpdated} /> : <></> } */}
                    {/* <Filters filters={filtersToUse} addClickableElementHandler={() => {}} selectionHandler={this.filtersUpdated} />  */}
                    {
                        Object.keys(filtersToUse).map((filter) => 
                            <div key={filter} className='betting-content-card-filters-wrapper-filter' style={{'--filters-count': Object.keys(filtersToUse).length}}>
                                <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                                <div>
                                    <InputSelection 
                                        enabled={true} 
                                        type="single" 
                                        typography="md" 
                                        options={filtersToUse[filter].options} 
                                        selection={filtersToUse[filter].selectedValue} 
                                        selectionHandler={(selection) => this.filtersUpdated({currentTarget: {dataset: {name: filter, selection: selection}}})}
                                    />
                                </div>
                            </div>
                        )
                    }
                </Drawer>
                :
                <></>
            }
        </div>
        <Legend legendCloseHandler={this.closeLegend} open={this.state.legendOpen}>
            {
                BETTING_TOOL_LEGEND.map((section, index) =>
                    <>
                    {
                        <>
                        <p className="font size-12 spaced full-width" style={{marginBottom: '8px'}}>{section.title}</p>
                        <div className="stats-legend-content-half-container">
                            {
                                section.entries.slice(0, section.entries.length / 2).map((entry) =>
                                <>
                                {
                                    section.type === "heading" ? 
                                    <>
                                        <p className="font size-14 weight-600 full-width display-block" style={{marginBottom: '4px'}}>{entry.key}</p>
                                        <p className="font size-14 full-width display-block" style={{marginBottom: '12px'}}>{entry.value}</p>
                                    </>
                                    :
                                    <>
                                        <p className="font size-14 weight-600"  style={{marginBottom: '12px'}}>{entry.key}</p>:&nbsp;
                                        <p className="font size-14"  style={{marginBottom: '12px'}}>{entry.value}</p><br/>
                                    </>
                                }
                                </>
                                )
                            }
                        </div>
                        <div className="stats-legend-content-half-container">
                            {
                                section.entries.slice(section.entries.length / 2, section.entries.length).map((entry) =>
                                <>
                                {
                                    section.type === "heading" ? 
                                    <>
                                        <p className="font size-14 weight-600 full-width display-block" style={{marginBottom: '4px'}}>{entry.key}</p>
                                        <p className="font size-14 full-width display-block" style={{marginBottom: '12px'}}>{entry.value}</p>
                                    </>
                                    :
                                    <>
                                        <p className="font size-14 weight-600"  style={{marginBottom: '12px'}}>{entry.key}</p>:&nbsp;
                                        <p className="font size-14"  style={{marginBottom: '12px'}}>{entry.value}</p><br/>
                                    </>
                                }
                                </>
                                )
                            }
                        </div>
                        {/* Divider */}
                        <div style={index === BETTING_TOOL_LEGEND.length - 1 ? {display: 'none'} : {background: '#D1D5DB', height: '1px', borderRadius: '2px', marginTop: '12px', marginBottom: '12px'}}></div>
                        </>
                    }
                    </>
                )
            }
        </Legend>
        </>
    );
  }
}


export default withRouter(BettingPage);
