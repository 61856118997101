import React from 'react';

import './Pricing.scss'
import '../components/App.scss'

import GlobalContext from '../components/GlobalContext';
import Button from '../components/Button';
import { isDictEmpty } from '../linemate-react-common/src/util';
import { getStripeCheckoutSessionUrl, getTheme, openStripeBillingPortal } from '../react-web-utils';
import { API_HOST } from '../react-web-constants';

const PLANS = [
    {
        title: 'Free',
        price: 0,
        billing: null,
        features: [
            'Access to single trends',
            'Access to the prop tool',
            'Access to the team and player profiles'
        ],
        action: (user, userAttributes, cookies) => {
            if (isDictEmpty(userAttributes) || userAttributes.accessLevel === 1) {
                return {
                    text: 'Current plan',
                    enabled: false,
                    onClick: null,
                    type: 'secondary'
                }
            }
            return {
                text: 'Change plan',
                enabled: !userAttributes.purchasePlatform || userAttributes.purchasePlatform === "web",
                onClick: () => user.getIdToken().then((token) => openStripeBillingPortal(API_HOST, token)),
                type: 'secondary'
            }
        }
    },
    {
        title: 'Monthly',
        price: 9.99,
        billing: {
            short: 'month',
            long: 'monthly'
        },
        features: [
            'Everything in Free, plus...',
            'Access to combo trends',
            'Access to parlay trends',
            'Access to premium features and filters',
            'Available on the web and app'
        ],
        action: (user, userAttributes, cookies) => {
            if (isDictEmpty(userAttributes)) {
                return {
                    text: 'Start free trial',
                    enabled: true,
                    onClick: () => window.location.href = 'signup?origin=/pricing',
                    type: 'secondary'
                }
            }
            if (userAttributes.accessLevel === 3) {
                return {
                    text: 'Manage',
                    enabled: !userAttributes.purchasePlatform || userAttributes.purchasePlatform === "web",
                    onClick: () => user.getIdToken().then((token) => openStripeBillingPortal(API_HOST, token)),
                    type: 'secondary'
                }
            }
            return {
                text: 'Upgrade',
                enabled: true,
                onClick: () => {
                    user.getIdToken().then((token) => {
                        getStripeCheckoutSessionUrl(
                            API_HOST, 
                            token, 
                            '1PI0fdFA68iq99Lmw7GwKjG4', 
                            null, 
                            (checkoutSessionUrl) => {
                                window.location.href = checkoutSessionUrl
                            }, 
                            cookies.get('_fprom_tid')
                        )
                    })
                },
                type: 'secondary'
            }
        }
    },
    {
        title: 'Annual',
        price: 119.99,
        discountPercentage: 33.3333,
        discountMessage: 'BEST VALUE',
        billing: {
            short: 'year',
            long: 'annually'
        },
        features: [
            'Everything in Free, plus...',
            'Access to combo trends',
            'Access to parlay trends',
            'Access to premium features and filters',
            'Available on the web and app'
        ],
        highlighted: true,
        action: (user, userAttributes, cookies) => {
            if (isDictEmpty(userAttributes)) {
                return {
                    text: 'Start free trial',
                    enabled: true,
                    onClick: () => window.location.href = 'signup?origin=/pricing',
                    type: 'primary'
                }
            }
            if (userAttributes.accessLevel === 3) {
                return {
                    text: 'Manage',
                    enabled: !userAttributes.purchasePlatform || userAttributes.purchasePlatform === "web",
                    onClick: () => user.getIdToken().then((token) => openStripeBillingPortal(API_HOST, token)),
                    type: 'secondary'
                }
            }
            return {
                text: 'Upgrade',
                enabled: true,
                onClick: () => {
                    user.getIdToken().then((token) => {
                        getStripeCheckoutSessionUrl(
                            API_HOST, 
                            token, 
                            '1PuELgFA68iq99LmFawZGrz2', 
                            null, 
                            (checkoutSessionUrl) => {
                                window.location.href = checkoutSessionUrl
                            },
                            cookies.get('_fprom_tid')
                        )
                    })
                },
                type: 'primary'
            }
        }
    }
]

export const SHOWCASE_FEATURES = [
    {
        title: 'Combo Trends',
        subtitle: 'Access plays with trending badges, backed by more than 1 statistical edge.',
        image: `assets/${getTheme()}-combo-showcase.png`
    },
    {
        title: 'Parlay Trends',
        subtitle: 'Unlock the same trends-based research, but now for parlays.',
        image: `assets/${getTheme()}-parlay-showcase.png`
    },
    {
        title: 'Premium Filters & Features',
        subtitle: 'Gain access to with/without filters, trends impacted by player injuries, and more.',
        image: `assets/${getTheme()}-injury-showcase.png`
    }
]

// TODO: origin  
class Pricing extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.planPickerOptions = {
            free: 'Free',
            paid: 'Linemate+'
        }

        this.state = {
            planPickerSelection: Object.keys(this.planPickerOptions)[1]
        }
    }

    componentDidMount() {
        document.title = 'Pricing - Linemate';
    }

    render() {
        return (
            <div className='pricing-page-container'>
                <div className='pricing-page-intro'>
                    <div className='pricing-page-intro-header'>
                        <p className='text-style-label-semibold color-fig-accent'>SAVE TIME, PLAY SMARTER</p>
                        <p className='color-fig-default'>Pricing</p>
                        <p className='text-style-h-3-normal color-fig-subtle'>Upgrade to gain access to more trends and save time on your research. Start your 7-day free trial of Linemate+.</p>
                    </div>
                    <div className='pricing-page-intro-plans'>
                    {
                        PLANS.map((plan) =>{
                            const planAction = plan.action(this.context.user, this.context.userAttributes, this.context.cookies);
                            return (
                                <div className='pricing-page-intro-plans-plan' style={{'--plan-options-count': PLANS.length}} data-highlighted={plan.highlighted}>
                                    <div className='pricing-page-intro-plans-plan-title'>
                                        <p className='text-style-h-3-medium color-fig-subtle'>{plan.title}</p>
                                        {/* <div>
                                            {plan.discountMessage && (<p className='text-style-caption-medium color-fig-accent'>{plan.discountMessage}</p>)}
                                        </div> */}
                                    </div>
                                    <div className='pricing-page-intro-plans-plan-pricing' data-discounted={!!plan.discountPercentage}>
                                        {
                                            !plan.discountPercentage && (
                                                <>
                                                    <p className='text-style-h-3-semibold color-fig-default'>USD ${plan.price}</p>
                                                    {plan.billing && (<p className='text-style-h-3-semibold color-fig-default'>/{plan.billing.short}</p>)}
                                                </>
                                            )
                                        }
                                        {
                                            plan.discountPercentage && (
                                                <div className='pricing-page-intro-plans-plan-pricing-discounted'>
                                                    <div>
                                                        <p className='text-style-h-3-semibold color-fig-default'>USD ${(plan.price * ((100-plan.discountPercentage)/100)).toFixed(2)}/{plan.billing.short}</p>
                                                        <div className='pricing-page-intro-plans-plan-pricing-discounted-badge'>
                                                            <p className='text-style-caption-uppercase-semibold'>-{plan.discountPercentage.toFixed(1)}%</p>
                                                        </div>
                                                    </div>
                                                    <p className='text-style-h-3-semibold color-fig-subtle'><s>USD ${plan.price}</s></p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='pricing-page-intro-plans-plan-divider'></div>
                                    <div className='pricing-page-intro-plans-plan-features'>
                                    {
                                        plan.features.map((feature) =>
                                            <div className='pricing-page-intro-plans-plan-features-feature'>
                                                <img src='assets/check.svg' alt="check"/>
                                                <p className='text-style-label-normal color-fig-default'>{feature}</p>
                                            </div>
                                        )
                                    }
                                    </div>
                                    <div className='pricing-page-intro-plans-plan-button-wrapper'>
                                        <Button text={planAction.text} typography="xl" type={planAction.type} enabled={planAction.enabled} onClick={planAction.onClick}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    {
                        this.context.userAttributes.accessLevel === 3 &&
                        this.context.userAttributes.purchasePlatform && 
                        this.context.userAttributes.purchasePlatform === "ios" && (
                            <p className='text-style-body-semibold color-fig-default'>To manage your subscription please visit the Subscriptions section of your Apple account associated with this purchase</p>
                        )
                    }
                    {
                        this.context.userAttributes.accessLevel === 3 &&
                        this.context.userAttributes.purchasePlatform && 
                        this.context.userAttributes.purchasePlatform === "android" && (
                            <p className='text-style-body-semibold color-fig-default'>To manage your subscription please visit the Subscriptions section of your Google Play Store account associated with this purchase</p>
                        )
                    }
                </div>
                <div className='pricing-page-upgrade-showcase'>
                    <p className='text-style-h-1-semibold color-fig-default'>Upgrade your trends with Linemate+</p>
                    <p className='text-style-h-3-normal color-fig-subtle'>Unlocked access to daily plays with multiple merging trends. Time is money and we want you in a scoring position.</p>
                    {/* Copied on landing */}
                    <div className='pricing-page-upgrade-showcase-features'>
                        {
                            SHOWCASE_FEATURES.map((feature) =>
                                <div key={feature.title} className='pricing-page-upgrade-showcase-feature'>
                                    <div className='pricing-page-upgrade-showcase-feature-image'>
                                        <img src={feature.image} alt={feature.title}/>
                                    </div>
                                    <div className='pricing-page-upgrade-showcase-feature-text'>
                                        <p className='text-style-body-semibold color-fig-default'>{feature.title}</p>
                                        <p className='text-style-label-normal color-fig-subtle'>{feature.subtitle}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default Pricing;