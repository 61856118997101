import React from 'react';

import './AffiliatesList.scss'
import './App.scss'
import { base64Encode, getRegionAffiliateTitle, hasPremiumAccess } from '../linemate-react-common/src/util';

import GlobalContext from './GlobalContext';
import Button from './Button';
import Dialog from './Dialog';
import { getTheme } from '../react-web-utils';

class AffiliatesList extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            affiliateDisclaimerDialogOpen: false
        }
    }

    render() {
        const hasAffiliates = this.context.location.region.code && this.context.location.region.code in this.context.configuration.metadata.sportsbookAffiliates;
        if (!hasAffiliates) {
            return (<></>)
        }
        {/* Is this supposed to be fixed? */}
        return (
            <div className='affiliates-sidebar'>
                {
                    !hasPremiumAccess(this.context.userAttributes) && (
                        <a className='affiliates-sidebar-linemate-plus-ad clickable unselectable' href={`/pricing?origin=${window.location.pathname}`}>
                            <img src={`assets/linemate-plus-affiliate-${getTheme()}.png`} alt="lm+"/>
                            <div className='affiliates-sidebar-linemate-plus-ad-text'>
                                <p className='text-style-label-semibold color-fig-default'>Find your next bet with Linemate+</p>
                                <p className='text-style-caption-normal color-fig-subtle'>Get parlay trends, combo trends, and more. Starting from $9.99/month.</p>
                            </div>
                        </a>
                    )
                }
                <div className='affiliates-sidebar-header'>
                    <p className='text-style-h-3-medium color-fig-default'>{getRegionAffiliateTitle(this.context.location.region.code, this.context.location.region.name)}</p>
                    {
                        this.props.onClose && (
                            <img src='assets/close-icon.svg' alt='close' onClick={this.props.onClose}/>
                        )
                    }
                </div>
                {
                    Object.keys(this.context.configuration.metadata.sportsbookAffiliates[this.context.location.region.code]).map((book) => {
                        const regionBookEntry = this.context.configuration.metadata.sportsbookAffiliates[this.context.location.region.code][book];
                        const offer = regionBookEntry.offers[0];
                        const image = regionBookEntry.image;
                        return (
                            <div key={book} className='affiliates-sidebar-book'>
                                <div className='affiliates-sidebar-book-heading'>
                                    <img src={`assets/sportsbooks/${image}.svg`} alt={book}/>
                                    <p className='text-style-body-semibold color-fig-default'>{book}</p>
                                </div>
                                <p className='text-style-label-normal color-fig-default'>{offer.title}</p>
                                <div className='affiliates-sidebar-book-action-button'>
                                    <Button text={offer.actionText} typography="sm" type="secondary" enabled={true} onClick={() => {window.open(`/affiliate?link=${base64Encode(offer.link)}`, '_blank').focus();}}/>
                                </div>
                                {
                                    offer.expands && (
                                        <Dialog
                                            open={this.state.affiliateDisclaimerDialogOpen}
                                            onOpenChange={(open) => this.setState({affiliateDisclaimerDialogOpen: open})}
                                            trigger={
                                                <p className='text-style-caption-normal color-fig-subtle affiliates-sidebar-book-clickable-disclaimer' onClick={() => this.setState({affiliateDisclaimerDialogOpen: true})}>{offer.shortDetails}</p>
                                            }
                                            title={`${book} Disclaimer`}
                                            content={
                                                <div className='affiliates-sidebar-book-dialog-content'>
                                                <p className='text-style-label-normal color-fig-default'>{offer.details}</p>
                                                </div>
                                            }
                                            footer={
                                                <div className='affiliates-sidebar-book-dialog-footer'>
                                                <div>
                                                    <Button text="Close" typography="md" type="primary" enabled={true} onClick={() => this.setState({affiliateDisclaimerDialogOpen: false})}/>
                                                </div>
                                                </div>
                                            }
                                        />
                                    )
                                }
                                {
                                    !offer.expands && (
                                        <p className='text-style-caption-normal color-fig-subtle'>{offer.details}</p>
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default AffiliatesList;