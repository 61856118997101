import React from 'react';
import {getDictionaryValue, decimalPrecision, getPlayerBirthplace, getPlayerWeight} from './linemate-react-common/src/util.js';
import {getPlayerImage, getTeamLogoPath, getTheme} from './react-web-utils.js';
import {TEAM_COLOR_CODES, TEAM_CODE_TO_NAME, TEAM_CODE_TO_CITY} from './linemate-react-common/src/constants.js';
import { ResponsiveRadar } from '@nivo/radar';
import { getPlayerFullDisplayName } from './linemate-react-common/src/util.js';

import './fonts.css';
import './generic.css';
import './buttons.css';
import './profile-modal.css';

class PlayerProfileModal extends React.Component {
    constructor(props) {
        super(props);

        this.getSnowflakeData = this.getSnowflakeData.bind(this);
        this.backgroundClicked = this.backgroundClicked.bind(this);
        this.redirectToScreener = this.redirectToScreener.bind(this);
    }

    getSnowflakeData() {
        var snowflakeData = [];
        Object.keys(this.props.stats).map((key) => {
            const leadingPlayer = getDictionaryValue(this.props.leaders, this.props.stats[key])[0];
            const leadingValue = getDictionaryValue(leadingPlayer, this.props.stats[key]);
            const currentPlayerValue = getDictionaryValue(this.props.player, this.props.stats[key]);
            var snowflakeEntry = {};
            snowflakeEntry["stat"] = key;
            if (leadingValue === 0) {
                snowflakeEntry[getPlayerFullDisplayName(this.props.player.info)] = 0;
            } else {
                snowflakeEntry[getPlayerFullDisplayName(this.props.player.info)] = parseFloat(decimalPrecision((currentPlayerValue/leadingValue) * 100, 1));
                // TODO: we need a solution for ascending ordering stats
                // For the time being we only have GAA (since we flipped the rest), we'll put an exception for it
                // We have ordered GAA by DESC in the back-end, allowing us to use the same division but flipping it (100 - x)
                if (key.toUpperCase() === "GAA") {
                    snowflakeEntry[getPlayerFullDisplayName(this.props.player.info)] = 100 - snowflakeEntry[getPlayerFullDisplayName(this.props.player.info)];
                }
            }
            snowflakeData.push(snowflakeEntry);
        });
        return snowflakeData;
      }
    
      backgroundClicked(event) {
        if (event.target === event.currentTarget) {
            this.props.closeProfileFn();
        }
      }
    
      redirectToScreener() {
        window.location.href = `${this.props.activeLeague}/screener?tab=player&team=${this.props.player.teamCode}&player=${this.props.player.SRGUID}`;
      }
    
      render() {
        const snowflakeData = this.getSnowflakeData();
        return(
            <>
                <div class="profile-modal-modal-container">
                    <div class="profile-modal-modal-header">
                        <div class="text-lg-semi">Player Profile</div>
                        <div class="close-modal clickable" onClick={this.props.closeProfileFn}>
                            <img src="assets/close-major.svg" width="16" height="16" alt=""/>
                        </div>
                    </div>
                    <div class="profile-modal-modal-content">
                        <div class="profile-modal-info">
                            <div class="profile-modal-team-profile-card">
                                <div class="profile-modal-team-logo">
                                    {
                                        getPlayerImage(this.props.player, 80, 30)
                                    }
                                    <img class="team-badge rounded-icon" style={{top: '55px', left: '50px', backgroundColor: '#FFFFFF', padding: '3px'}} src={getTeamLogoPath(this.props.activeLeague, this.props.player.teamCode)} height={32} width={32} alt=""/>
                                </div>
                                <div>
                                    <div class="text-xl-bold">{getPlayerFullDisplayName(this.props.player.info)}</div>
                                    <div class="text-sm">
                                        {`#${this.props.player.info.number}`}
                                        <span class="profile-modal-spacer">&nbsp;|&nbsp;</span> 
                                        {this.props.player.info.position} 
                                        <span class="profile-modal-spacer">&nbsp;|&nbsp;</span> 
                                        {`${TEAM_CODE_TO_CITY[this.props.activeLeague][this.props.player.teamCode]} ${TEAM_CODE_TO_NAME[this.props.activeLeague][this.props.player.teamCode]}`} 
                                    </div>
                                </div>
                            </div>
                            <div class="profile-modal-player-info text-xs">
                                <div class="profile-modal-player-info-row">
                                    <div><span class="text-xs-semi">Age:</span>&nbsp;{this.props.player.info.age}</div>
                                    <div><span class="text-xs-semi">Birthplace:</span>&nbsp;{getPlayerBirthplace(this.props.player)}</div>
                                </div>
                                <div class="profile-modal-player-info-row" style={{marginTop: '4px'}}>
                                    <div><span class="text-xs-semi">Height:</span>&nbsp;{this.props.player.info.height}</div>
                                    <div><span class="text-xs-semi">Weight:</span>&nbsp;{getPlayerWeight(this.props.player.info)}</div>
                                    {/* <div><span class="text-xs-semi">Shoots:</span>&nbsp;Right</div> */}
                                </div>
                            </div>
                            <div class="profile-modal-stats">
                                <div class="profile-modal-stats-header text-lg-semi">Stats</div>
                                <div class="profile-modal-stats-section text-sm">
                                    {
                                        Object.keys(this.props.stats).map((statName, index) => 
                                        <>
                                            {
                                                // Skipping the odd indicies to grab 2 at a time to make up the row
                                                // We could use statName for one of the entries but to stay consisten we'll grab both via the index
                                                (index % 2 == 1) ? <></> : 
                                                <div class="profile-modal-row">
                                                    <div class="profile-modal-category profile-modal-category-spacing ">
                                                        <div class="font size-14 dark">{Object.keys(this.props.stats)[index]}</div>
                                                        <div class="font size-14 weight-600 dark">{getDictionaryValue(this.props.player,this.props.stats[Object.keys(this.props.stats)[index]])}</div>
                                                    </div>
                                                    {
                                                        (index + 1) >= Object.keys(this.props.stats).length ? 
                                                        <div class="profile-modal-category">
                                                        </div> 
                                                        :
                                                        <div class="profile-modal-category">
                                                            <div class="font size-14 dark">{Object.keys(this.props.stats)[index + 1]}</div>
                                                            <div class="font size-14 weight-600 dark">{getDictionaryValue(this.props.player,this.props.stats[Object.keys(this.props.stats)[index + 1]])}</div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="profile-modal-graph">
                            <ResponsiveRadar
                                data={snowflakeData}
                                keys={[getPlayerFullDisplayName(this.props.player.info)]}
                                indexBy="stat"
                                maxValue={100}
                                margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
                                curve="linearClosed"
                                borderWidth={2}
                                borderColor={{ from: 'color' }}
                                gridLevels={5}
                                gridShape="linear"
                                gridLabelOffset={16}
                                enableDots={true}
                                dotSize={8}
                                dotColor={{ theme: 'background' }}
                                dotBorderWidth={2}
                                dotBorderColor={{ from: 'color' }}
                                enableDotLabel={false}
                                dotLabel="value"
                                dotLabelYOffset={-12}
                                colors={[TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.player.teamCode.toLowerCase()()]]}
                                fillOpacity={0.25}
                                blendMode="multiply"
                                animate={true}
                                motionConfig="wobbly"
                                isInteractive={false}
                                legends={[]}
                            />
                        </div>
                    </div>
                    <div class="profile-modal-modal-footer">
                        <button class="btn-action-secondary profile-modal-btn-action-secondary modal-btn" onClick={this.props.closeProfileFn}>Close</button>
                        <button class="btn-action-primary profile-modal-btn-action-primary modal-btn" onClick={this.redirectToScreener}>View Screener</button>
                    </div>
                </div>
                <div class="profile-modal-overlay" onClick={this.backgroundClicked}></div>
            </>
        );
      }
}

export default PlayerProfileModal;
