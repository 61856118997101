import React from 'react';
import {getDictionaryValue, decimalPrecision, getTeamDisplayName, isSoccer} from './linemate-react-common/src/util.js';
import {TEAM_COLOR_CODES} from './linemate-react-common/src/constants.js';
import { ResponsiveRadar } from '@nivo/radar';

import './fonts.css';
import './generic.css';
import './buttons.css';
import './profile-modal.css';
import { getTeamLogoPath, getTheme } from './react-web-utils.js';

class TeamProfileModal extends React.Component {
  constructor(props) {
    super(props);

    this.getSnowflakeData = this.getSnowflakeData.bind(this);
    this.backgroundClicked = this.backgroundClicked.bind(this);
    this.redirectToScreener = this.redirectToScreener.bind(this);
  }

  getSnowflakeData() {
    var snowflakeData = [];
    var snowflakeMaximums = {};
    this.props.allStandings.forEach((teamRecord) => {
        Object.keys(this.props.stats).map((key) => {
            const teamStatValue = parseFloat(getDictionaryValue(teamRecord, this.props.stats[key]));
            // If the entry is missing or its value is lower than the current one, set the current as max
            if (!(key in snowflakeMaximums) || teamStatValue > snowflakeMaximums[key]) {
                snowflakeMaximums[key] = teamStatValue;
            }
        });
    });
    Object.keys(this.props.stats).map((key) => {
        const leader = snowflakeMaximums[key];
        const teamValue = getDictionaryValue(this.props.team, this.props.stats[key]);
        var snowflakeEntry = {};
        snowflakeEntry["stat"] = key;
        if (leader === 0) {
            snowflakeEntry[getTeamDisplayName(this.props.activeLeague, this.props.team)] = 0;
        } else {
            snowflakeEntry[getTeamDisplayName(this.props.activeLeague, this.props.team)] = decimalPrecision((teamValue/leader) * 100, 1);
        }
        snowflakeData.push(snowflakeEntry);
    });
    return snowflakeData;
  }

  backgroundClicked(event) {
    if (event.target === event.currentTarget) {
        this.props.closeProfileFn();
    }
  }

  redirectToScreener() {
    window.location.href = `${this.props.activeLeague}/screener?team=${this.props.team.code}`;
  }

  render() {
    const snowflakeData = this.getSnowflakeData();
    return(
        <>
            <div class="profile-modal-modal-container">
                <div class="profile-modal-modal-header">
                    <div class="text-lg-semi">Team Profile</div>
                    <div class="close-modal clickable" onClick={this.props.closeProfileFn}>
                        <img src="assets/close-major.svg" width="16" height="16" alt=""/>
                    </div>
                </div>
                <div class="profile-modal-modal-content">
                    <div class="profile-modal-info">
                        <div class="profile-modal-team-profile-card">
                            <div class="profile-modal-team-logo">
                                <img src={getTeamLogoPath(this.props.activeLeague, this.props.team.code)} height={80} width={80} alt=""/>
                            </div>
                            <div>
                                <div class="text-xl-bold">{getTeamDisplayName(this.props.activeLeague, this.props.team)}</div>
                                {
                                    isSoccer(this.props.activeLeague) ? <></> :
                                    // {/* League is for MLB */}
                                    <div class="text-sm">{this.props.team.standings.division}  <span class="profile-modal-spacer">|</span> {this.props.team.standings.conference || this.props.team.standings.league} </div>
                                }
                            </div>
                        </div>
                        <div class="profile-modal-team-info text-xs">
                            <div>
                                <div><span class="text-xs-semi">Home Venue: </span>&nbsp;{this.props.team.venue.name}</div>
                            </div>
                        </div>
                        <div class="profile-modal-stats">
                            <div class="profile-modal-stats-header text-lg-semi">Stats</div>
                            <div class="profile-modal-stats-section text-sm">
                                {
                                    Object.keys(this.props.stats).map((statName, index) => 
                                    <>
                                        {
                                            // Skipping the odd indicies to grab 2 at a time to make up the row
                                            // We could use statName for one of the entries but to stay consisten we'll grab both via the index
                                            (index % 2 == 1) ? <></> : 
                                            <div class="profile-modal-row">
                                                <div class="profile-modal-category profile-modal-category-spacing ">
                                                    <div class="font size-14 dark">{Object.keys(this.props.stats)[index]}</div>
                                                    <div class="font size-14 weight-600 dark">{getDictionaryValue(this.props.team,this.props.stats[Object.keys(this.props.stats)[index]])}</div>
                                                </div>
                                                {
                                                    (index + 1) >= Object.keys(this.props.stats).length ? 
                                                    <div class="profile-modal-category">
                                                    </div> 
                                                    :
                                                    <div class="profile-modal-category">
                                                        <div class="font size-14 dark">{Object.keys(this.props.stats)[index + 1]}</div>
                                                        <div class="font size-14 weight-600 dark">{getDictionaryValue(this.props.team,this.props.stats[Object.keys(this.props.stats)[index + 1]])}</div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div class="profile-modal-graph">
                        <ResponsiveRadar
                            data={snowflakeData}
                            keys={[getTeamDisplayName(this.props.activeLeague, this.props.team)]}
                            indexBy="stat"
                            maxValue={100}
                            margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={16}
                            enableDots={true}
                            dotSize={8}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={false}
                            dotLabel="value"
                            dotLabelYOffset={-12}
                            colors={[TEAM_COLOR_CODES[getTheme()][this.props.activeLeague][this.props.team.code.toLowerCase()]]}
                            fillOpacity={0.25}
                            blendMode="multiply"
                            animate={true}
                            motionConfig="wobbly"
                            isInteractive={false}
                            legends={[]}
                        />
                    </div>
                </div>
                <div class="profile-modal-modal-footer">
                    <button class="btn-action-secondary profile-modal-btn-action-secondary modal-btn" onClick={this.props.closeProfileFn}>Close</button>
                    <button class="btn-action-primary profile-modal-btn-action-primary modal-btn" onClick={this.redirectToScreener}>View Team Screener</button>
                </div>
            </div>
            <div class="profile-modal-overlay" onClick={this.backgroundClicked}></div>
        </>
    );
  }
}

export default TeamProfileModal;
